import "./MinimalisticTextAreaDescriptionComponent.scss";

interface ITextAreaDescriptionComponent {
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  hasPermissionToEdit: boolean;
}

const TextAreaDescriptionComponent = ({
  setDescription,
  description,
  hasPermissionToEdit,
}: ITextAreaDescriptionComponent) => {
  return (
    <div>
      <div className={"common-components__time-entry-description"}>
        Description
      </div>
      {hasPermissionToEdit ? (
        <textarea
          className={"common-components__time-entry-description-textarea"}
          placeholder={"Enter your description here"}
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />
      ) : (
        <div className={"common-components__time-entry-description-textarea"}>
          {description}
        </div>
      )}
    </div>
  );
};

export default TextAreaDescriptionComponent;
