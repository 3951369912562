import "./ProjectNewTaskButton.scss";
import { newTaskRoute, projectsRoute } from "constants/Routes";
import { Link } from "react-router-dom";

interface INewTaskButton {
  projectId: number;
}

const ProjectNewTaskButton = ({ projectId }: INewTaskButton) => {
  return (
    <Link to={`/${projectsRoute}/${projectId}/${newTaskRoute}`}>
      <div className={"project-page__new-task-button"}>Create task</div>
    </Link>
  );
};

export default ProjectNewTaskButton;
