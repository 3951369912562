import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import type { RootState } from "../store";

export interface ITimeEntryNewTasksState {
  task: IMyTaskVm | null;
}

const initialState: ITimeEntryNewTasksState = {
  task: null,
};

export const newTimeEntryTasksSlice = createSlice({
  name: "timeEntriesFilters",
  initialState,
  reducers: {
    modifyTasksStateForNewTimeEntry: (
      state,
      action: PayloadAction<IMyTaskVm | null>
    ) => {
      state.task = action.payload;
    },
    resetInitialValue: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
  },
});

export const { modifyTasksStateForNewTimeEntry } =
  newTimeEntryTasksSlice.actions;

export const selectNewTimeEntriesTasks = (state: RootState) => state;

export default newTimeEntryTasksSlice.reducer;
