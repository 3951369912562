import { FormStatuses } from "interfaces/Enums/FormsStatuses";
import NewItemIcon from "public/NewItemIcon.svg";
import YellowInfoIcon from "public/FormStatsesInfoIcons/YellowInfoIcon.svg";
import GreenInfoIcon from "public/FormStatsesInfoIcons/GreenInfoIcon.svg";
import "./FormStatusBar.scss";
import { blueText, grayText, greenText, yellowText } from "constants/JsColors";
interface IFormStatusBar {
  status: FormStatuses;
}

export interface IUpdateDeviationReduxStatus {
  formLinkId: number;
  status: "NEW" | "IN PROGRESS" | "COMPLETED" | "VOID";
}

const FormStatusBar = ({ status }: IFormStatusBar) => {
  const getAppropriateComponent = (status: FormStatuses) => {
    switch (status ? status.toLowerCase().replace(/ /g, "") : "") {
      case FormStatuses.NEW.toLowerCase().replace(/ /g, ""): {
        return {
          text: FormStatuses.NEW,
          icon: NewItemIcon,
          textColor: blueText,
        };
      }
      case FormStatuses["IN PROGRESS"].toLowerCase().replace(/ /g, ""): {
        return {
          text: FormStatuses["IN PROGRESS"],
          icon: YellowInfoIcon,
          textColor: yellowText,
        };
      }
      case FormStatuses.COMPLETED.toLowerCase().replace(/ /g, ""): {
        return {
          text: FormStatuses.COMPLETED,
          icon: GreenInfoIcon,
          textColor: greenText,
        };
      }
      default: {
        return {
          text: FormStatuses.VOID.toLowerCase().replace(/ /g, ""),
          icon: NewItemIcon,
          textColor: grayText,
        };
      }
    }
  };

  return (
    <div className="common-components__form-status-bar">
      <div className="common-components__form-status-bar-left">
        <img src={getAppropriateComponent(status).icon} alt={"Info icon"} />
        <span style={{ color: getAppropriateComponent(status).textColor }}>
          {getAppropriateComponent(status).text}
        </span>
      </div>
    </div>
  );
};

export default FormStatusBar;
