import DropdownWithLabel from "components/common/SingleInputsWithLabel/DropdownWithLabel/DropdownWithLabel";
import "./CreateChecklistsPageMain.scss";
import Select from "react-select";
import { dropdownStyles } from "styles/DropdownStyles";
import { ILabelValueStructure } from "interfaces/Other/ILabelValueStructure";
import { IFormsTemplatesVm as ITaskFormsTemplateVm } from "interfaces/ViewModels/MyFormsViewModels/ITaskFormsTemplatesVm";
import { convertToSelectOptions } from "helpers/General/ConvertToSelectOptions";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import { useSelector } from "react-redux";
import {
  IMyUserVmMerged,
  mergeMembersCredentials,
} from "helpers/General/MergeMembersCredentials";
import useAuth0 from "Auth/use-auth0";
import { useEffect } from "react";

interface ICreateChecklistsPageMain {
  taskFormTemplates: ITaskFormsTemplateVm[];
  source: ILabelValueStructure | undefined;
  setSource: React.Dispatch<
    React.SetStateAction<ILabelValueStructure | undefined>
  >;
  setResponsibleUser: React.Dispatch<
    React.SetStateAction<ILabelValueStructure | undefined>
  >;
  responsibleUser: ILabelValueStructure | undefined;
}

const CreateChecklistsPageMain = ({
  taskFormTemplates,
  source,
  setSource,
  setResponsibleUser,
  responsibleUser,
}: ICreateChecklistsPageMain) => {
  const { user } = useAuth0();
  const globalStates = useSelector(chosenOrganization);
  const formsOptions: ILabelValueStructure[] = convertToSelectOptions(
    taskFormTemplates.map((item) => {
      return { id: item.id, name: item.name };
    })
  );

  const mergedNames: IMyUserVmMerged[] = mergeMembersCredentials(
    globalStates.chosenProjectReducer.projectMembers
  );
  const mappedProjectMembers = mergedNames.map((member) => {
    return { name: member.name, id: member.id };
  });
  const teamOptions = convertToSelectOptions(mappedProjectMembers);

  useEffect(() => {
    if (!responsibleUser) {
      setResponsibleUser(
        teamOptions.find((item) => item.label === user?.email)
      );
    }
  }, [teamOptions]);

  return (
    <main className="create-checklists-page__main-layout">
      <DropdownWithLabel
        label={"Responsible"}
        marginBottom={16}
        required
        SelectComponent={
          <Select
            styles={dropdownStyles}
            options={teamOptions}
            onChange={(option: ILabelValueStructure | null) =>
              option && setResponsibleUser(option)
            }
            value={responsibleUser}
          />
        }
      />
      <DropdownWithLabel
        label={"Checklist template"}
        marginBottom={16}
        required
        SelectComponent={
          <Select
            styles={dropdownStyles}
            options={formsOptions}
            onChange={(option: ILabelValueStructure | null) =>
              option && setSource(option)
            }
            value={source}
          />
        }
      />
    </main>
  );
};

export default CreateChecklistsPageMain;
