import { HttpRequestsMethods } from "../../interfaces/Enums/HttpRequestsMethods";
import { IMyProfileVm } from "../../interfaces/ViewModels/MyProjectsViewModels/IMyProfileVm";
import application from "../../config.json";
import { AxiosClient } from "./AxiosClient";

const base = application.baseUrl;
const MyProfile = "MyProfile";

const getUserProfileDetails = async (): Promise<IMyProfileVm> => {
  return AxiosClient(HttpRequestsMethods.GET, `${MyProfile}`, base);
};

const getUserProjectRoleId = async (projectId: number): Promise<number> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyProfile}/Project/${projectId}`,
    base
  );
};

export { getUserProfileDetails, getUserProjectRoleId };
