import { useState, useEffect } from "react";
import {
  HeadingAndHeaderHeight,
  FooterHeight,
} from "../../../constants/Consts";
import { IMyProjectVm } from "../../../interfaces/ViewModels/MyProjectsViewModels/IMyProjectVm";
import { IMyProjectsVm } from "../../../interfaces/ViewModels/MyTasksViewModels/IMyProjectsVm";
import FiltrationBar from "../../common/FiltrationBar/FiltrationBar";
import NoItemContainer from "../../common/NoItemContainer/NoItemContainer";
import "./ProjectsPageMain.scss";
import ProjectOverviewBar from "./ProjectOverviewBar/ProjectOverviewBar";
import InformationGrayIcon from "../../../public/InformationGrayIcon.svg";
import NoFiltersResultIcon from "../../../public/NoFiltersResultIcon.svg";
import ProjectsGrayIcon from "../../../public/ProjectsGrayIcon.svg";
import NewProjectButton from "./NewProjectButton/NewProjectButton";
import { IMyProfileVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProfileVm";
import { checkOrganizationAccess } from "helpers/Permission/CheckOrganizationAccess";
import { OrganizationRolesEnum } from "interfaces/Enums/OrganizationRolesEnum";

interface IMain {
  projects: IMyProjectsVm;
  userDetails: IMyProfileVm;
}

const ProjectsPageMain = ({ projects, userDetails }: IMain) => {
  const [offset, setOffset] = useState<number>(0);
  const [filtratedProjects, setFiltratedProjects] = useState<IMyProjectVm[]>(
    projects.myProjects
  );
  const [isFiltrationActive, setIsFiltrationActive] = useState<boolean>(false);
  const [filtrationInputValue, setFiltrationInputValue] = useState<string>("");

  const calculateWindowHeight = () => {
    const window = document.getElementById("root");
    if (window) {
      setOffset(window.offsetHeight - HeadingAndHeaderHeight + FooterHeight);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", calculateWindowHeight);
    calculateWindowHeight();
  });

  return (
    <div className={"projects-page-components__projects-main"}>
      <div>
        <div className={"projects-page-components__projects-main-heading"}>
          {projects.myProjects.length > 0 && (
            <>
              <div
                className={
                  "projects-page-components__projects-main-heading--wrapper"
                }
              >
                <img
                  src={InformationGrayIcon}
                  alt="Information gray icon"
                  width={20}
                  height={20}
                />
                <span className="projects-page-components__projects-main-heading--text">
                  Select a project to start
                </span>
              </div>
            </>
          )}
        </div>
        {projects.myProjects.length > 0 && (
          <FiltrationBar
            setFiltrationInputValue={setFiltrationInputValue}
            filtrationInputValue={filtrationInputValue}
            items={projects.myProjects}
            setFiltratedItems={setFiltratedProjects}
            setIsFiltrationActive={setIsFiltrationActive}
            filtrationMenu={null}
          />
        )}
      </div>
      {projects.myProjects.length > 0 ? (
        <div style={{ overflowY: "auto", height: offset }}>
          {isFiltrationActive && filtratedProjects.length === 0 ? (
            <div
              className={
                "projects-page-components__projects-main-no-filters-result"
              }
            >
              <img
                src={NoFiltersResultIcon}
                alt="No filters results"
                width={50}
                height={50}
              />
              <span
                className={
                  "projects-page-components__projects-main-no-filters-result-text"
                }
              >
                No results found
              </span>
              <span
                className={"projects-page-components__projects-main-info-text"}
              >
                Clear you filters and try again.
              </span>
              <div
                className={
                  "projects-page-components__projects-main-clear-filters-button"
                }
                onClick={() => {
                  setFiltrationInputValue("");
                  setIsFiltrationActive(false);
                  setFiltratedProjects(projects.myProjects);
                }}
              >
                clear filters
              </div>
            </div>
          ) : (
            <ProjectOverviewBar projects={filtratedProjects} />
          )}
        </div>
      ) : (
        <NoItemContainer
          imgSrc={ProjectsGrayIcon}
          imgAlt={"No projects"}
          imgHeight={50}
          imgWidth={50}
          secondText={
            "It looks like you don not have any assigned projects yet. Contact your manager for access"
          }
          firstText={"You have no assigned projects"}
          button={
            checkOrganizationAccess(
              userDetails.organizationRole,
              OrganizationRolesEnum.Manager
            ) ? (
              <NewProjectButton />
            ) : (
              <div />
            )
          }
        />
      )}
    </div>
  );
};

export default ProjectsPageMain;
