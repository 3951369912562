import useAuth0 from "Auth/use-auth0";

import { useEffect } from "react";
import { initializeGaTracker, logPageView } from "../../Analytics";

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();
  const activeCampaignScript = () => {
    return (
      <div>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            <script type="text/javascript">
            (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
            vgo('setAccount', '611510776');
            vgo('setTrackByDefault', true);
            
            vgo('setEmail', 'adres122@e.com');
            vgo('process');
            </script>
            `,
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    initializeGaTracker();
    activeCampaignScript();
    loginWithRedirect();
  });

  useEffect(() => {
    logPageView();
  }, [window.location.pathname, window.location.search]);

  return <div />;
};

export default LoginPage;
