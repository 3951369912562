import FiltrationBar from "components/common/FiltrationBar/FiltrationBar";
import {
  IMyUserVmMerged,
  mergeMembersCredentials,
} from "helpers/General/MergeMembersCredentials";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import { useState } from "react";
import ProjectTeamMember from "./ProjectTeamMember/ProjectTeamMember";
import "./ProjectTeamPageMain.scss";

interface IProjectTeamPageMain {
  projectMembers: IMyUserVm[];
}

const ProjectTeamPageMain = ({ projectMembers }: IProjectTeamPageMain) => {
  const mergedNames = mergeMembersCredentials(projectMembers);
  const [filtrationInputValue, setFiltrationInputValue] = useState<string>("");
  const [filtratedUsers, setFiltratedUsers] =
    useState<IMyUserVmMerged[]>(mergedNames);

  return (
    <main className="project-team-page-main__layout">
      <span className="project-team-page-main__title">Project team</span>
      <FiltrationBar
        setFiltrationInputValue={setFiltrationInputValue}
        filtrationInputValue={filtrationInputValue}
        items={mergedNames}
        setFiltratedItems={setFiltratedUsers}
        filtrationMenu={null}
      />
      <div className="project-team-page-main__members">
        {filtratedUsers.map((user) => {
          return (
            <ProjectTeamMember
              key={user.id}
              teamUser={user}
              projectMembers={projectMembers}
            />
          );
        })}
      </div>
    </main>
  );
};

export default ProjectTeamPageMain;
