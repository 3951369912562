import InputWithLabel from "one-common-components/lib/components/SingleInputsPack/InputWithLabel/InputWithLabel";
import "./NewProjectPageMain.scss";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ICreateProjectRequest } from "interfaces/Requests/ICreateNewProjectRequest";
import { checkIfStringOrNumberIsAnInteger } from "helpers/General/CheckIfStringOrNumberIsAnInteger";
import AsyncToast from "components/common/Toasts/AsyncToast";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { createProject } from "Pages/api/MyProjectsClient";
import { useSelector } from "react-redux";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import CancelSaveBottomBar from "components/common/Footers/CancelSaveBottomBar/CancelSaveBottomBar";

interface IFormInputs extends FieldValues {
  "Project name": string;
  Budget: string;
  "Estimated cost": string;
  Description: string;
}

const NewProjectPageMain = () => {
  const { register, getValues } = useForm<IFormInputs>();
  const navigate = useNavigate();
  const globalStates = useSelector(chosenOrganization);
  const onCancelClick = () => {
    navigate("/projects");
  };

  const createProjectRequestValidation = (): boolean | string => {
    if (getValues("Project name") === "") {
      return "Project name is required";
    }
    if (
      !checkIfStringOrNumberIsAnInteger(+getValues("Budget")) ||
      !checkIfStringOrNumberIsAnInteger(+getValues("Estimated cost"))
    ) {
      return "There are not valid numbers";
    }
    return (
      getValues("Project name") !== "" &&
      checkIfStringOrNumberIsAnInteger(+getValues("Budget")) &&
      checkIfStringOrNumberIsAnInteger(+getValues("Estimated cost"))
    );
  };

  const onSaveClick = async () => {
    const result = createProjectRequestValidation();
    if (result === true) {
      const request: ICreateProjectRequest = {
        name: getValues("Project name"),
        budget: +getValues("Budget"),
        cost: +getValues("Estimated cost"),
        description: getValues("Description"),
        organizationId:
          globalStates.chosenOrganizationReducer.chosenOrganizationId,
      };
      await AsyncToast(createProject(request), "Created project successfully");
      navigate("/projects");
    } else {
      SimpleToast({
        mode: ToastModes.info,
        message: result.toString(),
      });
    }
  };

  return (
    <>
      <div className="new-project-page__main">
        <InputWithLabel
          label={"Project name"}
          inputPlaceholder={"Enter project name"}
          register={register}
          required
        />
        <InputWithLabel
          label={"Budget"}
          inputPlaceholder={"Enter budget"}
          register={register}
          marginTop={24}
          type={"number"}
        />
        <InputWithLabel
          label={"Estimated cost"}
          inputPlaceholder={"Enter estimated cost"}
          register={register}
          marginTop={24}
          type={"number"}
        />
        <InputWithLabel
          label={"Description"}
          inputPlaceholder={"Enter project description..."}
          register={register}
          marginTop={24}
        />
      </div>
      <CancelSaveBottomBar
        onCancelClick={onCancelClick}
        onSaveClick={onSaveClick}
      />
    </>
  );
};

export default NewProjectPageMain;
