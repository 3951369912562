import { returnUsersCredentials } from "helpers/General/ReturnUserCredentials";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import UserAvatar from "one-common-components/lib/components/UserAvatar/UserAvatar";
import "./UsersContainerContent.scss";

interface IUserContainerContent {
  users: IMyUserVm[];
}

const UsersContainerContent = ({ users }: IUserContainerContent) => {
  return (
    <div className="user-container-content__item-wrapper">
      {users.map((user) => (
        <div key={user.id} className="user-container-content__item">
          <UserAvatar
            displayName={returnUsersCredentials(
              user.firstName,
              user.lastName,
              user.email
            )}
            size={32}
          />
          <div>
            {returnUsersCredentials(user.firstName, user.lastName, user.email)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default UsersContainerContent;
