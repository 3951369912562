import CancelSaveBottomBar from "components/common/Footers/CancelSaveBottomBar/CancelSaveBottomBar";
import HeaderPageTitleAndReturn from "components/common/Headers/HeaderPageTitleAndReturn/HeaderPageTitleAndReturn";
import AsyncToast from "components/common/Toasts/AsyncToast";
import SimpleToast from "components/common/Toasts/SimpleToast";
import ProjectOverviewEditPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/ProjectOverviewPageComponents/Pages/ProjectOverviewEditPageComponents/ProjectOverviewEditPageMain";
import { checkIfStringIsNaturalNumberOrZero } from "helpers/General/CheckIfStringIsNaturalNumber";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { IUpdateProjectRequest } from "interfaces/Requests/IUpdateProjectRequest";
import { updateProject } from "Pages/api/MyProjectsClient";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import { updateSelectedProject } from "store/Redux/Reducers/projectReducer";
import "./ProjectOverviewEditPage.scss";

export interface IProjectOverviewInputs extends FieldValues {
  "Project name": string;
  Budget: number;
  "Estimated cost": number;
  Description: string;
}

const ProjectOverviewEditPage = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const dispatch = useAppDispatch();

  const { register, getValues, setValue } = useForm<IProjectOverviewInputs>();
  const returnToPreviousPage = () => {
    return navigate(-1);
  };

  const validateRequest = () => {
    return (
      checkIfStringIsNaturalNumberOrZero(getValues("Budget").toString()) &&
      checkIfStringIsNaturalNumberOrZero(getValues("Estimated cost").toString())
    );
  };

  const onSaveClick = async () => {
    if (validateRequest()) {
      const request: IUpdateProjectRequest = {
        name: getValues("Project name"),
        budget: +getValues("Budget"),
        estimatedCost: +getValues("Estimated cost"),
        description: getValues("Description"),
      };
      if (projectId) {
        await AsyncToast(
          updateProject(request, +projectId),
          "Updated project successfully"
        );
        dispatch(updateSelectedProject(request));
        navigate(-1);
      }
    } else {
      SimpleToast({
        mode: ToastModes.info,
        message: "You should not use floating numbers",
      });
    }
  };

  return (
    <div className="project-overview-edit-page__layout">
      <HeaderPageTitleAndReturn
        returnToPreviousPage={returnToPreviousPage}
        text={"Project details"}
      />
      <ProjectOverviewEditPageMain register={register} setValue={setValue} />
      <CancelSaveBottomBar
        onCancelClick={returnToPreviousPage}
        onSaveClick={onSaveClick}
      />
    </div>
  );
};

export default ProjectOverviewEditPage;
