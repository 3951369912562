import "./FiltrationBar.scss";
import GrayMagnifierIcon from "public/GrayMagnifierIcon.svg";
import React from "react";
import SeparationLine from "../SeparationLine/SeparationLine";

export interface ObjectWithName {
  name: string;
}

interface IFiltrationBar<T> {
  items: T[];
  setFiltratedItems: React.Dispatch<React.SetStateAction<Array<T>>>;
  setIsFiltrationActive?: React.Dispatch<React.SetStateAction<boolean>>;
  filtrationInputValue: string;
  setFiltrationInputValue: React.Dispatch<React.SetStateAction<string>>;
  filtrationMenu: JSX.Element | null;
  showSeparationLine?: boolean;
}

const FiltrationBar = <T extends ObjectWithName>({
  items,
  setFiltrationInputValue,
  setFiltratedItems,
  setIsFiltrationActive,
  filtrationInputValue,
  filtrationMenu,
  showSeparationLine = true,
}: IFiltrationBar<T>) => {
  const firstFiltration = (inputValue: string) => {
    const filtratedItems: T[] | ((prevState: T[]) => T[]) = [];
    items.map((item) => {
      if (
        item.name.toString().toLowerCase().includes(inputValue.toLowerCase())
      ) {
        filtratedItems.push(item);
      }
    });
    setFiltratedItems(filtratedItems);
    if (setIsFiltrationActive) {
      setIsFiltrationActive(true);
    }
    setFiltrationInputValue(inputValue);
  };

  return (
    <React.Fragment>
      <div className={"common-components__filtration-bar-wrapper"}>
        <div className={"common-components__filtration-bar-inputs"}>
          <img
            src={GrayMagnifierIcon}
            alt="Onesoft logo"
            width={20}
            height={20}
          />
          <input
            value={filtrationInputValue}
            onChange={(e) => {
              firstFiltration(e.target.value);
            }}
            className={"common-components__filtration-bar-search-text"}
            placeholder="Search"
          />
        </div>
        {filtrationMenu}
      </div>
      {showSeparationLine && (
        <SeparationLine marginBottom={16} marginTop={13} />
      )}
    </React.Fragment>
  );
};

export default FiltrationBar;
