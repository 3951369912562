import { GettingStartedContext } from "contexts/GettingStartedContext";
import { IntroductoryPageContent } from "interfaces/Enums/IntroductoryPageContent";
import React from "react";
import "./IntroductoryMain.scss";

const IntroductoryMain = () => {
  const setMainComponent = React.useContext(GettingStartedContext);
  const setMainComponentHandler = (value: IntroductoryPageContent) => {
    setMainComponent(value);
  };

  return (
    <main>
      <div className={"getting-started-page__introductory-main-text"}>
        To get started, create or join organization
      </div>
      <div
        className={"getting-started-page__introductory-main-button--green"}
        data-cy={"Create-organization-button"}
        onClick={() =>
          setMainComponentHandler(
            IntroductoryPageContent.CreateOrganizationMain
          )
        }
      >
        Create
      </div>
      <div
        className={"getting-started-page__introductory-main-button--gray"}
        onClick={() =>
          setMainComponentHandler(IntroductoryPageContent.JoinOrganizationMain)
        }
      >
        Join
      </div>
    </main>
  );
};

export default IntroductoryMain;
