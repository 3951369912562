import SmallGreenPlusIcon from "public/SmallGreenPlusIcon.svg";
import "../FormsComponent.scss";
import DeviationItem from "components/common/DeviationsComponents/DeviationItem/DeviationItem";
import React from "react";
import DeviationVm from "interfaces/ViewModels/MyDeviationsViewModels/DeviationVm";

interface IFormsComponent {
  assignedForms: DeviationVm[] | undefined;
  label: string;
  redirectToForm: () => void;
  hasPermissionToEditTask: boolean;
}

const FormsComponent = ({
  assignedForms,
  label,
  redirectToForm,
  hasPermissionToEditTask,
}: IFormsComponent) => {
  return (
    <div className="forms-component__main-checklists-part">
      {hasPermissionToEditTask && (
        <div
          className="forms-component__main-add-button"
          onClick={redirectToForm}
        >
          <img src={SmallGreenPlusIcon} alt="Green plus icon" />
          Add {label}
        </div>
      )}
      {assignedForms &&
        assignedForms.map((form) => {
          return <DeviationItem deviation={form} key={form.id} />;
        })}
    </div>
  );
};

export default FormsComponent;
