import { isBefore, isSameDay } from "date-fns";
import { IFiltration as ProjectsFilter } from "store/Redux/Reducers/projectsFilterReducer";
import { isToday } from "date-fns";

interface IHasStartDate {
  dueDate: Date | string;
}

export const taskEndDateFilter = <T extends IHasStartDate>(
  foundFilter: ProjectsFilter,
  filteredArray: T[]
) => {
  if (foundFilter.deadline && !isToday(new Date(foundFilter.deadline))) {
    const dueDate = foundFilter.deadline;
    if (dueDate) {
      return filteredArray.filter(
        (item) =>
          isBefore(new Date(item.dueDate), new Date(dueDate)) ||
          isSameDay(new Date(item.dueDate), new Date(dueDate))
      );
    }
  }
};
