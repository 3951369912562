import FiltrationsHeader from "components/common/FiltrationsHeader/FiltrationsHeader";
import GrayFiltrationBar from "components/common/GrayFiltrationBar/GrayFiltrationBar";
import ListOfUsersContainer from "components/common/ListOfUsers/ListOfUsersContainer/ListOfUsersContainer";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { filtersRoute, projectsRoute } from "constants/Routes";
import {
  IMyUserVmMerged,
  mergeMembersCredentials,
} from "helpers/General/MergeMembersCredentials";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import {
  IAssigneeState,
  IFiltration,
  selectFilters,
  setAssigneesState,
} from "store/Redux/Reducers/projectsFilterReducer";

const UsersFilterPage = () => {
  const globalStates = useSelector(selectFilters);
  const [usersMergedNames, setUsersMergedNames] = useState<IMyUserVmMerged[]>(
    []
  );
  const navigate = useNavigate();
  const { projectId } = useParams();
  const foundFilter = globalStates.projectsFiltersReducer.filters.find(
    (filter: IFiltration) => projectId && +filter.projectId === +projectId
  );
  const [chosenUsers, setChosenUsers] = useState<IMyUserVm[]>(
    foundFilter ? foundFilter.assignees : []
  );
  const [filtratedItems, setFiltratedItems] = useState<IMyUserVmMerged[]>([]);
  const dispatch = useAppDispatch();

  const confirmStateChange = () => {
    if (projectId) {
      const changingDates: IAssigneeState = {
        assignees: chosenUsers,
        projectId: +projectId,
      };
      dispatch(
        setAssigneesState({
          ...changingDates,
        })
      );
      navigate(`/${projectsRoute}/${projectId}/${filtersRoute}`);
    } else {
      SimpleToast({
        mode: ToastModes.error,
        message: "Project id cannot be null",
      });
    }
  };
  const handleProjectMembers = async () => {
    const mergedNames = mergeMembersCredentials(
      globalStates.chosenProjectReducer.projectMembers
    );
    setFiltratedItems(mergedNames);
    setUsersMergedNames(mergedNames);
  };

  useEffect(() => {
    handleProjectMembers();
  }, []);

  return (
    <div>
      <FiltrationsHeader
        pageName={"Users"}
        applyFiltersText={"Save"}
        confimationFunction={confirmStateChange}
        showConfirmationToast={false}
      />
      <GrayFiltrationBar
        originalArray={usersMergedNames}
        setFiltratedItems={setFiltratedItems}
      />
      <ListOfUsersContainer
        users={filtratedItems}
        chosenUsers={chosenUsers}
        setChosenUsers={setChosenUsers}
      />
    </div>
  );
};

export default UsersFilterPage;
