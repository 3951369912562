import ShowAllTasksComponent from "components/common/ShowAllTasksComponent/ShowAllTasksComponent";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";

interface ITaskContainerContent {
  tasks: IMyTaskVm[];
}

const TaskContainerContent = ({ tasks }: ITaskContainerContent) => {
  return <ShowAllTasksComponent tasks={tasks} />;
};
export default TaskContainerContent;
