import { dotsDateFormat } from "constants/DateFormats";
import { differenceInMinutes, format, getISOWeek, getYear } from "date-fns";
import { IMyTimeEntriesWeekPeriods } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntriesWeekPeriods";
import { IMyTimeEntryVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVm";
import { createNewDay } from "./CreateNewDay";
import { createNewWeek } from "./CreateNewWeek";

export const separateTimeEntriesIntoPeriodSections = (
  timeEntries: IMyTimeEntryVm[]
): IMyTimeEntriesWeekPeriods => {
  const myTimEntriesWeeksPeriods: IMyTimeEntriesWeekPeriods = {
    timeEntriesWeeks: [],
  };
  timeEntries.map((timeEntry) => {
    const timeEntryDuration = differenceInMinutes(
      new Date(timeEntry.finishDate),
      new Date(timeEntry.startDate)
    );
    const weekNumber = getISOWeek(new Date(timeEntry.startDate));
    const yearNumber = getYear(new Date(timeEntry.startDate));
    const selectedWeek = myTimEntriesWeeksPeriods.timeEntriesWeeks.find(
      (item) => item.weekNumber === weekNumber && item.yearNumber === yearNumber
    );
    if (!selectedWeek) {
      const newWeek = createNewWeek(timeEntry, weekNumber);
      myTimEntriesWeeksPeriods.timeEntriesWeeks.push(newWeek);
    } else {
      const selectedDay = selectedWeek?.timeEntriesDays.find(
        (item) =>
          item.dayDate === format(new Date(timeEntry.startDate), dotsDateFormat)
      );
      if (!selectedDay) {
        const newDay = createNewDay(timeEntry);
        selectedWeek?.timeEntriesDays.push(newDay);
        selectedWeek.sumOfMinutes += timeEntryDuration;
      } else {
        selectedDay.timeEntries.push(timeEntry);
        selectedDay.sumOfMinutes += timeEntryDuration;
        selectedWeek.sumOfMinutes += timeEntryDuration;
      }
    }
  });
  return myTimEntriesWeeksPeriods;
};
