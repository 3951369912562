import OneBigLogo from "../../public/OneBigLogo.svg";
import "./GettingStartedPage.scss";
import LogOutComponent from "components/GettingStartedPageComponents/LogOutComponent/LogOutComponent";
import IntroductoryMain from "./IntroductoryMain/IntroductoryMain";
import JoinOrganizationMain from "./JoinOrganizationMain/JoinOrganizationMain";
import CreateOrganizationMain from "./CreateOrganizationMain/CreateOrganizationMain";
import { IntroductoryPageContent } from "interfaces/Enums/IntroductoryPageContent";
import { GettingStartedContext } from "contexts/GettingStartedContext";
import { useState } from "react";

interface IGettingStartedPage {
  setMyOrganizationId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const GettingStartedPage = ({ setMyOrganizationId }: IGettingStartedPage) => {
  const [mainComponent, setMainComponent] = useState<IntroductoryPageContent>(
    IntroductoryPageContent.IntroductoryMain
  );

  const returnAppropriateMainContent = () => {
    switch (mainComponent) {
      case IntroductoryPageContent.IntroductoryMain: {
        return <IntroductoryMain />;
      }
      case IntroductoryPageContent.JoinOrganizationMain: {
        return (
          <JoinOrganizationMain setMyOrganizationId={setMyOrganizationId} />
        );
      }
      case IntroductoryPageContent.CreateOrganizationMain: {
        return (
          <CreateOrganizationMain setMyOrganizationId={setMyOrganizationId} />
        );
      }
    }
  };

  return (
    <div className={"getting-started-page__main-wrapper"}>
      <header>
        <img src={OneBigLogo} alt={"One Big Logo"} height={69} width={172} />
      </header>
      <GettingStartedContext.Provider value={setMainComponent}>
        {returnAppropriateMainContent()}
      </GettingStartedContext.Provider>
      <footer className={"getting-started-page__footer"}>
        <LogOutComponent />
      </footer>
    </div>
  );
};

export default GettingStartedPage;
