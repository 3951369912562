import "./OnlyRightButtonFooter.scss";

interface IOnlyRightButtonFooter {
  buttonText: string;
  onClick: () => void;
}

const OnlyRightButtonFooter = ({
  buttonText,
  onClick,
}: IOnlyRightButtonFooter) => {
  return (
    <div className="only-right-button-footer__wrapper">
      <div className="only-right-button-footer__button" onClick={onClick}>
        {buttonText}
      </div>
    </div>
  );
};

export default OnlyRightButtonFooter;
