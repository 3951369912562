import { isBefore, isSameDay } from "date-fns";
import { IFiltration as TimeEntriesFilter } from "store/Redux/Reducers/timeEntriesFilterReducer";
import { isToday } from "date-fns";
interface IHasFinishDate {
  finishDate: Date | string;
}

export const timeEntryEndDateFilter = <T extends IHasFinishDate>(
  foundFilter: TimeEntriesFilter,
  filteredArray: T[]
) => {
  if (foundFilter.deadline && !isToday(new Date(foundFilter.deadline))) {
    const dueDate = foundFilter.deadline;
    if (dueDate) {
      return filteredArray.filter(
        (item) =>
          isBefore(new Date(item.finishDate), new Date(dueDate)) ||
          isSameDay(new Date(item.finishDate), new Date(dueDate))
      );
    }
  }
};
