export interface ICountriesAndCurrencies {
  [key: string]: string;
}
const CountriesAndCurrencies: ICountriesAndCurrencies = {
  Poland: "PLN",
  "United Kingdom": "GBP",
  Norway: "NOK",
  USA: "USD",
  Germany: "EUR",
};

export const countriesAndCurrenciesOptions = Object.keys(
  CountriesAndCurrencies
).map((key: string) => {
  return { label: key, value: CountriesAndCurrencies[key] };
});
