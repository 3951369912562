import {
  verticalArrowCommonWidth,
  verticalArrowCommonHeight,
} from "../../../../constants/Consts";
import "./FiltrationMenuButton.scss";
import GreenRightArrow from "public/GreenRightArrow.svg";
import { Link } from "react-router-dom";

interface IFiltrationMenuButton {
  projectId: number;
  link: string;
}

const FiltrationMenuButton = ({ link }: IFiltrationMenuButton) => {
  return (
    <Link to={link}>
      <div className={"common-components__filtration-menu-button-wrapper"}>
        <span>Filters</span>
        <img
          src={GreenRightArrow}
          alt="Right green arrow"
          width={verticalArrowCommonWidth}
          height={verticalArrowCommonHeight}
        />
      </div>
    </Link>
  );
};

export default FiltrationMenuButton;
