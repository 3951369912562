import { IMyOrganizationVm } from "interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationVm";
import "./ProjectPageMain.scss";
import { IMyProjectTasksVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectTasksVm";
import { IMyProjectVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectVm";
import TasksGrayIcon from "public/TasksGrayIcon.svg";
import NoItemContainer from "components/common/NoItemContainer/NoItemContainer";
import ProjectNewTaskButton from "../ProjectNewTaskButton/ProjectNewTaskButton";
import FiltrationBar from "components/common/FiltrationBar/FiltrationBar";
import React, { useState } from "react";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import ProjectTasksSection from "../ProjectTasksSection/ProjectTasksSection";
import FiltrationMenuButton from "components/common/FiltrationBar/FiltrationMenuButton/FiltrationMenuButton";
import SmallGreenDot from "public/SmallGreenDot.svg";
import SmallBlueDot from "public/SmallBlueDot.svg";
import SmallOrangeDot from "public/SmallOrangeDot.svg";
import BlueVerticalUnderline from "public/BlueVerticalUnderline.svg";
import YellowVerticalUnderline from "public/YellowVerticalUnderline.svg";
import GreenVerticalUnderline from "public/GreenVerticalUnderline.svg";
import GrayDownArrow from "public/GrayDownArrow.svg";
import { useSelector } from "react-redux";
import {
  createFiltration,
  IFiltration,
  initialItemState,
  selectFilters,
} from "store/Redux/Reducers/projectsFilterReducer";
import { useAppDispatch } from "store/Redux/hooks";
import { filtersRoute, projectsRoute } from "constants/Routes";
import { shouldShowTasks } from "helpers/Projects/ShouldShowTasks";
import { useParams } from "react-router-dom";
import ProjectPageInformationHeader from "components/common/ProjectPageInformationHeader/ProjectPageInformationHeader";
import { TaskCategoryNames } from "interfaces/Enums/TaskCategoryName";
import ShowAllTasksComponent from "components/common/ShowAllTasksComponent/ShowAllTasksComponent";

interface IMain {
  project: IMyProjectVm;
  myOrganization: IMyOrganizationVm;
  tasks: IMyProjectTasksVm;
}

const Main = ({ project, myOrganization, tasks }: IMain) => {
  const { projectId } = useParams();
  const filters = useSelector(selectFilters);
  let foundFilter = filters.projectsFiltersReducer.filters.find(
    (filter: IFiltration) => projectId && +filter.projectId === +projectId
  );
  const dispatch = useAppDispatch();
  if (!foundFilter && project.id) {
    const projectId = +project.id;
    dispatch(
      createFiltration({
        ...initialItemState,
        projectId,
      })
    );
    foundFilter = { ...initialItemState };
  }

  const applyFiltersToTheTasks = () => {
    const basicFiltration: IMyTaskVm[] = applyFilterStatesToTheTasks();
    return basicFiltration;
  };

  const applyFilterStatesToTheTasks = () => {
    const filtratedTasks: IMyTaskVm[] = [];
    if (foundFilter) {
      filtratedTasks.push(
        ...shouldShowTasks(
          foundFilter,
          foundFilter.showCompleted,
          tasks.completedTasks
        )
      );
      filtratedTasks.push(
        ...shouldShowTasks(foundFilter, foundFilter.showToDo, tasks.toDoTasks)
      );
      filtratedTasks.push(
        ...shouldShowTasks(
          foundFilter,
          foundFilter.showInProgress,
          tasks.inProgressTasks
        )
      );
      return filtratedTasks;
    }
    return [];
  };

  const [filtratedTasks, setFiltratedTasks] = useState<IMyTaskVm[]>(
    applyFiltersToTheTasks()
  );
  const [filtrationInputValue, setFiltrationInputValue] = useState<string>("");

  const areThereAnyTasks =
    tasks.completedTasks.length +
      tasks.toDoTasks.length +
      tasks.inProgressTasks.length +
      tasks.completedTasks.length >
    0;

  let shouldShowToDoTasks: IMyTaskVm[] = [];
  let shouldShowInProgressTasks: IMyTaskVm[] = [];
  let shouldShowCompletedTasks: IMyTaskVm[] = [];
  if (foundFilter) {
    shouldShowToDoTasks = shouldShowTasks(
      foundFilter,
      foundFilter.showToDo,
      tasks.toDoTasks
    );
    shouldShowInProgressTasks = shouldShowTasks(
      foundFilter,
      foundFilter.showInProgress,
      tasks.inProgressTasks
    );
    shouldShowCompletedTasks = shouldShowTasks(
      foundFilter,
      foundFilter.showCompleted,
      tasks.completedTasks
    );
  }

  return (
    <div className={"project-page__main"}>
      <ProjectPageInformationHeader
        project={project}
        myOrganization={myOrganization}
      />
      {areThereAnyTasks ? (
        <main className={"project-page__main-content"}>
          <div className={"project-page__main-filtration-content"}>
            <div className={"project-page__main-filtration-content-title"}>
              My Tasks
            </div>
            <FiltrationBar
              items={applyFiltersToTheTasks()}
              setFiltratedItems={setFiltratedTasks}
              filtrationInputValue={filtrationInputValue}
              setFiltrationInputValue={setFiltrationInputValue}
              filtrationMenu={
                <FiltrationMenuButton
                  projectId={project.id}
                  link={`/${projectsRoute}/${projectId}/${filtersRoute}`}
                />
              }
            />
          </div>
          {filtrationInputValue.length > 0 ? (
            <React.Fragment>
              <div className={"project-page__main-filtration-label"}>
                <span className={"project-page__main-filtration-label-text"}>
                  All
                </span>
                <span className={"project-page__main-filtration-label-count"}>
                  &nbsp;({filtratedTasks.length})
                </span>
                <div>
                  <img
                    src={GrayDownArrow}
                    alt="Gray down arrow"
                    width={12}
                    height={12}
                  />
                </div>
              </div>
              <ShowAllTasksComponent tasks={filtratedTasks} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {shouldShowToDoTasks.length > 0 && (
                <ProjectTasksSection
                  imgSrc={SmallBlueDot}
                  taskCategoryName={TaskCategoryNames[TaskCategoryNames.ToDo]}
                  count={shouldShowToDoTasks.length}
                  dotHeight={6}
                  dotWidth={6}
                  tasks={shouldShowToDoTasks}
                  verticalUnderline={BlueVerticalUnderline}
                />
              )}
              {shouldShowInProgressTasks.length > 0 && (
                <ProjectTasksSection
                  imgSrc={SmallOrangeDot}
                  taskCategoryName={
                    TaskCategoryNames[TaskCategoryNames.InProgress]
                  }
                  count={shouldShowInProgressTasks.length}
                  dotHeight={6}
                  dotWidth={6}
                  tasks={shouldShowInProgressTasks}
                  verticalUnderline={YellowVerticalUnderline}
                  shouldBeOpenByDefault
                />
              )}
              {shouldShowCompletedTasks.length > 0 && (
                <ProjectTasksSection
                  imgSrc={SmallGreenDot}
                  taskCategoryName={
                    TaskCategoryNames[TaskCategoryNames.Completed]
                  }
                  count={shouldShowCompletedTasks.length}
                  dotHeight={6}
                  dotWidth={6}
                  tasks={shouldShowCompletedTasks}
                  verticalUnderline={GreenVerticalUnderline}
                />
              )}
            </React.Fragment>
          )}
        </main>
      ) : (
        <NoItemContainer
          imgSrc={TasksGrayIcon}
          imgAlt={"No tasks icon"}
          imgWidth={47}
          imgHeight={47}
          firstText={"There are no task in project"}
          secondText={"There are no tasks in this project yet"}
          button={
            project.canCreateTasks ? (
              <ProjectNewTaskButton projectId={project.id} />
            ) : (
              <div />
            )
          }
        />
      )}
    </div>
  );
};

export default Main;
