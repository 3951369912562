import AddFileButton from "components/common/Buttons/AddFileButton/AddFileButton";
import FiltrationBar from "components/common/FiltrationBar/FiltrationBar";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { greenText } from "constants/JsColors";
import { addFile } from "helpers/Files/AddNewFileRequest";
import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { FileMetadata } from "interfaces/ViewModels/MyDocumentsViewModels/FileMetadata";
import { FileModuleEnum } from "one-common-components/lib/enums/FileModuleEnum";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import DocumentItem from "./DocumentItem/DocumentItem";
import "./DocumentsPageMain.scss";

interface IProjectDocuments {
  documents: FileMetadata[];
  setDocuments: React.Dispatch<
    React.SetStateAction<FileMetadata[] | undefined>
  >;
}

const DocumentsPageMain = ({ documents, setDocuments }: IProjectDocuments) => {
  const [filtrationInputValue, setFiltartionInputValue] = useState<string>("");
  const [filtratedItems, setFiltratedItems] =
    useState<FileMetadata[]>(documents);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const globalStates = useSelector(chosenOrganization);
  const { projectId } = useParams();
  const onDrop = useCallback(
    async (acceptedFiles) => {
      setIsImageUploading(true);
      const existsInCollection: FileMetadata | undefined = documents.find(
        (item) => item.fileDisplayedName === acceptedFiles[0].name
      );
      if (existsInCollection) {
        setIsImageUploading(false);
        return SimpleToast({
          mode: ToastModes.info,
          message: "There is already a file named like that.",
        });
      }
      if (!projectId) {
        return SimpleToast({
          mode: ToastModes.error,
          message: "Project id was not found",
        });
      }
      const result = await addFile(
        +projectId,
        acceptedFiles[0],
        FileModuleEnum.ProjectDocuments,
        globalStates.chosenOrganizationReducer.chosenOrganizationId,
        +projectId
      );
      const mappedCreatedObject: FileMetadata = {
        guid: result.guid,
        fileDisplayedName: result.fileName,
        name: result.fileName,
        isVisibleInMobile: true,
        createdOn: new Date(),
        contentType: result.contentType,
      };
      setFiltratedItems([...documents, mappedCreatedObject]);
      setDocuments([...documents, mappedCreatedObject]);
      setIsImageUploading(false);
    },
    [documents]
  );

  const hasPermissionToEditDocuments = useMemo(() => {
    if (globalStates.userProfileReducer.userProfile !== null) {
      return restrictProjectAccessBySpecifiedRoles(
        globalStates.userProfileReducer.userProfile.projectRole,
        [ProjectRolesEnum.Viewer]
      );
    }
    return false;
  }, [globalStates.userProfileReducer.userProfile]);

  return (
    <main className="documents-page__main-layout">
      <span className="documents-page__page-title">DOCUMENTS</span>
      <FiltrationBar
        items={documents}
        setFiltratedItems={setFiltratedItems}
        filtrationInputValue={filtrationInputValue}
        setFiltrationInputValue={setFiltartionInputValue}
        filtrationMenu={<></>}
      />
      {!isImageUploading ? (
        <>
          {hasPermissionToEditDocuments && (
            <AddFileButton marginTop={"0px"} onDrop={onDrop} />
          )}
        </>
      ) : (
        <ClipLoader color={greenText} size={15} />
      )}

      <div className="checklists-page__main-checklists">
        {filtratedItems.map((item) => {
          return (
            <DocumentItem
              key={item.guid}
              documentItem={item}
              hasPermissionToEditDocuments={hasPermissionToEditDocuments}
            />
          );
        })}
      </div>
    </main>
  );
};

export default DocumentsPageMain;
