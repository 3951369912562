import DateBadge from "components/common/Badges/DateBadge/DateBadge";
import { FileMetadata } from "interfaces/ViewModels/MyDocumentsViewModels/FileMetadata";
import FileIcon from "one-common-components/lib/constants/FileIcon/FileIcon";
import DownloadIcon from "public/DownloadIcon.svg";
import ShowFileIcon from "public/ShowFileIcon.svg";
import "./DocumentItem.scss";
import SeparationLine from "components/common/SeparationLine/SeparationLine";
import { downloadFile } from "Pages/api/FileDownloadClient";
import ChangeFileNameInput from "components/common/ChangeFileNameInput/ChangeFileNameInput";

interface IDocumentItem {
  documentItem: FileMetadata;
  hasPermissionToEditDocuments: boolean;
}

const DocumentItem = ({
  documentItem,
  hasPermissionToEditDocuments,
}: IDocumentItem) => {
  const handleDownload = async () => {
    downloadFile(documentItem.guid).then((fileBlob) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(fileBlob);
      link.download = documentItem.name;
      link.click();
    });
  };

  const handleOverviewDocument = async () => {
    downloadFile(documentItem.guid).then((fileBlob) => {
      const fileURL = window.URL.createObjectURL(fileBlob);
      const win = window.open();
      win?.document.write(
        '<iframe src="' +
          fileURL +
          '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
      );
    });
  };

  return (
    <div className="document-item__wrapper">
      <div className="document-item__title">
        <FileIcon contentType={documentItem.contentType} />
        <ChangeFileNameInput
          fileName={documentItem.fileDisplayedName}
          fileId={documentItem.guid}
          canEdit={hasPermissionToEditDocuments}
        />
      </div>
      <div className="document-item__created-on">
        <span>Created on:</span>
        {documentItem.createdOn && (
          <DateBadge date={documentItem.createdOn} marginLeft={8} />
        )}
      </div>
      <SeparationLine marginBottom={8} marginTop={8} />
      <div className="document-item__actions">
        <span>Actions:</span>
        <div className="document-item__action-icons">
          <img
            src={DownloadIcon}
            alt="Download icon"
            onClick={handleDownload}
          />
          <img
            src={ShowFileIcon}
            alt="Download icon"
            onClick={handleOverviewDocument}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentItem;
