import FiltrationsHeader from "components/common/FiltrationsHeader/FiltrationsHeader";
import { parseTasksDividedByDatesToOneArray } from "helpers/TasksHelpers/ParseTasksDividedByDatesToOneArray";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import { getProjectTasksWithDetails } from "Pages/api/MyProjectsClient";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/Redux/hooks";
import {
  modifyTasksStateForNewTimeEntry,
  selectNewTimeEntriesTasks,
} from "store/Redux/Reducers/newTimeEntryReducer";

import { useLocation, useParams } from "react-router-dom";
import TimeEntryTasksPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/TimeEntriesPageComponents/AddTimeEntryPageComponents/AddTimeEntryTasksPage/TimeEntryTasksPageMain/TimeEntryTasksPageMain";
import { storePathValues } from "helpers/General/StorePathValue";

const TimeEntryTasksPage = () => {
  const states = useSelector(selectNewTimeEntriesTasks);
  const [chosenTask, setChosenTask] = useState<IMyTaskVm | null>(
    states.newTimeEntryReducer.task ? states.newTimeEntryReducer.task : null
  );
  const [filtratedTasks, setFiltratedTask] = useState<IMyTaskVm[]>([]);
  const [tasks, setTasks] = useState<IMyTaskVm[]>();
  const { projectId } = useParams();
  const dispatch = useAppDispatch();

  const saveTasksStateToStorage = () => {
    dispatch(modifyTasksStateForNewTimeEntry(chosenTask));
  };

  const location = useLocation();

  useEffect(() => {
    saveTasksStateToStorage();
  }, [chosenTask]);

  const handleTasks = async (projectId: number) => {
    const tasks = await getProjectTasksWithDetails(projectId);
    const parsedTasks = parseTasksDividedByDatesToOneArray(tasks);
    setFiltratedTask(parsedTasks);
    setTasks(parsedTasks);
  };

  useEffect(() => storePathValues, [location]);

  useEffect(() => {
    if (projectId) {
      handleTasks(+projectId);
    }
  }, [projectId]);

  if (!tasks) {
    return <div />;
  }

  return (
    <div>
      <FiltrationsHeader pageName={"Tasks"} applyFiltersText={"Save"} />
      <TimeEntryTasksPageMain
        filtratedTasks={filtratedTasks}
        chosenTask={chosenTask}
        setChosenTask={setChosenTask}
        setFiltratedTask={setFiltratedTask}
        allTasks={tasks}
      />
    </div>
  );
};

export default TimeEntryTasksPage;
