import "./SaveButton.scss";

interface ISaveButton {
  save: () => void;
  buttonName?: string;
}

const SaveButton = ({ save, buttonName = "Save" }: ISaveButton) => {
  return (
    <div onClick={save} className={"common-components__save-button"}>
      {buttonName}
    </div>
  );
};

export default SaveButton;
