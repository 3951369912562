import React, { useState } from "react";
import OrganizationsModal from "components/common/Navigation/OrganizationsModal/OrganizationsModal";
import { IMyProfileVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProfileVm";
import BurgerGreenMenuIcon from "../../../public/BurgerGreenMenuIcon.svg";
import { IMyOrganizationVm } from "interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationVm";

interface INavigation {
  userDetails: IMyProfileVm;
  organizations: IMyOrganizationVm[];
  myOrganizationId: number;
  setMyOrganizationId?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
}

const Navigation = ({
  userDetails,
  organizations,
  myOrganizationId,
  setMyOrganizationId,
}: INavigation) => {
  const [openOrganizationModal, setOpenOrganizationModal] =
    useState<boolean>(false);
  const openModal = () => {
    setOpenOrganizationModal(true);
  };

  const handleCloseOrganizationModal = () => {
    setOpenOrganizationModal(false);
  };

  return (
    <React.Fragment>
      <OrganizationsModal
        openOrganizationModal={openOrganizationModal}
        handleCloseOrganizationModal={handleCloseOrganizationModal}
        userDetails={userDetails}
        organizations={organizations}
        myOrganizationId={myOrganizationId}
        setMyOrganizationId={setMyOrganizationId}
      />
      <div onClick={() => openModal()}>
        <img
          src={BurgerGreenMenuIcon}
          alt="User profile picture"
          width={24}
          height={16}
        />
      </div>
    </React.Fragment>
  );
};

export default Navigation;
