import ChecklistsAnswerLabel from "components/common/ChecklistsComponents/ChecklistsAnswerLabel/ChecklistsAnswerLabel";
import { AnswerDto } from "interfaces/ViewModels/MyFormsViewModels/AnswerDto";
import ChecklistsAnswerContent from "../../ChecklistsAnswerContent/ChecklistsAnswerContent";
import CommentAnswers from "../CommentAnswers/CommentAnswers";
import "./TextBoxAnswers.scss";

interface ITextBoxAnswers {
  answer: AnswerDto;
}

const TextBoxAnswers = ({ answer }: ITextBoxAnswers) => {
  return (
    <div className="checklists-answers--margins">
      <ChecklistsAnswerLabel text={answer.header} position={answer.order} />
      <ChecklistsAnswerContent
        text={answer.value ? answer.value.toString() : ""}
      />
      <CommentAnswers text={answer.comment} />
    </div>
  );
};

export default TextBoxAnswers;
