import { IFiltration as ProjectsFilter } from "store/Redux/Reducers/projectsFilterReducer";
import { IFiltration as TimeEntriesFilter } from "store/Redux/Reducers/timeEntriesFilterReducer";

interface IHasNumberInProject {
  taskNumberInProject: number;
}

export const taskTasksFilter = <T extends IHasNumberInProject>(
  foundFilter: ProjectsFilter | TimeEntriesFilter,
  filteredArray: T[]
) => {
  if (foundFilter.tasks.length > 0) {
    return filteredArray.filter((value) => {
      return (
        foundFilter &&
        foundFilter.tasks.some(
          (item) => item.taskNumberInProject === value.taskNumberInProject
        )
      );
    });
  }
};
