import GrayLeftArrow from "public/GrayLeftArrow.svg";
import {
  horizontalBigArrowCommonHeight,
  horizontalArrowCommonWidth,
} from "constants/Consts";
import "./HeaderPageTitleAndReturn.scss";

interface IHeaderPageTitleAndReturn {
  returnToPreviousPage: () => void;
  rightElementImgSrc?: string;
  rightElementImgAlt?: string;
  rightElementClick?: () => void;
  text: string;
}

const HeaderPageTitleAndReturn = ({
  returnToPreviousPage,
  rightElementImgSrc,
  rightElementImgAlt,
  rightElementClick,
  text,
}: IHeaderPageTitleAndReturn) => {
  return (
    <div className="common-components__page-title-and-return">
      <div onClick={returnToPreviousPage}>
        <img
          src={GrayLeftArrow}
          alt="Gray left arrow"
          width={horizontalArrowCommonWidth}
          height={horizontalBigArrowCommonHeight}
        />
      </div>
      <div>{text}</div>
      {rightElementImgSrc ? (
        <img
          onClick={rightElementClick}
          src={rightElementImgSrc}
          alt={rightElementImgAlt}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default HeaderPageTitleAndReturn;
