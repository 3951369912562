// import InputWithLabel from "one-common-components/lib/components/SingleInputsPack/InputWithLabel/InputWithLabel";
import React, { useEffect, useState } from "react";
import "./NewTaskPageMain.scss";
import { UseFormRegister } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { addDays } from "date-fns";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import { ILabelValueStructure } from "interfaces/Other/ILabelValueStructure";
import { calculateDifferenceInBusinessDays } from "helpers/TimeHelpers/CalculateDifferenceInDays";
import { IMyChecklistVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyChecklistVm";
import { dropdownStyles } from "styles/DropdownStyles";
import { ICreateTaskFormInput } from "Pages/ProjectsPage/ProjectPage/NewTaskPage/NewTaskPage";
import FromToCalendarComponents from "components/common/FromToCalendarComponents/FromToCalendarComponents";
import {
  IMyUserVmMerged,
  mergeMembersCredentials,
} from "helpers/General/MergeMembersCredentials";
import SubsectionsGrayBarSeparator from "one-common-components/lib/components/SubsectionsGrayBarSeparator/SubsectionsGrayBarSeparator";
import { convertToSelectOptions } from "helpers/General/ConvertToSelectOptions";
import TeamMembersMultiSelect from "components/common/MultipleInputsWithLabel/TeamMembersMultiSelect/TeamMembersMultiSelect";
import UploadFileComponent from "one-common-components/lib/components/Files/Components/UploadSpecificComponents/UploadFileComponent/UploadFileComponent";
import UploadedImagesButton from "one-common-components/lib/components/Files/Components/UploadSpecificComponents/UploadedImagesButton/UploadedImagesButton";
import { HasPreviewItem } from "one-common-components/lib/components/Files/Interfaces/HasPreviewItem";
import AddFileFromProjectDocuments from "components/common/AddFileFromProjectDocuments/AddFileFromProjectDocuments";
import SeparationLine from "components/common/SeparationLine/SeparationLine";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { downloadFile } from "Pages/api/FileDownloadClient";
import { useParams } from "react-router-dom";
import InputWithLabel from "one-common-components/lib/components/SingleInputsPack/InputWithLabel/InputWithLabel";

interface INewTaskPageMain {
  register: UseFormRegister<ICreateTaskFormInput>;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  startDate: Date | null;
  setDueDate: React.Dispatch<React.SetStateAction<Date | null>>;
  dueDate: Date | null;
  projectMembers: IMyUserVm[];
  setUsers: React.Dispatch<React.SetStateAction<ILabelValueStructure[]>>;
  projectChecklists: IMyChecklistVm[];
  setChecklists: React.Dispatch<React.SetStateAction<ILabelValueStructure[]>>;
  users: ILabelValueStructure[];
  setDuration: React.Dispatch<React.SetStateAction<string>>;
  duration: string;
  setMyFiles: React.Dispatch<React.SetStateAction<File[]>>;
  myFiles: File[];
}

const NewTaskPageMain = ({
  register,
  setStartDate,
  startDate,
  setDueDate,
  dueDate,
  projectMembers,
  projectChecklists,
  users,
  setUsers,
  setChecklists,
  duration,
  setDuration,
  setMyFiles,
  myFiles,
}: INewTaskPageMain) => {
  const [checklistsOptions, setChecklistsOptions] = useState<
    ILabelValueStructure[]
  >([]);
  const [previews, setPreviews] = useState<HasPreviewItem[]>([]);
  const { projectId } = useParams();
  const handleSetStartDate = (date: Date | null) => {
    if (date && dueDate) {
      setStartDate(date);
      setDuration(
        (calculateDifferenceInBusinessDays(date, dueDate) + 1).toString()
      );
    }
  };

  const handleSetDueDate = (date: Date | null) => {
    if (date && startDate) {
      setDueDate(date);
      setDuration(
        (calculateDifferenceInBusinessDays(startDate, date) + 1).toString()
      );
    }
  };

  const mergedNames: IMyUserVmMerged[] =
    mergeMembersCredentials(projectMembers);
  const mappedProjectMembers = mergedNames.map((member) => {
    return { name: member.name, id: member.id };
  });
  const teamOptions = convertToSelectOptions(mappedProjectMembers);

  const handleChecklistsMultiSet = (options: ILabelValueStructure[]) => {
    setChecklists(options);
  };

  const parseChecklistsOptions = () => {
    if (projectChecklists && projectChecklists.length > 0) {
      const checklistNameAndIdList = projectChecklists.map(
        (item: IMyChecklistVm) => {
          return {
            name: item.name,
            id: item.id,
          };
        }
      );
      setChecklistsOptions(convertToSelectOptions(checklistNameAndIdList));
    }
  };

  const setDurationString = (value: string) => {
    setDuration(value);
    updateDueDate(+value);
  };

  const updateDueDate = (daysCount: number) => {
    if (startDate) {
      setDueDate(addDays(startDate, daysCount));
    }
  };

  const handleAddItemToList = async (option: ILabelValueStructure) => {
    if (!projectId) {
      return SimpleToast({
        mode: ToastModes.error,
        message: "Project id was not found",
      });
    }
    downloadFile(option.value.toString()).then(async (fileBlob) => {
      const file = new File([fileBlob], option.label, { type: fileBlob.type });
      setMyFiles([...myFiles, file]);
      const preview: HasPreviewItem = {
        preview: URL.createObjectURL(file),
        name: option.label,
        size: file.size,
        lastModified: file.lastModified,
      };
      setPreviews((previews) => [...previews, preview]);
    });
  };

  useEffect(() => {
    parseChecklistsOptions();
  }, []);

  return (
    <div className="new-task-page-main__layout">
      <div className={"new-task-page-main__top-part"}>
        <InputWithLabel
          label={"Name"}
          inputPlaceholder={"Enter task name"}
          register={register}
          required
        />
        <FromToCalendarComponents
          handleSetStartDate={handleSetStartDate}
          handleSetDueDate={handleSetDueDate}
          startDate={startDate}
          dueDate={dueDate}
          showWeekends={false}
        />
        <InputWithLabel
          required
          label={"Estimate Duration"}
          inputPlaceholder={"Days"}
          marginBottom={24}
          marginTop={24}
          register={register}
          type={"number"}
          value={duration}
          onValueChange={setDurationString}
        />
        {checklistsOptions.length > 0 && (
          <div
            className={"new-task-page-main__priority-and-checklists-dropdown"}
          >
            <div className={"new-task-page-main__input-wrapper-label"}>
              <span>Checklists</span>
            </div>
            <Select
              styles={dropdownStyles}
              placeholder={"Checklists"}
              isMulti
              options={checklistsOptions}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore: Unreachable type code error
              onChange={handleChecklistsMultiSet}
            />
          </div>
        )}
      </div>
      <SubsectionsGrayBarSeparator text={"Team"} />
      <div
        data-testid="teamMembers"
        className={"new-task-page-main__main-team-members"}
      >
        <div className={"new-task-page-main__main-team-members-dropdown"}>
          <TeamMembersMultiSelect
            users={users}
            setUsers={setUsers}
            teamOptions={teamOptions}
            label={"Team members"}
          />
        </div>
      </div>
      <SubsectionsGrayBarSeparator text={"Task details"} />
      <div className={"new-task-page-main__main-team-members"}>
        <InputWithLabel
          label={"Description"}
          registerName={"description"}
          inputPlaceholder={"Description"}
          marginTop={24}
          marginBottom={24}
          maxInputLength={500}
          register={register}
        />
      </div>
      <SubsectionsGrayBarSeparator text={"Uploaded files"} marginBottom={16} />
      <SeparationLine marginBottom={16} marginTop={16} />
      <div className="new-task-page-main__project-files-wrapper">
        <AddFileFromProjectDocuments
          handleAddItemToList={handleAddItemToList}
        />
      </div>
      <UploadFileComponent
        myFiles={myFiles}
        setMyFiles={setMyFiles}
        setPreviews={setPreviews}
        previews={previews}
        serachButton={
          <UploadedImagesButton
            setPreviews={setPreviews}
            previews={previews}
            setMyFiles={setMyFiles}
            myFiles={myFiles}
          />
        }
      />
    </div>
  );
};

export default NewTaskPageMain;
