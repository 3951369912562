import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUpdateDeviationReduxStatus } from "components/common/FormStatusBar/FormStatusBar";
import ChecklistVm from "interfaces/ViewModels/MyChecklistsViewModels/ChecklistVm";
import { AnswerDto } from "interfaces/ViewModels/MyFormsViewModels/AnswerDto";
import { GroupedAnswersDto } from "interfaces/ViewModels/MyFormsViewModels/GroupedAnswerDto";
import type { RootState } from "../store";

export interface IMyChecklistsReducerInitialState {
  checklists: ChecklistVm[];
  originalChecklists: ChecklistVm[];
}

export interface UpdateChecklistsAnswer {
  answer: AnswerDto;
  value: string | string[];
  userId: number;
  checklistId: number;
  group?: GroupedAnswersDto;
}

export interface UpdateChecklistsComment {
  answer: AnswerDto;
  comment: string;
  userId: number;
  checklistId: number;
  group?: GroupedAnswersDto;
}

const initialState: IMyChecklistsReducerInitialState = {
  checklists: [],
  originalChecklists: [],
};

export const selectedFormSlice = createSlice({
  name: "selectedChecklist",
  initialState,
  reducers: {
    changeSelectedChecklist: (state, action: PayloadAction<ChecklistVm>) => {
      const foundFormIndex = state.checklists.findIndex(
        (item) => item.id === action.payload.id
      );
      if (foundFormIndex == -1) {
        state.checklists.push(action.payload);
        state.originalChecklists.push(action.payload);
      } else {
        state.checklists.splice(foundFormIndex, 1, action.payload);
        state.originalChecklists.splice(foundFormIndex, 1, action.payload);
      }
    },
    revertChangesInChecklist: (state, action: PayloadAction<ChecklistVm>) => {
      const updatedState = state.checklists.map((modifiedForm) => {
        if (action.payload.id === modifiedForm.id) {
          return action.payload;
        }
        return modifiedForm;
      });
      state.checklists = updatedState;
    },
    updateChecklistAnswer: (
      state,
      action: PayloadAction<UpdateChecklistsAnswer>
    ) => {
      const foundChecklists = state.checklists.find(
        (item) => item.id === action.payload.checklistId
      );

      if (!foundChecklists) {
        return;
      }

      let foundAnswer: AnswerDto | undefined = undefined;

      if (action.payload.group) {
        const foundGroup = foundChecklists.formAnswer.groupedAnswers.find(
          (group) => group.groupIndex === action.payload.group?.groupIndex
        );

        if (!foundGroup) {
          return;
        }

        foundAnswer = foundGroup.questions.find(
          (answer) =>
            JSON.stringify(answer) === JSON.stringify(action.payload.answer)
        );
      } else {
        foundAnswer = foundChecklists.formAnswer.answers.find(
          (answer) =>
            JSON.stringify(answer) === JSON.stringify(action.payload.answer)
        );
      }

      if (!foundAnswer) {
        return;
      }

      foundAnswer.lastChangedAt = new Date().toDateString();
      foundAnswer.lastChangedBy = action.payload.userId;
      foundAnswer.value = action.payload.value;
    },
    updateChecklistComment: (
      state,
      action: PayloadAction<UpdateChecklistsComment>
    ) => {
      const foundChecklists = state.checklists.find(
        (item) => item.id === action.payload.checklistId
      );

      if (!foundChecklists) {
        return;
      }

      let foundAnswer: AnswerDto | undefined = undefined;

      if (action.payload.group) {
        const foundGroup = foundChecklists.formAnswer.groupedAnswers.find(
          (group) => group.groupIndex === action.payload.group?.groupIndex
        );

        if (!foundGroup) {
          return;
        }

        foundAnswer = foundGroup.questions.find(
          (answer) =>
            JSON.stringify(answer) === JSON.stringify(action.payload.answer)
        );
      } else {
        foundAnswer = foundChecklists.formAnswer.answers.find(
          (answer) =>
            JSON.stringify(answer) === JSON.stringify(action.payload.answer)
        );
      }

      if (!foundAnswer) {
        return;
      }

      foundAnswer.lastChangedAt = new Date().toDateString();
      foundAnswer.lastChangedBy = action.payload.userId;
      foundAnswer.comment = action.payload.comment;
    },
    updateChecklistStatus: (
      state,
      action: PayloadAction<IUpdateDeviationReduxStatus>
    ) => {
      const foundChecklists = state.checklists.find(
        (item) => item.id === action.payload.formLinkId
      );
      if (!foundChecklists) {
        return;
      }
      foundChecklists.formAnswer.status = action.payload.status;
    },
  },
});

export const {
  changeSelectedChecklist,
  updateChecklistAnswer,
  updateChecklistComment,
  revertChangesInChecklist,
  updateChecklistStatus,
} = selectedFormSlice.actions;

export const selectedForm = (state: RootState) => state;

export default selectedFormSlice.reducer;
