import SeparationLine from "components/common/SeparationLine/SeparationLine";
import React from "react";
import "./FiltrationPosition.scss";

interface IFiltrationPosition {
  title: string;
  selectButton: JSX.Element;
  onPositionClickFunction?: () => void;
}

const FiltrationPosition = ({
  title,
  selectButton,
  onPositionClickFunction,
}: IFiltrationPosition) => {
  return (
    <React.Fragment>
      <div
        onClick={() => onPositionClickFunction && onPositionClickFunction()}
        className={"common-components__filtration-position-wrapper"}
      >
        <div className={"common-components__filtration-position-title"}>
          {title}
        </div>
        {selectButton}
      </div>
      <SeparationLine />
    </React.Fragment>
  );
};

export default FiltrationPosition;
