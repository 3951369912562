import { isValid } from "date-fns";
import { checkIfStringIsNaturalNumber } from "helpers/General/CheckIfStringIsNaturalNumber";

export const createADateFromTime = (time: string, date?: Date) => {
  const modifiedDate = date ? date : new Date();
  if (checkIfStringIsNaturalNumber(time) && +time <= 24) {
    modifiedDate.setHours(+time);
    modifiedDate.setMinutes(0);
    modifiedDate.setSeconds(0);
    return modifiedDate;
  }
  if (+time.split(":")[0] < 24 && +time.split(":")[0] >= 0) {
    modifiedDate.setHours(+time.split(":")[0]);
  } else {
    return;
  }
  if (+time.split(":")[1] < 60 && +time.split(":")[1] >= 0) {
    modifiedDate.setMinutes(+time.split(":")[1]);
  } else {
    return;
  }
  modifiedDate.setSeconds(0);
  if (isValid(modifiedDate)) {
    return modifiedDate;
  }
};
