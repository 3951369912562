import { IMyProjectTasksVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectTasksVm";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";

export const parseTasksDividedByDatesToOneArray = (
  tasks: IMyProjectTasksVm | null
) => {
  const allTasks: IMyTaskVm[] = [];
  if (tasks) {
    allTasks.push(
      ...tasks.completedTasks,
      ...tasks.inProgressTasks,
      ...tasks.toDoTasks
    );
  }

  return allTasks;
};
