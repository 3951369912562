import DescriptionOverviewComponent from "components/common/DescriptionComponents/DescriptionOverviewComponent/DescriptionOverviewComponent";
import MoneyValueBadge, {
  AvailableMoneyBadgeColours,
} from "components/common/Badges/MoneyValueBadge/MoneyValueBadge";
import ProjectPageInformationHeader from "components/common/ProjectPageInformationHeader/ProjectPageInformationHeader";
import { parseTasksDividedByDatesToOneArray } from "helpers/TasksHelpers/ParseTasksDividedByDatesToOneArray";
import { useSelector } from "react-redux";
import { selectedProject } from "store/Redux/Reducers/projectReducer";
import "./ProjectOverviewPageMain.scss";
import ProjectOverviewPageDropdown from "./ProjectOverviewPageDropdown/ProjectOverviewPageDropdown";
import TaskContainerContent from "./ContainerContents/TasksContainerContent";
import UsersContainerContent from "./ContainerContents/UsersContainerContent";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";

interface IProjectOverviewPageMain {
  projectMembers: IMyUserVm[];
}

const ProjectOverviewPageMain = ({
  projectMembers,
}: IProjectOverviewPageMain) => {
  const globalStates = useSelector(selectedProject);
  const tasks = parseTasksDividedByDatesToOneArray(
    globalStates.chosenProjectReducer.projectTasks
  );
  return (
    <div className="project-overview-page-main__layout">
      {globalStates.chosenProjectReducer.project &&
        globalStates.chosenOrganizationReducer.myOrganization && (
          <>
            <ProjectPageInformationHeader
              project={globalStates.chosenProjectReducer.project}
              myOrganization={
                globalStates.chosenOrganizationReducer.myOrganization
              }
            />
            <main className="project-overview-page-main__badges-layout">
              <MoneyValueBadge
                moneySum={globalStates.chosenProjectReducer.project.budget}
                currency={
                  globalStates.chosenOrganizationReducer.myOrganization.currency
                }
                badgeAndIconColor={AvailableMoneyBadgeColours.gray}
              />
              <MoneyValueBadge
                moneySum={
                  globalStates.chosenProjectReducer.project.estimatedCost
                }
                currency={
                  globalStates.chosenOrganizationReducer.myOrganization.currency
                }
                badgeAndIconColor={AvailableMoneyBadgeColours.green}
              />
              <DescriptionOverviewComponent
                text={globalStates.chosenProjectReducer.project.description}
                showSeparationLine={true}
                marginBottom={32}
                marginTop={16}
              />
            </main>
            <ProjectOverviewPageDropdown
              count={tasks.length}
              dropdownName={"Tasks"}
              containerContent={<TaskContainerContent tasks={tasks} />}
            />
            <ProjectOverviewPageDropdown
              count={projectMembers ? projectMembers.length : 0}
              dropdownName={"Project Team"}
              containerContent={
                <UsersContainerContent
                  users={projectMembers ? projectMembers : []}
                />
              }
            />
          </>
        )}
    </div>
  );
};

export default ProjectOverviewPageMain;
