import application from "../../config.json";
import { HttpRequestsMethods } from "../../interfaces/Enums/HttpRequestsMethods";
import { ICreateNewTaskRequest } from "../../interfaces/Requests/ICreateNewTaskRequest";
import { IMyTaskVm } from "../../interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import { TaskExecutionState } from "interfaces/Enums/TasksExecutionState";
import { AxiosClient } from "./AxiosClient";
import { IUpdateTaskDateRequest } from "interfaces/Requests/IUpdateTaskDateRequest";

const base = application.baseUrl;
const MyTasks = "MyTasks";

const createTask = async (body: ICreateNewTaskRequest): Promise<number> => {
  return AxiosClient(HttpRequestsMethods.POST, `${MyTasks}`, base, { body });
};

const getTaskById = async (taskId: number): Promise<IMyTaskVm> => {
  return AxiosClient(HttpRequestsMethods.GET, `${MyTasks}/${taskId}`, base);
};

const updateTaskName = async (
  taskId: number,
  body: string
): Promise<IMyTaskVm> => {
  return AxiosClient(
    HttpRequestsMethods.PUT,
    `${MyTasks}/name/${taskId}`,
    base,
    {
      body,
    }
  );
};

const updateTaskAssignees = async (
  taskId: number,
  body: number[]
): Promise<IMyTaskVm> => {
  return AxiosClient(
    HttpRequestsMethods.PUT,
    `${MyTasks}/assignees/${taskId}`,
    base,
    {
      body,
    }
  );
};

const updateAssigneeTasks = async (
  userId: number,
  body: number[]
): Promise<IMyTaskVm> => {
  return AxiosClient(
    HttpRequestsMethods.PUT,
    `${MyTasks}/assignee/${userId}`,
    base,
    {
      body,
    }
  );
};

const updateTaskStatus = async (
  taskId: number,
  body: TaskExecutionState
): Promise<IMyTaskVm> => {
  return AxiosClient(
    HttpRequestsMethods.PUT,
    `${MyTasks}/status/${taskId}`,
    base,
    {
      body,
    }
  );
};

const updateTaskDates = async (
  taskId: number,
  body: IUpdateTaskDateRequest
): Promise<IMyTaskVm> => {
  return AxiosClient(
    HttpRequestsMethods.PUT,
    `${MyTasks}/dates/${taskId}`,
    base,
    {
      body,
    }
  );
};

const updateTaskDescription = async (
  taskId: number,
  body: string
): Promise<IMyTaskVm> => {
  return AxiosClient(
    HttpRequestsMethods.PUT,
    `${MyTasks}/description/${taskId}`,
    base,
    {
      body,
    }
  );
};

export {
  createTask,
  getTaskById,
  updateTaskDescription,
  updateTaskAssignees,
  updateAssigneeTasks,
  updateTaskStatus,
  updateTaskName,
  updateTaskDates,
};
