import { differenceInMinutes, getYear, startOfWeek } from "date-fns";
import { IMyTimeEntriesWeek } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntriesWeek";
import { IMyTimeEntryVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVm";
import { createNewDay } from "./CreateNewDay";
import { getWeekDuration } from "./GetWeekDuration";

export const createNewWeek = (
  timeEntry: IMyTimeEntryVm,
  weekNumber: number
) => {
  const newDay = createNewDay(timeEntry);
  const newWeek: IMyTimeEntriesWeek = {
    weekDuration: getWeekDuration(new Date(timeEntry.startDate)),
    yearNumber: getYear(new Date(timeEntry.startDate)),
    weekNumber: weekNumber,
    firstDayOfWeek: startOfWeek(new Date(timeEntry.startDate)),
    sumOfMinutes: differenceInMinutes(
      new Date(timeEntry.finishDate),
      new Date(timeEntry.startDate)
    ),
    timeEntriesDays: [newDay],
  };
  return newWeek;
};
