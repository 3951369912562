import { AxiosClient } from "./AxiosClient";
import application from "../../config.json";
import { HttpRequestsMethods } from "interfaces/Enums/HttpRequestsMethods";
import DeviationVm from "interfaces/ViewModels/MyDeviationsViewModels/DeviationVm";
import { IUpdateFormStatus } from "interfaces/Requests/IUpdateFormStatus";
import { DeviationCategoryVm } from "interfaces/ViewModels/MyFormsViewModels/DeviationCategoryVm";
import { IUpdateDeviationDetailsRequest } from "interfaces/Requests/IUpdateDeviationDetailsRequest";
import { IAddDeviationRequest } from "interfaces/Requests/IAddDeviationRequest";
import { DeviationCauseVm } from "interfaces/ViewModels/MyFormsViewModels/DeviationCauseVm";

const base = application.baseUrl;
const MyForms = "MyDeviations";

const getTaskDeviations = async (
  taskId: number,
  projectId: number
): Promise<DeviationVm[]> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyForms}/Project/${projectId}/Task/${taskId}`,
    base
  );
};

const getDeviationsForProject = async (
  projectId: number
): Promise<DeviationVm[]> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyForms}/Project/${projectId}`,
    base
  );
};

const changeDeviationStatus = async (
  body: IUpdateFormStatus
): Promise<null> => {
  return AxiosClient(HttpRequestsMethods.PUT, `${MyForms}/Status`, base, {
    body,
  });
};

const getDeviationsCategories = async (
  organizationId: number
): Promise<DeviationCategoryVm[]> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyForms}/Organization/${organizationId}/Categories`,
    base
  );
};

const getDeviationsCauses = async (
  organizationId: number
): Promise<DeviationCauseVm[]> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyForms}/Organization/${organizationId}/Causes`,
    base
  );
};

const updateDeviationDetails = async (
  body: IUpdateDeviationDetailsRequest
): Promise<DeviationVm> => {
  return AxiosClient(HttpRequestsMethods.PUT, `${MyForms}/Details`, base, {
    body,
  });
};

const addDeviation = async (body: IAddDeviationRequest): Promise<number> => {
  return AxiosClient(
    HttpRequestsMethods.POST,
    `${MyForms}/AddDeviation`,
    base,
    { body }
  );
};

export {
  getTaskDeviations,
  getDeviationsForProject,
  getDeviationsCategories,
  changeDeviationStatus,
  updateDeviationDetails,
  addDeviation,
  getDeviationsCauses,
};
