import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import { IFiltration } from "store/Redux/Reducers/projectsFilterReducer";
import { datesTasksAndAssigneesFilters } from "./FiltrateProjects";

export const shouldShowTasks = (
  foundFilter: IFiltration | undefined,
  condition: boolean | undefined,
  tasks: IMyTaskVm[]
) => {
  if (foundFilter && condition) {
    return datesTasksAndAssigneesFilters(foundFilter, tasks);
  }
  return [];
};
