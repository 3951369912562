import "./CancelButton.scss";

interface ICancelButton {
  cancel: () => void;
  buttonName?: string;
}

const CancelButton = ({ cancel, buttonName = "Cancel" }: ICancelButton) => {
  return (
    <div onClick={cancel} className={"common-components__cancel-button"}>
      {buttonName}
    </div>
  );
};

export default CancelButton;
