import FiltrationsHeader from "components/common/FiltrationsHeader/FiltrationsHeader";
import GrayFiltrationBar from "components/common/GrayFiltrationBar/GrayFiltrationBar";
import ListOfTasksContainer from "components/common/ListOfTasks/ListOfTasksContainer/ListOfTasksContainer";
import { parseTasksDividedByDatesToOneArray } from "helpers/TasksHelpers/ParseTasksDividedByDatesToOneArray";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import {
  getProjectMemberTasksWithDetails,
  getProjectTasksWithDetails,
} from "Pages/api/MyProjectsClient";
import { updateAssigneeTasks } from "Pages/api/MyTasksClient";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./ProjectTeamAssignTaskPage.scss";

const ProjectTeamAssignTaskPage = () => {
  const [allTasks, setAllTasks] = useState<IMyTaskVm[]>([]);
  const [filtratedItems, setFiltratedItems] = useState<IMyTaskVm[]>(allTasks);
  const [chosenTasks, setChosenTasks] = useState<IMyTaskVm[]>([]);
  const { projectId, memberId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleAssignTaskToUsers = async () => {
    if (!memberId) {
      navigate("/error");
      return;
    }
    await updateAssigneeTasks(
      +memberId,
      chosenTasks.map((task) => task.id)
    );
    navigate(-1);
  };

  const handleProjectTasksWithDetails = async (projectId: number) => {
    const tasks = await getProjectTasksWithDetails(projectId);
    const parsedTasks = parseTasksDividedByDatesToOneArray(tasks);
    setAllTasks(parsedTasks);
    setFiltratedItems(parsedTasks);
  };

  const handleGetAssignedTasksToUser = async (
    projectId: number,
    userId: number
  ) => {
    const tasksVm = await getProjectMemberTasksWithDetails(projectId, userId);
    const allChosenTasks = [
      ...tasksVm.completedTasks,
      ...tasksVm.toDoTasks,
      ...tasksVm.inProgressTasks,
    ];
    setChosenTasks(allChosenTasks);
    setIsLoading(false);
  };

  useEffect(() => {
    if (projectId && memberId) {
      handleProjectTasksWithDetails(+projectId);
      handleGetAssignedTasksToUser(+projectId, +memberId);
    }
  }, [projectId, memberId]);

  if (isLoading) {
    return <div />;
  }
  return (
    <div className="project-team-assign-task__layout">
      <FiltrationsHeader
        pageName={"Users"}
        applyFiltersText={"Save"}
        confimationFunction={handleAssignTaskToUsers}
        showConfirmationToast={false}
      />
      <main className="project-team-assign-task__main-layout">
        <GrayFiltrationBar
          originalArray={allTasks}
          setFiltratedItems={setFiltratedItems}
        />
        <ListOfTasksContainer
          tasks={filtratedItems}
          chosenTasks={chosenTasks}
          setChosenTasks={setChosenTasks}
        />
      </main>
    </div>
  );
};

export default ProjectTeamAssignTaskPage;
