import "./CommentAnswers.scss";

interface ICommentAnswers {
  text: string;
}

const CommentAnswers = ({ text }: ICommentAnswers) => {
  return (
    <div className="checklist-answers__comment-answer--text">
      {text !== "" && `Comment: `}
      {text}
    </div>
  );
};

export default CommentAnswers;
