import { differenceInCalendarDays, differenceInBusinessDays } from "date-fns";

export const calculateDifferenceInCalendarDays = (
  startDate: Date,
  dueDate: Date
) => {
  return differenceInCalendarDays(dueDate, startDate);
};

export const calculateDifferenceInBusinessDays = (
  startDate: Date,
  dueDate: Date
) => {
  return differenceInBusinessDays(dueDate, startDate);
};
