import { createADateFromTime } from "helpers/TimeHelpers/CreateADateFromStringTime";
import { setTotalValue } from "helpers/TimeHelpers/SetTotalValue";
import React from "react";
import HoursContainer from "./HoursContainer/HoursContainer";
import "./HoursInputs.scss";

interface IHoursInputs {
  setTotalTime: React.Dispatch<React.SetStateAction<string>>;
  totalTime: string;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  date: Date;
  hasPermissionToEditTimeEntry: boolean;
}

const HoursInputs = ({
  setTotalTime,
  totalTime,
  setDate,
  date,
  hasPermissionToEditTimeEntry,
}: IHoursInputs) => {
  const totalDateTime = createADateFromTime(totalTime);
  return (
    <div className={"common-components__hours-inputs"}>
      <div className={"common-components__hours-inputs-total-label"}>
        Total:
        <span className={"common-components__hours-inputs-total-hours-label"}>
          {setTotalValue(totalDateTime)}
        </span>
      </div>
      <HoursContainer
        setTotalTime={setTotalTime}
        setDate={setDate}
        date={date}
        totalTime={totalTime}
        hasPermissionToEditTimeEntry={hasPermissionToEditTimeEntry}
      />
    </div>
  );
};

export default HoursInputs;
