import Footer from "components/common/Footers/Footer";
import TaskPageHeader from "components/ProjectsComponents/ProjectPageComponents/Pages/TaskPageComponents/TaskPageHeader/TaskPageHeader";
import TaskPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/TaskPageComponents/TaskPageMain/TaskPageMain";
import { storePathValues } from "helpers/General/StorePathValue";
import { downloadAllTasksFiles } from "Pages/api/FileDownloadClient";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DownloadedFile } from "one-common-components/lib/components/Files/Interfaces/DownloadedFile";
import "./TaskPage.scss";
import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import { useAppSelector } from "store/Redux/hooks";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";

const TaskPage = () => {
  const [files, setFiles] = useState<DownloadedFile[]>([]);
  const [filesWereLoaded, setFilesWereLoaded] = useState<boolean>(false);
  const globalStates = useAppSelector(chosenOrganization);
  const { taskId } = useParams();
  storePathValues();
  const handleTaskFiles = async (taskId: number) => {
    setFiles(await downloadAllTasksFiles(taskId));
    setFilesWereLoaded(true);
  };

  useEffect(() => {
    if (taskId) {
      handleTaskFiles(+taskId);
    }
  }, [taskId]);

  if (!globalStates.userProfileReducer.userProfile) {
    return <div />;
  }

  return (
    <div className="task-page__page-layout">
      <TaskPageHeader />
      <TaskPageMain
        myFiles={files}
        setMyFiles={setFiles}
        filesWereLoaded={filesWereLoaded}
      />
      <Footer
        isInProjectsPage={false}
        isInTimeEntriesPage={false}
        hasPermissionToCreate={restrictProjectAccessBySpecifiedRoles(
          globalStates.userProfileReducer.userProfile.projectRole,
          [ProjectRolesEnum.Viewer]
        )}
      />
    </div>
  );
};

export default TaskPage;
