import Footer from "components/common/Footers/Footer";
import HeaderAndCustomEndElement from "components/common/Headers/HeaderWithNotifications/HeaderAndCustomEndElement";
import NotifiactionsBellAndMenu from "components/common/NotifiactionsBellAndMenu/NotifiactionsBellAndMenu";
import DeviationsPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/DeviationsPageComponents/DeviationsPageMain";
import { projectsRoute } from "constants/Routes";
import DeviationVm from "interfaces/ViewModels/MyDeviationsViewModels/DeviationVm";
import { getDeviationsForProject } from "Pages/api/MyDeviationsClient";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/Redux/hooks";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import "./DeviationsPage.scss";
import { compareDesc } from "date-fns";
import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";

const DeviationsPage = () => {
  const { projectId } = useParams();
  const [deviations, setDeviations] = useState<DeviationVm[]>([]);
  const globalStates = useAppSelector(chosenOrganization);
  const navigate = useNavigate();

  const handleDeviations = async (projectId: number) => {
    const result = await getDeviationsForProject(projectId);
    const sortedDeviations = result.sort((firstItem, secondItem) =>
      compareDesc(new Date(firstItem.assigned), new Date(secondItem.assigned))
    );
    setDeviations(sortedDeviations);
  };

  useEffect(() => {
    if (!projectId) {
      navigate("/error");
      return;
    }
    handleDeviations(+projectId);
  }, []);

  if (
    !globalStates.allUsersOrganizationsSlice.usersOrganizations ||
    !globalStates.userProfileReducer.userProfile
  ) {
    return <div />;
  }
  return (
    <div className="deviations-page__layout">
      <HeaderAndCustomEndElement
        endElement={
          <NotifiactionsBellAndMenu
            organizations={
              globalStates.allUsersOrganizationsSlice.usersOrganizations
            }
            userDetails={globalStates.userProfileReducer.userProfile}
            myOrganizationId={
              globalStates.chosenOrganizationReducer.chosenOrganizationId
            }
          />
        }
        returnUrl={`/${projectsRoute}/${projectId}`}
      />
      <DeviationsPageMain deviations={deviations} />
      <Footer
        isInProjectsPage={false}
        isInTimeEntriesPage={false}
        hasPermissionToCreate={restrictProjectAccessBySpecifiedRoles(
          globalStates.userProfileReducer.userProfile.projectRole,
          [ProjectRolesEnum.Viewer]
        )}
      />
    </div>
  );
};

export default DeviationsPage;
