import FiltrationsHeader from "components/common/FiltrationsHeader/FiltrationsHeader";
import GrayFiltrationBar from "components/common/GrayFiltrationBar/GrayFiltrationBar";
import ListOfUsersContainer from "components/common/ListOfUsers/ListOfUsersContainer/ListOfUsersContainer";
import { projectsRoute, teamRoute } from "constants/Routes";
import {
  IMyUserVmMerged,
  mergeMembersCredentials,
} from "helpers/General/MergeMembersCredentials";
import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import { getOrganizationUsersWithoutSpecificProject } from "Pages/api/MyUserOrganizationsClient";
import { addUsersToProject } from "Pages/api/MyUserProjectsClient";
import NoAccessPage from "Pages/NoAccessPage/NoAccessPage";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import { setProjectMembers } from "store/Redux/Reducers/projectReducer";
import "./ProjectTeamAddMemberPage.scss";

const ProjectTeamAddMemberPage = () => {
  const [organizationUsers, setOrganizationUsers] = useState<IMyUserVm[]>();
  const [chosenUsers, setChosenUsers] = useState<IMyUserVm[]>([]);
  const [filtratedUsers, setFiltratedUsers] = useState<IMyUserVmMerged[]>([]);
  const navigate = useNavigate();
  const { projectId } = useParams();
  const globalStates = useSelector(chosenOrganization);
  const dispatch = useAppDispatch();

  const handleAddUsersToProject = async () => {
    if (!projectId) {
      navigate("/error");
      return;
    }
    await addUsersToProject(
      chosenUsers.map((item) => item.id),
      +projectId
    );
    dispatch(
      setProjectMembers([
        ...globalStates.chosenProjectReducer.projectMembers,
        ...chosenUsers.map((item) => {
          return { ...item, projectRoleId: ProjectRolesEnum.Member };
        }),
      ])
    );
    navigate(`/${projectsRoute}/${projectId}/${teamRoute}`);
  };

  const handleOrganizationUsers = async (projectId: number) => {
    const organizationUsers = await getOrganizationUsersWithoutSpecificProject(
      globalStates.chosenOrganizationReducer.chosenOrganizationId,
      projectId
    );
    setFiltratedUsers(mergeMembersCredentials(organizationUsers));
    setOrganizationUsers(organizationUsers);
  };

  useEffect(() => {
    if (projectId) {
      handleOrganizationUsers(+projectId);
    }
  }, [projectId]);

  const hasPermissionToAddMembers = useMemo(() => {
    if (globalStates.userProfileReducer.userProfile !== null) {
      return restrictProjectAccessBySpecifiedRoles(
        globalStates.userProfileReducer.userProfile.projectRole,
        [
          ProjectRolesEnum.Member,
          ProjectRolesEnum.Guest,
          ProjectRolesEnum.Viewer,
        ]
      );
    }
    return false;
  }, [globalStates.userProfileReducer.userProfile]);

  if (!hasPermissionToAddMembers) {
    return <NoAccessPage />;
  }

  if (!organizationUsers || !filtratedUsers) {
    return <div />;
  }
  return (
    <div className="project-team-add-member__layout">
      <FiltrationsHeader
        pageName={"Users"}
        applyFiltersText={"Save"}
        confimationFunction={handleAddUsersToProject}
        showConfirmationToast={false}
      />
      <main className="project-team-add-member__main-layout">
        <GrayFiltrationBar
          originalArray={mergeMembersCredentials(organizationUsers)}
          setFiltratedItems={setFiltratedUsers}
        />
        <ListOfUsersContainer
          users={filtratedUsers}
          chosenUsers={chosenUsers}
          setChosenUsers={setChosenUsers}
        />
      </main>
    </div>
  );
};

export default ProjectTeamAddMemberPage;
