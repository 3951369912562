import React from "react";
import "./ChecklistsCheckbox.scss";
import ChecklistSimpleCheckbox from "./ChecklistSimpleCheckbox/ChecklistSimpleCheckbox";

interface IChecklistsCheckbox {
  text: string;
  height?: number;
  checked: boolean;
  onClick: (value: string) => void;
}

const ChecklistsCheckbox = ({
  text,
  height,
  checked,
  onClick,
}: IChecklistsCheckbox) => {
  return (
    <div
      className={"common-components__checkbox-item-wrapper"}
      style={{ height: height }}
    >
      <ChecklistSimpleCheckbox
        checked={checked}
        onClick={onClick}
        value={text}
      />
      <span className={"common-components__checkbox-item-text"}>{text}</span>
    </div>
  );
};

export default ChecklistsCheckbox;
