import Footer from "components/common/Footers/Footer";
import HeaderAndCustomEndElement from "components/common/Headers/HeaderWithNotifications/HeaderAndCustomEndElement";
import NotifiactionsBellAndMenu from "components/common/NotifiactionsBellAndMenu/NotifiactionsBellAndMenu";
import DocumentsPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/DocumentsPageComponents/DocumentsPageMain";
import { projectsRoute } from "constants/Routes";
import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import { FileMetadata } from "interfaces/ViewModels/MyDocumentsViewModels/FileMetadata";
import { downloadAllProjectFilesMetadata } from "Pages/api/FileDownloadClient";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import "./DocumentsPage.scss";

const DocumentsPage = () => {
  const [documents, setDocuments] = useState<FileMetadata[]>();
  const { projectId } = useParams();
  const globalStates = useSelector(chosenOrganization);

  const handleGetProjectDocuments = async (projectId: number) => {
    const documents = await downloadAllProjectFilesMetadata(projectId);
    const mappedDocumenstForFiltration = documents.map((item) => {
      return { ...item, name: item.fileDisplayedName };
    });
    setDocuments(mappedDocumenstForFiltration);
  };

  useEffect(() => {
    if (projectId) {
      handleGetProjectDocuments(+projectId);
    }
  }, [projectId]);

  if (
    !globalStates.allUsersOrganizationsSlice.usersOrganizations ||
    !globalStates.userProfileReducer.userProfile ||
    !documents
  ) {
    return <div />;
  } else {
    return (
      <div className="documents-page__layout">
        <HeaderAndCustomEndElement
          endElement={
            <NotifiactionsBellAndMenu
              organizations={
                globalStates.allUsersOrganizationsSlice.usersOrganizations
              }
              userDetails={globalStates.userProfileReducer.userProfile}
              myOrganizationId={
                globalStates.chosenOrganizationReducer.chosenOrganizationId
              }
            />
          }
          returnUrl={`/${projectsRoute}/${projectId}`}
        />
        <DocumentsPageMain documents={documents} setDocuments={setDocuments} />
        <Footer
          isInProjectsPage={false}
          isInTimeEntriesPage={false}
          hasPermissionToCreate={restrictProjectAccessBySpecifiedRoles(
            globalStates.userProfileReducer.userProfile.projectRole,
            [ProjectRolesEnum.Viewer]
          )}
        />
      </div>
    );
  }
};

export default DocumentsPage;
