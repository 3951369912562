import isNullOrWhitespace from "./IsNullOrWhitespace";

export const returnUsersCredentials = (
  name: string,
  surName: string,
  email: string
) => {
  if (isNullOrWhitespace(name) || isNullOrWhitespace(surName)) {
    return email;
  } else {
    return `${name} ${surName}`;
  }
};
