import useAuth0 from "Auth/use-auth0";
import TurnOffIcon from "../../../public/TurnOffIcon.svg";
import "./LogOutComponent.scss";

const LogOutComponent = () => {
  const { logout } = useAuth0();
  return (
    <>
      <div
        onClick={() => logout({ returnTo: window.location.origin })}
        className={"getting-started-page__log-out-button"}
      >
        <img src={TurnOffIcon} alt={"Turn Off Icon"} height={21} width={21} />
      </div>
      <span className={"getting-started-page__log-out-button-text"}>
        Log out button
      </span>
    </>
  );
};

export default LogOutComponent;
