import { ILabelValueStructure } from "interfaces/Other/ILabelValueStructure";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import { getProjectChecklists } from "Pages/api/MyProjectsClient";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import "./NewTaskPage.scss";
import toast from "react-hot-toast";
import { IMyProjectChecklists } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectChecklists";
import { createTask } from "Pages/api/MyTasksClient";
import { useNavigate, useParams } from "react-router-dom";
import AsyncToast from "components/common/Toasts/AsyncToast";
import NewTaskPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/NewTaskPageComponents/NewTaskPageMain/NewTaskPageMain";
import CancelSaveBottomBar from "components/common/Footers/CancelSaveBottomBar/CancelSaveBottomBar";
import HeaderPageTitleAndReturn from "components/common/Headers/HeaderPageTitleAndReturn/HeaderPageTitleAndReturn";
import { useAppSelector } from "store/Redux/hooks";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import { addMultipleFiles } from "helpers/Files/AddNewFileRequest";
import { FileModuleEnum } from "one-common-components/lib/enums/FileModuleEnum";
import { TaskExecutionState } from "interfaces/Enums/TasksExecutionState";
import useViewerPageRestriction from "Hooks/UseViewerPageRestrictions";
import NoAccessPage from "Pages/NoAccessPage/NoAccessPage";

export interface ICreateTaskFormInput extends FieldValues {
  Name: string;
  From: Date;
  To: Date;
  "Estimate Duration": number;
  "Team members": IMyUserVm[];
  Checklists: number[];
  description: string;
}

export interface ICreateNewTaskRequest {
  projectId: number;
  name: string;
  description: string;
  startDate: Date;
  DueDate: Date;
  duration: number;
  executionState: TaskExecutionState;
  selectedChecklistTemplatesIds: number[];
  selectedUsersIds: number[];
}

const NewTask = () => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [dueDate, setDueDate] = useState<Date | null>(new Date());
  const [users, setUsers] = useState<ILabelValueStructure[]>([]);
  const [checklists, setChecklists] = useState<ILabelValueStructure[]>([]);
  const [projectChecklists, setProjectChecklists] =
    useState<IMyProjectChecklists>();
  const { projectId } = useParams();
  const globalStates = useAppSelector(chosenOrganization);
  const { register, getValues } = useForm<ICreateTaskFormInput>();
  const [duration, setDuration] = useState<string>("1");
  const navigate = useNavigate();
  const { hasPermissions } = useViewerPageRestriction();
  const [myFiles, setMyFiles] = useState<File[]>([]);

  const onSubmit = async () => {
    const request: ICreateNewTaskRequest = {
      projectId: Number(projectId),
      name: getValues("Name"),
      description: getValues("description"),
      startDate: startDate ? startDate : new Date(),
      DueDate: dueDate ? dueDate : new Date(),
      duration: +duration,
      selectedChecklistTemplatesIds: checklists.map((item) =>
        Number(item.value)
      ),
      selectedUsersIds: users.map((item) => Number(item.value)),
      executionState: TaskExecutionState.ToDo,
    };
    if (!projectId) {
      navigate("/error");
      return;
    }
    if (+duration <= 0) {
      toast.error("Duration should not be below 1");
    } else if (getValues("Name") === "") {
      toast.error("Name should not be empty");
    } else {
      const taskId = await AsyncToast(
        createTask(request),
        "Task created successfully"
      );
      if (myFiles.length > 0) {
        await AsyncToast(
          addMultipleFiles(
            taskId,
            myFiles,
            FileModuleEnum.Tasks,
            globalStates.chosenOrganizationReducer.chosenOrganizationId,
            +projectId
          ),
          "Adding file completed"
        );
      }
      returnToPreviousPage();
    }
  };

  const handleProjectsChecklists = async (projectId: number) => {
    setProjectChecklists(await getProjectChecklists(projectId));
  };

  useEffect(() => {
    if (projectId) {
      handleProjectsChecklists(+projectId);
    }
  }, [projectId]);

  if (!globalStates.chosenProjectReducer.projectMembers && !projectChecklists) {
    return <div />;
  }

  const returnToPreviousPage = () => {
    navigate(-1);
  };
  if (!hasPermissions) {
    return <NoAccessPage />;
  }

  return (
    <div className={"new-task-page__main-layout"}>
      <HeaderPageTitleAndReturn
        returnToPreviousPage={returnToPreviousPage}
        text={"Create new task"}
      />
      <NewTaskPageMain
        projectChecklists={
          projectChecklists ? projectChecklists?.projectChecklistsViewModel : []
        }
        projectMembers={
          globalStates.chosenProjectReducer.projectMembers
            ? globalStates.chosenProjectReducer.projectMembers
            : []
        }
        register={register}
        startDate={startDate}
        setStartDate={setStartDate}
        dueDate={dueDate}
        setDueDate={setDueDate}
        setUsers={setUsers}
        users={users}
        setChecklists={setChecklists}
        setDuration={setDuration}
        duration={duration}
        setMyFiles={setMyFiles}
        myFiles={myFiles}
      />
      <CancelSaveBottomBar
        onCancelClick={returnToPreviousPage}
        onSaveClick={onSubmit}
      />
    </div>
  );
};

export default NewTask;
