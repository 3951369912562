import "./ChecklistsAnswerContent.scss";

interface IChecklistsAnswerContent {
  text: string;
}

const ChecklistsAnswerContent = ({ text }: IChecklistsAnswerContent) => {
  return <div className="checklists-answers__content-font-styles">{text}</div>;
};

export default ChecklistsAnswerContent;
