import toast from "react-hot-toast";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AsyncToast = async (action: Promise<any>, message: string) => {
  return await toast.promise(
    action,
    {
      loading: `In progress...`,
      success: `${message}!`,
      error: "Something went wrong",
    },
    {
      success: {
        duration: 2000,
      },
    }
  );
};

export default AsyncToast;
