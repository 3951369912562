import { dotsDateFormat } from "constants/DateFormats";
import { differenceInMinutes, format } from "date-fns";
import { IMyTimeEntriesDay } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntriesDay";
import { IMyTimeEntryVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVm";

export const createNewDay = (timeEntry: IMyTimeEntryVm) => {
  const newDay: IMyTimeEntriesDay = {
    dayDate: format(new Date(timeEntry.startDate), dotsDateFormat),
    sumOfMinutes: differenceInMinutes(
      new Date(timeEntry.finishDate),
      new Date(timeEntry.startDate)
    ),
    timeEntries: [timeEntry],
  };
  return newDay;
};
