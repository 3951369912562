import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUpdateProjectRequest } from "interfaces/Requests/IUpdateProjectRequest";
import { IMyProjectTasksVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectTasksVm";
import { IMyProjectVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectVm";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import type { RootState } from "../store";

export interface IMySelectedProjectState {
  project: IMyProjectVm | null;
  projectTasks: IMyProjectTasksVm | null;
  startDateInferredFromTasks: Date | null;
  endDateInferredFromTasks: Date | null;
  doesProjectContainTasks: boolean;
  projectMembers: IMyUserVm[];
  canEditProjectMembers: boolean;
  canCreateTasks: boolean;
  canCreateMembers: boolean;
}

export interface ProjectsPermissions {
  canCreateTasks: boolean;
  canCreateMembers: boolean;
}

const initialState: IMySelectedProjectState = {
  project: null,
  projectTasks: null,
  startDateInferredFromTasks: null,
  endDateInferredFromTasks: null,
  doesProjectContainTasks: false,
  projectMembers: [],
  canEditProjectMembers: false,
  canCreateTasks: false,
  canCreateMembers: false,
};

export const selectedProjectSlice = createSlice({
  name: "selectedProject",
  initialState,
  reducers: {
    setSelectedProject: (state, action: PayloadAction<IMyProjectVm | null>) => {
      state.project = action.payload;
      if (action.payload !== null) {
        state.doesProjectContainTasks = state.project
          ? state.project?.completedTasksCount +
              state.project?.inProgressTasksCount +
              state.project?.toDoTasksCount >
            0
          : false;
      }
    },
    updateSelectedProject: (
      state,
      action: PayloadAction<IUpdateProjectRequest | null>
    ) => {
      if (action.payload && state.project) {
        state.project.name = action.payload.name;
        state.project.description = action.payload.description;
        state.project.budget = action.payload.budget;
        state.project.estimatedCost = action.payload.estimatedCost;
      }
    },
    setStartProjectDateFromTasks: (
      state,
      action: PayloadAction<Date | null>
    ) => {
      state.startDateInferredFromTasks = action.payload;
    },
    setEndProjectDateFromTasks: (state, action: PayloadAction<Date | null>) => {
      state.endDateInferredFromTasks = action.payload;
    },
    setProjectTasks: (
      state,
      action: PayloadAction<IMyProjectTasksVm | null>
    ) => {
      state.projectTasks = action.payload;
    },
    setProjectMembers: (state, action: PayloadAction<IMyUserVm[]>) => {
      state.projectMembers = action.payload;
    },
    setProjectMembersPermissions: (state, action: PayloadAction<boolean>) => {
      state.canEditProjectMembers = action.payload;
    },
    setProjectPermissions: (
      state,
      action: PayloadAction<ProjectsPermissions>
    ) => {
      state.canCreateMembers = action.payload.canCreateMembers;
      state.canCreateTasks = action.payload.canCreateTasks;
    },
    resetInitialValue: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
  },
});

export const {
  setSelectedProject,
  setStartProjectDateFromTasks,
  setEndProjectDateFromTasks,
  setProjectTasks,
  updateSelectedProject,
  setProjectMembers,
  setProjectMembersPermissions,
  setProjectPermissions,
} = selectedProjectSlice.actions;

export const selectedProject = (state: RootState) => state;

export default selectedProjectSlice.reducer;
