import { checkTaskStateAndReturnDot } from "helpers/TasksHelpers/CheckTaskStateAndReturnDot";
import { checkTaskStateAndReturnUnderline } from "helpers/TasksHelpers/CheckTaskStateAndReturnUndeline";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import TaskInfoContainer from "../TaskInfoContainer/TaskInfoContainer";
import "./ShowAllTasksComponent.scss";
import { useNavigate, useParams } from "react-router-dom";
import { projectsRoute, taskRoute } from "constants/Routes";
import { useAppDispatch } from "store/Redux/hooks";
import { changeSelectedTask } from "store/Redux/Reducers/selectedTaskReducer";

interface IShowAllTasksComponent {
  tasks: IMyTaskVm[];
}

const ShowAllTasksComponent = ({ tasks }: IShowAllTasksComponent) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const navigateToTaskDetails = (item: IMyTaskVm) => {
    dispatch(changeSelectedTask(item));
    navigate(`/${projectsRoute}/${projectId}/${taskRoute}/${item.id}`);
  };
  return (
    <div>
      {tasks
        .sort(
          (firstItem, secondItem) =>
            new Date(firstItem.dueDate).getTime() -
            new Date(secondItem.dueDate).getTime()
        )
        .map((item) => (
          <div
            className={"project-page__main-filtration-items-container"}
            key={item.taskNumberInProject}
          >
            <div
              className="project-page__main-filtration-item-container-wrapper"
              onClick={() => navigateToTaskDetails(item)}
            >
              <TaskInfoContainer
                task={item}
                imgSrc={checkTaskStateAndReturnDot(item.executionState)}
                verticalUnderline={checkTaskStateAndReturnUnderline(
                  item.executionState
                )}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default ShowAllTasksComponent;
