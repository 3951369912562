import { countriesAndCurrenciesOptions } from "interfaces/Other/CountriesAndCurrencies";
import { ILabelValueStructure } from "interfaces/Other/ILabelValueStructure";
import { dropdownStyles } from "styles/DropdownStyles";
import Select from "react-select";
import { UseFormRegister } from "react-hook-form";
import { Dispatch, SetStateAction } from "react";
import { ICreateOrganizationFormInputs } from "interfaces/RegisterInterfaces/ICreateOrganizationFormInputs";
import DropdownWithLabel from "one-common-components/lib/components/SingleInputsPack/DropdownWithLabel/DropdownWithLabel";
import InputWithLabel from "one-common-components/lib/components/SingleInputsPack/InputWithLabel/InputWithLabel";

interface ICreateOrganizationInputs {
  register: UseFormRegister<ICreateOrganizationFormInputs>;
  countryOption: ILabelValueStructure | undefined;
  setCountryOption: Dispatch<SetStateAction<ILabelValueStructure | undefined>>;
}

const CreateOrganizationInputs = ({
  register,
  setCountryOption,
  countryOption,
}: ICreateOrganizationInputs) => {
  return (
    <>
      <InputWithLabel
        label={"Organization name"}
        inputPlaceholder={"Organization name"}
        register={register}
        marginBottom={24}
        required
        datacy="Organization-name-input"
      />
      <DropdownWithLabel
        label={"Country and currency"}
        required
        SelectComponent={
          <Select
            styles={dropdownStyles}
            options={countriesAndCurrenciesOptions}
            onChange={(option: ILabelValueStructure | null) =>
              option && setCountryOption(option)
            }
            id={"Organization-country-select"}
            value={countryOption}
          />
        }
        marginBottom={24}
      />
      <InputWithLabel
        label={"Organization website"}
        inputPlaceholder={"Organization website"}
        register={register}
        marginBottom={24}
        datacy="Organization-website-input"
      />
    </>
  );
};

export default CreateOrganizationInputs;
