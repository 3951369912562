import { GettingStartedContext } from "contexts/GettingStartedContext";
import { IntroductoryPageContent } from "interfaces/Enums/IntroductoryPageContent";
import React from "react";
import "./ReturnButton.scss";
import GrayLeftArrow from "public/GrayLeftArrow.svg";

const ReturnButton = () => {
  const context = React.useContext(GettingStartedContext);

  return (
    <div
      onClick={() => {
        context(IntroductoryPageContent.IntroductoryMain);
      }}
      className={"getting-started-page__return-button"}
    >
      <img src={GrayLeftArrow} alt={"Gray left arrow"} />
    </div>
  );
};

export default ReturnButton;
