import SimpleToast from "components/common/Toasts/SimpleToast";
import { InviteCodeLength } from "constants/InvideCodeLenght";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { joinOrganization } from "Pages/api/MyOrganizationInviteClient";
import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "store/Redux/hooks";
import { setChosenOrganizationIdState } from "store/Redux/Reducers/chosenOrganizationReducer";
import ReturnButton from "../ReturnButton/ReturnButton";
import { getCodeFromLink } from "./Helpers/GetCodeFromLink";
import "./JoinOrganizationMain.scss";

interface IJoinOrganizationMain {
  setMyOrganizationId: Dispatch<SetStateAction<number | undefined>>;
}

interface IFormInputs {
  link: string;
}

const JoinOrganizationMain = ({
  setMyOrganizationId,
}: IJoinOrganizationMain) => {
  const dispatch = useAppDispatch();
  const { register, getValues } = useForm<IFormInputs>();

  const handleFormSubmit = async () => {
    const link = getValues()["link"];
    const code = getCodeFromLink(link);
    if (code.length === InviteCodeLength) {
      const organizationId = await joinOrganization(code);
      setMyOrganizationId(organizationId);
      dispatch(setChosenOrganizationIdState(organizationId));
    } else {
      SimpleToast({
        mode: ToastModes.info,
        message: "The invite link is invalid",
      });
    }
  };

  return (
    <main className={"getting-started-page__join-organization-main"}>
      <div className={"getting-started-page__join-organization-main-text"}>
        <ReturnButton />
        Join organization
      </div>
      <input
        {...register("link", {
          required: true,
        })}
        className={"getting-started-page__join-organization-button--gray"}
        placeholder="Paste your invitation link here"
      />
      <div
        className={"getting-started-page__join-organization-button--green"}
        onClick={handleFormSubmit}
      >
        Join
      </div>
    </main>
  );
};

export default JoinOrganizationMain;
