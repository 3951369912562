import { TaskExecutionState } from "interfaces/Enums/TasksExecutionState";
import SmallGrayDot from "public/SmallGrayDot.svg";
import SmallGreenDot from "public/SmallGreenDot.svg";
import SmallOrangeDot from "public/SmallOrangeDot.svg";
import SmallBlueDot from "public/SmallBlueDot.svg";

export const checkTaskStateAndReturnDot = (
  executionState: TaskExecutionState
): string => {
  switch (executionState) {
    case TaskExecutionState.Completed: {
      return SmallGreenDot;
    }
    case TaskExecutionState.InProgress: {
      return SmallOrangeDot;
    }
    case TaskExecutionState.ToDo: {
      return SmallBlueDot;
    }
    default: {
      return SmallGrayDot;
    }
  }
};
