import ChecklistItem from "components/common/ChecklistsComponents/ChecklistItem/ChecklistItem";
import SmallGreenPlusIcon from "public/SmallGreenPlusIcon.svg";
import "../FormsComponent.scss";
import React from "react";
import ChecklistVm from "interfaces/ViewModels/MyChecklistsViewModels/ChecklistVm";

interface ITasksChecklists {
  assignedForms: ChecklistVm[] | undefined;
  label: string;
  redirectToForm: () => void;
  hasPermissionToEditTask: boolean;
}

const TasksChecklists = ({
  assignedForms,
  label,
  redirectToForm,
  hasPermissionToEditTask,
}: ITasksChecklists) => {
  return (
    <div className="forms-component__main-checklists-part">
      {hasPermissionToEditTask && (
        <div
          className="forms-component__main-add-button"
          onClick={redirectToForm}
        >
          <img src={SmallGreenPlusIcon} alt="Green plus icon" />
          Add {label}
        </div>
      )}
      {assignedForms &&
        assignedForms.map((form) => {
          return <ChecklistItem key={form.id} checklistItem={form} />;
        })}
    </div>
  );
};

export default TasksChecklists;
