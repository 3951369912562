interface ITasksNumberContainer {
  styles: string;
  tasksNumber: number;
}

const TasksNumberContainer = ({
  styles,
  tasksNumber,
}: ITasksNumberContainer) => {
  return (
    <div className={styles}>
      <span>{tasksNumber}</span>
    </div>
  );
};

export default TasksNumberContainer;
