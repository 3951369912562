import "./NoAccessPage.scss";
import RedLockIcon from "public/Big-lock-icon.svg";

const NoAccessPage = () => {
  return (
    <div className="no-access__container" data-cy="No-access-page">
      <div className="no-access__content">
        <img
          width={150}
          height={150}
          src={RedLockIcon}
          alt={"Big red lock icon"}
        />
        <span className="no-access__content--title">You shall not pass</span>
        <span className="no-access__content--subtitle">
          Guess you don’t have access to this page. Check with your admin for
          more information.
        </span>
      </div>
    </div>
  );
};
export default NoAccessPage;
