import AsyncToast from "components/common/Toasts/AsyncToast";
import { TaskExecutionState } from "interfaces/Enums/TasksExecutionState";
import { updateTaskStatus } from "Pages/api/MyTasksClient";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import { setSelectedTaskState } from "store/Redux/Reducers/selectedTaskReducer";
import "./TaskPageExecutionStateDropdown.scss";

interface ITaskPageExceutionStateDropdown {
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaskPageExecutionStateDropdown = ({
  setIsDropdownOpen,
}: ITaskPageExceutionStateDropdown) => {
  const { taskId } = useParams();
  const dispatch = useAppDispatch();
  const handleStatusClick = (status: TaskExecutionState) => {
    if (taskId) {
      AsyncToast(
        updateTaskStatus(+taskId, status),
        "Task status updated successfully"
      );
      dispatch(setSelectedTaskState(status));
      setIsDropdownOpen(false);
    }
  };

  return (
    <div className="task-page__dropdown--execution-stage">
      <div onClick={() => handleStatusClick(TaskExecutionState.ToDo)}>
        To do
      </div>
      <div onClick={() => handleStatusClick(TaskExecutionState.InProgress)}>
        In progress
      </div>
      <div onClick={() => handleStatusClick(TaskExecutionState.Completed)}>
        Completed
      </div>
    </div>
  );
};

export default TaskPageExecutionStateDropdown;
