import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";

const useViewerPageRestriction = () => {
  const globalStates = useSelector(chosenOrganization);

  const hasPermissions = useMemo(() => {
    if (globalStates.userProfileReducer.userProfile !== null) {
      return restrictProjectAccessBySpecifiedRoles(
        globalStates.userProfileReducer.userProfile.projectRole,
        [ProjectRolesEnum.Viewer]
      );
    }
    return false;
  }, [globalStates.userProfileReducer.userProfile]);

  return { hasPermissions };
};
export default useViewerPageRestriction;
