import SeparationLine from "components/common/SeparationLine/SeparationLine";
import mapEnumToArray from "helpers/General/MapEnumToArray";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import { updateProjectMemberRole } from "Pages/api/MyUserProjectsClient";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import { updateSelectedMemberRole } from "store/Redux/Reducers/selectedProjectMemberReducer";
import "./MemberRoleDropdownContent.scss";

interface IMemberRoleDropdownContent {
  memberId: number | undefined;
  setIsMemberRoleDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MemberRoleDropdownContent = ({
  memberId,
  setIsMemberRoleDropdownOpen,
}: IMemberRoleDropdownContent) => {
  const options = ["Team Member", "Viewer"];
  const { projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const roles = mapEnumToArray(ProjectRolesEnum);
  const changeMemberRole = async (selectedRole: string) => {
    if (!projectId || !memberId) {
      navigate("/error");
      return;
    }
    const foundRole: ProjectRolesEnum = roles.find((role: string) =>
      selectedRole.includes(role)
    );
    const roleId = ProjectRolesEnum[foundRole];
    await updateProjectMemberRole(+roleId, +projectId, memberId);
    dispatch(updateSelectedMemberRole(foundRole));
    setIsMemberRoleDropdownOpen(false);
  };

  const renderOptions = () => {
    return options.map((item, index) => {
      return (
        <div
          key={item}
          className="project-team-page__role-dropdown-item--padding"
          onClick={() => changeMemberRole(item)}
        >
          <div className="project-team-page__role-dropdown-item-text">
            {item}
          </div>
          {index !== 2 && <SeparationLine />}
        </div>
      );
    });
  };
  return (
    <div className="project-team-page__role-dropdown-content">
      {renderOptions()}
    </div>
  );
};

export default MemberRoleDropdownContent;
