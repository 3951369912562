import { IMyUserVmMerged } from "helpers/General/MergeMembersCredentials";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import React from "react";
import "./ListOfUsersContainer.scss";
import ListOfUsersItem from "../ListOfUsersItem/ListOfUsersItem";

interface IListOfUsersContainer {
  users: IMyUserVmMerged[];
  setChosenUsers: React.Dispatch<React.SetStateAction<IMyUserVm[]>>;
  chosenUsers: IMyUserVm[];
}

const ListOfUsersContainer = ({
  users,
  chosenUsers,
  setChosenUsers,
}: IListOfUsersContainer) => {
  return (
    <div className={"common-components-list-of-users__main-layout"}>
      {users.map((user, index) => (
        <ListOfUsersItem
          key={`${user.name} ${index}`}
          user={user}
          setChosenUsers={setChosenUsers}
          chosenUsers={chosenUsers}
        />
      ))}
    </div>
  );
};

export default ListOfUsersContainer;
