import ChecklistVm from "interfaces/ViewModels/MyChecklistsViewModels/ChecklistVm";
import React from "react";
import { categoriesStatusComponents } from "./CategoriesStatusComponents";

export const differentiateEditAmongTypes = (
  checklist: ChecklistVm,
  userId: number
) => {
  return (
    <React.Fragment>
      {checklist.formAnswer.answers.map((item, index) => {
        return (
          <React.Fragment key={`${item.order}, ${item.value} ${index}`}>
            {
              categoriesStatusComponents(item, userId, checklist.id)
                ?.editComponent
            }
          </React.Fragment>
        );
      })}
      {checklist.formAnswer.groupedAnswers.map((group, index) => {
        return (
          <React.Fragment
            key={`${group.groupIndex}, ${group.groupName} ${index}`}
          >
            <div className="title-group-name">{group.groupName}</div>
            {group.questions.map((groupAnswer, index) => {
              return (
                <React.Fragment
                  key={`${groupAnswer.order}, ${groupAnswer.value} ${index}`}
                >
                  {
                    categoriesStatusComponents(
                      groupAnswer,
                      userId,
                      checklist.id,
                      group
                    )?.editComponent
                  }
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
