import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import GrayRightArrow from "public/GrayRightArrow.svg";
import "./MemberRoleDropdownContainer.scss";

interface IMemberRoleDropdownContainer {
  role: string | number | undefined;
  onContainerClick?: () => void | null;
  shouldShowArrow?: boolean;
}

const MemberRoleDropdownContainer = ({
  role,
  onContainerClick,
  shouldShowArrow = true,
}: IMemberRoleDropdownContainer) => {
  return (
    <section
      onClick={onContainerClick}
      className="project-team-page__role-section"
    >
      <span className="project-team-page__role-name">Role</span>
      <div className="project-team-page__change-role-button">
        <span
          className="project-team-page__change-role-button-title"
          style={{ marginRight: shouldShowArrow ? "26px" : 0 }}
        >
          Team&nbsp;
          {role && Number.isInteger(role)
            ? ProjectRolesEnum[+role]
            : role?.toString()}
        </span>
        {shouldShowArrow && (
          <img src={GrayRightArrow} alt={"Gray right arrow"} />
        )}
      </div>
    </section>
  );
};
export default MemberRoleDropdownContainer;
