import SeparationLine from "components/common/SeparationLine/SeparationLine";
import { IMyOrganizationVm } from "interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationVm";
import React from "react";
import "./OrganizationDropdownContent.scss";
import AddItemIcon from "public/AddItemIcon.svg";
import { projectsRoute } from "constants/Routes";
import { switchOrganization } from "Pages/api/MyOrganizationsClient";
import { setChosenOrganizationIdState } from "store/Redux/Reducers/chosenOrganizationReducer";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import CorpImage from "public/CorpImage.svg";

interface IOrganizationsDropdown {
  setMyOrganizationId?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  handleCloseOrganizationModal: () => void;
  organizations: IMyOrganizationVm[];
}

const OrganizationsDropdown = ({
  setMyOrganizationId,
  handleCloseOrganizationModal,
  organizations,
}: IOrganizationsDropdown) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const navigateToCreateOrganizationPage = () => {
    navigate(`/newOrganization`);
  };

  const switchToOrganization = async (organizationId: number) => {
    if (setMyOrganizationId === undefined) {
      await switchOrganization(organizationId);
      handleCloseOrganizationModal();
      navigate(`/${projectsRoute}`);
      return;
    }
    if (location.pathname === `/${projectsRoute}`) {
      await switchOrganization(organizationId);
      dispatch(setChosenOrganizationIdState(organizationId));
      setMyOrganizationId(organizationId);
      handleCloseOrganizationModal();
      navigate(`/`);
    } else {
      await switchOrganization(organizationId);
      setMyOrganizationId(organizationId);
      handleCloseOrganizationModal();
      navigate(`/${projectsRoute}`);
    }
  };

  return (
    <div
      className={
        "common-components__organizations-modal-organizations-dropdown"
      }
    >
      {organizations.map((organization: IMyOrganizationVm, index) => (
        <React.Fragment key={organization.id}>
          <div
            className={
              "common-components__organizations-modal-organizations-item"
            }
            onClick={() => switchToOrganization(organization.id)}
          >
            <img
              src={CorpImage}
              alt="Dropdown down icon"
              width={32}
              height={32}
            />
            <span>{organization.name}</span>
          </div>
          {index + 1 < organizations.length && (
            <SeparationLine marginTop={0} marginBottom={0} />
          )}
        </React.Fragment>
      ))}
      <SeparationLine marginTop={0} marginBottom={0} />
      <div
        className={"common-components__organizations-modal-organizations-item"}
        onClick={navigateToCreateOrganizationPage}
      >
        <img
          src={AddItemIcon}
          alt="Dropdown down icon"
          width={32}
          height={32}
        />
        <span>Create Organization</span>
      </div>
    </div>
  );
};

export default OrganizationsDropdown;
