import AddFileButton from "components/common/Buttons/AddFileButton/AddFileButton";
import BorderedTextAreaDescriptionComponent from "one-common-components/lib/components/SingleInputsPack/BorderedTextAreaDescriptionComponent/BorderedTextAreaDescriptionComponent";
import TeamMembersMultiSelect from "components/common/MultipleInputsWithLabel/TeamMembersMultiSelect/TeamMembersMultiSelect";
import DropdownWithLabel from "one-common-components/lib/components/SingleInputsPack//DropdownWithLabel/DropdownWithLabel";
import InputWithLabel from "one-common-components/lib/components/SingleInputsPack//InputWithLabel/InputWithLabel";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { addFile } from "helpers/Files/AddNewFileRequest";
import { convertToSelectOptions } from "helpers/General/ConvertToSelectOptions";
import {
  IMyUserVmMerged,
  mergeMembersCredentials,
} from "helpers/General/MergeMembersCredentials";
import { returnUsersCredentials } from "helpers/General/ReturnUserCredentials";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { ILabelValueStructure } from "interfaces/Other/ILabelValueStructure";
import { DeviationCategoryVm } from "interfaces/ViewModels/MyFormsViewModels/DeviationCategoryVm";
import { FileModuleEnum } from "one-common-components/lib/enums/FileModuleEnum";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "store/Redux/hooks";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import { dropdownStyles } from "styles/DropdownStyles";
import { DeleteFile } from "Pages/api/FileManagementClient";
import CancelSaveBottomBar from "components/common/Footers/CancelSaveBottomBar/CancelSaveBottomBar";
import CalendarWithLabel from "components/common/SingleInputsWithLabel/CalendarWithLabel/CalendarWithLabel";
import { IUpdateDeviationDetailsRequest } from "interfaces/Requests/IUpdateDeviationDetailsRequest";
import "./EditDeviationPageMain.scss";
import DeviationVm from "interfaces/ViewModels/MyDeviationsViewModels/DeviationVm";
import {
  getDeviationsCategories,
  updateDeviationDetails,
} from "Pages/api/MyDeviationsClient";
import DownloadOverviewImagesComponent from "one-common-components/lib/components/Files/Components/DownloadSpecificComponents/DownloadOverviewImagesComponent/DownloadOverviewImagesComponent";
import DownloadedFilesItems from "one-common-components/lib/components/Files/Components/DownloadSpecificComponents/DownloadedFilesItems/DownloadedFilesItems";
import { DownloadedFile } from "one-common-components/lib/components/Files/Interfaces/DownloadedFile";
import { changeSelectedDeviation } from "store/Redux/Reducers/selectedDeviationsReducer";
import isNullOrWhitespace from "helpers/General/IsNullOrWhitespace";
import { updateFileName, uploadFile } from "Pages/api/FileUploadClient";
import AddFileFromProjectDocuments from "components/common/AddFileFromProjectDocuments/AddFileFromProjectDocuments";
import { downloadFile } from "Pages/api/FileDownloadClient";
import { DeviationCauseVm } from "interfaces/ViewModels/MyFormsViewModels/DeviationCauseVm";

interface IEditDeviationPageMain {
  myFiles: DownloadedFile[];
  setMyFiles: React.Dispatch<React.SetStateAction<DownloadedFile[]>>;
  filesWereLoaded: boolean;
  chosenDeviation: DeviationVm;
  deviationsCauses: DeviationCauseVm[];
}

const EditDeviationPageMain = ({
  myFiles,
  setMyFiles,
  filesWereLoaded,
  chosenDeviation,
  deviationsCauses,
}: IEditDeviationPageMain) => {
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [deviationsCategories, setDeviationsCategories] = useState<
    DeviationCategoryVm[]
  >([]);
  const [deadline, setDeadline] = useState<Date | null>(
    chosenDeviation.deadline ? new Date(chosenDeviation.deadline) : null
  );
  const [selectedCategory, setSelectedCategory] =
    useState<ILabelValueStructure>();
  const [selectedCause, setSelectedCause] =
    useState<ILabelValueStructure | null>(
      chosenDeviation.causeId
        ? {
            label: chosenDeviation.causeName,
            value: chosenDeviation.causeId,
          }
        : null
    );
  const { projectId } = useParams();
  const globalStates = useAppSelector(chosenOrganization);
  const handleDeviationsCategories = async (organizationId: number) => {
    const categoriesResult = await getDeviationsCategories(organizationId);
    setDeviationsCategories(categoriesResult);
    const chosenCategory = categoriesResult.find(
      (category) => category.name === chosenDeviation.categoryName
    );
    setSelectedCategory({
      label: `${chosenCategory?.name}`,
      value: `${chosenCategory?.id}`,
    });
  };

  const deviationsCausesOptions: ILabelValueStructure[] = useMemo(() => {
    return selectedCategory
      ? deviationsCauses
          .filter(
            (item) => item.deviationCategoriesId === selectedCategory.value
          )
          .map((item) => {
            return { label: item.name, value: item.id };
          })
      : [];
  }, [selectedCategory]);

  const handleSetDeadline = (date: Date | null) => {
    setDeadline(date);
  };

  const { register, getValues, setValue } = useForm();
  const chosenOrganizationCurrency =
    globalStates.chosenOrganizationReducer.myOrganization?.currency;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  if (!chosenDeviation) {
    navigate("/error");
    return <div />;
  }
  const validatedUserCredentials = returnUsersCredentials(
    chosenDeviation.formResponsibleUserDto.firstName,
    chosenDeviation.formResponsibleUserDto.lastName,
    chosenDeviation.formResponsibleUserDto.email
  );

  const [chosenResponsible, setChosenResponsible] =
    useState<ILabelValueStructure>({
      label: validatedUserCredentials,
      value: chosenDeviation.formResponsibleUserDto.userId,
    });
  const [usersOptions, setUsersOptions] = useState<ILabelValueStructure[]>(
    chosenDeviation.formLinkUsers.map((user) => {
      return {
        label: returnUsersCredentials(
          user.firstName,
          user.lastName,
          user.email
        ),
        value: user.userId,
      };
    })
  );

  const handleInputsStartValues = () => {
    setValue("Description", chosenDeviation.description);
    setValue("Immediate reaction", chosenDeviation.immediateReaction);
    setValue("Corrective actions", chosenDeviation.correctivesActions);
    setValue("Title", chosenDeviation.name);
    setValue(
      "Time implication [DAYS]",
      chosenDeviation.timeImplication
        ? chosenDeviation.timeImplication.toString()
        : "0"
    );
    setValue(
      "Cost",
      chosenDeviation.cost ? chosenDeviation.cost.toString() : "0"
    );
  };

  const mergedNames: IMyUserVmMerged[] = mergeMembersCredentials(
    globalStates.chosenProjectReducer.projectMembers
  );

  const mappedProjectMembers = mergedNames.map((member) => {
    return { name: member.name, id: member.id };
  });
  const deviationsOptions = convertToSelectOptions(deviationsCategories);
  const teamOptions = convertToSelectOptions(mappedProjectMembers);
  const handleChosenResponsibleOptions = () => {
    return teamOptions.filter((item) => item.value !== chosenResponsible.value);
  };

  const handleUsersOptions = () => {
    return teamOptions.filter((item) => !usersOptions.includes(item));
  };

  const [assigneesOptions, setAssigneesOptions] = useState<
    ILabelValueStructure[]
  >(handleUsersOptions());
  const [responsibleOptions, setResponsibleOptions] = useState<
    ILabelValueStructure[]
  >(handleChosenResponsibleOptions());

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setIsImageUploading(true);
      const existsInCollection: DownloadedFile | undefined = myFiles.find(
        (item) => item.imageDetails.fileDownloadName === acceptedFiles[0].name
      );
      if (existsInCollection) {
        setIsImageUploading(false);
        return SimpleToast({
          mode: ToastModes.info,
          message: "There is already a file named like that.",
        });
      }
      if (!chosenDeviation) {
        setIsImageUploading(false);
        return navigate("/error");
      }
      if (!projectId) {
        return SimpleToast({
          mode: ToastModes.error,
          message: "Project id was not found",
        });
      }
      const result = await addFile(
        +chosenDeviation.id,
        acceptedFiles[0],
        FileModuleEnum.Deviation,
        globalStates.chosenOrganizationReducer.chosenOrganizationId,
        +projectId
      );
      const mappedCreatedObject: DownloadedFile = {
        imageDetails: {
          contentType: result.contentType,
          enableRangeProcessing: true,
          entityTag: "",
          fileContents: result.content,
          fileDownloadName: result.fileName,
        },
        guid: result.guid,
        createdOn: new Date(),
        groupIndex: "",
        questionIndex: "",
      };
      setMyFiles([...myFiles, mappedCreatedObject]);
      setIsImageUploading(false);
    },
    [myFiles]
  );

  const removeFile = async (file: DownloadedFile) => {
    const newFiles = [...myFiles];
    const result = newFiles.splice(
      newFiles.findIndex((item) => item.guid === file.guid),
      1
    );
    setMyFiles(newFiles);
    await DeleteFile(result[0].guid);
  };

  const returnToPreviousPage = () => {
    navigate(-1);
  };

  const handleAddItemToList = async (option: ILabelValueStructure) => {
    if (!projectId) {
      return SimpleToast({
        mode: ToastModes.error,
        message: "Project id was not found",
      });
    }
    setIsImageUploading(true);
    downloadFile(option.value.toString()).then(async (fileBlob) => {
      const formData = new FormData();
      formData.append("FormFile", fileBlob);
      formData.append("FileName", option.label);
      formData.append(
        "OrganizationId",
        globalStates.chosenOrganizationReducer.chosenOrganizationId.toString()
      );
      formData.append("FileModule", `${FileModuleEnum.Deviation}`);
      formData.append("RefId", `${chosenDeviation.id}`);
      projectId && formData.append("ProjectId", projectId.toString());
      const result = await uploadFile(formData);
      const mappedCreatedObject: DownloadedFile = {
        imageDetails: {
          contentType: result.contentType,
          enableRangeProcessing: true,
          entityTag: "",
          fileContents: result.content,
          fileDownloadName: result.fileName,
        },
        guid: result.guid,
        createdOn: new Date(),
        groupIndex: "",
        questionIndex: "",
      };
      setMyFiles([...myFiles, mappedCreatedObject]);
      setIsImageUploading(false);
    });
  };

  const validateRequest = (request: IUpdateDeviationDetailsRequest) => {
    if (!request.categoryId) {
      SimpleToast({
        mode: ToastModes.error,
        message: "Deviation category cannot be empty",
      });
      return false;
    }
    if (isNullOrWhitespace(request.formName)) {
      SimpleToast({
        mode: ToastModes.error,
        message: "Deviation title cannot be empty",
      });
      return false;
    }
    if (!request.formDescription) {
      SimpleToast({
        mode: ToastModes.error,
        message: "Deviation description cannot be empty",
      });
      return false;
    }
    return true;
  };

  const saveChangedValues = async () => {
    if (!chosenResponsible) {
      return SimpleToast({
        mode: ToastModes.info,
        message: "Responsible and deadline must be inserted",
      });
    }
    if (!projectId) {
      navigate("/error");
      return;
    }
    if (!chosenResponsible) {
      SimpleToast({
        mode: ToastModes.error,
        message: "Deviation responsible cannot be empty",
      });
      return;
    }
    const request: IUpdateDeviationDetailsRequest = {
      formLinkId: chosenDeviation.id,
      categoryId: selectedCategory ? +selectedCategory.value : 0,
      causeId: selectedCause ? +selectedCause.value : 0,
      deadline: deadline ? new Date(deadline) : null,
      responsibleUserId: +chosenResponsible.value,
      chosenUsersIds: usersOptions.map((item) => +item.value),
      formDescription: getValues("Description"),
      immediateReaction: getValues("Immediate reaction"),
      correctivesActions: getValues("Corrective actions"),
      formName: getValues("Title"),
      cost: +getValues("Cost"),
      timeImplication: +getValues("Time implication [DAYS]"),
      projectId: +projectId,
    };
    if (!validateRequest(request)) {
      return;
    }
    const updatedDeviation = await updateDeviationDetails(request);
    dispatch(changeSelectedDeviation(updatedDeviation));
    navigate(-1);
  };

  useEffect(() => {
    setResponsibleOptions(handleChosenResponsibleOptions());
  }, [chosenResponsible]);

  useEffect(() => {
    setAssigneesOptions(handleUsersOptions());
  }, [usersOptions]);

  useEffect(() => {
    handleInputsStartValues();
  }, []);

  useEffect(() => {
    if (globalStates.chosenOrganizationReducer.chosenOrganizationId) {
      handleDeviationsCategories(
        +globalStates.chosenOrganizationReducer.chosenOrganizationId
      );
    }
  }, [globalStates.chosenOrganizationReducer.chosenOrganizationId]);

  return (
    <>
      <main className="edit-deviation-page__main">
        <InputWithLabel
          label={"Title"}
          marginBottom={16}
          inputPlaceholder={"Enter form title"}
          register={register}
          required
        />
        <DropdownWithLabel
          label={"Category"}
          marginBottom={16}
          required
          SelectComponent={
            <Select
              styles={dropdownStyles}
              options={deviationsOptions}
              onChange={(option: ILabelValueStructure | null) =>
                option && setSelectedCategory(option)
              }
              value={selectedCategory}
            />
          }
        />
        <DropdownWithLabel
          label={"Responsible"}
          required
          SelectComponent={
            <Select
              styles={dropdownStyles}
              options={responsibleOptions}
              onChange={(option: ILabelValueStructure | null) =>
                option && setChosenResponsible(option)
              }
              value={chosenResponsible}
            />
          }
        />
        <BorderedTextAreaDescriptionComponent
          register={register}
          label={"Description"}
          marginBottom={16}
          marginTop={16}
          required
        />
        <DropdownWithLabel
          label={"Cause"}
          marginBottom={16}
          SelectComponent={
            <Select
              styles={dropdownStyles}
              options={deviationsCausesOptions}
              onChange={(option: ILabelValueStructure | null) =>
                option && setSelectedCause(option)
              }
              value={selectedCause}
            />
          }
        />
        <div>
          <CalendarWithLabel
            date={deadline}
            handleSetDate={handleSetDeadline}
            label={"Deadline"}
            marginBottom={16}
          />
        </div>
        <AddFileFromProjectDocuments
          handleAddItemToList={handleAddItemToList}
        />
        {filesWereLoaded && <AddFileButton onDrop={onDrop} />}
        {filesWereLoaded ? (
          <>
            <DownloadedFilesItems
              myFiles={Array.isArray(myFiles) ? myFiles : []}
              removeFile={removeFile}
              isImageUploading={isImageUploading}
              setOtherFiles={setMyFiles}
              organizationId={
                globalStates.chosenOrganizationReducer.chosenOrganizationId
              }
              updateFileName={updateFileName}
            />
            <DownloadOverviewImagesComponent
              marginTop={16}
              removeFile={removeFile}
              myFiles={myFiles.filter((files) =>
                files.imageDetails.contentType.includes("image")
              )}
              setOtherFiles={setMyFiles}
              organizationId={
                globalStates.chosenOrganizationReducer.chosenOrganizationId
              }
              updateFileName={updateFileName}
            />
          </>
        ) : (
          <div className="edit-deviation-page-main__loading-text">
            Loading...
          </div>
        )}
        <BorderedTextAreaDescriptionComponent
          register={register}
          marginTop={16}
          label={"Immediate reaction"}
        />
        <BorderedTextAreaDescriptionComponent
          register={register}
          label={"Corrective actions"}
          marginBottom={16}
          marginTop={16}
        />
        <InputWithLabel
          marginBottom={16}
          label={`Cost`}
          labelWithVariables={`Cost implication [${chosenOrganizationCurrency}]`}
          inputPlaceholder={"Enter number"}
          register={register}
          type={"number"}
          min={0}
          inputMode={"numeric"}
          pattern="[0-9]*"
        />
        <InputWithLabel
          label={"Time implication [DAYS]"}
          inputPlaceholder={"Enter number of days "}
          register={register}
          type={"number"}
          min={0}
          inputMode={"numeric"}
          pattern="[0-9]*"
          marginBottom={16}
        />
        <TeamMembersMultiSelect
          users={usersOptions}
          setUsers={setUsersOptions}
          teamOptions={assigneesOptions}
          label={"Team members"}
        />
      </main>
      <CancelSaveBottomBar
        onCancelClick={returnToPreviousPage}
        cancelButtonName={"CANCEL"}
        onSaveClick={saveChangedValues}
        saveButtonName={"SAVE"}
      />
    </>
  );
};

export default EditDeviationPageMain;
