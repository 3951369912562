import { uploadFile } from "Pages/api/FileUploadClient";
import { FileModuleEnum } from "one-common-components/lib/enums/FileModuleEnum";

const appendFormData = (
  fileToUpload: File,
  itemId: number,
  module: FileModuleEnum,
  organizationId: number,
  projectId?: number
) => {
  const formData = new FormData();
  formData.append("FormFile", fileToUpload);
  formData.append("FileName", fileToUpload.name);
  formData.append("OrganizationId", organizationId.toString());
  formData.append("FileModule", `${FileModuleEnum[module]}`);
  formData.append("RefId", `${itemId}`);
  projectId && formData.append("ProjectId", projectId.toString());
  return formData;
};

export const addMultipleFiles = async (
  itemId: number,
  myFiles: File[],
  module: FileModuleEnum,
  organizationId: number,
  projectId?: number
) => {
  myFiles.map(async (fileToUpload) => {
    const formData = appendFormData(
      fileToUpload,
      itemId,
      module,
      organizationId,
      projectId
    );
    await uploadFile(formData);
  });
};

export const addFile = async (
  itemId: number,
  myFile: File,
  module: FileModuleEnum,
  organizationId: number,
  projectId?: number
) => {
  const formData = appendFormData(
    myFile,
    itemId,
    module,
    organizationId,
    projectId
  );
  return await uploadFile(formData);
};
