import { isBefore, isSameDay } from "date-fns";
import { IFiltration as ProjectsFilter } from "store/Redux/Reducers/projectsFilterReducer";
import { IFiltration as TimeEntriesFilter } from "store/Redux/Reducers/timeEntriesFilterReducer";
import { isToday } from "date-fns";

interface IHasStartDate {
  startDate: Date | string;
}

export const startDateFilter = <T extends IHasStartDate>(
  foundFilter: ProjectsFilter | TimeEntriesFilter,
  filteredArray: T[]
) => {
  if (foundFilter.startDate && !isToday(new Date(foundFilter.startDate))) {
    const startDate = foundFilter.startDate;
    if (startDate) {
      return filteredArray.filter(
        (item) =>
          isBefore(new Date(startDate), new Date(item.startDate)) ||
          isSameDay(new Date(startDate), new Date(item.startDate))
      );
    }
  }
};
