import { getHoursFromMinutes } from "helpers/TimeHelpers/GetHoursFromMinutes";
import { IMyTimeEntriesWeek } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntriesWeek";
import TimeEntriesDay from "./TimeEntriesDay/TimeEntriesDay";
import "./TimeEntriesWeek.scss";

interface ITimeEntriesWeek {
  week: IMyTimeEntriesWeek;
}
const TimeEntriesWeeks = ({ week }: ITimeEntriesWeek) => (
  <div>
    <div className={"time-entries-week__layout"}>
      <span>{week.weekDuration}</span>
      <span>
        Week hours:{" "}
        <span className={"time-entries-week__text--black"}>
          {getHoursFromMinutes(week.sumOfMinutes)}
        </span>
      </span>
    </div>
    {week.timeEntriesDays.map((day) => {
      return <TimeEntriesDay key={day.dayDate} day={day} />;
    })}
  </div>
);

export default TimeEntriesWeeks;
