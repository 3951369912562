import "./FooterAddButtonDropdown.scss";
import DrodpownPosition from "./DropdownPosition/DropdownPosition";
import { lightGreen, whiteBackground } from "../../../../constants/JsColors";
import {
  projectsRoute,
  newTaskRoute,
  timeEntriesRoute,
  addRoute,
  teamRoute,
  deviationsRoute,
  checklistsRoute,
} from "../../../../constants/Routes";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store/Redux/store";

const FooterAddButtonDropdown = () => {
  const router = useLocation();
  const globalStates = useSelector((state: RootState) => state);
  const { projectId } = useParams();
  return (
    <>
      <div className={"common-components__footer-add-button-dropdown-button"}>
        {globalStates.chosenProjectReducer.canCreateTasks && (
          <Link
            to={`/${projectsRoute}/${projectId}/${newTaskRoute}`}
            className={
              "common-components__footer-add-button-dropdown-item-wrapper"
            }
            style={{
              background:
                router.pathname === `/${projectsRoute}/${projectId}`
                  ? lightGreen
                  : whiteBackground,
            }}
          >
            <DrodpownPosition text={"task"} />
          </Link>
        )}
        <Link
          to={`/${projectsRoute}/${projectId}/${checklistsRoute}/${addRoute}`}
          className={
            "common-components__footer-add-button-dropdown-item-wrapper"
          }
          style={{
            background:
              router.pathname ===
              `/${projectsRoute}/${projectId}/${checklistsRoute}`
                ? lightGreen
                : whiteBackground,
          }}
        >
          <DrodpownPosition text={"checklist"} />
        </Link>
        <Link
          to={`/${projectsRoute}/${projectId}/${deviationsRoute}/${addRoute}`}
          className={
            "common-components__footer-add-button-dropdown-item-wrapper"
          }
          style={{
            background:
              router.pathname ===
              `/${projectsRoute}/${projectId}/${deviationsRoute}`
                ? lightGreen
                : whiteBackground,
          }}
        >
          <DrodpownPosition text={"deviation"} />
        </Link>
        <Link
          to={`/${projectsRoute}/${projectId}/${timeEntriesRoute}/${addRoute}`}
          className={
            "common-components__footer-add-button-dropdown-item-wrapper"
          }
          style={{
            background:
              router.pathname ===
              `/${projectsRoute}/${projectId}/${timeEntriesRoute}`
                ? lightGreen
                : whiteBackground,
          }}
        >
          <DrodpownPosition text={"time entry"} />
        </Link>
        {globalStates.chosenProjectReducer.canCreateTasks && (
          <Link
            to={`/${projectsRoute}/${projectId}/${teamRoute}/${addRoute}`}
            className={
              "common-components__footer-add-button-dropdown-item-wrapper"
            }
            style={{
              background:
                router.pathname ===
                `/${projectsRoute}/${projectId}/${teamRoute}`
                  ? lightGreen
                  : whiteBackground,
            }}
          >
            <DrodpownPosition text={"project member"} />
          </Link>
        )}
      </div>
      <div
        className={
          "common-components__footer-add-button-dropdown-pointer-element"
        }
      />
    </>
  );
};

export default FooterAddButtonDropdown;
