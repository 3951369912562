import ChecklistsAnswerLabel from "components/common/ChecklistsComponents/ChecklistsAnswerLabel/ChecklistsAnswerLabel";
import InputWithLabel from "components/common/SingleInputsWithLabel/InputWithLabel/InputWithLabel";
import { AnswerDto } from "interfaces/ViewModels/MyFormsViewModels/AnswerDto";
import { GroupedAnswersDto } from "interfaces/ViewModels/MyFormsViewModels/GroupedAnswerDto";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "store/Redux/hooks";
import {
  updateChecklistAnswer,
  updateChecklistComment,
} from "store/Redux/Reducers/selectedChecklistsReducer";
import CommentInput from "../CommentInput/CommentInput";
import { assignArrayValues as assingArrayValues } from "./AssignArrayValues";
import "./MultipleTextBoxInputs.scss";

interface IMultipleTextBoxInputs {
  answer: AnswerDto;
  userId: number;
  group?: GroupedAnswersDto;
  checklistId: number;
}

const MultipleTextBoxInputs = ({
  answer,
  group,
  userId,
  checklistId,
}: IMultipleTextBoxInputs) => {
  const [inputText, setInputText] = useState<string>(
    answer.comment ? answer.comment : ""
  );
  const dispatch = useAppDispatch();
  const { register, setValue } = useForm();

  const setMultipleFieldsComment = (comment: string) => {
    dispatch(
      updateChecklistComment({ group, answer, comment, userId, checklistId })
    );
  };

  const setTextBoxInputValue = (value: string, index?: number) => {
    if (!index && index != 0) {
      return;
    }

    const answerValuesArray = assingArrayValues(
      answer.value,
      value,
      index,
      answer.multipleTextBoxAnswersCount
    );
    dispatch(
      updateChecklistAnswer({
        group,
        answer,
        value: answerValuesArray,
        userId,
        checklistId,
      })
    );
  };

  useEffect(() => {
    for (let i = 0; i < answer.multipleTextBoxAnswersCount; i++) {
      setValue(`Answer ${i + 1}`, answer.value ? answer.value[i] : "");
    }
  }, []);

  const renderMultipleBoxes = () => {
    const array: JSX.Element[] = [];
    {
      for (let i = 0; i < answer.multipleTextBoxAnswersCount; i++) {
        array.push(
          <div
            className="checklists-answers--multiple-boxes-margins"
            key={`${answer.header}${answer.order}${i}`}
          >
            <InputWithLabel
              register={register}
              label={`Answer ${i + 1}`}
              inputPlaceholder={"Enter Your Answer"}
              onBlur={setTextBoxInputValue}
              index={i}
            />
          </div>
        );
      }
      return array;
    }
  };

  return (
    <div className="checklists-answers--margins">
      <ChecklistsAnswerLabel text={answer.header} position={answer.order} />
      {renderMultipleBoxes()}
      <CommentInput
        onBlur={setMultipleFieldsComment}
        onChange={setInputText}
        value={inputText}
      />
    </div>
  );
};

export default MultipleTextBoxInputs;
