import FiltrationsHeader from "components/common/FiltrationsHeader/FiltrationsHeader";
import GrayFiltrationBar from "components/common/GrayFiltrationBar/GrayFiltrationBar";
import ListOfUsersContainer from "components/common/ListOfUsers/ListOfUsersContainer/ListOfUsersContainer";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { projectsRoute, taskRoute } from "constants/Routes";
import {
  IMyUserVmMerged,
  mergeMembersCredentials,
} from "helpers/General/MergeMembersCredentials";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import { updateTaskAssignees } from "Pages/api/MyTasksClient";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import {
  adjustSelectedTaskUsers,
  selectedTask,
} from "store/Redux/Reducers/selectedTaskReducer";
import "./TaskUsersPage.scss";

const TaskUsersPage = () => {
  const globalStates = useSelector(selectedTask);
  const task = globalStates.selectedTaskReducer.task;
  const [usersMergedNames, setUsersMergedNames] = useState<IMyUserVmMerged[]>(
    []
  );
  const navigate = useNavigate();
  const { projectId, taskId } = useParams();
  const [chosenUsers, setChosenUsers] = useState<IMyUserVm[]>([]);
  const [filtratedItems, setFiltratedItems] = useState<IMyUserVmMerged[]>([]);
  const dispatch = useAppDispatch();

  const confirmStateChange = async () => {
    if (taskId) {
      await updateTaskAssignees(
        +taskId,
        chosenUsers.map((user) => {
          return user.id;
        })
      );
      dispatch(adjustSelectedTaskUsers(chosenUsers));
      navigate(`/${projectsRoute}/${projectId}/${taskRoute}/${task?.id}`);
    } else {
      SimpleToast({
        mode: ToastModes.error,
        message: "Task id cannot be null",
      });
    }
  };
  const handleProjectMembers = async () => {
    setChosenUsers(task ? task.assignedUsers : []);
    const mergedNames = mergeMembersCredentials(
      globalStates.chosenProjectReducer.projectMembers
    );
    setFiltratedItems(mergedNames);
    setUsersMergedNames(mergedNames);
  };

  useEffect(() => {
    handleProjectMembers();
  }, []);

  if (!globalStates.chosenProjectReducer.projectMembers) {
    return <div />;
  }
  return (
    <div className="task-users-page__layout">
      <FiltrationsHeader
        pageName={"Users"}
        applyFiltersText={"Save"}
        confimationFunction={confirmStateChange}
        showConfirmationToast={false}
      />
      <main className="task-users-page__main-layout">
        <GrayFiltrationBar
          originalArray={usersMergedNames}
          setFiltratedItems={setFiltratedItems}
        />
        <ListOfUsersContainer
          users={filtratedItems}
          chosenUsers={chosenUsers}
          setChosenUsers={setChosenUsers}
        />
      </main>
    </div>
  );
};

export default TaskUsersPage;
