import { dotsDateFormat } from "constants/DateFormats";
import { endOfWeek, format, startOfWeek } from "date-fns";

export const getWeekDuration = (timeEntryStartDate: Date) => {
  const firstDayOfWeek = startOfWeek(new Date(timeEntryStartDate), {
    weekStartsOn: 1,
  });
  const lastDayOfWeek = endOfWeek(new Date(timeEntryStartDate), {
    weekStartsOn: 1,
  });
  return `${format(new Date(firstDayOfWeek), dotsDateFormat)} - ${format(
    new Date(lastDayOfWeek),
    dotsDateFormat
  )}`;
};
