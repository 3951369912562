import "./ChecklistItem.scss";
import { getChecklistOrDeviationStateImage } from "components/common/ChecklistsOrDeviationsStateImages/ChecklistsOrDeviationsStateImages";
import { useNavigate, useParams } from "react-router-dom";
import { answersRoute, checklistsRoute, projectsRoute } from "constants/Routes";
import { changeSelectedChecklist } from "store/Redux/Reducers/selectedChecklistsReducer";
import { useAppDispatch } from "store/Redux/hooks";
import ChecklistVm from "interfaces/ViewModels/MyChecklistsViewModels/ChecklistVm";
import IdBadge from "one-common-components/lib/components/IdBadge/IdBadge";
import UserAvatarWithCredentials from "components/common/UserAvatarWithCredentials/UserAvatarWithCredentials";
import { returnUsersCredentials } from "helpers/General/ReturnUserCredentials";

interface IChecklistItem {
  checklistItem: ChecklistVm;
}

const ChecklistItem = ({ checklistItem }: IChecklistItem) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const dispatch = useAppDispatch();

  const saveFormToRedux = (form: ChecklistVm) => {
    dispatch(changeSelectedChecklist(form));
  };

  const moveToChecklistRouteAndSaveChecklistStates = () => {
    saveFormToRedux(checklistItem);

    navigate(
      `/${projectsRoute}/${projectId}/${checklistsRoute}/${checklistItem.id}/${answersRoute}`
    );
  };

  return (
    <div
      className="common-components__checklist-item"
      onClick={moveToChecklistRouteAndSaveChecklistStates}
    >
      <div className="common-components__checklist-item-upper-part">
        <div className="common-components__checklist-item-upper-left-part">
          {getChecklistOrDeviationStateImage(checklistItem.formAnswer.status)}
          <span className="common-components__checklist-item-upper-left-part-text">
            {checklistItem.name}
          </span>
          <IdBadge id={checklistItem.id} />
        </div>
      </div>
      <div className="common-components__checklist-item-lower-part">
        {checklistItem.formResponsibleUserDto && (
          <div className="common-components__checklist-item-responsible">
            <UserAvatarWithCredentials
              validatedUserCredentials={returnUsersCredentials(
                checklistItem.formResponsibleUserDto.firstName,
                checklistItem.formResponsibleUserDto.lastName,
                checklistItem.formResponsibleUserDto.email
              )}
              role={checklistItem.formResponsibleUserDto.role}
            />
          </div>
        )}
        <span className="common-components__checklist-item-lower-part-text">
          {checklistItem.formCategory}
        </span>
      </div>
    </div>
  );
};

export default ChecklistItem;
