export const assignArrayValues = (
  answerValues: string | string[],
  value: string,
  index: number,
  boxesCount: number
): string[] => {
  let answerValuesArray: string[];
  if (!Array.isArray(answerValues)) {
    answerValuesArray = [];
    for (let i = 0; i < boxesCount; i++) {
      answerValuesArray.push("");
    }
    answerValuesArray[index] = value;
  } else {
    answerValuesArray = [...answerValues];
    answerValuesArray[index] = value;
  }
  return answerValuesArray;
};
