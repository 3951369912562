export const projectsRoute = "projects";
export const formsRoute = "forms";
export const newOrganizationRoute = "newOrganization";
export const overviewRoute = "overview";
export const teamRoute = "team";
export const memberRoute = "member";
export const editRoute = "edit";
export const newProjectRoute = "newProject";
export const timeEntriesRoute = "timeEntries";
export const profileRoute = "profile";
export const newTaskRoute = "newTask";
export const filtersRoute = "filters";
export const taskRoute = "task";
export const assignRoute = "assign";
export const checklistsRoute = "checklists";
export const deviationsRoute = "deviations";
export const answersRoute = "answers";
export const tasksRoute = "tasks";
export const usersRoute = "users";
export const addRoute = "add";
export const updateRoute = "update";
export const documentsRoute = "documents";
