import "./SeparationLine.scss";

interface ISeparationLine {
  marginTop?: number;
  marginBottom?: number;
}

const SeparationLine = ({ marginTop, marginBottom }: ISeparationLine) => {
  return (
    <div
      style={{ marginTop: marginTop, marginBottom: marginBottom }}
      className={"common-components__separation-line"}
    />
  );
};

export default SeparationLine;
