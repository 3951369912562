import { ILabelStringStructure } from "interfaces/Other/ILabelValueStructure";
import "./TimeEntryStatus.scss";

interface ITImeEntryStatus {
  status: ILabelStringStructure | undefined;
}

const TimeEntryStatus = ({ status }: ITImeEntryStatus) => {
  return (
    <>
      {status && (
        <div className={"time-entry-status__wrapper"}>
          <img src={status.value.icon} alt="Info icon" width={20} height={20} />
          <span
            className={"time-entry-status__status-label"}
            style={{ color: status.value.color }}
          >
            {status.label.replace(/([A-Z])/g, " $1").trim()}
          </span>
        </div>
      )}
    </>
  );
};

export default TimeEntryStatus;
