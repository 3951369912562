import DateContent from "components/common/TimeEntriesInputs/DateContainer/DateContent/DateContent";
import Label from "components/common/TimeEntriesInputs/DateContainer/DateLabel/Label";
import React, { useState } from "react";
import { greenText, borderWhite } from "../../../../constants/JsColors";
import CalendarComponent from "../../CalendarComponent/CalendarComponent";
import "./HoursContainer.scss";

interface IHoursContainer {
  setTotalTime: React.Dispatch<React.SetStateAction<string>>;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  date: Date;
  totalTime: string;
  hasPermissionToEditTimeEntry: boolean;
}

const HoursContainer = ({
  setTotalTime,
  date,
  setDate,
  totalTime,
  hasPermissionToEditTimeEntry,
}: IHoursContainer) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  const onSaveClick = (date: Date | null) => {
    if (date) {
      setDate(date);
    }
  };

  return (
    <>
      <div className={"common-components__hours-container"}>
        <div
          className={"common-components__hours-container-wrapper "}
          style={{
            borderColor: isCalendarOpen ? greenText : borderWhite,
            width: "48%",
          }}
          onClick={() =>
            hasPermissionToEditTimeEntry && setIsCalendarOpen(true)
          }
        >
          <Label text={"date"} showCalendar={true} />
          <DateContent calendarDate={date} />
        </div>
        <div
          className={"common-components__hours-container-wrapper"}
          style={{
            width: "48%",
          }}
        >
          <Label text={"HOURS"} />
          <DateContent
            setTime={setTotalTime}
            value={totalTime}
            hasPermissionToEditTimeEntry={hasPermissionToEditTimeEntry}
          />
        </div>
      </div>
      <CalendarComponent
        isOpen={isCalendarOpen}
        isOpenSetter={setIsCalendarOpen}
        date={date}
        onSaveClick={onSaveClick}
      />
    </>
  );
};

export default HoursContainer;
