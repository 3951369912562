import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { registerLocale } from "react-datepicker";
import enGb from "date-fns/locale/en-GB";
import { LocaleTimeZone } from "./constants/TimeZone";
import LoginPage from "Pages/LoginPage/LoginPage";
import ProjectsPage from "Pages/ProjectsPage/ProjectsPage";
import useAuth0 from "Auth/use-auth0";
import ProfilePage from "Pages/ProfilePage/ProfilePage";
import ProjectPage from "Pages/ProjectsPage/ProjectPage/ProjectPage";
import NewTaskPage from "Pages/ProjectsPage/ProjectPage/NewTaskPage/NewTaskPage";
import ProjectFiltersPage from "Pages/ProjectsPage/ProjectPage/ProjectFiltersPage/ProjectFiltersPage";
import UsersFilterPage from "Pages/ProjectsPage/ProjectPage/ProjectFiltersPage/UsersFilterPage/UsersFilterPage";
import TasksFilterPage from "Pages/ProjectsPage/ProjectPage/ProjectFiltersPage/TasksFilterPage/TasksFIlterPage";
import TimeEntriesPage from "Pages/ProjectsPage/ProjectPage/TimeEntriesPage/TimeEntriesPage";
import AddTimeEntryPage from "Pages/ProjectsPage/ProjectPage/TimeEntriesPage/AddTimeEntryPage/AddTimeEntryPage";
import TimeEntryTasksPage from "Pages/ProjectsPage/ProjectPage/TimeEntriesPage/TimeEntryPage/TimeEntryTasksPage/TimeEntryTasksPage";
import TimeEntryPage from "Pages/ProjectsPage/ProjectPage/TimeEntriesPage/TimeEntryPage/TimeEntryPage";
import AddTimeEntryTasksPage from "Pages/ProjectsPage/ProjectPage/TimeEntriesPage/AddTimeEntryPage/AddTimeEntryTasksPage/AddTimeEntryTasksPage";
import TimeEntriesFiltersPage from "Pages/ProjectsPage/ProjectPage/TimeEntriesPage/TimeEntriesFiltersPage/TimeEntriesFiltersPage";
import NewProjectPage from "Pages/ProjectsPage/NewProjectPage/NewProjectPage";
import TimeEntriesTasksFilterPage from "Pages/ProjectsPage/ProjectPage/TimeEntriesPage/TimeEntriesFiltersPage/TimeEntriesTasksFilterPage/TimeEntriesTasksFilterPage";
import NewOrganizationPage from "Pages/NewOrganizationPage/NewOrganizationPage";
import ProjectOverviewPage from "Pages/ProjectsPage/ProjectPage/ProjectOverviewPage/ProjectOverviewPage";
import ProjectOverviewEditPage from "Pages/ProjectsPage/ProjectPage/ProjectOverviewPage/ProjectOverviewEditPage/ProjectOverviewEditPage";
import TaskPage from "Pages/ProjectsPage/ProjectPage/TaskPage/TaskPage";
import TaskUsersPage from "Pages/ProjectsPage/ProjectPage/TaskPage/TaskUsersPage/TaskUsersPage";
import NotFoundPage from "Pages/NotFoundPage/NotFoundPage";
import WebPage from "Pages/WebPage/WebPage";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastModes } from "interfaces/Enums/ToastModes";
import SimpleToast from "components/common/Toasts/SimpleToast";
import ErrorPage from "Pages/ErrorPage/ErrorPage";
import ChecklistsPage from "Pages/ProjectsPage/ProjectPage/ChecklistsPage/ChecklistsPage";
import ChecklistsAnswersPage from "Pages/ProjectsPage/ProjectPage/ChecklistsPage/ChecklistsAnswersPage/ChecklistsAnswersPage";
import ProjectTeamPage from "Pages/ProjectsPage/ProjectPage/ProjectTeamPage/ProjectTeamPage";
import ProjectTeamMemberPage from "Pages/ProjectsPage/ProjectPage/ProjectTeamPage/ProjectTeamMemberPage/ProjectTeamMemberPage";
import ProjectTeamAddMemberPage from "Pages/ProjectsPage/ProjectPage/ProjectTeamPage/ProjectTeamAddMemberPage/ProjectTeamAddMemberPage";
import ProjectTeamAssignTaskPage from "Pages/ProjectsPage/ProjectPage/ProjectTeamPage/ProjectTeamAssignTaskPage/ProjectTeamAssignTaskPage";
import DeviationsPage from "Pages/ProjectsPage/ProjectPage/DeviationsPage/DeviationsPage";
import DeviationOverviewPage from "Pages/ProjectsPage/ProjectPage/DeviationsPage/DeviationOverviewPage/DeviationOverviewPage";
import CreateDeviationsPage from "components/common/Pages/CreateDeviationPage/CreateDeviationsPage";
import CreateChecklistsPage from "components/common/Pages/CreateChecklistPage/CreateChecklistsPage";
import EditDeviationPage from "Pages/ProjectsPage/ProjectPage/DeviationsPage/DeviationOverviewPage/EditDeviationPage/EditDeviationPage";
import {
  addRoute,
  answersRoute,
  checklistsRoute,
  deviationsRoute,
  documentsRoute,
  editRoute,
  filtersRoute,
  memberRoute,
  newOrganizationRoute,
  newProjectRoute,
  newTaskRoute,
  overviewRoute,
  profileRoute,
  projectsRoute,
  taskRoute,
  tasksRoute,
  teamRoute,
  timeEntriesRoute,
  usersRoute,
} from "constants/Routes";
import DocumentsPage from "Pages/ProjectsPage/ProjectPage/DocumentsPage/DocumentsPage";

registerLocale(LocaleTimeZone, enGb);

enum DeviceType {
  Tablet = "tablet",
  Mobile = "mobile",
  Desktop = "desktop",
}

export const App = () => {
  const [isAccessTokenSet, setIsAccessTokenSet] = useState<boolean>(false);

  const { isLoading, isAuthenticated, getAccessTokenSilently, logout } =
    useAuth0();

  const getAccessTokenAndSetAxiosInterceptors = async () => {
    const accessToken = await getAccessTokenSilently();
    if (accessToken !== "") {
      setAxiosInterceptor(accessToken);
      setIsAccessTokenSet(true);
    }
  };

  const setAxiosInterceptor = (accessToken: string) => {
    axios.interceptors.request.use(
      (config) => {
        if (config && config.headers) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      () => {
        SimpleToast({
          mode: ToastModes.error,
          message: "Cannot authorize, please login again",
        });
        logout();
      }
    );
  };

  const checkIfRouteIsAuthenticated = (component: JSX.Element) => {
    return !isAuthenticated ? (
      <Navigate to="/login-page" />
    ) : isAccessTokenSet ? (
      component
    ) : (
      <div />
    );
  };

  const deviceType = () => {
    const ua = navigator.userAgent;
    // if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    //   return DeviceType.Tablet;
    // } else if (
    //   /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
    //     ua
    //   )
    // ) {
    //   return DeviceType.Mobile;
    // }
    return DeviceType.Mobile;
  };

  const checkIfRouteIsOnMobileDevice = (component: JSX.Element) => {
    if (deviceType() !== DeviceType.Mobile) {
      return <Navigate to="/web" />;
    } else {
      return component;
    }
  };

  const manageAuthenticationAndDeviceWidth = (component: JSX.Element) => {
    const authResultComponent = checkIfRouteIsAuthenticated(component);
    return checkIfRouteIsOnMobileDevice(authResultComponent);
  };

  useEffect(() => {
    if (!isAccessTokenSet && isAuthenticated)
      getAccessTokenAndSetAxiosInterceptors();
  }, [isAccessTokenSet, isAuthenticated]);

  if (isLoading) {
    return <div />;
  } else {
    return (
      <Router>
        <Routes>
          <Route
            path="/"
            element={manageAuthenticationAndDeviceWidth(
              <Navigate to={`${projectsRoute}`} />
            )}
          />
          <Route path={`login-page`} element={<LoginPage />} />
          <Route path={`error`} element={<ErrorPage />} />
          <Route path={`web`} element={<WebPage />} />
          <Route
            path={`${projectsRoute}`}
            element={manageAuthenticationAndDeviceWidth(<ProjectsPage />)}
          />
          <Route
            path={`${newOrganizationRoute}`}
            element={manageAuthenticationAndDeviceWidth(
              <NewOrganizationPage />
            )}
          />
          <Route
            path={`${profileRoute}`}
            element={manageAuthenticationAndDeviceWidth(<ProfilePage />)}
          />
          <Route
            path={`${projectsRoute}/${newProjectRoute}`}
            element={manageAuthenticationAndDeviceWidth(<NewProjectPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId`}
            element={manageAuthenticationAndDeviceWidth(<ProjectPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${overviewRoute}`}
            element={manageAuthenticationAndDeviceWidth(
              <ProjectOverviewPage />
            )}
          />
          <Route
            path={`${projectsRoute}/:projectId/${overviewRoute}/${editRoute}`}
            element={manageAuthenticationAndDeviceWidth(
              <ProjectOverviewEditPage />
            )}
          />
          <Route
            path={`${projectsRoute}/:projectId/${teamRoute}`}
            element={manageAuthenticationAndDeviceWidth(<ProjectTeamPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${teamRoute}/${memberRoute}/:memberId`}
            element={manageAuthenticationAndDeviceWidth(
              <ProjectTeamMemberPage />
            )}
          />
          <Route
            path={`${projectsRoute}/:projectId/${teamRoute}/${addRoute}`}
            element={manageAuthenticationAndDeviceWidth(
              <ProjectTeamAddMemberPage />
            )}
          />
          <Route
            path={`${projectsRoute}/:projectId/${teamRoute}/${memberRoute}/:memberId/${tasksRoute}`}
            element={manageAuthenticationAndDeviceWidth(
              <ProjectTeamAssignTaskPage />
            )}
          />
          ProjectTeamAssignTaskPage
          <Route
            path={`${projectsRoute}/:projectId/${newTaskRoute}`}
            element={manageAuthenticationAndDeviceWidth(<NewTaskPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${taskRoute}/:taskId`}
            element={manageAuthenticationAndDeviceWidth(<TaskPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${taskRoute}/:taskId/${usersRoute}`}
            element={manageAuthenticationAndDeviceWidth(<TaskUsersPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${taskRoute}/:taskId/${checklistsRoute}`}
            element={manageAuthenticationAndDeviceWidth(
              <CreateChecklistsPage />
            )}
          />
          <Route
            path={`${projectsRoute}/:projectId/${taskRoute}/:taskId/${deviationsRoute}`}
            element={manageAuthenticationAndDeviceWidth(
              <CreateDeviationsPage />
            )}
          />
          <Route
            path={`${projectsRoute}/:projectId/${filtersRoute}`}
            element={manageAuthenticationAndDeviceWidth(<ProjectFiltersPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${filtersRoute}/${tasksRoute}`}
            element={manageAuthenticationAndDeviceWidth(<TasksFilterPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${filtersRoute}/${usersRoute}`}
            element={manageAuthenticationAndDeviceWidth(<UsersFilterPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${timeEntriesRoute}`}
            element={manageAuthenticationAndDeviceWidth(<TimeEntriesPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${timeEntriesRoute}/${addRoute}`}
            element={manageAuthenticationAndDeviceWidth(<AddTimeEntryPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${timeEntriesRoute}/${addRoute}/${tasksRoute}`}
            element={manageAuthenticationAndDeviceWidth(
              <AddTimeEntryTasksPage />
            )}
          />
          <Route
            path={`${projectsRoute}/:projectId/${timeEntriesRoute}/:timeEntryId`}
            element={manageAuthenticationAndDeviceWidth(<TimeEntryPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${timeEntriesRoute}/:timeEntryId/${tasksRoute}`}
            element={manageAuthenticationAndDeviceWidth(<TimeEntryTasksPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${timeEntriesRoute}/${filtersRoute}`}
            element={manageAuthenticationAndDeviceWidth(
              <TimeEntriesFiltersPage />
            )}
          />
          <Route
            path={`${projectsRoute}/:projectId/${timeEntriesRoute}/${filtersRoute}/${tasksRoute}`}
            element={manageAuthenticationAndDeviceWidth(
              <TimeEntriesTasksFilterPage />
            )}
          />
          <Route
            path={`${projectsRoute}/:projectId/${checklistsRoute}`}
            element={manageAuthenticationAndDeviceWidth(<ChecklistsPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${checklistsRoute}/:checklistId/${answersRoute}`}
            element={manageAuthenticationAndDeviceWidth(
              <ChecklistsAnswersPage />
            )}
          />
          <Route
            path={`${projectsRoute}/:projectId/${deviationsRoute}`}
            element={manageAuthenticationAndDeviceWidth(<DeviationsPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${deviationsRoute}/:deviationId`}
            element={manageAuthenticationAndDeviceWidth(
              <DeviationOverviewPage />
            )}
          />
          <Route
            path={`${projectsRoute}/:projectId/${documentsRoute}`}
            element={manageAuthenticationAndDeviceWidth(<DocumentsPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${deviationsRoute}/:deviationId/${editRoute}`}
            element={manageAuthenticationAndDeviceWidth(<EditDeviationPage />)}
          />
          <Route
            path={`${projectsRoute}/:projectId/${checklistsRoute}/${addRoute}`}
            element={manageAuthenticationAndDeviceWidth(
              <CreateChecklistsPage />
            )}
          />
          <Route
            path={`${projectsRoute}/:projectId/${deviationsRoute}/${addRoute}`}
            element={manageAuthenticationAndDeviceWidth(
              <CreateDeviationsPage />
            )}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    );
  }
};
export default App;
