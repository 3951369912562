import { startDateFilter } from "helpers/FiltersModulesHelpers/TaskStartDateFilter";
import { timeEntryEndDateFilter } from "helpers/FiltersModulesHelpers/TimeEntryEndDateFilter";
import { timeEntryTasksFilter } from "helpers/FiltersModulesHelpers/TimeEntryTasksFilter";
import { TimeEntryStatuses } from "interfaces/Enums/TimeEntryStatuses";
import { IMyTimeEntryVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVm";
import { IFiltration } from "store/Redux/Reducers/timeEntriesFilterReducer";
import { checkArrayIfBooleanConditionIsMet } from "./CheckArrayIfBooleanConditionIsMet";

export const datesAndTasksFilters = (
  foundFilter: IFiltration | undefined,
  timeEntries: IMyTimeEntryVm[]
) => {
  let filteredArray: IMyTimeEntryVm[] = [...timeEntries];

  if (foundFilter) {
    const startDateResult = startDateFilter(foundFilter, filteredArray);
    if (startDateResult) {
      filteredArray = startDateResult;
    }
    const endDateResult = timeEntryEndDateFilter(foundFilter, filteredArray);
    if (endDateResult) {
      filteredArray = endDateResult;
    }
    const tasksResult = timeEntryTasksFilter(foundFilter, filteredArray);
    if (tasksResult) {
      filteredArray = tasksResult;
    }

    const foundFiltersBooleanConditions = [
      foundFilter.showDidNotSendToApprove,
      foundFilter.showApproved,
      foundFilter.showAwaiting,
      foundFilter.showDisapproved,
    ];
    let index = 0;
    for (const item in TimeEntryStatuses) {
      if (typeof item === "string" && item !== TimeEntryStatuses.ReSent) {
        filteredArray = checkArrayIfBooleanConditionIsMet(
          filteredArray,
          foundFiltersBooleanConditions[index],
          item
        );
        index++;
      }
    }
  }

  return filteredArray;
};
