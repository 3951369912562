import HeaderAndCustomEndElement from "components/common/Headers/HeaderWithNotifications/HeaderAndCustomEndElement";

import AddTimeEntrySwitch from "components/ProjectsComponents/ProjectPageComponents/Pages/TimeEntriesPageComponents/AddTimeEntryPageComponents/AddTimeEntrySwitch/AddTimeEntrySwitch";
import TimeEntryPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/TimeEntriesPageComponents/TimeEntryPageComponents/Main/TimeEntryPageMain";
import { projectsRoute, timeEntriesRoute } from "constants/Routes";
import { storePathValues } from "helpers/General/StorePathValue";
import { useLocalStorage } from "Hooks/UseLocalStorage";
import { getTaskById } from "Pages/api/MyTasksClient";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import { modifyTasksStateForNewTimeEntry } from "store/Redux/Reducers/newTimeEntryReducer";
import { selectedTimeEntry } from "store/Redux/Reducers/selectedTimeEntryReducer";
import "./TimeEntryPage.scss";

const EditTimeEntry = () => {
  const [areHoursActive, setAreHoursActive] = useLocalStorage<boolean>(
    "timeEntriesAreHoursActive",
    false
  );
  const { projectId } = useParams();
  const globalStates = useSelector(selectedTimeEntry);
  const dispatch = useAppDispatch();
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const location = useLocation();

  const handleTask = async () => {
    const taskId = globalStates.selectedTimeEntryReducer.timeEntry?.taskId;
    if (taskId) {
      const timeEntrytaskId = await getTaskById(taskId);
      dispatch(modifyTasksStateForNewTimeEntry(timeEntrytaskId));
    } else {
      dispatch(modifyTasksStateForNewTimeEntry(null));
    }
    setIsLoading(false);
  };

  useEffect(() => storePathValues, [location]);

  useEffect(() => {
    const storage = globalThis?.sessionStorage;
    if (!storage.prevPath.includes("/timeEntries/")) {
      setAreHoursActive(false);
      handleTask();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (!globalStates.chosenProjectReducer.project || isLoading) {
    return <div />;
  }

  return (
    <div className={"time-entry-page__layout"}>
      <HeaderAndCustomEndElement
        endElement={
          <AddTimeEntrySwitch
            isActive={areHoursActive}
            setIsActive={setAreHoursActive}
          />
        }
        returnUrl={`/${projectsRoute}/${projectId}/${timeEntriesRoute}`}
      />
      <TimeEntryPageMain
        project={globalStates.chosenProjectReducer.project}
        timeEntry={globalStates.selectedTimeEntryReducer.timeEntry}
        task={globalStates.newTimeEntryReducer.task}
        showHours={areHoursActive}
        setAreHoursActive={setAreHoursActive}
        setIsPageLoading={setIsPageLoading}
        isPageLoading={isPageLoading}
      />
    </div>
  );
};

export default EditTimeEntry;
