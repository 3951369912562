import HeaderPageTitleAndReturn from "components/common/Headers/HeaderPageTitleAndReturn/HeaderPageTitleAndReturn";
import useViewerPageRestriction from "Hooks/UseViewerPageRestrictions";
import { ILabelValueStructure } from "interfaces/Other/ILabelValueStructure";
import { DeviationCategoryVm } from "interfaces/ViewModels/MyFormsViewModels/DeviationCategoryVm";
import { DeviationCauseVm } from "interfaces/ViewModels/MyFormsViewModels/DeviationCauseVm";
import {
  getDeviationsCategories,
  getDeviationsCauses,
} from "Pages/api/MyDeviationsClient";
import NoAccessPage from "Pages/NoAccessPage/NoAccessPage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store/Redux/store";
import "./CreateDeviationsPage.scss";
import CreateDeviationPageMain from "./Main/CreateDeviationPageMain";

const CreateDeviationsPage = () => {
  const [responsibleUser, setResponsibleUser] = useState<
    ILabelValueStructure | undefined
  >();
  const [deviationsCategories, setDeviationsCategories] = useState<
    DeviationCategoryVm[]
  >([]);
  const organizationId = useSelector(
    (state: RootState) => state.chosenOrganizationReducer.chosenOrganizationId
  );
  const [deviationsCauses, setDeviationsCauses] = useState<DeviationCauseVm[]>(
    []
  );
  const navigate = useNavigate();
  const returnToPreviousPage = () => {
    navigate(-1);
  };

  const handleDeviationsCategories = async (organizationId: number) => {
    setDeviationsCategories(await getDeviationsCategories(organizationId));
  };

  const handleDeviationsCauses = async (organizationId: number) => {
    setDeviationsCauses(await getDeviationsCauses(organizationId));
  };

  useEffect(() => {
    if (organizationId) {
      handleDeviationsCategories(organizationId);
      handleDeviationsCauses(organizationId);
    }
  }, [organizationId]);

  const { hasPermissions } = useViewerPageRestriction();

  if (!hasPermissions) {
    return <NoAccessPage />;
  }

  return (
    <div className="add-form-page__layout">
      <HeaderPageTitleAndReturn
        returnToPreviousPage={returnToPreviousPage}
        text={"Add form"}
      />
      <CreateDeviationPageMain
        setResponsibleUser={setResponsibleUser}
        responsibleUser={responsibleUser}
        deviationsCategories={deviationsCategories}
        deviationsCauses={deviationsCauses}
      />
    </div>
  );
};

export default CreateDeviationsPage;
