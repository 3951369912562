import CancelSaveBottomBar from "components/common/Footers/CancelSaveBottomBar/CancelSaveBottomBar";
import CreateOrganizationInputs from "components/common/CreateOrganizationInputs/CreateOrganizationInputs";
import AsyncToast from "components/common/Toasts/AsyncToast";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { ILabelValueStructure } from "interfaces/Other/ILabelValueStructure";
import { ICreateOrganizationFormInputs } from "interfaces/RegisterInterfaces/ICreateOrganizationFormInputs";
import { ICreateOrganizationRequest } from "interfaces/Requests/ICreateOrganizationRequest";
import { createOrganization } from "Pages/api/MyOrganizationsClient";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import { setChosenOrganizationIdState } from "store/Redux/Reducers/chosenOrganizationReducer";
import "./NewOrganizationPageMain.scss";

const NewOrganizationPageMain = () => {
  const { register, getValues } = useForm<ICreateOrganizationFormInputs>();
  const [countryOption, setCountryOption] = useState<ILabelValueStructure>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (countryOption && getValues()["Organization name"]) {
      const request: ICreateOrganizationRequest = {
        country: countryOption.label,
        currency: countryOption.value.toString().toLocaleUpperCase(),
        name: getValues()["Organization name"],
        url: getValues()["Organization website"],
      };
      const result = await AsyncToast(
        createOrganization(request),
        "Created organization"
      );
      dispatch(setChosenOrganizationIdState(result));
      navigate("/Projects");
    } else {
      SimpleToast({
        mode: ToastModes.info,
        message: "Name and country are required",
      });
    }
  };

  const returnToThePreviousPage = () => {
    navigate(-1);
  };

  return (
    <div className="new-organization-page__main-layout">
      <div>
        <CreateOrganizationInputs
          register={register}
          countryOption={countryOption}
          setCountryOption={setCountryOption}
        />
      </div>
      <div>
        <CancelSaveBottomBar
          onCancelClick={returnToThePreviousPage}
          onSaveClick={handleSubmit}
        />
      </div>
    </div>
  );
};
export default NewOrganizationPageMain;
