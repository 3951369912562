import { IMyTimeEntriesDay } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntriesDay";
import TimeEntries from "./TimeEntry/TimeEntry";
import "./TimeEntriesDay.scss";
import { getHoursFromMinutes } from "helpers/TimeHelpers/GetHoursFromMinutes";

interface ITimeEntriesDay {
  day: IMyTimeEntriesDay;
}

const TimeEntriesDay = ({ day }: ITimeEntriesDay) => {
  return (
    <div>
      <div>
        <div className={"time-entries-day__layout"}>
          <span>{day.dayDate}</span>
          <span>
            Day hours:{" "}
            <span className={"time-entries-day__text--black"}>
              {getHoursFromMinutes(day.sumOfMinutes)}
            </span>
          </span>
        </div>
      </div>
      {day.timeEntries.map((timeEntry) => {
        return <TimeEntries key={timeEntry.id} timeEntry={timeEntry} />;
      })}
    </div>
  );
};

export default TimeEntriesDay;
