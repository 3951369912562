import SeparationLine from "components/common/SeparationLine/SeparationLine";
import { useRef, useState } from "react";
import GrayRightArrow from "public/GrayRightArrow.svg";
import "./ProjectTasksSection.scss";
import GrayDownArrow from "public/GrayDownArrow.svg";
import GrayLeftArrow from "public/GrayLeftArrow.svg";
import {
  verticalArrowCommonHeight,
  verticalArrowCommonWidth,
} from "constants/Consts";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import TaskSectionItem from "./ProjectTaskSectionItem/ProjectTaskSectionModule";
import { grayBackground, whiteBackground } from "constants/JsColors";
import { separateCamelCaseWithSpaces } from "helpers/General/SeparateCamelCaseWithSpaces";

interface ITasksSection {
  imgSrc: string;
  taskCategoryName: string;
  count: number;
  dotWidth: number;
  dotHeight: number;
  tasks: IMyTaskVm[];
  verticalUnderline: string;
  shouldBeOpenByDefault?: boolean;
}

const ProjectTasksSection = ({
  imgSrc,
  count,
  taskCategoryName,
  dotWidth,
  dotHeight,
  tasks,
  verticalUnderline,
  shouldBeOpenByDefault = false,
}: ITasksSection) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(
    shouldBeOpenByDefault
  );
  const tasksDropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div
      data-testid="taskContainerSection"
      ref={tasksDropdownRef}
      onClick={() => {
        setIsDropdownOpen(!isDropdownOpen);
      }}
    >
      <div
        className={"project-page__tasks-wrapper"}
        style={{
          background: isDropdownOpen ? grayBackground : whiteBackground,
        }}
      >
        <div className={"project-page__tasks-wrapper-info"}>
          <img
            src={imgSrc}
            alt="Task vertical underline"
            width={dotWidth}
            height={dotHeight}
          />
          <span className={"project-page__tasks-category-name"}>
            {separateCamelCaseWithSpaces(taskCategoryName)}
          </span>
          &nbsp;
          <span className={"project-page__tasks-count-text"}>({count})</span>
          {isDropdownOpen && (
            <div className={"project-page__tasks-arrow--bottom"}>
              <img
                src={GrayDownArrow}
                alt="Gray down arrow"
                width={12}
                height={12}
              />
            </div>
          )}
        </div>
        <div className={"project-page__tasks-arrow-wrapper--bottom"}>
          <span> {isDropdownOpen ? "Hide" : "Show"}</span>
          <img
            src={isDropdownOpen ? GrayLeftArrow : GrayRightArrow}
            alt={isDropdownOpen ? "Left arrow" : "Right arrow"}
            width={verticalArrowCommonWidth}
            height={verticalArrowCommonHeight}
          />
        </div>
      </div>
      {isDropdownOpen && tasks.length > 0 && (
        <TaskSectionItem
          tasks={tasks}
          imgSrc={imgSrc}
          verticalUnderline={verticalUnderline}
          taskCategoryName={taskCategoryName}
        />
      )}
      <SeparationLine marginTop={0} marginBottom={0} />
    </div>
  );
};

export default ProjectTasksSection;
