import CheckboxItem from "components/common/CheckboxItem/CheckboxItem";
import FromToInputs from "components/common/FromToInputs/FromToInputs";
import HoursInputs from "components/common/HoursInputs/HoursInputs";
import SeparationLine from "components/common/SeparationLine/SeparationLine";
import TextAreaDescriptionComponent from "components/common/DescriptionComponents/MinimalisticTextAreaDescriptionComponent/MinimalisticTextAreaDescriptionComponent";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { projectsRoute, timeEntriesRoute, tasksRoute } from "constants/Routes";
import { differenceInMinutes } from "date-fns";
import { createADateFromTime } from "helpers/TimeHelpers/CreateADateFromStringTime";
import { getHoursFromMinutes } from "helpers/TimeHelpers/GetHoursFromMinutes";
import { getTimeFromDate } from "helpers/TimeHelpers/GetTimeFromDate";
import { useLocalStorage } from "Hooks/UseLocalStorage";
import { TimeEntryStatuses } from "interfaces/Enums/TimeEntryStatuses";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { timeEntryStatuses } from "interfaces/Other/ITimeEntryStatuses";
import { ICreateTimeEntryRequest } from "interfaces/Requests/ICreateTimeEntryRequest";
import { IMyProjectVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectVm";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import { IMyTimeEntryVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVm";
import { Link, useNavigate, useParams } from "react-router-dom";
import { patchTimeEntry, updateTimeEntry } from "Pages/api/MyTimeEntriesClient";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import "./TimeEntryPageMain.scss";
import asyncToast from "components/common/Toasts/AsyncToast";
import { JSONPatchDocumentReplaceString } from "interfaces/JSONPatchFormats/JSONPatchDocumentReplaceString";
import TimeEntryDropdown from "../../AddTimeEntryPageComponents/AddTimeEntryPageMain/TimeEntryDropdown/TimeEntryDropdown";
import TimeEntryStatus from "../../AddTimeEntryPageComponents/TimeEntryStatus/TimeEntryStatus";
import SaveButton from "components/common/Buttons/SaveButton/SaveButton";
import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import { useSelector } from "react-redux";
import { selectedTask } from "store/Redux/Reducers/selectedTaskReducer";

interface IMain {
  project: IMyProjectVm;
  timeEntry: IMyTimeEntryVm | null;
  showHours: boolean;
  setAreHoursActive: (value: boolean | ((val: boolean) => boolean)) => void;
  task: IMyTaskVm | null;
  setIsPageLoading: Dispatch<SetStateAction<boolean>>;
  isPageLoading: boolean;
}

const TimeEntryPageMain = ({
  project,
  timeEntry,
  showHours,
  setAreHoursActive,
  task,
  setIsPageLoading,
  isPageLoading,
}: IMain) => {
  const [isSendToApproveActive, setIsSendToApproveActive] =
    useLocalStorage<boolean>("editTimeEntriesRequestApproved", false);
  const globalStates = useSelector(selectedTask);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [startTime, setStartTime] = useLocalStorage<string>(
    "timeEntriesStartTime",
    timeEntry ? getTimeFromDate(new Date(timeEntry?.startDate)) : ""
  );
  const [finishTime, setFinishTime] = useLocalStorage<string>(
    "timeEntriesFinishTime",
    timeEntry ? getTimeFromDate(new Date(timeEntry?.finishDate)) : ""
  );
  const [totalTime, setTotalTime] = useLocalStorage<string>(
    "timeEntriesTotalTime",
    ""
  );
  const [description, setDescription] = useLocalStorage<string>(
    "timeEntriesDescription",
    ""
  );
  const [date, setDate] = useLocalStorage<Date>(
    "timeEntriesDate",
    timeEntry ? new Date(timeEntry?.startDate) : new Date()
  );

  const statusConditionRestriction: boolean =
    timeEntry?.status === "NotRequestedForApproval" ||
    timeEntry?.status === "Disapproved";

  useEffect(() => {
    const storage = globalThis?.sessionStorage;
    if (timeEntry && !storage.prevPath.includes("/tasks")) {
      setAreHoursActive(
        getTimeFromDate(new Date(timeEntry?.startDate)) === "00:00"
      );
      setStartTime(getTimeFromDate(new Date(timeEntry.startDate)));
      setFinishTime(getTimeFromDate(new Date(timeEntry.finishDate)));
      setTotalTime(getHoursFromMinutes(timeEntry.totalNumberOfMinutes));
      setDescription(timeEntry.description);
      setDate(new Date(timeEntry.startDate));
      setIsSendToApproveActive(false);
    }
    setIsPageLoading(false);
  }, [timeEntry]);

  useEffect(() => {
    if (timeEntry?.status === "Awaiting") {
      setIsSendToApproveActive(true);
    }
  }, [timeEntry?.status]);

  const redirectToMainTimeEntriesPage = () => {
    navigate(`/${projectsRoute}/${projectId}/${timeEntriesRoute}`);
  };

  const sendTimeEntryToApproval = async (timeEntryId: number) => {
    if (projectId) {
      const JSONrequest: JSONPatchDocumentReplaceString[] = [
        {
          op: "replace",
          path: "/status",
          value: TimeEntryStatuses.Awaiting,
        },
      ];
      await asyncToast(
        patchTimeEntry(JSONrequest, timeEntryId, +projectId),
        "Send time entry to approval"
      );
    }
  };

  const updateRequestForHours = async () => {
    const startDate = createADateFromTime("00:00", new Date(date));
    const finishDate = createADateFromTime(totalTime, new Date(date));
    if (
      startDate &&
      finishDate &&
      totalTime !== "00:00" &&
      showHours &&
      timeEntry &&
      projectId
    ) {
      const request: ICreateTimeEntryRequest = {
        startDate: startDate,
        finishDate: finishDate,
        description: encodeURIComponent(description),
        taskId: task ? task.id : 0,
        projectId: +projectId,
        containsFromAndToDates: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const createdTimeEntry: IMyTimeEntryVm = await asyncToast(
        updateTimeEntry(request, timeEntry?.id),
        "Updated time entry"
      );
      if (isSendToApproveActive) {
        await sendTimeEntryToApproval(createdTimeEntry.id);
      }
      await redirectToMainTimeEntriesPage();
    } else {
      SimpleToast({
        mode: ToastModes.info,
        message: "You did not insert the correct dates",
      });
    }
  };

  const updateRequestForFromTo = async () => {
    const startDate = createADateFromTime(startTime, new Date(date));
    const finishDate = createADateFromTime(finishTime, new Date(date));
    if (
      !showHours &&
      startDate &&
      finishDate &&
      differenceInMinutes(new Date(finishDate), new Date(startDate)) > 0 &&
      timeEntry &&
      projectId
    ) {
      const request: ICreateTimeEntryRequest = {
        startDate: startDate ? startDate : new Date(),
        finishDate: finishDate ? finishDate : new Date(),
        description: encodeURIComponent(description),
        taskId: task ? task.id : 0,
        projectId: +projectId,
        containsFromAndToDates: true,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const createdTimeEntry: IMyTimeEntryVm = await asyncToast(
        updateTimeEntry(request, timeEntry.id),
        "Updated time entry"
      );
      if (isSendToApproveActive) {
        await sendTimeEntryToApproval(createdTimeEntry.id);
      }
      await redirectToMainTimeEntriesPage();
    } else {
      SimpleToast({
        mode: ToastModes.error,
        message: "You did not insert the correct dates",
      });
    }
  };

  const createTimeEntryHandler = () => {
    if (showHours) {
      updateRequestForHours();
    } else {
      updateRequestForFromTo();
    }
  };

  const hasPermissionToEditTimeEntry = useMemo(() => {
    if (globalStates.userProfileReducer.userProfile !== null) {
      return restrictProjectAccessBySpecifiedRoles(
        globalStates.userProfileReducer.userProfile.projectRole,
        [ProjectRolesEnum.Viewer]
      );
    }
    return false;
  }, [globalStates.userProfileReducer.userProfile]);

  if (isPageLoading) {
    return <div />;
  }

  return (
    <div className={"time-entry-page-main__layout"}>
      <TimeEntryStatus
        status={timeEntryStatuses.find(
          (item) => item.label === timeEntry?.status
        )}
      />
      {showHours ? (
        <HoursInputs
          setTotalTime={setTotalTime}
          totalTime={totalTime}
          setDate={setDate}
          date={date}
          hasPermissionToEditTimeEntry={hasPermissionToEditTimeEntry}
        />
      ) : (
        <FromToInputs
          setDate={setDate}
          date={date}
          startTime={startTime}
          finishTime={finishTime}
          setStartTime={setStartTime}
          setFinishTime={setFinishTime}
          hasPermissionToEditTimeEntry={hasPermissionToEditTimeEntry}
        />
      )}
      <TimeEntryDropdown
        firstText={"Projects"}
        secondText={project.name}
        isActive={true}
        isRightArrowVisible={false}
      />
      <SeparationLine />
      {statusConditionRestriction && hasPermissionToEditTimeEntry ? (
        <Link
          to={`/${projectsRoute}/${projectId}/${timeEntriesRoute}/${timeEntry?.id}/${tasksRoute}`}
        >
          <TimeEntryDropdown
            firstText={"Tasks"}
            secondText={task ? task.name : "No task"}
            isActive={task !== null}
          />
        </Link>
      ) : (
        <TimeEntryDropdown
          firstText={"Tasks"}
          secondText={task ? task.name : "No task"}
          isActive={task !== null}
          isRightArrowVisible={hasPermissionToEditTimeEntry}
        />
      )}
      <SeparationLine />
      <TextAreaDescriptionComponent
        setDescription={setDescription}
        description={description}
        hasPermissionToEdit={hasPermissionToEditTimeEntry}
      />
      <SeparationLine />
      <div className={"time-entry-page-main__checkbox-item-wrapper"}>
        <CheckboxItem
          isActive={isSendToApproveActive}
          setIsActive={setIsSendToApproveActive}
          text={"Send to approve"}
        />
      </div>
      {statusConditionRestriction && hasPermissionToEditTimeEntry && (
        <SaveButton save={createTimeEntryHandler} />
      )}
    </div>
  );
};

export default TimeEntryPageMain;
