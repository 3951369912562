import RequiredStar from "components/common/RequiredStar/RequiredStar";
import "./DropdownWithLabel.scss";

interface IDropdownWithLabel {
  label: string;
  marginTop?: number;
  marginBottom?: number;
  SelectComponent: JSX.Element;
  required?: boolean;
}

const DropdownWithLabel = ({
  label,
  marginTop,
  marginBottom,
  SelectComponent,
  required = false,
}: IDropdownWithLabel) => {
  return (
    <div
      className={"common-components__dropdown-with-label-wrapper"}
      style={{ marginTop: marginTop, marginBottom: marginBottom }}
    >
      <div className={"common-components__dropdown-with-label-label"}>
        <span>
          {label}
          {required && <RequiredStar />}
        </span>
      </div>
      {SelectComponent}
    </div>
  );
};

export default DropdownWithLabel;
