import React, { useState } from "react";
import "./FromToContainer.scss";
import "react-datepicker/dist/react-datepicker.css";
import { greenText, borderWhite } from "../../../../constants/JsColors";
import CalendarComponent from "../../CalendarComponent/CalendarComponent";
import DateContent from "components/common/TimeEntriesInputs/DateContainer/DateContent/DateContent";
import Label from "components/common/TimeEntriesInputs/DateContainer/DateLabel/Label";

interface IFromToContainer {
  setStartTime: React.Dispatch<React.SetStateAction<string>>;
  setFinishTime: React.Dispatch<React.SetStateAction<string>>;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  date: Date;
  startTime: string;
  finishTime: string;
  hasPermissionToEditTimeEntry: boolean;
}

const FromToContainer = ({
  setStartTime,
  setFinishTime,
  setDate,
  date,
  finishTime,
  startTime,
  hasPermissionToEditTimeEntry,
}: IFromToContainer) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const onSaveClick = (date: Date | null) => {
    if (date) {
      setDate(date);
    }
  };

  const containersWidth = "31%";

  return (
    <>
      <div className={"common-components__from-to-container"}>
        <div
          className={"common-components__from-to-container-wrapper"}
          style={{
            borderColor: isCalendarOpen ? greenText : borderWhite,
            width: containersWidth,
          }}
          onClick={() =>
            hasPermissionToEditTimeEntry && setIsCalendarOpen(true)
          }
        >
          <Label text={"date"} showCalendar={true} />
          <DateContent
            calendarDate={new Date(date)}
            hasPermissionToEditTimeEntry={hasPermissionToEditTimeEntry}
          />
        </div>
        <div
          className={"common-components__from-to-container-wrapper"}
          style={{
            width: containersWidth,
          }}
        >
          <Label text={"START"} />
          <DateContent
            setTime={setStartTime}
            value={startTime}
            hasPermissionToEditTimeEntry={hasPermissionToEditTimeEntry}
          />
        </div>
        -
        <div
          className={"common-components__from-to-container-wrapper"}
          style={{
            width: containersWidth,
          }}
        >
          <Label text={"FINISH"} />
          <DateContent
            setTime={setFinishTime}
            value={finishTime}
            hasPermissionToEditTimeEntry={hasPermissionToEditTimeEntry}
          />
        </div>
      </div>
      <CalendarComponent
        isOpen={isCalendarOpen}
        isOpenSetter={setIsCalendarOpen}
        date={date}
        onSaveClick={onSaveClick}
      />
    </>
  );
};

export default FromToContainer;
