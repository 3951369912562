import Footer from "components/common/Footers/Footer";
import HeaderAndCustomEndElement from "components/common/Headers/HeaderWithNotifications/HeaderAndCustomEndElement";
import NotifiactionsBellAndMenu from "components/common/NotifiactionsBellAndMenu/NotifiactionsBellAndMenu";
import ChecklistsPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/ChecklistsPageComponents/ChecklistsPageMain";
import { projectsRoute } from "constants/Routes";
import ChecklistVm from "interfaces/ViewModels/MyChecklistsViewModels/ChecklistVm";
import { getChecklistsForProject } from "Pages/api/MyChecklistsClient";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import "./ChecklistsPage.scss";
import { compareDesc } from "date-fns";
import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";

const ChecklistsPage = () => {
  const { projectId } = useParams();
  const globalStates = useSelector(chosenOrganization);
  const [assignedForms, setAssignedForms] = useState<ChecklistVm[]>();

  const handleAssignedForms = async (projectId: number) => {
    const result = await getChecklistsForProject(projectId);
    const sortedChecklists = result.sort((firstItem, secondItem) =>
      compareDesc(new Date(firstItem.assigned), new Date(secondItem.assigned))
    );
    setAssignedForms(sortedChecklists);
  };

  useEffect(() => {
    if (projectId) {
      handleAssignedForms(+projectId);
    }
  }, [projectId]);

  if (
    !globalStates.allUsersOrganizationsSlice.usersOrganizations ||
    !globalStates.userProfileReducer.userProfile ||
    !assignedForms
  ) {
    return <div />;
  } else
    return (
      <div className="checklists-page__main-layout">
        <HeaderAndCustomEndElement
          endElement={
            <NotifiactionsBellAndMenu
              organizations={
                globalStates.allUsersOrganizationsSlice.usersOrganizations
              }
              userDetails={globalStates.userProfileReducer.userProfile}
              myOrganizationId={
                globalStates.chosenOrganizationReducer.chosenOrganizationId
              }
            />
          }
          returnUrl={`/${projectsRoute}/${projectId}`}
        />
        <ChecklistsPageMain checklists={assignedForms} />
        <Footer
          isInProjectsPage={false}
          isInTimeEntriesPage={false}
          hasPermissionToCreate={restrictProjectAccessBySpecifiedRoles(
            globalStates.userProfileReducer.userProfile.projectRole,
            [ProjectRolesEnum.Viewer]
          )}
        />
      </div>
    );
};

export default ChecklistsPage;
