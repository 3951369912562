import { UseFormRegister } from "react-hook-form";
import "./BorderedTextAreaDescriptionComponent.scss";

interface IBorderedTextAreaDescriptionComponent {
  marginTop?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  marginBottom?: number;
  label: string;
}

const BorderedTextAreaDescriptionComponent = ({
  marginBottom,
  marginTop,
  register,
  label,
}: IBorderedTextAreaDescriptionComponent) => {
  return (
    <div
      style={{
        marginTop: marginTop,
        marginBottom: marginBottom,
      }}
    >
      <div className={"common-components__bordered-description"}>{label}</div>
      <textarea
        className={"common-components__bordered-description-textarea"}
        placeholder={"Enter your description here"}
        {...register(label)}
      />
    </div>
  );
};

export default BorderedTextAreaDescriptionComponent;
