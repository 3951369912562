import { getChecklistOrDeviationStateImage } from "components/common/ChecklistsOrDeviationsStateImages/ChecklistsOrDeviationsStateImages";
import "./DeviationItem.scss";
import ColoredBadge from "components/common/Badges/ColoredBadge/ColoredBadge";
import { ColoredBadgeColors } from "interfaces/Enums/ColoredBadgeColors";
import DateBadge from "components/common/Badges/DateBadge/DateBadge";
import { returnUsersCredentials } from "helpers/General/ReturnUserCredentials";
import SeparationLine from "components/common/SeparationLine/SeparationLine";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import UserAvatarWithCredentials from "components/common/UserAvatarWithCredentials/UserAvatarWithCredentials";
import { format } from "date-fns";
import { slashesDateFormat } from "constants/DateFormats";
import DeviationVm from "interfaces/ViewModels/MyDeviationsViewModels/DeviationVm";
import { changeSelectedDeviation } from "store/Redux/Reducers/selectedDeviationsReducer";
import UsersAvatarsGroup from "one-common-components/lib/components/UsersAvatarsGroup/UsersAvatarsGroup";
import { projectsRoute, deviationsRoute } from "constants/Routes";

interface IDeviationItem {
  deviation: DeviationVm;
}

const DeviationItem = ({ deviation }: IDeviationItem) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const handleDeviationClick = () => {
    dispatch(changeSelectedDeviation(deviation));
    navigate(
      `/${projectsRoute}/${projectId}/${deviationsRoute}/${deviation.id}`
    );
  };

  const userCredentials = deviation.formResponsibleUserDto
    ? returnUsersCredentials(
        deviation.formResponsibleUserDto.firstName,
        deviation.formResponsibleUserDto.lastName,
        deviation.formResponsibleUserDto.email
      )
    : "User not available";

  return (
    <div className="deviation-item__wrapper" onClick={handleDeviationClick}>
      <header className="deviation-item__heading">
        <div className="deviation-item__title-and-icon">
          {getChecklistOrDeviationStateImage(deviation.status)}
          <span className="deviation-item__name">{deviation.name}</span>
        </div>
        <div className="deviation-item__deadline">
          {format(new Date(deviation.assigned), slashesDateFormat)}
        </div>
      </header>
      <main className="deviation-item__main">
        <div className="deviation-item__badges">
          <ColoredBadge
            text={deviation.categoryName}
            color={ColoredBadgeColors.red}
          />
          {deviation.deadline && (
            <DateBadge date={new Date(deviation.deadline)} marginLeft={8} />
          )}
        </div>
        <div className="deviation-item__assignees">
          <UserAvatarWithCredentials
            validatedUserCredentials={userCredentials}
            role={
              deviation.formResponsibleUserDto
                ? deviation.formResponsibleUserDto.role
                : ""
            }
          />
          <UsersAvatarsGroup
            users={deviation.formLinkUsers}
            maxAvatarsNumber={3}
            size={32}
          />
        </div>
      </main>
      <SeparationLine />
      <footer className="deviation-item__footer">
        <div className="deviation-item__footer-label">
          Cost:
          <span className="deviation-item__footer-cost">
            {deviation.cost ? deviation.cost : "0"}
          </span>
        </div>
        <div className="deviation-item__footer-label">
          Time:
          <span className="deviation-item__footer-duration">
            {deviation.timeImplication ? deviation.timeImplication : "0"}
          </span>
        </div>
      </footer>
    </div>
  );
};

export default DeviationItem;
