import FiltrationsHeader from "components/common/FiltrationsHeader/FiltrationsHeader";
import GrayFiltrationBar from "components/common/GrayFiltrationBar/GrayFiltrationBar";
import ListOfTasksContainer from "components/common/ListOfTasks/ListOfTasksContainer/ListOfTasksContainer";
import SimpleToast from "components/common/Toasts/SimpleToast";
import {
  filtersRoute,
  projectsRoute,
  timeEntriesRoute,
} from "constants/Routes";
import { parseTasksDividedByDatesToOneArray } from "helpers/TasksHelpers/ParseTasksDividedByDatesToOneArray";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import { getProjectTasksWithDetails } from "Pages/api/MyProjectsClient";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import {
  selectFilters,
  ITasksState,
  setTasksState,
  IFiltration,
} from "store/Redux/Reducers/timeEntriesFilterReducer";
import "./TimeEntriesTasksFilterPage.scss";

const TimeEntriesTasksFilterPage = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [allTasks, setAllTasks] = useState<IMyTaskVm[]>([]);
  const filters = useSelector(selectFilters);
  const foundFilter = filters.timeEntriesFiltersReducer.filters.find(
    (filter: IFiltration) => projectId && +filter.projectId === +projectId
  );

  const [chosenTasks, setChosenTasks] = useState<IMyTaskVm[]>(
    foundFilter ? foundFilter.tasks : []
  );
  const [filtratedItems, setFiltratedItems] = useState<IMyTaskVm[]>(allTasks);
  const dispatch = useAppDispatch();

  const confirmStateChange = () => {
    if (projectId) {
      const changingTasks: ITasksState = {
        tasks: chosenTasks,
        projectId: +projectId,
      };
      dispatch(
        setTasksState({
          ...changingTasks,
        })
      );
      navigate(
        `/${projectsRoute}/${projectId}/${timeEntriesRoute}/${filtersRoute}`
      );
    } else {
      SimpleToast({
        mode: ToastModes.error,
        message: "Project id cannot be 0 or null.",
      });
    }
  };

  const handleProjectTasksWithDetails = async (projectId: number) => {
    const tasks = await getProjectTasksWithDetails(projectId);
    const parsedTasks = parseTasksDividedByDatesToOneArray(tasks);
    setAllTasks(parsedTasks);
    setFiltratedItems(parsedTasks);
  };

  useEffect(() => {
    if (projectId) {
      handleProjectTasksWithDetails(+projectId);
    }
  }, [projectId]);

  if (!allTasks) {
    return <div />;
  }

  return (
    <div className="time-entries__tasks-filter-page-layout">
      <FiltrationsHeader
        pageName={"Task Id"}
        applyFiltersText={"Save"}
        confimationFunction={confirmStateChange}
        showConfirmationToast={false}
      />
      <main className="time-entries__task-filter-page-main-layout">
        <GrayFiltrationBar
          originalArray={allTasks}
          setFiltratedItems={setFiltratedItems}
        />
        <ListOfTasksContainer
          tasks={filtratedItems}
          chosenTasks={chosenTasks}
          setChosenTasks={setChosenTasks}
        />
      </main>
    </div>
  );
};

export default TimeEntriesTasksFilterPage;
