import { slashesDateFormat } from "constants/DateFormats";
import { format } from "date-fns";
import "./DateContent.scss";

interface IDateContent {
  calendarDate?: Date | null;
  setTime?: React.Dispatch<React.SetStateAction<string>>;
  value?: string;
  hasPermissionToEditTimeEntry?: boolean;
}

const DateContent = ({
  calendarDate,
  setTime,
  value,
  hasPermissionToEditTimeEntry = true,
}: IDateContent) => {
  return (
    <div className={"add-time-entry__date-container-content"}>
      <span className={"add-time-entry__date-container-date-content"}>
        {calendarDate ? (
          format(new Date(calendarDate), slashesDateFormat)
        ) : (
          <input
            id="dateContentInput"
            className={"add-time-entry__date-container-date-input"}
            placeholder={"08:00"}
            value={value}
            onChange={(e) =>
              setTime && hasPermissionToEditTimeEntry && setTime(e.target.value)
            }
            maxLength={5}
          />
        )}
      </span>
    </div>
  );
};

export default DateContent;
