import "./CalendarWithLabel.scss";
import { format } from "date-fns";
import { slashesDateFormat } from "constants/DateFormats";
import { useState } from "react";
import CalendarComponent from "../../CalendarComponent/CalendarComponent";
import GrayCalendarIcon from "public/GrayCalendarIcon.svg";

interface ICalendarWithLabel {
  date: Date | null;
  handleSetDate: (date: Date | null) => void;
  label: string;
  marginBottom?: number;
  marginTop?: number;
}

const CalendarWithLabel = ({
  date,
  handleSetDate,
  label,
  marginBottom,
  marginTop,
}: ICalendarWithLabel) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  return (
    <>
      <div
        className={"common-components__calendar-with-label-wrapper"}
        style={{
          marginTop: marginTop,
          marginBottom: marginBottom,
        }}
      >
        <div className={"common-components__calendar-with-label"}>
          <span>{label}</span>
        </div>
        <div
          onClick={() => setIsCalendarOpen(true)}
          className={"common-components__calendar-with-label-input"}
        >
          <img
            src={GrayCalendarIcon}
            alt={"Calendar"}
            className="common-components__calendar-with-label-image"
          />
          {date ? format(date, slashesDateFormat) : null}
        </div>
      </div>
      <CalendarComponent
        isOpen={isCalendarOpen}
        isOpenSetter={setIsCalendarOpen}
        date={date}
        onSaveClick={handleSetDate}
      />
    </>
  );
};

export default CalendarWithLabel;
