import SaveButton from "components/common/Buttons/SaveButton/SaveButton";
import CancelButton from "../../CancelButton/CancelButton";
import "./CancelSaveBottomBar.scss";

interface ICancelSaveBottomBar {
  onCancelClick: () => void;
  cancelButtonName?: string;
  onSaveClick: () => void;
  saveButtonName?: string;
}

const CancelSaveBottomBar = ({
  onCancelClick,
  cancelButtonName = "Cancel",
  onSaveClick,
  saveButtonName = "Save",
}: ICancelSaveBottomBar) => {
  return (
    <div className="new-project-page__footer">
      <div className="new-project-page__cancel-button-wrapper">
        <CancelButton cancel={onCancelClick} buttonName={cancelButtonName} />
      </div>
      <div className="new-project-page__save-button-wrapper">
        <SaveButton save={onSaveClick} buttonName={saveButtonName} />
      </div>
    </div>
  );
};

export default CancelSaveBottomBar;
