import SeparationLine from "components/common/SeparationLine/SeparationLine";
import { useRef, useState } from "react";
import GrayRightArrow from "public/GrayRightArrow.svg";
import "./ProjectOverviewPageDropdown.scss";
import GrayDownArrow from "public/GrayDownArrow.svg";
import GrayLeftArrow from "public/GrayLeftArrow.svg";
import {
  verticalArrowCommonHeight,
  verticalArrowCommonWidth,
} from "constants/Consts";
import { grayBackground, whiteBackground } from "constants/JsColors";

interface IProjectOverviewPageDropdown {
  count: number;
  dropdownName: string;
  containerContent: JSX.Element;
}

const ProjectOverviewPageDropdown = ({
  count,
  dropdownName,
  containerContent,
}: IProjectOverviewPageDropdown) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const tasksDropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div
      data-testid="taskContainerSection"
      ref={tasksDropdownRef}
      onClick={() => {
        setIsDropdownOpen(!isDropdownOpen);
      }}
      style={{
        background: isDropdownOpen ? grayBackground : whiteBackground,
      }}
      className="project-page-overview-dropdown__tasks-container"
    >
      <div className={"project-page-overview-dropdown__tasks-wrapper"}>
        <div className={"project-page-overview-dropdown__tasks-wrapper-info"}>
          <span>{dropdownName}</span>
          &nbsp;
          <span className={"project-page-overview-dropdown__tasks-count-text"}>
            ({count})
          </span>
          {isDropdownOpen && (
            <div
              className={"project-page-overview-dropdown__tasks-arrow--bottom"}
            >
              <img
                src={GrayDownArrow}
                alt="Gray down arrow"
                width={12}
                height={12}
              />
            </div>
          )}
        </div>
        <div
          className={
            "project-page-overview-dropdown__tasks-arrow-wrapper--bottom"
          }
        >
          <span> {isDropdownOpen ? "Hide" : "Show"}</span>
          <img
            src={isDropdownOpen ? GrayLeftArrow : GrayRightArrow}
            alt={isDropdownOpen ? "Left arrow" : "Right arrow"}
            width={verticalArrowCommonWidth}
            height={verticalArrowCommonHeight}
          />
        </div>
      </div>
      {isDropdownOpen && containerContent}
      <div className="project-page-overview-dropdown__separation-line-wrapper">
        {!isDropdownOpen && <SeparationLine marginTop={0} marginBottom={0} />}
      </div>
    </div>
  );
};

export default ProjectOverviewPageDropdown;
