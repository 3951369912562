import "./StatusIcon.scss";

interface IStatusIcon {
  icon: string;
  iconAlt: string;
}

const StatusIcon = ({ icon, iconAlt }: IStatusIcon) => {
  return (
    <div className={"time-entry__status-icon"}>
      <img src={icon} alt={iconAlt} width={20} height={20} />
    </div>
  );
};

export default StatusIcon;
