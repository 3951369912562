import { ToastModes } from "interfaces/Enums/ToastModes";
import { successToast } from "./Toasts/SuccessToast";
import { errorToast } from "./Toasts/ErrorToast";
import { infoToast } from "./Toasts/InfoToast";

interface ISimpleToast {
  mode: ToastModes;
  message: string;
}

const SimpleToast = ({ mode, message }: ISimpleToast) => {
  switch (mode) {
    case ToastModes.info:
      return infoToast(message);
    case ToastModes.success:
      return successToast(message);
    case ToastModes.error:
      return errorToast(message);
  }
};

export default SimpleToast;
