import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import type { RootState } from "../store";

export interface IBooleanFiltration {
  showApproved: boolean;
  showDisapproved: boolean;
  showAwaiting: boolean;
  showDidNotSendToApprove: boolean;
  projectId: number;
}

export interface IPeriodFiltration {
  startDate: string | null;
  finishDate: string | null;
  projectId: number;
}

export interface ITasksState {
  tasks: IMyTaskVm[];
  projectId: number;
}

export interface IFiltration {
  showApproved: boolean;
  showDisapproved: boolean;
  showAwaiting: boolean;
  showDidNotSendToApprove: boolean;
  projectId: number;
  startDate: string | null;
  deadline: string | null;
  tasks: IMyTaskVm[];
}
export interface IDeadlineDateFiltration {
  deadline: string | null;
  projectId: number;
}

export interface IStartDateFiltration {
  startDate: string | null;
  projectId: number;
}

// Define the initial state using that type
export const initialItemState: IFiltration = {
  showApproved: true,
  showDisapproved: true,
  showAwaiting: true,
  showDidNotSendToApprove: true,
  projectId: 0,
  startDate: "",
  deadline: "",
  tasks: [],
};

export interface IFiltrationArray {
  filters: IFiltration[];
}

const initialState: IFiltrationArray = {
  filters: [],
};

export const filtrationSlice = createSlice({
  name: "timeEntriesFilters",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    createFiltration: (state, action: PayloadAction<IFiltration>) => {
      if (
        !state.filters.some(
          (filter) => filter.projectId === action.payload.projectId
        )
      ) {
        const newItem = { ...initialItemState };
        newItem.projectId = action.payload.projectId;
        state.filters.push(newItem);
      }
    },
    setBooleanFilters: (state, action: PayloadAction<IBooleanFiltration>) => {
      const item = state.filters.find(
        (filter) => filter.projectId === action.payload.projectId
      );
      if (item) {
        item.showApproved = action.payload.showApproved;
        item.showDisapproved = action.payload.showDisapproved;
        item.showAwaiting = action.payload.showAwaiting;
        item.showDidNotSendToApprove = action.payload.showDidNotSendToApprove;
      }
    },
    setStartDateState: (state, action: PayloadAction<IStartDateFiltration>) => {
      const item = state.filters.find(
        (filter) => filter.projectId === action.payload.projectId
      );
      if (item) {
        item.startDate = action.payload.startDate;
      }
    },
    setDeadlineDateState: (
      state,
      action: PayloadAction<IDeadlineDateFiltration>
    ) => {
      const item = state.filters.find(
        (filter) => filter.projectId === action.payload.projectId
      );
      if (item) {
        item.deadline = action.payload.deadline;
      }
    },
    setTasksState: (state, action: PayloadAction<ITasksState>) => {
      const item = state.filters.find(
        (filter) => filter.projectId === action.payload.projectId
      );
      if (item) {
        item.tasks = action.payload.tasks;
      }
    },
    resetTimeEntriesFilters: (state, action: PayloadAction<number>) => {
      const item = state.filters.find(
        (filter) => filter.projectId === action.payload
      );
      if (item) {
        state.filters.splice(state.filters.indexOf(item), 1);
        const newItem = { ...initialItemState };
        newItem.projectId = action.payload;
        state.filters.push(newItem);
      }
    },
  },
});

export const {
  setTasksState,
  createFiltration,
  setBooleanFilters,
  setDeadlineDateState,
  setStartDateState,
  resetTimeEntriesFilters,
} = filtrationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectFilters = (state: RootState) => state;

export default filtrationSlice.reducer;
