import "./WebPage.scss";
import Logo from "public/Color-logo-new-version.png";

const WebPage = () => {
  return (
    <div className="web-page__wrapper">
      <div className="web-page__content-wrapper">
        <img src={Logo} alt="Logo" />
        <div className="web-page__text">
          <div>
            Oh! It is good too see you here. <br />
            Do you know that we are available on the web app?
          </div>
          <div>
            <a
              href={"https://app.onesoft.no/"}
              className="web-page__redirect-button"
            >
              Move to the web app
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebPage;
