import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import type { RootState } from "../store";

export interface IBooleanFiltration {
  showCompleted: boolean;
  showInProgress: boolean;
  showToDo: boolean;
  projectId: number;
}

export interface IDeadlineDateFiltration {
  deadline: string | null;
  projectId: number;
}

export interface IStartDateFiltration {
  startDate: string | null;
  projectId: number;
}

export interface IAssigneeState {
  assignees: IMyUserVm[];
  projectId: number;
}

export interface ITasksState {
  tasks: IMyTaskVm[];
  projectId: number;
}
// Define a type for the slice state

export interface IFiltration {
  projectId: number;
  showCompleted: boolean;
  showInProgress: boolean;
  showToDo: boolean;
  startDate: string | null;
  assignees: IMyUserVm[];
  tasks: IMyTaskVm[];
  deadline: string | null;
}
// Define the initial state using that type
export const initialItemState: IFiltration = {
  projectId: 0,
  showCompleted: true,
  showToDo: true,
  showInProgress: true,
  startDate: null,
  deadline: null,
  assignees: [],
  tasks: [],
};

export interface IFiltrationArray {
  filters: IFiltration[];
}

const initialState: IFiltrationArray = {
  filters: [],
};

export const filtrationSlice = createSlice({
  name: "projectsFilters",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    createFiltration: (state, action: PayloadAction<IFiltration>) => {
      if (
        !state.filters.some(
          (filter) => filter.projectId === action.payload.projectId
        )
      ) {
        const newItem = { ...initialItemState };
        newItem.projectId = action.payload.projectId;
        state.filters.push(newItem);
      }
    },
    setBooleanFilters: (state, action: PayloadAction<IBooleanFiltration>) => {
      const item = state.filters.find(
        (filter) => filter.projectId === action.payload.projectId
      );
      if (item) {
        item.showCompleted = action.payload.showCompleted;
        item.showInProgress = action.payload.showInProgress;
        item.showToDo = action.payload.showToDo;
      }
    },
    setStartDateState: (state, action: PayloadAction<IStartDateFiltration>) => {
      const item = state.filters.find(
        (filter) => filter.projectId === action.payload.projectId
      );
      if (item) {
        item.startDate = action.payload.startDate;
      }
    },
    setDeadlineDateState: (
      state,
      action: PayloadAction<IDeadlineDateFiltration>
    ) => {
      const item = state.filters.find(
        (filter) => filter.projectId === action.payload.projectId
      );
      if (item) {
        item.deadline = action.payload.deadline;
      }
    },
    setAssigneesState: (state, action: PayloadAction<IAssigneeState>) => {
      const item = state.filters.find(
        (filter) => filter.projectId === action.payload.projectId
      );
      if (item) {
        item.assignees = action.payload.assignees;
      }
    },
    setTasksState: (state, action: PayloadAction<ITasksState>) => {
      const item = state.filters.find(
        (filter) => filter.projectId === action.payload.projectId
      );
      if (item) {
        item.tasks = action.payload.tasks;
      }
    },
    resetProjectsFilters: (state, action: PayloadAction<number>) => {
      const item = state.filters.find(
        (filter) => filter.projectId === action.payload
      );
      if (item) {
        state.filters.splice(state.filters.indexOf(item), 1);
        const newItem = { ...initialItemState };
        newItem.projectId = action.payload;
        state.filters.push(newItem);
      }
    },
  },
});

export const {
  setBooleanFilters,
  setStartDateState,
  setDeadlineDateState,
  setAssigneesState,
  setTasksState,
  createFiltration,
  resetProjectsFilters,
} = filtrationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectFilters = (state: RootState) => state;

export default filtrationSlice.reducer;
