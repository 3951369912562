import ReactGA from "react-ga";

export const initializeGaTracker = () => {
  if (window.location.href.includes("https://app.onesoft.no")) {
    ReactGA.initialize("UA-207686042-1");
  }
};

export const logPageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const logEvent = (category = "", action = "", label = "") => {
  if (category && action) {
    ReactGA.event({ category, action, label });
  }
};

export const logException = (description = "", fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};
