import ChecklistCompletedStatusIcon from "public/ChecklistCompletedStatusIcon.svg";
import GrayErrorIcon from "public/GrayErrorIcon.svg";
import NewItemIcon from "public/NewItemIcon.svg";
import AwaitingYellowIcon from "public/AwaitingYellowIcon.svg";

export const getChecklistOrDeviationStateImage = (state: string) => {
  switch (state) {
    case "NEW": {
      return (
        <img
          height={16}
          width={16}
          src={NewItemIcon}
          alt={"Deviation new item status"}
        />
      );
    }
    case "IN PROGRESS": {
      return (
        <img
          height={16}
          width={16}
          src={AwaitingYellowIcon}
          alt={"Deviation in progress status"}
        />
      );
    }
    case "COMPLETED": {
      return (
        <img
          height={16}
          width={16}
          src={ChecklistCompletedStatusIcon}
          alt={"Deviation completed status"}
        />
      );
    }
    case "VOID": {
      return (
        <img
          height={16}
          width={16}
          src={GrayErrorIcon}
          alt={"Deviation void status"}
        />
      );
    }
  }
};
