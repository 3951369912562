import FiltrationBar from "components/common/FiltrationBar/FiltrationBar";
import DeviationVm from "interfaces/ViewModels/MyDeviationsViewModels/DeviationVm";
import { useEffect, useState } from "react";
import DeviationItem from "../../../../common/DeviationsComponents/DeviationItem/DeviationItem";
import "./DeviationsPageMain.scss";

interface IDeviationsPageMain {
  deviations: DeviationVm[];
}

const DeviationsPageMain = ({ deviations }: IDeviationsPageMain) => {
  const [filtrationInputValue, setFiltartionInputValue] = useState<string>("");
  const [filtratedDeviations, setFiltratedDeviations] =
    useState<DeviationVm[]>(deviations);

  const handleFiltratedDeviations = () => {
    setFiltratedDeviations(deviations);
  };

  useEffect(() => {
    handleFiltratedDeviations();
  }, [deviations]);

  return (
    <div className="deviations-page-main__layout">
      <span className="deviations-page-main__title">Deviations</span>
      <FiltrationBar
        items={deviations}
        setFiltratedItems={setFiltratedDeviations}
        filtrationInputValue={filtrationInputValue}
        setFiltrationInputValue={setFiltartionInputValue}
        filtrationMenu={<></>}
      />
      {filtratedDeviations.map((item) => {
        return <DeviationItem key={item.id} deviation={item} />;
      })}
    </div>
  );
};

export default DeviationsPageMain;
