import DropdownWithLabel from "../SingleInputsWithLabel/DropdownWithLabel/DropdownWithLabel";
import Select from "react-select";
import { dropdownStyles } from "styles/DropdownStyles";
import { ILabelValueStructure } from "interfaces/Other/ILabelValueStructure";
import { useEffect, useState } from "react";
import { downloadAllProjectFilesMetadata } from "Pages/api/FileDownloadClient";
import { useParams } from "react-router-dom";
import "./AddFileFromProjectDocuments.scss";

interface IAddFileFromProjectDocuments {
  handleAddItemToList: (option: ILabelValueStructure) => void;
}

const AddFileFromProjectDocuments = ({
  handleAddItemToList,
}: IAddFileFromProjectDocuments) => {
  const [documents, setDocuments] = useState<ILabelValueStructure[]>([]);
  const { projectId } = useParams();

  const handleGetProjectDocuments = async (projectId: number) => {
    const documents = await downloadAllProjectFilesMetadata(projectId);
    const mappedDocuments: ILabelValueStructure[] = documents.map((item) => {
      return { value: item.guid, label: item.fileDisplayedName };
    });
    setDocuments(mappedDocuments);
  };

  useEffect(() => {
    if (projectId) {
      handleGetProjectDocuments(+projectId);
    }
  }, [projectId]);

  return (
    <DropdownWithLabel
      label={"Project documents"}
      SelectComponent={
        <Select
          styles={dropdownStyles}
          options={documents}
          isClearable={false}
          onChange={(option, action) => {
            if (action.action === "select-option" && option) {
              handleAddItemToList(option);
            }
          }}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
        />
      }
    />
  );
};

export default AddFileFromProjectDocuments;
