export const checkIfStringIsNaturalNumber = (number: string) => {
  return (
    +number > 0 && Math.floor(+number) === +number && !number.includes("e")
  );
};

export const checkIfStringIsNaturalNumberOrZero = (number: string) => {
  return (
    +number >= 0 && Math.floor(+number) === +number && !number.includes("e")
  );
};
