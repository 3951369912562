import TrashCan from "public/TrashCanIcon.svg";
import SeparationLine from "components/common/SeparationLine/SeparationLine";
import Select, { components, OptionProps } from "react-select";
import React from "react";
import { dropdownStyles } from "styles/DropdownStyles";
import { ILabelValueStructure } from "interfaces/Other/ILabelValueStructure";
import "./TeamMembersMultiSelect.scss";
import UserAvatar from "one-common-components/lib/components/UserAvatar/UserAvatar";

interface TeamMembersMultiSelect {
  users: ILabelValueStructure[];
  setUsers: React.Dispatch<React.SetStateAction<ILabelValueStructure[]>>;
  teamOptions: ILabelValueStructure[];
  label: string;
}

const TeamMembersMultiSelect = ({
  users,
  setUsers,
  teamOptions,
  label,
}: TeamMembersMultiSelect) => {
  const handleTeamMultiSet = (options: ILabelValueStructure[]) => {
    setUsers(options);
  };

  const handleRemovingAssignee = (user: ILabelValueStructure) => {
    const usersCopy = [...users];
    usersCopy.splice(users.indexOf(user), 1);
    setUsers(usersCopy);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const singleOption = (props: OptionProps<any>) => {
    return (
      <components.Option {...props}>
        <div
          className={
            "common-components__team-members-multi-selected-option--single"
          }
        >
          <UserAvatar displayName={props.label} size={32} />
          <span
            className={
              "common-components__team-members-multi-selected-option--single-label"
            }
          >
            {props.label}
          </span>
        </div>
      </components.Option>
    );
  };
  return (
    <>
      <div className={"common-components__team-members-multi-select-label"}>
        <span>{label}</span>
      </div>
      <Select
        styles={dropdownStyles}
        placeholder={label}
        isMulti
        options={teamOptions}
        value={users}
        components={{
          Option: singleOption,
          IndicatorsContainer: () => null,
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Unreachable type code error
        onChange={handleTeamMultiSet}
        controlShouldRenderValue={false}
      />
      {users.map((user) => (
        <React.Fragment key={user.value}>
          <div
            className={
              "common-components__team-members-multi-selected-option--wrapper"
            }
          >
            <div
              className={
                "common-components__team-members-multi-selected-option--single"
              }
            >
              <UserAvatar displayName={user.label} size={32} />
              <span
                className={
                  "common-components__team-members-multi-selected-option--single-label"
                }
              >
                {user.label}
              </span>
            </div>
            <div onClick={() => handleRemovingAssignee(user)}>
              <img src={TrashCan} alt="Trash can icon" width={11} height={14} />
            </div>
          </div>
          <SeparationLine />
        </React.Fragment>
      ))}
    </>
  );
};

export default TeamMembersMultiSelect;
