import SeparationLine from "components/common/SeparationLine/SeparationLine";
import {
  calendarHeight,
  calendarWidth,
  verticalArrowCommonHeight,
  verticalArrowCommonWidth,
} from "constants/Consts";
import React, { useState } from "react";
import "./ProjectFiltersPageMain.scss";
import GrayRightArrow from "public/GrayRightArrow.svg";
import GreenRightArrow from "public/GreenRightArrow.svg";
import GrayCalendar from "public/GrayCalendar.svg";
import ToggleSwitch from "components/common/ToggleSwitch/ToggleSwitch";
import { useParams, Link } from "react-router-dom";
import {
  projectsRoute,
  filtersRoute,
  tasksRoute,
  usersRoute,
} from "constants/Routes";
import "react-datepicker/dist/react-datepicker.css";
import {
  IDeadlineDateFiltration,
  IStartDateFiltration,
  setDeadlineDateState,
  setStartDateState,
} from "store/Redux/Reducers/projectsFilterReducer";
import { useAppDispatch } from "store/Redux/hooks";
import { slashesDateFormat } from "constants/DateFormats";
import format from "date-fns/format";
import SelectButton from "components/common/Buttons/SelectButton/SelectButton";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import CalendarComponent from "components/common/CalendarComponent/CalendarComponent";
import FiltrationPosition from "components/common/FIltrationPosition/FiltrationPosition";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { ToastModes } from "interfaces/Enums/ToastModes";

interface IMain {
  setDeadlineDate: React.Dispatch<React.SetStateAction<Date | null>>;
  deadlineDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  startDate: Date | null;
  setCompletedToggleChecked: React.Dispatch<React.SetStateAction<boolean>>;
  completedToggleChecked: boolean;
  setToDoToggleChecked: React.Dispatch<React.SetStateAction<boolean>>;
  toDoToggleChecked: boolean;
  setInProgressToggleChecked: React.Dispatch<React.SetStateAction<boolean>>;
  inProgressToggleChecked: boolean;
  tasks: IMyTaskVm[] | undefined;
  users: IMyUserVm[] | undefined;
}

const ProjectFiltersPageMain = ({
  setDeadlineDate,
  deadlineDate,
  setStartDate,
  startDate,
  setCompletedToggleChecked,
  completedToggleChecked,
  setToDoToggleChecked,
  toDoToggleChecked,
  setInProgressToggleChecked,
  inProgressToggleChecked,
  tasks,
  users,
}: IMain) => {
  const { projectId } = useParams();
  const [isStartCalendarOpen, setIsStartCalendarOpen] =
    useState<boolean>(false);
  const [isDeadlineCalendarOpen, setIsDeadlineCalendarOpen] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const saveStartDate = (date: Date | null) => {
    if (projectId) {
      const changingStartDate: IStartDateFiltration = {
        startDate: date ? date.toString() : null,
        projectId: +projectId,
      };
      setStartDate(date);
      dispatch(
        setStartDateState({
          ...changingStartDate,
        })
      );
    } else {
      SimpleToast({
        mode: ToastModes.error,
        message: "Project id cannot be null",
      });
    }
  };

  const saveDeadlineDate = (date: Date | null) => {
    if (projectId) {
      const changingDeadlineDate: IDeadlineDateFiltration = {
        deadline: date ? date.toString() : null,
        projectId: +projectId,
      };
      setDeadlineDate(date);
      dispatch(
        setDeadlineDateState({
          ...changingDeadlineDate,
        })
      );
    } else {
      SimpleToast({
        mode: ToastModes.error,
        message: "Project id cannot be null",
      });
    }
  };

  return (
    <React.Fragment>
      <div className={"project-filters-page__main"}>
        <SeparationLine />
        <Link
          to={`/${projectsRoute}/${projectId}/${filtersRoute}/${tasksRoute}`}
        >
          <FiltrationPosition
            title={"Task Id"}
            selectButton={
              <SelectButton
                selectIcon={GrayRightArrow}
                imgWidth={verticalArrowCommonWidth}
                imgHeight={verticalArrowCommonHeight}
                greenSelectIcon={GreenRightArrow}
                isActive={tasks && tasks?.length > 0}
              />
            }
          />
        </Link>
        <Link
          to={`/${projectsRoute}/${projectId}/${filtersRoute}/${usersRoute}`}
        >
          <FiltrationPosition
            title={"Assigned to"}
            selectButton={
              <SelectButton
                selectIcon={GrayRightArrow}
                imgWidth={verticalArrowCommonWidth}
                imgHeight={verticalArrowCommonHeight}
                greenSelectIcon={GreenRightArrow}
                isActive={users && users?.length > 0}
              />
            }
          />
        </Link>
        <div onClick={() => setIsStartCalendarOpen(true)}>
          <FiltrationPosition
            title={"Start date"}
            selectButton={
              <SelectButton
                selectIcon={GrayCalendar}
                imgWidth={calendarWidth}
                imgHeight={calendarHeight}
                value={
                  startDate
                    ? format(new Date(startDate), slashesDateFormat)
                    : null
                }
                isActive={startDate !== null}
              />
            }
          />
        </div>
        <CalendarComponent
          isOpen={isStartCalendarOpen}
          isOpenSetter={setIsStartCalendarOpen}
          date={startDate}
          onSaveClick={saveStartDate}
        />
        <div onClick={() => setIsDeadlineCalendarOpen(true)}>
          <FiltrationPosition
            title={"Deadline"}
            selectButton={
              <SelectButton
                selectIcon={GrayCalendar}
                imgWidth={calendarWidth}
                imgHeight={calendarHeight}
                value={
                  deadlineDate
                    ? format(new Date(deadlineDate), slashesDateFormat)
                    : null
                }
                isActive={deadlineDate !== null}
              />
            }
          />
        </div>
        <CalendarComponent
          isOpen={isDeadlineCalendarOpen}
          isOpenSetter={setIsDeadlineCalendarOpen}
          date={deadlineDate}
          onSaveClick={saveDeadlineDate}
        />

        <FiltrationPosition
          title={"Show to do"}
          selectButton={
            <ToggleSwitch
              setter={setToDoToggleChecked}
              getter={toDoToggleChecked}
            />
          }
        />
        <FiltrationPosition
          title={"Show in progress"}
          selectButton={
            <ToggleSwitch
              setter={setInProgressToggleChecked}
              getter={inProgressToggleChecked}
            />
          }
        />
        <FiltrationPosition
          title={"Show completed"}
          selectButton={
            <ToggleSwitch
              setter={setCompletedToggleChecked}
              getter={completedToggleChecked}
            />
          }
        />
      </div>
    </React.Fragment>
  );
};

export default ProjectFiltersPageMain;
