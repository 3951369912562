import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import { IFiltration } from "store/Redux/Reducers/projectsFilterReducer";

interface IHasUsers {
  assignedUsers: IMyUserVm[];
}

export const taskAssigneesFilter = <T extends IHasUsers>(
  foundFilter: IFiltration,
  filteredArray: T[]
) => {
  if (foundFilter.assignees.length > 0) {
    return filteredArray.filter((value) => {
      return value.assignedUsers.find((item) => {
        return (
          foundFilter &&
          foundFilter.assignees.some(
            (filterSettings) => filterSettings.id === item.id
          )
        );
      });
    });
  }
};
