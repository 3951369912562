import SeparationLine from "components/common/SeparationLine/SeparationLine";
import { projectsRoute, teamRoute, memberRoute } from "constants/Routes";
import { IMyUserVmMerged } from "helpers/General/MergeMembersCredentials";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import UserAvatar from "one-common-components/lib/components/UserAvatar/UserAvatar";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/Redux/hooks";
import { changeSelectedProjectMember } from "store/Redux/Reducers/selectedProjectMemberReducer";
import "./ProjectTeamMember.scss";

interface IProjectTeamMember {
  teamUser: IMyUserVmMerged;
  projectMembers: IMyUserVm[];
}

const ProjectTeamMember = ({
  teamUser,
  projectMembers,
}: IProjectTeamMember) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(
    (state) => state.userProfileReducer.userProfile
  );

  const moveToProjectMemberPage = () => {
    const member = projectMembers.find((member) => member.id === teamUser.id);

    if (!member || !currentUser) {
      return navigate("/error");
    }

    dispatch(
      changeSelectedProjectMember({
        user: member ? member : null,
        managingUserRole: member.projectRoleId,
      })
    );
    navigate(
      `/${projectsRoute}/${projectId}/${teamRoute}/${memberRoute}/${teamUser.id}`
    );
  };

  return (
    <>
      <div
        className="project-team-page__user-item-wrapper"
        onClick={moveToProjectMemberPage}
      >
        <div className="project-team-page__user-item-first-part">
          <UserAvatar displayName={teamUser.name} size={32} />
          <span className="project-team-page__member-name">
            {teamUser.name}
          </span>
        </div>
        <span className="project-team-page__member-role">
          Team {teamUser.role}
        </span>
      </div>
      <SeparationLine marginBottom={12} marginTop={12} />
    </>
  );
};

export default ProjectTeamMember;
