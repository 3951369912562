import ChecklistItem from "components/common/ChecklistsComponents/ChecklistItem/ChecklistItem";
import FiltrationBar from "components/common/FiltrationBar/FiltrationBar";
import { projectsRoute, checklistsRoute, answersRoute } from "constants/Routes";
import ChecklistVm from "interfaces/ViewModels/MyChecklistsViewModels/ChecklistVm";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import { changeSelectedChecklist } from "store/Redux/Reducers/selectedChecklistsReducer";
import "./ChecklistsPageMain.scss";

interface IChecklistsPageMain {
  checklists: ChecklistVm[];
}

const ChecklistsPageMain = ({ checklists }: IChecklistsPageMain) => {
  const [filtratedItems, setFiltratedItems] =
    useState<ChecklistVm[]>(checklists);
  const [filtrationInputValue, setFiltartionInputValue] = useState<string>("");
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const saveChecklistToRedux = (form: ChecklistVm) => {
    dispatch(changeSelectedChecklist(form));
  };

  return (
    <main className="checklist-page__main-layout">
      <span className="checklist-page__page-title">CHECKLISTS</span>
      <FiltrationBar
        items={checklists}
        setFiltratedItems={setFiltratedItems}
        filtrationInputValue={filtrationInputValue}
        setFiltrationInputValue={setFiltartionInputValue}
        filtrationMenu={<></>}
      />
      <div className="checklists-page__main-checklists">
        {filtratedItems.map((form) => {
          return (
            <Link
              key={form.id}
              to={`/${projectsRoute}/${projectId}/${checklistsRoute}/${form.id}/${answersRoute}`}
              onClick={() => saveChecklistToRedux(form)}
              state={{ previousPath: pathname }}
            >
              <ChecklistItem checklistItem={form} />
            </Link>
          );
        })}
      </div>
    </main>
  );
};

export default ChecklistsPageMain;
