import { ILabelStringStructure } from "./ILabelValueStructure";
import InfoGrayIcon from "public/InfoGrayIcon.svg";
import InfoGreenIcon from "public/InfoGreenIcon.svg";
import InfoYellowIcon from "public/InfoYellowIcon.svg";
import InfoRedIcon from "public/InfoRedIcon.svg";
import { grayText, greenText, redAccent, yellowText } from "constants/JsColors";

export interface IIconAndColor {
  icon: string;
  color: string;
}

export interface ITimeEntryStatuses {
  [key: string]: IIconAndColor;
}
const timeEntryStatus: ITimeEntryStatuses = {
  NotRequestedForApproval: { icon: InfoGrayIcon, color: grayText },
  Awaiting: { icon: InfoYellowIcon, color: yellowText },
  Approved: { icon: InfoGreenIcon, color: greenText },
  Disapproved: { icon: InfoRedIcon, color: redAccent },
};

export const timeEntryStatuses = Object.keys(timeEntryStatus).map(
  (key: string): ILabelStringStructure => {
    return { label: key, value: timeEntryStatus[key] };
  }
);
