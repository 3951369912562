import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { loadState } from "./browser-storage";
import filterReducer from "./Reducers/projectsFilterReducer";
import newTimeEntryReducer from "./Reducers/newTimeEntryReducer";
import timeEntriesFilterReducer from "./Reducers/timeEntriesFilterReducer";
import selectedTimeEntryReducer from "./Reducers/selectedTimeEntryReducer";
import chosenOrganizationIdReducer from "./Reducers/chosenOrganizationReducer";
import selectedProjectReducer from "./Reducers/projectReducer";
import selectedTaskReducer from "./Reducers/selectedTaskReducer";
import userProfileReducer from "./Reducers/userProfileReducer";
import selectedChecklistsReducer from "./Reducers/selectedChecklistsReducer";
import selectedProjectMemberReducer from "./Reducers/selectedProjectMemberReducer";
import allUsersOrganizationsSlice from "./Reducers/allUsersOrganizationsReducer";
import selectedDeviationsReducer from "./Reducers/selectedDeviationsReducer";

const reducers = combineReducers({
  projectsFiltersReducer: filterReducer,
  newTimeEntryReducer: newTimeEntryReducer,
  timeEntriesFiltersReducer: timeEntriesFilterReducer,
  selectedTimeEntryReducer: selectedTimeEntryReducer,
  chosenOrganizationReducer: chosenOrganizationIdReducer,
  chosenProjectReducer: selectedProjectReducer,
  selectedTaskReducer: selectedTaskReducer,
  selectedProjectMemberReducer,
  userProfileReducer,
  selectedChecklistsReducer,
  selectedDeviationsReducer,
  allUsersOrganizationsSlice,
});

export const store = configureStore({
  devTools: true,
  reducer: reducers,
  preloadedState: loadState(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
