import Checkbox from "components/common/Checkbox/Checkbox";
import { TimeEntriesContext } from "contexts/TimeEntriesContext";
import { arrayPushOrRemoveItem } from "helpers/General/ArrayPushOrRemove";
import { getHoursFromMinutes } from "helpers/TimeHelpers/GetHoursFromMinutes";
import { TimeEntryStatuses } from "interfaces/Enums/TimeEntryStatuses";
import { IMyTimeEntryVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVm";
import React from "react";
import { useState } from "react";
import "./TimeEntry.scss";
import AwaitingIcon from "public/AwaitingIcon.svg";
import ApprovedIcon from "public/ApprovedIcon.svg";
import DisapprovedIcon from "public/DisapprovedIcon.svg";
import { lightGrayBackground, whiteBackground } from "constants/JsColors";
import StatusIcon from "./StatusIcon/StatusIcon";
import { projectsRoute, timeEntriesRoute } from "constants/Routes";
import { Link } from "react-router-dom";
import { changeSelectedTimeEntry } from "store/Redux/Reducers/selectedTimeEntryReducer";
import { useAppDispatch } from "store/Redux/hooks";
import IdBadge from "one-common-components/lib/components/IdBadge/IdBadge";
interface ITimeEntries {
  timeEntry: IMyTimeEntryVm;
}

const TimeEntries = ({ timeEntry }: ITimeEntries) => {
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const context = React.useContext(TimeEntriesContext);
  const dispatch = useAppDispatch();
  const handleTimeEntry = (timeEntry: IMyTimeEntryVm) => {
    dispatch(changeSelectedTimeEntry(timeEntry));
  };

  const clickedCheckbox = () => {
    arrayPushOrRemoveItem(context.selectedTimeEntries, timeEntry);
    context.setSelectedTimeEntries([...context.selectedTimeEntries]);
  };

  const isDisabled =
    timeEntry.status === TimeEntryStatuses.Awaiting ||
    timeEntry.status === TimeEntryStatuses.Approved;

  return (
    <div
      className={"time-entry__layout"}
      style={{ background: isDisabled ? lightGrayBackground : whiteBackground }}
    >
      {timeEntry.status === TimeEntryStatuses.NotRequestedForApproval && (
        <div className={"time-entry__checkbox"}>
          <Checkbox
            setIsActive={() => {
              context.hasPermissionToEditTimeEntries && clickedCheckbox();
              context.hasPermissionToEditTimeEntries &&
                setCheckboxChecked(!checkboxChecked);
            }}
            checked={checkboxChecked}
          />
        </div>
      )}
      {timeEntry.status === TimeEntryStatuses.Awaiting && (
        <StatusIcon icon={AwaitingIcon} iconAlt={"Awaiting icon"} />
      )}
      {timeEntry.status === TimeEntryStatuses.Approved && (
        <StatusIcon icon={ApprovedIcon} iconAlt={"Approved icon"} />
      )}
      {timeEntry.status === TimeEntryStatuses.Disapproved && (
        <StatusIcon icon={DisapprovedIcon} iconAlt={"Disapproved icon"} />
      )}
      <Link
        key={timeEntry.id}
        to={`/${projectsRoute}/${context.projectId}/${timeEntriesRoute}/${timeEntry.id}`}
        onClick={() => {
          handleTimeEntry(timeEntry);
        }}
        className={"time-entry__content"}
      >
        <div className={"time-entry__content-heading"}>
          <div className={"time-entry__id-badge"}>
            <span className={"time-entry__heading-text"}>
              {timeEntry.taskName ? timeEntry.taskName : "No task"}
            </span>
            {timeEntry.taskId && <IdBadge id={timeEntry.taskNumberInProject} />}
          </div>
          <div>
            <span className={"time-entry__heading-text"}>
              {getHoursFromMinutes(timeEntry.totalNumberOfMinutes)}
            </span>
          </div>
        </div>
        <div className={"time-entry__description"}>
          {timeEntry.description
            ? timeEntry.description
            : "No description provided"}
        </div>
      </Link>
    </div>
  );
};

export default TimeEntries;
