import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectedTask } from "store/Redux/Reducers/selectedTaskReducer";
import "./Checkbox.scss";

interface ICheckbox {
  setIsActive?: React.Dispatch<React.SetStateAction<boolean>>;
  checked: boolean;
}

const Checkbox = ({ setIsActive, checked }: ICheckbox) => {
  const globalStates = useSelector(selectedTask);

  const hasPermissionToEditTimeEntry = useMemo(() => {
    if (globalStates.userProfileReducer.userProfile !== null) {
      return restrictProjectAccessBySpecifiedRoles(
        globalStates.userProfileReducer.userProfile.projectRole,
        [ProjectRolesEnum.Viewer]
      );
    }
    return false;
  }, [globalStates.userProfileReducer.userProfile]);

  const handleChange = () => {
    setIsActive && hasPermissionToEditTimeEntry && setIsActive(!checked);
  };

  return (
    <div>
      <label
        className={`${"common-components__checkbox-control"}  ${"common-components__control-checkbox"}`}
      >
        <input
          type={"checkbox"}
          onChange={handleChange}
          checked={checked}
          tabIndex={-1}
        />
        <div className={"common-components__control-indicator"} />
      </label>
    </div>
  );
};

export default Checkbox;
