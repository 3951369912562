import OnlyRightButtonFooter from "components/common/Footers/OnlyRightButtonFooter/OnlyRightButtonFooter";
import HeaderPageTitleAndReturn from "components/common/Headers/HeaderPageTitleAndReturn/HeaderPageTitleAndReturn";
import DeviationOverviewPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/DeviationsPageComponents/DeviationOverviewPageMain/DeviationOverviewPageMain";
import { deviationsRoute, editRoute, projectsRoute } from "constants/Routes";
import { DownloadedFile } from "one-common-components/lib/components/Files/Interfaces/DownloadedFile";
import { downloadAllDeviationFiles } from "Pages/api/FileDownloadClient";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/Redux/hooks";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import "./DeviationOverviewPage.scss";

const DeviationOverviewPage = () => {
  const globalStates = useAppSelector(chosenOrganization);
  const navigate = useNavigate();
  const { deviationId, projectId } = useParams();
  const chosenDeviation =
    globalStates.selectedDeviationsReducer.deviations.find(
      (item) => item.id?.toString() === deviationId?.toString()
    );
  if (!chosenDeviation) {
    navigate("/error");
    return <div />;
  }
  const [filesWereLoaded, setFilesWereLoaded] = useState<boolean>(false);
  const [files, setFiles] = useState<DownloadedFile[]>([]);

  const returnToPreviousPage = () => {
    navigate(-1);
  };

  const handleDeviationFiles = async (
    deviationId: number,
    projectId: number
  ) => {
    setFiles(await downloadAllDeviationFiles(deviationId, projectId));
    setFilesWereLoaded(true);
  };

  const navigateToEditDeviationPage = () => {
    navigate(
      `/${projectsRoute}/${projectId}/${deviationsRoute}/${deviationId}/${editRoute}`
    );
  };

  useEffect(() => {
    if (chosenDeviation && projectId) {
      handleDeviationFiles(+chosenDeviation.id, +projectId);
    }
  }, [chosenDeviation]);

  return (
    <div className="deviation-overview-page__layout">
      <HeaderPageTitleAndReturn
        returnToPreviousPage={returnToPreviousPage}
        text={"Deviation preview"}
      />
      <DeviationOverviewPageMain
        chosenDeviation={chosenDeviation}
        myFiles={files}
        setMyFiles={setFiles}
        filesWereLoaded={filesWereLoaded}
        hasPermissionToEditDeviation={chosenDeviation.canEditDeviation}
      />
      {chosenDeviation.canEditDeviation && (
        <OnlyRightButtonFooter
          buttonText={"Edit"}
          onClick={navigateToEditDeviationPage}
        />
      )}
    </div>
  );
};

export default DeviationOverviewPage;
