import "./ProjectOverviewBar.scss";
import React from "react";
import TasksNumberContainer from "../TasksNumberContainer/TasksNumberContainer";
import { projectsRoute } from "../../../../constants/Routes";
import { IMyProjectVm } from "../../../../interfaces/ViewModels/MyProjectsViewModels/IMyProjectVm";
import { useAppDispatch } from "../../../../store/Redux/hooks";
import {
  createFiltration as projectsCreateFiltration,
  initialItemState as projectsInitialItemState,
} from "../../../../store/Redux/Reducers/projectsFilterReducer";
import {
  createFiltration as timeEntriesCreateFiltration,
  initialItemState as timeEntriesInitialItemState,
} from "../../../../store/Redux/Reducers/timeEntriesFilterReducer";
import { Link } from "react-router-dom";
import IdBadge from "one-common-components/lib/components/IdBadge/IdBadge";

interface IProjectOverviewBar {
  projects: IMyProjectVm[];
}

const ProjectOverviewBar = ({ projects }: IProjectOverviewBar) => {
  const dispatch = useAppDispatch();
  const manageFiltrationState = (projectId: number) => {
    dispatch(
      projectsCreateFiltration({
        ...projectsInitialItemState,
        projectId,
      })
    );
    dispatch(
      timeEntriesCreateFiltration({
        ...timeEntriesInitialItemState,
        projectId,
      })
    );
  };

  return (
    <React.Fragment>
      {projects.map((project: IMyProjectVm) => (
        <Link
          to={`/${projectsRoute}/${project.id}`}
          key={project.projectNumberInOrganization}
          onClick={() => manageFiltrationState(project.id)}
        >
          <div
            className={"projects-page-components__projects-main-overview-bar"}
          >
            <div
              className={
                "projects-page-components__projects-main-overview-bar-left-info-part"
              }
            >
              <span>{project.name}</span>
              <IdBadge id={project.projectNumberInOrganization} />
            </div>
            <div
              className={
                "projects-page-components__projects-main-overview-bar-right-info-part"
              }
            >
              <TasksNumberContainer
                styles={
                  "projects-page-components__projects-main-overview-bar-task-background--blue"
                }
                tasksNumber={project.toDoTasksCount}
              />
              <TasksNumberContainer
                styles={
                  "projects-page-components__projects-main-overview-bar-task-background--yellow"
                }
                tasksNumber={project.inProgressTasksCount}
              />
              <TasksNumberContainer
                styles={
                  "projects-page-components__projects-main-overview-bar-task-background--green"
                }
                tasksNumber={project.completedTasksCount}
              />
            </div>
          </div>
        </Link>
      ))}
    </React.Fragment>
  );
};

export default ProjectOverviewBar;
