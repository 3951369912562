import { arrayPushOrRemoveString } from "helpers/General/ArrayPushOrRemove";

export const fillArrayValues = (
  answerValues: string | string[],
  value: string
): string[] => {
  let answerValuesArray: string[];
  if (!Array.isArray(answerValues)) {
    answerValuesArray = [];
  } else {
    answerValuesArray = [...answerValues];
  }
  arrayPushOrRemoveString(answerValuesArray, value);
  return answerValuesArray;
};
