import { HttpRequestsMethods } from "interfaces/Enums/HttpRequestsMethods";
import application from "../../config.json";
import { AxiosClient } from "./AxiosClient";

const base = application.baseUrl;
const MyUserProjects = "MyUserProjects";

const updateProjectMemberRole = async (
  body: number,
  projectId: number,
  memberId: number
): Promise<null> => {
  return AxiosClient(
    HttpRequestsMethods.PUT,
    `${MyUserProjects}/${projectId}/member/${memberId}/status`,
    base,
    {
      body,
    }
  );
};

const addUsersToProject = async (
  body: number[],
  projectId: number
): Promise<null> => {
  return AxiosClient(
    HttpRequestsMethods.POST,
    `${MyUserProjects}/${projectId}`,
    base,
    {
      body,
    }
  );
};

export { updateProjectMemberRole, addUsersToProject };
