import FiltrationsHeader from "components/common/FiltrationsHeader/FiltrationsHeader";
import { projectsRoute, timeEntriesRoute } from "constants/Routes";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  IBooleanFiltration,
  IDeadlineDateFiltration,
  IFiltration,
  IStartDateFiltration,
  selectFilters,
  setBooleanFilters,
  setDeadlineDateState,
  setStartDateState,
  setTasksState,
} from "store/Redux/Reducers/timeEntriesFilterReducer";
import { useAppDispatch } from "store/Redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import TimeEntriesFiltersPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/TimeEntriesPageComponents/TimeEntriesFiltersPageComponents/TimeEntriesFiltersPageMain/TimeEntriesFiltersPageMain";
import { resetTimeEntriesFilters } from "store/Redux/Reducers/timeEntriesFilterReducer";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { ToastModes } from "interfaces/Enums/ToastModes";

const Filters = () => {
  const { projectId } = useParams();

  const filters = useSelector(selectFilters);
  const foundFilter = filters.timeEntriesFiltersReducer.filters.find(
    (filter: IFiltration) =>
      projectId && +filter.projectId === +projectId.toString()
  );
  const [approvedToggleChecked, setApprovedToggleChecked] = useState<boolean>(
    foundFilter ? foundFilter.showApproved : false
  );
  const [disapprovedToggleChecked, setDisapprovedToggleChecked] =
    useState<boolean>(foundFilter ? foundFilter.showDisapproved : false);
  const [awaitingToggleChecked, setAwaitingToggleChecked] = useState<boolean>(
    foundFilter ? foundFilter.showAwaiting : false
  );
  const [notSendToApprovalToggleChecked, setNotSendToApprovalToggleChecked] =
    useState<boolean>(
      foundFilter ? foundFilter.showDidNotSendToApprove : false
    );
  const [resetFilters, setResetFilters] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(
    foundFilter && foundFilter.startDate
      ? new Date(foundFilter.startDate)
      : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    foundFilter && foundFilter.deadline ? new Date(foundFilter.deadline) : null
  );
  const navigate = useNavigate();

  const setTasksGlobalState = () => {
    if (projectId) {
      dispatch(
        setTasksState({
          tasks: [],
          projectId: +projectId,
        })
      );
    } else {
      SimpleToast({
        mode: ToastModes.error,
        message: "Project id cannot be null",
      });
    }
  };

  const onFiltersApply = () => {
    if (projectId) {
      const changingStartDate: IStartDateFiltration = {
        startDate: startDate ? startDate.toString() : null,
        projectId: +projectId,
      };
      const changingDeadlineDate: IDeadlineDateFiltration = {
        deadline: endDate ? endDate.toString() : null,
        projectId: +projectId,
      };
      const changingState: IBooleanFiltration = {
        showDisapproved: disapprovedToggleChecked,
        showApproved: approvedToggleChecked,
        showAwaiting: awaitingToggleChecked,
        showDidNotSendToApprove: notSendToApprovalToggleChecked,
        projectId: +projectId,
      };
      dispatch(
        setBooleanFilters({
          ...changingState,
        })
      );
      dispatch(
        setDeadlineDateState({
          ...changingDeadlineDate,
        })
      );
      dispatch(
        setStartDateState({
          ...changingStartDate,
        })
      );
      navigate(`/${projectsRoute}/${projectId}/${timeEntriesRoute}`);
    } else {
      SimpleToast({
        mode: ToastModes.error,
        message: "Project id cannot be null",
      });
    }
  };

  const handleResetStates = () => {
    setApprovedToggleChecked(true);
    setDisapprovedToggleChecked(true);
    setAwaitingToggleChecked(true);
    setNotSendToApprovalToggleChecked(true);
    setTasksGlobalState();
    setResetFilters(false);
    setStartDate(null);
    setEndDate(null);
  };

  const handleRejectionFunction = () => {
    if (projectId) {
      setResetFilters(true);
      dispatch(resetTimeEntriesFilters(+projectId));
    } else {
      SimpleToast({
        mode: ToastModes.error,
        message: "Project id cannot be null",
      });
    }
  };

  useEffect(() => {
    if (resetFilters) {
      handleResetStates();
    }
  }, [resetFilters]);

  const dispatch = useAppDispatch();

  return (
    <div>
      <FiltrationsHeader
        pageName={"Filters"}
        applyFiltersText={"Apply"}
        confimationFunction={onFiltersApply}
        rejectFunction={handleRejectionFunction}
      />
      <TimeEntriesFiltersPageMain
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setApprovedToggleChecked={setApprovedToggleChecked}
        approvedToggleChecked={approvedToggleChecked}
        setDisapprovedToggleChecked={setDisapprovedToggleChecked}
        disapprovedToggleChecked={disapprovedToggleChecked}
        setAwaitingToggleChecked={setAwaitingToggleChecked}
        awaitingToggleChecked={awaitingToggleChecked}
        notSendToApprovalToggleChecked={notSendToApprovalToggleChecked}
        setNotSendToApprovalToggleChecked={setNotSendToApprovalToggleChecked}
        tasks={foundFilter?.tasks}
      />
    </div>
  );
};

export default Filters;
