import Footer from "components/common/Footers/Footer";
import HeaderPageTitleAndReturn from "components/common/Headers/HeaderPageTitleAndReturn/HeaderPageTitleAndReturn";
import ProjectTeamMemberPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/ProjectTeamPageComponents/Pages/OverviewProjectMemberComponents/ProjectTeamMemberPageMain";
import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import { IMyProfileVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProfileVm";
import { IMyProjectTasksVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectTasksVm";
import { getUserProfileDetails } from "Pages/api/MyProfileClient";
import { getProjectMemberTasksWithDetails } from "Pages/api/MyProjectsClient";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import "./ProjectTeamMemberPage.scss";

const ProjectTeamMemberPage = () => {
  const { projectId, memberId } = useParams();
  const globalStates = useSelector(chosenOrganization);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState<IMyProfileVm>(); //TODO Maybe there is an option to move those into the redux?
  const [memberTasks, setMemberTasks] = useState<IMyProjectTasksVm>();

  const handleUserDetails = async () => {
    setUserDetails(await getUserProfileDetails());
  };

  const handleUserTasks = async () => {
    if (!memberId || !projectId) {
      navigate("/error");
      return;
    }

    setMemberTasks(
      await getProjectMemberTasksWithDetails(+projectId, +memberId)
    );
  };

  useEffect(() => {
    if (projectId) {
      handleUserTasks();
      handleUserDetails();
    }
  }, [projectId]);

  if (
    !globalStates.allUsersOrganizationsSlice.usersOrganizations ||
    !globalStates.userProfileReducer.userProfile ||
    !userDetails ||
    !memberTasks
  ) {
    return <div />;
  }

  const returnToPreviousPage = () => {
    navigate(-1);
  };

  return (
    <div className="project-team-page-member__layout">
      <HeaderPageTitleAndReturn
        returnToPreviousPage={returnToPreviousPage}
        text={"Member details"}
      />
      <ProjectTeamMemberPageMain memberTasks={memberTasks} />
      <Footer
        isInProjectsPage={true}
        isInTimeEntriesPage={false}
        hasPermissionToCreate={restrictProjectAccessBySpecifiedRoles(
          globalStates.userProfileReducer.userProfile.projectRole,
          [ProjectRolesEnum.Viewer]
        )}
      />
    </div>
  );
};

export default ProjectTeamMemberPage;
