import toast from "react-hot-toast";
import BlueInfoIcon from "public/BlueInfoIcon.svg";

export const infoToast = (message: string) => {
  return toast(message, {
    duration: 2000,
    icon: <img src={BlueInfoIcon} />,
    style: {
      color: "#2CA3FF",
      backgroundColor: "#D5EDFF",
      width: "100%",
    },
  });
};
