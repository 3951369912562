import "./ProfilePageMain.scss";
import { IMyProfileVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProfileVm";
import { returnUsersCredentials } from "helpers/General/ReturnUserCredentials";
import UserAvatar from "one-common-components/lib/components/UserAvatar/UserAvatar";

interface IMain {
  userDetails: IMyProfileVm;
}

const Main = ({ userDetails }: IMain) => {
  return (
    <div className={"profile-page-components__main"}>
      <div className={"profile-page-components__main-heading"}>
        <UserAvatar
          displayName={returnUsersCredentials(
            userDetails.firstName,
            userDetails.lastName,
            userDetails.email
          )}
          size={100}
        />
        <span
          className={"profile-page-components__main-heading-userCredentials"}
        >
          {userDetails.firstName && userDetails.lastName ? (
            <span>
              {userDetails.firstName}
              &nbsp;
              {userDetails.lastName}
            </span>
          ) : (
            "No name and surname"
          )}
        </span>
        <span className={"profile-page-components__main-heading-text"}>
          Edit Photo
        </span>
      </div>
      <div className={"profile-page-components__user-details-section"}>
        <div>
          {userDetails.firstName ? userDetails.firstName : "Not inserted"}
        </div>
        <div>
          {userDetails.lastName ? userDetails.lastName : "Not inserted"}
        </div>
        <div>{userDetails.email}</div>
      </div>
    </div>
  );
};

export default Main;
