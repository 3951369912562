import {
  switchButtonDiameter,
  switchHeight,
  switchWidth,
} from "constants/Consts";
import {
  switchOnColor,
  switchOffColor,
  greenText,
  switchBoxShadow,
  activeSwitchBoxShadow,
} from "constants/JsColors";
import React from "react";
import WrongSwitch from "react-switch";
//On the dat of 30.06.2022 Switch does not have support for typescript.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Switch = WrongSwitch as any;
interface IToggleSwitch {
  setter: React.Dispatch<React.SetStateAction<boolean>>;
  getter: boolean;
}

const ToggleSwitch = ({ setter, getter }: IToggleSwitch) => {
  return (
    <React.Fragment>
      {typeof window !== "undefined" && (
        <Switch
          onChange={() => setter(!getter)}
          checked={getter}
          onColor={switchOnColor}
          offColor={switchOffColor}
          onHandleColor={greenText}
          handleDiameter={switchButtonDiameter}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow={switchBoxShadow}
          activeBoxShadow={activeSwitchBoxShadow}
          height={switchHeight}
          width={switchWidth}
        />
      )}
    </React.Fragment>
  );
};

export default ToggleSwitch;
