import ChecklistVm from "interfaces/ViewModels/MyChecklistsViewModels/ChecklistVm";
import { changeChecklistStatus } from "Pages/api/MyChecklistsClient";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import { updateChecklistStatus } from "store/Redux/Reducers/selectedChecklistsReducer";
import "./DifferentiateFormsAnswers.scss";
interface IDifferentiateFormsAnswers {
  checklist: ChecklistVm | null;
  userId: number;
  differentiateFunc(checklist: ChecklistVm, userId: number): JSX.Element;
  isInEditMode?: boolean;
}

const DifferentiateFormsAnswers = ({
  checklist,
  userId,
  differentiateFunc,
  isInEditMode,
}: IDifferentiateFormsAnswers) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const updateChecklistsStatus = async (checklist: ChecklistVm) => {
    if (checklist.formAnswer.status !== "COMPLETED") {
      await changeChecklistStatus({
        status: "IN PROGRESS",
        projectId: projectId ? +projectId : 0,
        formLinkId: checklist.id,
      }),
        dispatch(
          updateChecklistStatus({
            formLinkId: checklist.id,
            status: "IN PROGRESS",
          })
        );
    }
  };
  useEffect(() => {
    if (checklist && isInEditMode) {
      updateChecklistsStatus(checklist);
    }
  }, [checklist]);
  if (checklist) {
    return (
      <div className="differentiate-forms-answers__wrapper">
        {differentiateFunc(checklist, userId)}
      </div>
    );
  } else return <></>;
};

export default DifferentiateFormsAnswers;
