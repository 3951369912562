import HeaderPageTitleAndReturn from "components/common/Headers/HeaderPageTitleAndReturn/HeaderPageTitleAndReturn";
import EditDeviationPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/DeviationsPageComponents/DeviationOverviewPageMain/EditDeviationPageMain/EditDeviationPageMain";
import { DeviationCauseVm } from "interfaces/ViewModels/MyFormsViewModels/DeviationCauseVm";
import { DownloadedFile } from "one-common-components/lib/components/Files/Interfaces/DownloadedFile";
import { downloadAllDeviationFiles } from "Pages/api/FileDownloadClient";
import { getDeviationsCauses } from "Pages/api/MyDeviationsClient";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/Redux/hooks";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import { RootState } from "store/Redux/store";

const EditDeviationPage = () => {
  const globalStates = useAppSelector(chosenOrganization);
  const navigate = useNavigate();
  const { deviationId, projectId } = useParams();
  const organizationId = useSelector(
    (state: RootState) => state.chosenOrganizationReducer.chosenOrganizationId
  );
  const chosenDeviation =
    globalStates.selectedDeviationsReducer.deviations.find(
      (item) => item.id?.toString() === deviationId?.toString()
    );
  if (!chosenDeviation) {
    navigate("/error");
    return <div />;
  }
  const [filesWereLoaded, setFilesWereLoaded] = useState<boolean>(false);
  const [files, setFiles] = useState<DownloadedFile[]>([]);
  const [deviationsCauses, setDeviationsCauses] = useState<DeviationCauseVm[]>(
    []
  );

  const returnToPreviousPage = () => {
    navigate(-1);
  };

  const handleDeviationFiles = async (
    deviationId: number,
    projectId: number
  ) => {
    const result = await downloadAllDeviationFiles(projectId, deviationId);
    setFiles(result);
    setFilesWereLoaded(true);
  };

  const handleDeviationsCauses = async () => {
    setDeviationsCauses(await getDeviationsCauses(organizationId));
  };

  useEffect(() => {
    if (organizationId) {
      handleDeviationsCauses();
    }
  }, [organizationId]);

  useEffect(() => {
    if (chosenDeviation && projectId) {
      handleDeviationFiles(+chosenDeviation.id, +projectId);
    }
  }, [chosenDeviation]);

  return (
    <div>
      <HeaderPageTitleAndReturn
        returnToPreviousPage={returnToPreviousPage}
        text={"Deviation preview"}
      />
      <EditDeviationPageMain
        chosenDeviation={chosenDeviation}
        myFiles={files}
        setMyFiles={setFiles}
        filesWereLoaded={filesWereLoaded}
        deviationsCauses={deviationsCauses}
      />
    </div>
  );
};

export default EditDeviationPage;
