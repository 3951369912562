import ChecklistsAnswerLabel from "components/common/ChecklistsComponents/ChecklistsAnswerLabel/ChecklistsAnswerLabel";
import InputWithLabel from "components/common/SingleInputsWithLabel/InputWithLabel/InputWithLabel";
import { AnswerDto } from "interfaces/ViewModels/MyFormsViewModels/AnswerDto";
import { GroupedAnswersDto } from "interfaces/ViewModels/MyFormsViewModels/GroupedAnswerDto";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store/Redux/hooks";
import {
  selectedForm,
  updateChecklistAnswer,
  updateChecklistComment,
} from "store/Redux/Reducers/selectedChecklistsReducer";
import CommentInput from "../CommentInput/CommentInput";
import "./CostInputs.scss";

interface ICostInputs {
  answer: AnswerDto;
  userId: number;
  group?: GroupedAnswersDto;
  checklistId: number;
}

const CostInputs = ({ group, answer, userId, checklistId }: ICostInputs) => {
  const [inputText, setInputText] = useState<string>(
    answer.comment ? answer.comment : ""
  );
  const dispatch = useAppDispatch();
  const { register, setValue } = useForm();
  const globalStates = useAppSelector(selectedForm);
  const chosenOrganizationCurrency =
    globalStates.chosenOrganizationReducer.myOrganization?.currency;
  const setCurrencyFieldInputValue = (value: string) => {
    dispatch(
      updateChecklistAnswer({ group, answer, value, userId, checklistId })
    );
  };

  useEffect(() => {
    setValue(
      `Answer [${chosenOrganizationCurrency}]`,
      answer.value ? answer.value.toString() : 0
    );
  }, []);

  const setCostComment = (comment: string) => {
    dispatch(
      updateChecklistComment({ group, answer, comment, userId, checklistId })
    );
  };

  return (
    <div className="checklists-answers--margins">
      <ChecklistsAnswerLabel text={answer.header} position={answer.order} />
      <InputWithLabel
        register={register}
        label={`Answer [${chosenOrganizationCurrency}]`}
        inputPlaceholder={"Enter Your Answer [number]"}
        onBlur={setCurrencyFieldInputValue}
        type={"number"}
        min={0}
        inputMode={"numeric"}
        pattern="[0-9]*"
        marginBottom={16}
      />
      <CommentInput
        onBlur={setCostComment}
        onChange={setInputText}
        value={inputText}
      />
    </div>
  );
};

export default CostInputs;
