import { isValid } from "date-fns";
import { parseToTwoDigitNumber } from "./ParseToTwoDigitNumbers";

export const setTotalValue = (totalDateTime: Date | undefined) => {
  if (totalDateTime && isValid(totalDateTime)) {
    return `${parseToTwoDigitNumber(totalDateTime.getHours())}:${
      parseToTwoDigitNumber(totalDateTime.getMinutes())
        ? parseToTwoDigitNumber(totalDateTime.getMinutes())
        : "00"
    }`;
  } else {
    return "00:00";
  }
};
