import OnlyRightButtonFooter from "components/common/Footers/OnlyRightButtonFooter/OnlyRightButtonFooter";
import HeaderAndCustomEndElement from "components/common/Headers/HeaderWithNotifications/HeaderAndCustomEndElement";

import NotifiactionsBellAndMenu from "components/common/NotifiactionsBellAndMenu/NotifiactionsBellAndMenu";
import ProjectOverviewPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/ProjectOverviewPageComponents/ProjectOverviewPageMain";
import { editRoute, overviewRoute, projectsRoute } from "constants/Routes";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import "./ProjectOverviewPage.scss";

const ProjectOverviewPage = () => {
  const { projectId } = useParams();
  const globalStates = useSelector(chosenOrganization);
  const navigate = useNavigate();

  const redirectToEditProjectPage = () => {
    navigate(`/${projectsRoute}/${projectId}/${overviewRoute}/${editRoute}`);
  };

  if (
    !globalStates.allUsersOrganizationsSlice.usersOrganizations ||
    !globalStates.userProfileReducer.userProfile
  ) {
    return <div />;
  }
  return (
    <div className="project-overview-page__layout">
      <HeaderAndCustomEndElement
        endElement={
          <NotifiactionsBellAndMenu
            organizations={
              globalStates.allUsersOrganizationsSlice.usersOrganizations
            }
            userDetails={globalStates.userProfileReducer.userProfile}
            myOrganizationId={
              globalStates.chosenOrganizationReducer.chosenOrganizationId
            }
          />
        }
        returnUrl={`/${projectsRoute}/${projectId}`}
      />
      <ProjectOverviewPageMain
        projectMembers={globalStates.chosenProjectReducer.projectMembers}
      />
      <OnlyRightButtonFooter
        buttonText={"Edit"}
        onClick={redirectToEditProjectPage}
      />
    </div>
  );
};

export default ProjectOverviewPage;
