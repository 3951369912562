import useAuth0 from "Auth/use-auth0";
import ZeroAsNotFoundIcon from "public/ZeroAsNotFoundIcon.svg";
import "./ErrorPage.scss";

const ErrorPage = () => {
  const { logout } = useAuth0();
  return (
    <section className="error-page__layout">
      <div className="error-page__content-wrapper">
        <img
          src={ZeroAsNotFoundIcon}
          alt={"Error image"}
          className="error-page__main-icon"
        />
        <span className="error-page__title">Ohh no!</span>
        <span className="error-page__subtitle">Something went wrong</span>
        <div className="error-page__logout-button" onClick={() => logout()}>
          Log out
        </div>
      </div>
    </section>
  );
};

export default ErrorPage;
