import "./OrganizationsModal.scss";
import Modal from "react-modal";
import GreenCloseButton from "../../../../public/GreenCloseButton.svg";
import { useMemo, useState } from "react";
import React from "react";
import NavigationRouteItem from "../NavigationRouteItem/NavigationRouteItem";
import GrayProjectsIcon from "../../../../public/GrayProjectsIcon.svg";
import GrayTimeEntriesIcon from "../../../../public/GrayTimeEntriesIcon.svg";
import GrayTasksIcon from "../../../../public/GrayTasksIcon.svg";
import GreenProjectsIcon from "../../../../public/GreenProjectsIcon.svg";
import GreenTimeEntriesIcon from "../../../../public/GreenTimeEntriesIcon.svg";
import GreenTasksIcon from "../../../../public/GreenTasksIcon.svg";
import {
  projectsRoute,
  timeEntriesRoute,
  profileRoute,
  checklistsRoute,
  overviewRoute,
  teamRoute,
  deviationsRoute,
  documentsRoute,
} from "../../../../constants/Routes";
import { IMyOrganizationVm } from "../../../../interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationVm";
import { IMyProfileVm } from "../../../../interfaces/ViewModels/MyProjectsViewModels/IMyProfileVm";
import SeparationLine from "../../SeparationLine/SeparationLine";
import { useParams, useLocation, Link } from "react-router-dom";
import useAuth0 from "Auth/use-auth0";
import GreenProjectOverviewIcon from "public/GreenProjectOverviewIcon.svg";
import GrayProjectOverviewIcon from "public/GrayProjectOverviewIcon.svg";
import GrayProjectTeamIcon from "public/GrayProjectTeamIcon.svg";
import GreenProjectTeamIcon from "public/GreenProjectTeamIcon.svg";
import GreenChecklistIcon from "public/GreenChecklistIcon.svg";
import GrayChecklistsIcon from "public/GrayChecklistsIcon.svg";
import GrayDeviationsIcon from "public/GrayDeviationsIcon.svg";
import GreenDeviationsIcon from "public/GreenDeviationsIcon.svg";
import GrayDocumentIcon from "public/GrayDocumentIcon.svg";
import GreenDocumentIcon from "public/GreenDocumentIcon.svg";
import OrganizationDropdownContent from "./OrganizationDropdown/OrganizationDropdownContent/OrganizationDropdownContent";
import OrganizationDropdownContainer from "./OrganizationDropdown/OrganizationDropdownContainer/OrganizationDropdownContainer";
import DropdownWrapper from "one-common-components/lib/components/DropdownWrapper/DropdownWrapper";
import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import { useSelector } from "react-redux";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";

interface IOrganizationsModal {
  openOrganizationModal: boolean;
  handleCloseOrganizationModal: () => void;
  userDetails: IMyProfileVm;
  organizations: IMyOrganizationVm[];
  myOrganizationId: number;
  setMyOrganizationId?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
}

const OrganizationsModal = ({
  openOrganizationModal,
  handleCloseOrganizationModal,
  organizations,
  setMyOrganizationId,
  myOrganizationId,
}: IOrganizationsModal) => {
  const [isOrganizationDropdownOpen, setIsOrganizationDropdownOpen] =
    useState<boolean>(false);
  const location = useLocation();
  const { logout } = useAuth0();
  const { projectId } = useParams();
  const globalStates = useSelector(chosenOrganization);
  const currentOrganization = organizations.find(
    (organizationsItem) => organizationsItem.id === myOrganizationId
  );

  const hasAccessToTimeEntries = useMemo(() => {
    if (globalStates.userProfileReducer.userProfile !== null) {
      return restrictProjectAccessBySpecifiedRoles(
        globalStates.userProfileReducer.userProfile.projectRole,
        [ProjectRolesEnum.Viewer]
      );
    }
    return false;
  }, [globalStates.userProfileReducer.userProfile]);

  return (
    <Modal
      isOpen={openOrganizationModal}
      onRequestClose={handleCloseOrganizationModal}
      className={"common-components__organizations-modal"}
      overlayClassName={"common-components__organizations-modal-overlay"}
      ariaHideApp={false}
    >
      <div className={"common-components__organizations-modal-main"}>
        <div className={"common-components__organizations-modal-heading"}>
          <DropdownWrapper
            maxWidth
            isOpen={isOrganizationDropdownOpen}
            setIsOpen={setIsOrganizationDropdownOpen}
            dropdownContainer={
              <OrganizationDropdownContainer
                isOrganizationDropdownOpen={isOrganizationDropdownOpen}
                setIsOrganizationDropdownOpen={setIsOrganizationDropdownOpen}
                currentOrganization={currentOrganization}
              />
            }
            dropdownContent={
              <OrganizationDropdownContent
                organizations={organizations}
                setMyOrganizationId={setMyOrganizationId}
                handleCloseOrganizationModal={handleCloseOrganizationModal}
              />
            }
          />
          <div onClick={() => handleCloseOrganizationModal()}>
            <img
              src={GreenCloseButton}
              alt="Close button"
              className="common-components__close-modal-icon"
              width={18}
              height={18}
            />
          </div>
        </div>
        {projectId && (
          <div
            className={
              "common-components__organizations-modal-menu-composition"
            }
          >
            <NavigationRouteItem
              icon={GrayProjectsIcon}
              href={`/${projectsRoute}`}
              location={"Projects"}
              isActive={location.pathname === `/${projectsRoute}`}
              activeIcon={GreenProjectsIcon}
            />
            <NavigationRouteItem
              icon={GrayProjectTeamIcon}
              href={`/${projectsRoute}/${projectId}/${teamRoute}`}
              location={"Project team"}
              isActive={
                location.pathname ===
                `/${projectsRoute}/${projectId}/${teamRoute}`
              }
              activeIcon={GreenProjectTeamIcon}
            />
            <NavigationRouteItem
              icon={GrayProjectOverviewIcon}
              href={`/${projectsRoute}/${projectId}/${overviewRoute}`}
              location={"Project overview"}
              isActive={
                location.pathname ===
                `/${projectsRoute}/${projectId}/${overviewRoute}`
              }
              activeIcon={GreenProjectOverviewIcon}
            />
            <NavigationRouteItem
              icon={GrayTasksIcon}
              href={`/${projectsRoute}/${projectId}`}
              location={"Tasks"}
              isActive={location.pathname === `/${projectsRoute}/${projectId}`}
              activeIcon={GreenTasksIcon}
            />
            {hasAccessToTimeEntries && (
              <NavigationRouteItem
                icon={GrayTimeEntriesIcon}
                href={`/${projectsRoute}/${projectId}/${timeEntriesRoute}`}
                location={"Time entries"}
                isActive={
                  location.pathname ===
                  `/${projectsRoute}/${projectId}/${timeEntriesRoute}`
                }
                activeIcon={GreenTimeEntriesIcon}
              />
            )}
            <NavigationRouteItem
              icon={GrayChecklistsIcon}
              href={`/${projectsRoute}/${projectId}/${checklistsRoute}`}
              location={"Checklists"}
              isActive={
                location.pathname ===
                `/${projectsRoute}/${projectId}/${checklistsRoute}`
              }
              activeIcon={GreenChecklistIcon}
            />
            <NavigationRouteItem
              icon={GrayDeviationsIcon}
              href={`/${projectsRoute}/${projectId}/${deviationsRoute}`}
              location={"Deviations"}
              isActive={
                location.pathname ===
                `/${projectsRoute}/${projectId}/${deviationsRoute}`
              }
              activeIcon={GreenDeviationsIcon}
            />
            <NavigationRouteItem
              icon={GrayDocumentIcon}
              href={`/${projectsRoute}/${projectId}/${documentsRoute}`}
              location={"Documents"}
              isActive={
                location.pathname ===
                `/${projectsRoute}/${projectId}/${documentsRoute}`
              }
              activeIcon={GreenDocumentIcon}
            />
          </div>
        )}
      </div>
      <div className={"common-components__organizations-modal-footer"}>
        <SeparationLine />
        <Link to={`/${profileRoute}`}>
          <div
            className={
              "common-components__organizations-modal-my-profile-route"
            }
          >
            My profile
          </div>
        </Link>
        <div className={"common-components__organizations-modal-help-route"}>
          Help
        </div>
        <div
          className={"common-components__organizations-modal-sign-out"}
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Sign Out
        </div>
      </div>
    </Modal>
  );
};

export default OrganizationsModal;
