import FiltrationBar from "components/common/FiltrationBar/FiltrationBar";
import FiltrationMenuButton from "components/common/FiltrationBar/FiltrationMenuButton/FiltrationMenuButton";
import {
  filtersRoute,
  projectsRoute,
  timeEntriesRoute,
} from "constants/Routes";
import { TimeEntriesContext } from "contexts/TimeEntriesContext";
import { separateTimeEntriesIntoPeriodSections } from "helpers/TimeEntriesHelpers/SeparateTimeEntriesIntoPeriodSections";
import { IMyTimeEntriesVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntriesVm";
import { IMyTimeEntriesWeekPeriods } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntriesWeekPeriods";
import { IMyTimeEntryVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVm";
import { useEffect, useState } from "react";
import "./TimeEntriesPageMain.scss";
import SendToApprovalButton from "./SendToApprovalButton/SendToApprovalButton";
import TimeEntriesWeek from "./TimeEntriesWeek/TimeEntriesWeek";
import Modal from "react-modal";
import ApprovalModalContent from "./ApprovalModalContent/ApprovalModalContent";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/Redux/hooks";
import {
  createFiltration,
  IFiltration,
  initialItemState,
  selectFilters,
} from "store/Redux/Reducers/timeEntriesFilterReducer";
import { datesAndTasksFilters } from "helpers/TimeEntriesHelpers/FiltrateTimeEntries";
import { IMyTimeEntryVmFiltrationMapped } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVmFiltrationMapped";

interface IMain {
  projectId: number;
  timeEntriesVm: IMyTimeEntriesVm;
  hasPermissionToEditTimeEntries: boolean;
}

const TimeEntriesPageMain = ({
  projectId,
  timeEntriesVm,
  hasPermissionToEditTimeEntries,
}: IMain) => {
  const [isApproveButtonVisible, setIsApproveButtonVisible] =
    useState<boolean>(false);
  const [selectedTimeEntries, setSelectedTimeEntries] = useState<
    IMyTimeEntryVm[]
  >([]);
  const [openApprovalModal, setOpenApprovalModal] = useState<boolean>(false);
  const [filtratedTimeEntries, setFiltratedTimeEntries] = useState<
    IMyTimeEntryVmFiltrationMapped[]
  >([]);
  const [filtrationInputValue, setFiltartionInputValue] = useState<string>("");
  const filters = useSelector(selectFilters);
  let foundFilter = filters.timeEntriesFiltersReducer.filters.find(
    (filter: IFiltration) => projectId && +filter.projectId === +projectId
  );
  const dispatch = useAppDispatch();
  if (!foundFilter && projectId) {
    dispatch(
      createFiltration({
        ...initialItemState,
        projectId,
      })
    );
    foundFilter = { ...initialItemState };
  }

  const filtratedTasks = datesAndTasksFilters(
    foundFilter,
    timeEntriesVm.timeEntries
  );

  const [separatedTimeEntries, setSeparatedTimeEntries] =
    useState<IMyTimeEntriesWeekPeriods>(
      separateTimeEntriesIntoPeriodSections(filtratedTasks)
    );

  useEffect(() => {
    setIsApproveButtonVisible(selectedTimeEntries.length > 0);
  }, [selectedTimeEntries]);

  const onApproval = () => {
    setOpenApprovalModal(true);
  };

  const closeApprovalModal = () => {
    setOpenApprovalModal(false);
  };

  const timeEntriesMapped = timeEntriesVm.timeEntries.map((item) => {
    return { ...item, name: item.description };
  });

  useEffect(() => {
    if (filtrationInputValue !== "")
      setSeparatedTimeEntries(
        separateTimeEntriesIntoPeriodSections(filtratedTimeEntries)
      );
    else {
      setSeparatedTimeEntries(
        separateTimeEntriesIntoPeriodSections(filtratedTasks)
      );
    }
  }, [filtrationInputValue, timeEntriesVm]);

  return (
    <>
      <Modal
        isOpen={openApprovalModal}
        onRequestClose={() => closeApprovalModal()}
        className={"organizationsModal"}
        overlayClassName={"organizationsModalOverlay"}
        ariaHideApp={false}
      >
        <ApprovalModalContent
          selectedTimeEntries={selectedTimeEntries}
          closeApprovalModal={closeApprovalModal}
          setSelectedTimeEntries={setSelectedTimeEntries}
          setSeparatedTimeEntries={setSeparatedTimeEntries}
          timeEntriesVm={timeEntriesVm}
        />
      </Modal>
      <div
        className={"time-entries-page-main__layout"}
        style={{
          paddingBottom: isApproveButtonVisible ? "130px" : "100px",
          overflowY: openApprovalModal ? "hidden" : "auto",
        }}
      >
        <div className={"time-entries-page__main-title"}>Time tracker</div>
        <FiltrationBar
          items={timeEntriesMapped}
          setFiltratedItems={setFiltratedTimeEntries}
          filtrationInputValue={filtrationInputValue}
          setFiltrationInputValue={setFiltartionInputValue}
          showSeparationLine={false}
          filtrationMenu={
            <FiltrationMenuButton
              projectId={projectId}
              link={`/${projectsRoute}/${projectId}/${timeEntriesRoute}/${filtersRoute}`}
            />
          }
        />
        <TimeEntriesContext.Provider
          value={{
            setSelectedTimeEntries: setSelectedTimeEntries,
            selectedTimeEntries: selectedTimeEntries,
            projectId: projectId,
            hasPermissionToEditTimeEntries: hasPermissionToEditTimeEntries,
          }}
        >
          <div>
            {separatedTimeEntries.timeEntriesWeeks.map((week) => {
              return <TimeEntriesWeek key={week.weekDuration} week={week} />;
            })}
          </div>
        </TimeEntriesContext.Provider>
        {isApproveButtonVisible && (
          <SendToApprovalButton onApproval={onApproval} />
        )}
      </div>
    </>
  );
};

export default TimeEntriesPageMain;
