export const fixTextareaHeightOnChange = () => {
  const textarea = document.getElementById("textarea");

  if (textarea) {
    textarea.addEventListener("input", function () {
      this.style.height = "auto";
      this.style.height = this.scrollHeight + "px";
    });
  }
};

export const fixTextareaHeight = () => {
  const textarea = document.getElementById("textarea");

  if (textarea) {
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }
};
