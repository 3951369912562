import UserAvatar from "one-common-components/lib/components/UserAvatar/UserAvatar";
import "./UserAvatarWithCredentials.scss";

interface IUserAvatarWithCredentials {
  validatedUserCredentials: string;
  role: string;
}

const UserAvatarWithCredentials = ({
  validatedUserCredentials,
  role,
}: IUserAvatarWithCredentials) => {
  return (
    <div className="common-components__responsible-user-avatar-and-credentials">
      <UserAvatar displayName={validatedUserCredentials} size={32} />
      <div className="common-components__responsible-user-credentials">
        <div>{validatedUserCredentials}</div>
        <div>Team {role}</div>
      </div>
    </div>
  );
};

export default UserAvatarWithCredentials;
