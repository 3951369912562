import "./NewProjectButton.scss";
import { newProjectRoute, projectsRoute } from "constants/Routes";
import { Link } from "react-router-dom";

const NewProjectButton = () => {
  return (
    <Link to={`/${projectsRoute}/${newProjectRoute}`}>
      <div className={"project-page__new-project-button"}>Create project</div>
    </Link>
  );
};

export default NewProjectButton;
