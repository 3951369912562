import CorpImage from "public/CorpImage.svg";
import GrayDropdownDownArrow from "public/GrayDropdownDownArrow.svg";
import {
  horizontalArrowCommonWidth,
  horizontalArrowCommonHeight,
} from "constants/Consts";
import { grayText, greenText } from "constants/JsColors";
import { IMyOrganizationVm } from "interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationVm";
import "./OrganizationDropdownContainer.scss";

interface IOrganizationDropdownContainer {
  isOrganizationDropdownOpen: boolean;
  setIsOrganizationDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentOrganization: IMyOrganizationVm | undefined;
}

const OrganizationDropdownContainer = ({
  isOrganizationDropdownOpen,
  setIsOrganizationDropdownOpen,
  currentOrganization,
}: IOrganizationDropdownContainer) => {
  return (
    <div
      className={"common-components__organizations-modal-dropdown-button"}
      onClick={() => setIsOrganizationDropdownOpen(true)}
      style={{
        borderColor: isOrganizationDropdownOpen ? greenText : grayText,
      }}
    >
      <div>
        <img src={CorpImage} alt="Corp image" width={32} height={32} />
        <span
          className={"common-components__organizations-modal-organization-name"}
        >
          {currentOrganization?.name}
        </span>
      </div>
      <div className={"common-components__organizations-modal-dropdown-arrow"}>
        <img
          src={GrayDropdownDownArrow}
          alt="Dropdown down icon"
          width={horizontalArrowCommonWidth}
          height={horizontalArrowCommonHeight}
        />
      </div>
    </div>
  );
};

export default OrganizationDropdownContainer;
