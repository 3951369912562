import "./AddTimeEntryPageMain.scss";
import { useEffect } from "react";
import TimeEntryStatus from "../TimeEntryStatus/TimeEntryStatus";
import { TimeEntryStatuses } from "interfaces/Enums/TimeEntryStatuses";
import { timeEntryStatuses } from "interfaces/Other/ITimeEntryStatuses";
import SeparationLine from "components/common/SeparationLine/SeparationLine";
import TimeEntryDropdown from "./TimeEntryDropdown/TimeEntryDropdown";
import { IMyProjectVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectVm";
import CheckboxItem from "components/common/CheckboxItem/CheckboxItem";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  addRoute,
  projectsRoute,
  tasksRoute,
  timeEntriesRoute,
} from "constants/Routes";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import { ICreateTimeEntryRequest } from "interfaces/Requests/ICreateTimeEntryRequest";
import { useSelector } from "react-redux";
import { selectNewTimeEntriesTasks } from "store/Redux/Reducers/newTimeEntryReducer";
import { createADateFromTime } from "helpers/TimeHelpers/CreateADateFromStringTime";
import asyncToast from "components/common/Toasts/AsyncToast";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { differenceInMinutes } from "date-fns";
import { JSONPatchDocumentReplaceString } from "interfaces/JSONPatchFormats/JSONPatchDocumentReplaceString";
import { IMyTimeEntryVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVm";
import { useLocalStorage } from "Hooks/UseLocalStorage";
import FromToInputs from "components/common/FromToInputs/FromToInputs";
import HoursInputs from "components/common/HoursInputs/HoursInputs";
import TextAreaDescriptionComponent from "components/common/DescriptionComponents/MinimalisticTextAreaDescriptionComponent/MinimalisticTextAreaDescriptionComponent";
import { patchTimeEntry, createTimeEntry } from "Pages/api/MyTimeEntriesClient";
import SaveButton from "components/common/Buttons/SaveButton/SaveButton";

interface IMain {
  showHours: boolean;
  project: IMyProjectVm;
  selectedTask: IMyTaskVm | null;
}

const AddTimeEntryPageMain = ({ showHours, project, selectedTask }: IMain) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [isSendToApproveActive, setIsSendToApproveActive] =
    useLocalStorage<boolean>("timeEntriesRequestApproved", false);
  const [startTime, setStartTime] = useLocalStorage<string>(
    "timeEntriesStartTime",
    ""
  );
  const [finishTime, setFinishTime] = useLocalStorage<string>(
    "timeEntriesFinishTime",
    ""
  );
  const [totalTime, setTotalTime] = useLocalStorage<string>(
    "timeEntriesTotalTime",
    ""
  );
  const [description, setDescription] = useLocalStorage<string>(
    "timeEntriesDescription",
    ""
  );
  const [date, setDate] = useLocalStorage<Date>("timeEntriesDate", new Date());
  const states = useSelector(selectNewTimeEntriesTasks);

  useEffect(() => {
    const storage = globalThis?.sessionStorage;
    if (!storage.prevPath.includes("/timeEntries/add/tasks")) {
      setTotalTime("");
      setStartTime("");
      setFinishTime("");
      setDescription("");
      setDate(new Date());
      setIsSendToApproveActive(false);
    }
    setDate(new Date());
  }, []);

  const sendTimeEntryToApproval = async (timeEntryId: number) => {
    if (projectId) {
      const JSONrequest: JSONPatchDocumentReplaceString[] = [
        {
          op: "replace",
          path: "/status",
          value: TimeEntryStatuses.Awaiting,
        },
      ];
      await asyncToast(
        patchTimeEntry(JSONrequest, timeEntryId, +projectId),
        "Send time entry to approval"
      );
      navigate(`/${projectsRoute}/${project.id}/${timeEntriesRoute}`);
    }
  };

  const createRequestForHours = async () => {
    const startDate = createADateFromTime("00:00", new Date(date));
    const finishDate = createADateFromTime(totalTime, new Date(date));
    if (
      startDate &&
      finishDate &&
      totalTime !== "00:00" &&
      showHours &&
      projectId
    ) {
      const request: ICreateTimeEntryRequest = {
        startDate: startDate,
        finishDate: finishDate,
        description: encodeURIComponent(description),
        taskId: states.newTimeEntryReducer.task
          ? states.newTimeEntryReducer.task.id
          : 0,
        projectId: +projectId,
        containsFromAndToDates: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const createdTimeEntry: IMyTimeEntryVm = await asyncToast(
        createTimeEntry(request),
        "Created time entry"
      );
      if (isSendToApproveActive) {
        await sendTimeEntryToApproval(createdTimeEntry.id);
      }
      navigate(`/${projectsRoute}/${project.id}/${timeEntriesRoute}`);
    } else {
      SimpleToast({
        mode: ToastModes.info,
        message: "You did not insert the correct dates",
      });
    }
  };

  const createRequestForFromTo = async () => {
    const startDate = createADateFromTime(startTime, new Date(date));
    const finishDate = createADateFromTime(finishTime, new Date(date));
    if (
      !showHours &&
      startDate &&
      finishDate &&
      differenceInMinutes(new Date(finishDate), new Date(startDate)) > 0 &&
      projectId
    ) {
      const request: ICreateTimeEntryRequest = {
        startDate: startDate ? startDate : new Date(),
        finishDate: finishDate ? finishDate : new Date(),
        description: encodeURIComponent(description),
        taskId: states.newTimeEntryReducer.task
          ? states.newTimeEntryReducer.task.id
          : 0,
        projectId: +projectId,
        containsFromAndToDates: true,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const createdTimeEntry: IMyTimeEntryVm = await asyncToast(
        createTimeEntry(request),
        "Created time entry"
      );
      if (isSendToApproveActive) {
        await sendTimeEntryToApproval(createdTimeEntry.id);
      }
      navigate(`/${projectsRoute}/${project.id}/${timeEntriesRoute}`);
    } else {
      SimpleToast({
        mode: ToastModes.info,
        message: "You did not insert the correct dates",
      });
    }
  };

  const createTimeEntryHandler = () => {
    if (showHours) {
      createRequestForHours();
    } else {
      createRequestForFromTo();
    }
  };

  return (
    <div className={"add-time-entry-page__main"}>
      <TimeEntryStatus
        status={timeEntryStatuses.find(
          (item) => item.label === TimeEntryStatuses.NotRequestedForApproval
        )}
      />
      {showHours ? (
        <HoursInputs
          setTotalTime={setTotalTime}
          totalTime={totalTime}
          setDate={setDate}
          date={date ? date : new Date()}
          hasPermissionToEditTimeEntry={true} //Only authorized users can come here.
        />
      ) : (
        <FromToInputs
          setDate={setDate}
          date={date ? date : new Date()}
          startTime={startTime}
          finishTime={finishTime}
          setStartTime={setStartTime}
          setFinishTime={setFinishTime}
          hasPermissionToEditTimeEntry={true} //Only authorized users can come here.
        />
      )}
      <TimeEntryDropdown
        firstText={"Projects"}
        secondText={project.name}
        isActive={true}
        isRightArrowVisible={false}
      />
      <SeparationLine />
      <Link
        to={`/${projectsRoute}/${project.id}/${timeEntriesRoute}/${addRoute}/${tasksRoute}`}
      >
        <TimeEntryDropdown
          firstText={"Tasks"}
          secondText={selectedTask ? selectedTask.name : "No task"}
          isActive={selectedTask !== null}
        />
      </Link>
      <SeparationLine />
      <TextAreaDescriptionComponent
        setDescription={setDescription}
        description={description}
        hasPermissionToEdit={true} //Only authorized users can come here.
      />
      <SeparationLine />
      <div className={"add-time-entry-page__checkbox-item-wrapper"}>
        <CheckboxItem
          isActive={isSendToApproveActive}
          setIsActive={setIsSendToApproveActive}
          text={"Send to approve"}
        />
      </div>
      <SaveButton save={createTimeEntryHandler} />
    </div>
  );
};

export default AddTimeEntryPageMain;
