import { IFiltration as TimeEntriesFilter } from "store/Redux/Reducers/timeEntriesFilterReducer";

interface IHasTaskId {
  taskNumberInProject: number;
}

export const timeEntryTasksFilter = <T extends IHasTaskId>(
  foundFilter: TimeEntriesFilter,
  filteredArray: T[]
) => {
  if (foundFilter.tasks.length > 0) {
    return filteredArray.filter((value) => {
      return (
        foundFilter &&
        foundFilter.tasks.some(
          (item) => item.taskNumberInProject === value.taskNumberInProject
        )
      );
    });
  }
};
