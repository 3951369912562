import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TaskExecutionState } from "interfaces/Enums/TasksExecutionState";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import type { RootState } from "../store";

export interface IMyTaskVmReducerInitialState {
  task: IMyTaskVm | null;
}

const initialState: IMyTaskVmReducerInitialState = {
  task: null,
};

export const selectedTaskSlice = createSlice({
  name: "selectedProjectMember",
  initialState,
  reducers: {
    changeSelectedTask: (state, action: PayloadAction<IMyTaskVm | null>) => {
      state.task = action.payload;
    },
    changeSelectedTaskDueDate: (state, action: PayloadAction<string>) => {
      if (!state.task) {
        return;
      }
      state.task.dueDate = action.payload;
    },
    changeSelectedTaskStartDate: (state, action: PayloadAction<string>) => {
      if (!state.task) {
        return;
      }
      state.task.startDate = action.payload;
    },
    updateSelectedTaskDescription: (state, action: PayloadAction<string>) => {
      if (state.task) {
        state.task.description = action.payload;
      }
    },
    updateSelectedTaskTitle: (state, action: PayloadAction<string>) => {
      if (state.task) {
        state.task.name = action.payload;
      }
    },
    updateSelectedTaskExecutionState: (
      state,
      action: PayloadAction<TaskExecutionState>
    ) => {
      if (state.task) {
        state.task.executionState = action.payload;
      }
    },
    adjustSelectedTaskUsers: (state, action: PayloadAction<IMyUserVm[]>) => {
      if (state.task) {
        state.task.assignedUsers = action.payload;
      }
    },
    setSelectedTaskState: (
      state,
      action: PayloadAction<TaskExecutionState>
    ) => {
      if (state.task) {
        state.task.executionState = action.payload;
      }
    },
    resetInitialValue: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
  },
});

export const {
  changeSelectedTask,
  adjustSelectedTaskUsers,
  setSelectedTaskState,
  updateSelectedTaskDescription,
  changeSelectedTaskDueDate,
  changeSelectedTaskStartDate,
  updateSelectedTaskExecutionState,
  updateSelectedTaskTitle,
} = selectedTaskSlice.actions;

export const selectedTask = (state: RootState) => state;

export default selectedTaskSlice.reducer;
