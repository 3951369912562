import { AxiosClient } from "./AxiosClient";
import application from "../../config.json";
import ChecklistVm from "interfaces/ViewModels/MyChecklistsViewModels/ChecklistVm";
import { HttpRequestsMethods } from "interfaces/Enums/HttpRequestsMethods";
import { IUpdateFormStatus } from "interfaces/Requests/IUpdateFormStatus";
import { IAddChecklistRequest } from "interfaces/Requests/IAddChecklistRequest";

const base = application.baseUrl;
const MyForms = "MyChecklists";

const getTaskChecklists = async (taskId: number): Promise<ChecklistVm[]> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyForms}/Task/${taskId}`,
    base
  );
};

const getChecklistsForProject = async (
  projectId: number
): Promise<ChecklistVm[]> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyForms}/Project/${projectId}`,
    base
  );
};

const changeChecklistStatus = async (
  body: IUpdateFormStatus
): Promise<null> => {
  return AxiosClient(HttpRequestsMethods.PUT, `${MyForms}/Status`, base, {
    body,
  });
};

const addChecklist = async (
  body: IAddChecklistRequest
): Promise<ChecklistVm> => {
  return AxiosClient(
    HttpRequestsMethods.POST,
    `${MyForms}/AddChecklist`,
    base,
    { body }
  );
};

export {
  getTaskChecklists,
  getChecklistsForProject,
  changeChecklistStatus,
  addChecklist,
};
