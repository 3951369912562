import SeparationLine from "components/common/SeparationLine/SeparationLine";
import "./DescriptionOverviewComponent.scss";

interface IDescriptionOverviewComponent {
  text: string;
  showSeparationLine?: boolean;
  marginBottom?: number;
  label?: string;
  marginTop?: number;
}

const DescriptionOverviewComponent = ({
  text,
  showSeparationLine,
  marginBottom,
  marginTop = 0,
  label = "Description",
}: IDescriptionOverviewComponent) => {
  return (
    <>
      <div
        className={"description-overview-component__label"}
        style={{ marginTop: marginTop }}
      >
        {label}
      </div>
      {showSeparationLine && <SeparationLine marginTop={8} marginBottom={12} />}
      <div
        style={{ marginBottom: marginBottom }}
        className={"description-overview-component__content"}
      >
        {text}
      </div>
    </>
  );
};

export default DescriptionOverviewComponent;
