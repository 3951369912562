import "./GrayFiltrationBar.scss";
import GrayMagnifyingGlass from "public/GrayMagnifyingGlass.svg";
import React from "react";

interface IGrayFiltrationBar<T> {
  originalArray: T[];
  setFiltratedItems: React.Dispatch<React.SetStateAction<T[]>>;
}

interface IHasName {
  name: string;
}

const GrayFiltrationBar = <T extends IHasName>({
  setFiltratedItems,
  originalArray,
}: IGrayFiltrationBar<T>): JSX.Element => {
  const firstFiltration = (inputValue: string) => {
    const filtratedItemsArray: T[] | ((prevState: T[]) => T[]) = [];
    originalArray.map((item) => {
      if (
        item.name.toString().toLowerCase().includes(inputValue.toLowerCase())
      ) {
        filtratedItemsArray.push(item);
      }
    });
    setFiltratedItems(filtratedItemsArray);
  };

  return (
    <div className={"common-components__gray-filtration-bar-wrapper"}>
      <input
        placeholder="Search"
        onChange={(e) => {
          firstFiltration(e.target.value);
        }}
      />
      <div>
        <img
          src={GrayMagnifyingGlass}
          alt="Gray left arrow"
          width={17}
          height={17}
        />
      </div>
    </div>
  );
};

export default GrayFiltrationBar;
