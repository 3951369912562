import Checkbox from "components/common/Checkbox/Checkbox";
import SeparationLine from "components/common/SeparationLine/SeparationLine";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import IdBadge from "one-common-components/lib/components/IdBadge/IdBadge";
import React, { useEffect, useState } from "react";
import "./ListOfTasksItem.scss";

interface IListOfTasksItem {
  task: IMyTaskVm;
  setChosenTasks: React.Dispatch<React.SetStateAction<IMyTaskVm[]>>;
  chosenTasks: IMyTaskVm[];
}

const ListOfTasksItem = ({
  task,
  setChosenTasks,
  chosenTasks,
}: IListOfTasksItem) => {
  const [active, setIsActive] = useState<boolean>(
    chosenTasks.some(
      (item) => item.taskNumberInProject === task.taskNumberInProject
    )
  );

  const addOrRemoveTasksToList = () => {
    if (
      chosenTasks.some(
        (item) => item.taskNumberInProject === task.taskNumberInProject
      ) &&
      active === false
    ) {
      const foundIndex = chosenTasks.findIndex(
        (item) => item.taskNumberInProject === task.taskNumberInProject
      );
      const copiedArray = [...chosenTasks];
      copiedArray.splice(foundIndex, 1);
      setChosenTasks([...copiedArray]);
    }
    if (
      !chosenTasks.some(
        (item) => item.taskNumberInProject === task.taskNumberInProject
      ) &&
      active === true
    ) {
      setChosenTasks([...chosenTasks, task]);
    }
  };

  useEffect(() => {
    addOrRemoveTasksToList();
  }, [active]);

  return (
    <React.Fragment>
      <div className={"task-filter-item__wrapper"}>
        <div className={"task-filter-item__wrapper-left"}>
          <span className={"task-filter-item__wrapper-left-task-name"}>
            {task.name}
          </span>
          <IdBadge id={task.taskNumberInProject} />
        </div>
        <div>
          <Checkbox setIsActive={setIsActive} checked={active} />
        </div>
      </div>
      <SeparationLine />
    </React.Fragment>
  );
};

export default ListOfTasksItem;
