import toast from "react-hot-toast";
import GreenSuccessIcon from "public/GreenSuccessIcon.svg";

export const successToast = (message: string) => {
  return toast(message, {
    duration: 2000,
    icon: <img src={GreenSuccessIcon} />,
    style: {
      color: "#33CA75",
      backgroundColor: "#EAF9F1",
      width: "100%",
    },
  });
};
