import application from "../../config.json";
import { FileVm } from "interfaces/ViewModels/MyFilesViewModels/FileVm.js";
import axios from "axios";

const FileUpload = "FileUpload";
const base = application.baseUrl;

const uploadFile = async (formData: FormData): Promise<FileVm> => {
  const response = axios.post(`${base}/${FileUpload}`, formData);
  return (await response).data;
};

const updateFile = async (formData: FormData, id: string): Promise<FileVm> => {
  const response = axios.put(`${base}/${FileUpload}/${id}`, formData);
  return (await response).data;
};

const updateFileName = async (id: string, fileName: string): Promise<null> => {
  const response = axios.put(`${base}/${FileUpload}/File/${id}/${fileName}`);
  return (await response).data;
};

export { uploadFile, updateFile, updateFileName };
