import { differenceInMinutes } from "date-fns";
import { createADateFromTime } from "helpers/TimeHelpers/CreateADateFromStringTime";
import { getFullHoursAndMinutes } from "helpers/TimeHelpers/GetFullHoursAndMinutes";
import React from "react";
import FromToContainer from "./FromToContainer/FromToContainer";
import "./FromToInputs.scss";

interface IFromToInputs {
  setStartTime: React.Dispatch<React.SetStateAction<string>>;
  setFinishTime: React.Dispatch<React.SetStateAction<string>>;
  startTime: string;
  finishTime: string;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  date: Date;
  hasPermissionToEditTimeEntry: boolean;
}

const FromToInputs = ({
  setStartTime,
  setFinishTime,
  startTime,
  finishTime,
  date,
  setDate,
  hasPermissionToEditTimeEntry,
}: IFromToInputs) => {
  const startDate = createADateFromTime(startTime);
  const finishDate = createADateFromTime(finishTime);
  return (
    <div className={"common-components__from-to-inputs"}>
      <div className={"common-components__from-to-inputs-total-label"}>
        Total:
        <span className={"common-components__from-to-inputs-total-label-hours"}>
          {finishDate &&
          startDate &&
          differenceInMinutes(finishDate, startDate) > 0
            ? getFullHoursAndMinutes(startDate, finishDate)
            : "00:00"}
        </span>
      </div>
      <FromToContainer
        setStartTime={setStartTime}
        setFinishTime={setFinishTime}
        setDate={setDate}
        startTime={startTime}
        finishTime={finishTime}
        date={date}
        hasPermissionToEditTimeEntry={hasPermissionToEditTimeEntry}
      />
    </div>
  );
};

export default FromToInputs;
