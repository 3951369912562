import "./ChecklistSimpleCheckbox.scss";

interface IChecklistSimpleCheckbox {
  checked: boolean;
  value: string;
  onClick: (value: string) => void;
}

const ChecklistSimpleCheckbox = ({
  checked,
  onClick,
  value,
}: IChecklistSimpleCheckbox) => {
  return (
    <div>
      <label
        className={`${"common-components__checkbox-control"}  ${"common-components__control-checkbox"}`}
      >
        <input
          onChange={() => onClick(value)}
          type={"checkbox"}
          checked={checked}
          tabIndex={-1}
        />
        <div className={"common-components__control-indicator"} />
      </label>
    </div>
  );
};

export default ChecklistSimpleCheckbox;
