import GettingStartedPage from "components/GettingStartedPageComponents/GettingStartedPage";
import ProjectsPageHeader from "components/ProjectsComponents/ProjectsPageHeader/ProjectsPageHeader";
import ProjectsPageMain from "components/ProjectsComponents/ProjectsPageMain/ProjectsPageMain";
import { useEffect, useState } from "react";
import { IMyOrganizationsVm } from "../../interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationsVm";
import { IMyProfileVm } from "../../interfaces/ViewModels/MyProjectsViewModels/IMyProfileVm";
import { IMyProjectsVm } from "../../interfaces/ViewModels/MyTasksViewModels/IMyProjectsVm";
import {
  getChosenOrganizationId,
  getMyOrganizations,
} from "Pages/api/MyOrganizationsClient";
import { getUserProfileDetails } from "Pages/api/MyProfileClient";
import { getAllMyProjects } from "Pages/api/MyProjectsClient";
import "./ProjectsPage.scss";
import {
  setChosenOrganizationIdState,
  setOrganizationMembers,
} from "store/Redux/Reducers/chosenOrganizationReducer";
import { useAppDispatch } from "store/Redux/hooks";
import ProjectsPageFooter from "components/common/Footers/ProjectsPageFooter/ProjectsPageFooter";
import { getOrganizationUsers } from "Pages/api/MyUserOrganizationsClient";
import { setUserProfileState } from "store/Redux/Reducers/userProfileReducer";
import { setAllUsersOrganizations } from "store/Redux/Reducers/allUsersOrganizationsReducer";
import useAuth0 from "Auth/use-auth0";
import { OrganizationRolesEnum } from "interfaces/Enums/OrganizationRolesEnum";
import { checkOrganizationAccess } from "helpers/Permission/CheckOrganizationAccess";

const ProjectsPage = () => {
  const { logout } = useAuth0();
  const [userDetails, setUserDetails] = useState<IMyProfileVm>();
  const [organizations, setOrganizations] = useState<IMyOrganizationsVm>();
  const [myOrganizationId, setMyOrganizationId] = useState<number>();
  const [projects, setProjects] = useState<IMyProjectsVm>();
  const dispatch = useAppDispatch();

  const handleChosenOrganizationId = async () => {
    try {
      const result = await getUserProfileDetails();
      setUserDetails(result);
      dispatch(setUserProfileState(result));
      const organizationId = await getChosenOrganizationId();
      setMyOrganizationId(organizationId);
      dispatch(setChosenOrganizationIdState(organizationId));
    } catch {
      logout();
    }
  };

  const handleOrganizations = async () => {
    const result = await getMyOrganizations();
    setOrganizations(result);
    dispatch(setAllUsersOrganizations(result.myOrganizations));
  };

  const handleOrganizationUsers = async (myOrganizationId: number) => {
    dispatch(
      setOrganizationMembers(await getOrganizationUsers(myOrganizationId))
    );
  };

  const handleProjects = async (myOrganizationId: number) => {
    setProjects(await getAllMyProjects(myOrganizationId));
  };

  const handleEmptyProjects = () => {
    const item: IMyProjectsVm = {
      myProjects: [],
    };
    setProjects(item);
  };

  const handleEmptyOrganizations = () => {
    const item: IMyOrganizationsVm = {
      myOrganizations: [],
    };
    setOrganizations(item);
  };

  useEffect(() => {
    if (myOrganizationId === undefined) {
      handleChosenOrganizationId();
    }
  }, [myOrganizationId]);

  useEffect(() => {
    if (myOrganizationId !== undefined && myOrganizationId !== 0) {
      handleOrganizationUsers(myOrganizationId);
      handleProjects(myOrganizationId);
      handleOrganizations();
    }
  }, [myOrganizationId]);

  useEffect(() => {
    if (myOrganizationId === 0) {
      handleEmptyProjects();
      handleEmptyOrganizations();
    }
  }, [myOrganizationId]);

  if (
    !userDetails ||
    !organizations ||
    myOrganizationId === undefined ||
    !projects ||
    projects.myProjects === null
  ) {
    return <div />;
  }
  if (myOrganizationId === 0) {
    return <GettingStartedPage setMyOrganizationId={setMyOrganizationId} />;
  }
  return (
    <div className={"projects-page__main-layout"}>
      <ProjectsPageHeader
        userDetails={userDetails}
        organizations={organizations.myOrganizations}
        myOrganizationId={myOrganizationId}
        setMyOrganizationId={setMyOrganizationId}
      />
      <ProjectsPageMain projects={projects} userDetails={userDetails} />
      {checkOrganizationAccess(
        userDetails.organizationRole,
        OrganizationRolesEnum.Manager
      ) && <ProjectsPageFooter />}
    </div>
  );
};

export default ProjectsPage;
