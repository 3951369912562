import "./DateBadge.scss";
import CalendarIcon from "public/CalendarIcon.svg";
import { slashesDateFormat } from "constants/DateFormats";
import { format } from "date-fns";

interface IDateBadge {
  date: Date;
  marginLeft?: number;
  marginRight?: number;
}

const DateBadge = ({ date, marginRight, marginLeft }: IDateBadge) => {
  return (
    <div
      style={{
        marginRight: marginRight ? marginRight : 0,
        marginLeft: marginLeft ? marginLeft : 0,
      }}
      className="common-components__date-badge"
    >
      <img src={CalendarIcon} alt={"Calendar icon"} />
      {format(new Date(date), slashesDateFormat)}
    </div>
  );
};

export default DateBadge;
