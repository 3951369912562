export const whiteBackground = "#FFFFFF";
export const lightGrayBackground = "rgba(245, 245, 245, 0.5)";
export const lightGreenBackground = "#EAF9F1";
export const grayBackground = "#F5F5F5";
export const switchOnColor = "#D6F4E3";
export const switchOffColor = "#BFBFBF";
export const greenText = "#33CA75";
export const grayText = "#9F9F9F";
export const yellowText = "#F5A62F";
export const lightGreen = "#EAF9F1";
export const borderWhite = "#C4C4C4";
export const yellowBackground = "#F6F5CF";
export const lightRed = "rgba(202, 51, 61, 0.15)";
export const blackText = "#263238";
export const redAccent = "#CA333D";
export const switchBoxShadow = "0px 1px 5px rgba(0, 0, 0, 0.6)";
export const activeSwitchBoxShadow = "0px 0px 1px 1px rgba(0, 0, 0, 0.2)";
export const blueText = "rgba(44, 163, 255, 1)";
export const blueBackground = "rgba(44, 163, 255, 0.15)";
