import { taskAssigneesFilter } from "helpers/FiltersModulesHelpers/TaskAssigneesFilter";
import { taskEndDateFilter } from "helpers/FiltersModulesHelpers/TaskEndDateFilter";
import { startDateFilter } from "helpers/FiltersModulesHelpers/TaskStartDateFilter";
import { taskTasksFilter } from "helpers/FiltersModulesHelpers/TaskTasksFilters";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import { IFiltration } from "store/Redux/Reducers/projectsFilterReducer";

export const datesTasksAndAssigneesFilters = (
  foundFilter: IFiltration | undefined,
  tasks: IMyTaskVm[]
) => {
  let filteredArray: IMyTaskVm[] = [...tasks];

  if (foundFilter) {
    const startDateResult = startDateFilter(foundFilter, filteredArray);
    if (startDateResult) {
      filteredArray = startDateResult;
    }
    const endDateResult = taskEndDateFilter(foundFilter, filteredArray);
    if (endDateResult) {
      filteredArray = endDateResult;
    }
    const tasksResult = taskTasksFilter(foundFilter, filteredArray);
    if (tasksResult) {
      filteredArray = tasksResult;
    }
    const assigneesResult = taskAssigneesFilter(foundFilter, filteredArray);
    if (assigneesResult) {
      filteredArray = assigneesResult;
    }
  }
  return filteredArray;
};
