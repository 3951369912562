import Modal from "react-modal";
import "./GrayModalOverlay.scss";

interface IGrayModalOverlay {
  isGrayShadowModalOpen: boolean;
  setIsGrayShadowModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const GrayModalOverlay = ({
  isGrayShadowModalOpen,
  setIsGrayShadowModalOpen,
}: IGrayModalOverlay) => {
  return (
    <div onClick={() => setIsGrayShadowModalOpen(false)}>
      <Modal
        isOpen={isGrayShadowModalOpen}
        onRequestClose={() => setIsGrayShadowModalOpen(false)}
        className={"common-components__gray-modal-overlay"}
        overlayClassName={"common-components__gray-shadow-modal-overlay"}
        ariaHideApp={false}
      />
    </div>
  );
};
export default GrayModalOverlay;
