/* eslint-disable @typescript-eslint/no-explicit-any */
import { FileMetadata } from "interfaces/ViewModels/MyDocumentsViewModels/FileMetadata";
import { DownloadedFile } from "one-common-components/lib/components/Files/Interfaces/DownloadedFile";
import application from "../../config.json";
import { AxiosClient } from "./AxiosClient";

const fileDownload = "FileDownload";
const base = application.baseUrl;

const downloadFile = async (fileId: string): Promise<any> => {
  return AxiosClient("GET", `${fileDownload}/${fileId}`, base, {
    responseIsBlob: true,
  });
};

const downloadAllTasksFiles = async (
  taskId: number
): Promise<DownloadedFile[]> => {
  return AxiosClient("GET", `${fileDownload}/Task/${taskId}`, base);
};

const downloadAllDeviationFiles = async (
  projectId: number,
  formLinkId: number
): Promise<DownloadedFile[]> => {
  return AxiosClient(
    "GET",
    `${fileDownload}/Project/${projectId}/Deviation/${formLinkId}`,
    base
  );
};

const downloadAllProjectFilesMetadata = async (
  projectId: number
): Promise<FileMetadata[]> => {
  return AxiosClient("GET", `${fileDownload}/Project/${projectId}`, base);
};

export {
  downloadFile,
  downloadAllTasksFiles,
  downloadAllDeviationFiles,
  downloadAllProjectFilesMetadata,
};
