/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  blackText,
  borderWhite,
  grayText,
  greenText,
  lightGreen,
  whiteBackground,
} from "constants/JsColors";

export const dropdownStyles = {
  control: (provided: any, { isFocused }: any) => ({
    ...provided,
    width: "100%",
    height: 48,
    boxShadow: isFocused ? `0 0 0 1px ${greenText}` : borderWhite,
    borderColor: isFocused ? greenText : borderWhite,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: `${grayText}`,
  }),
  input: (provided: any) => ({
    ...provided,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "bold",
    fontStyle: "normal",
    color: grayText,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "bold",
    fontStyle: "normal",
    color: grayText,
  }),
  option: (styles: any) => ({
    ...styles,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "bold",
    fontStyle: "normal",
    color: grayText,
    backgroundColor: whiteBackground,
    "&:active": { backgroundColor: lightGreen },
  }),
  singleValue: (styles: any) => ({
    ...styles,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "bold",
    fontStyle: "normal",
    color: blackText,
  }),
};
