import ToggleSwitch from "components/common/ToggleSwitch/ToggleSwitch";
import React from "react";
import "./AddTimeEntrySwitch.scss";

interface IAddTimeEntrySwitch {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddTimeEntrySwitch = ({ isActive, setIsActive }: IAddTimeEntrySwitch) => {
  return (
    <div className={"add-time-entry-switch"}>
      <ToggleSwitch getter={isActive} setter={setIsActive} />
      <span className={"add-time-entry-switch__text"}>Hours</span>
    </div>
  );
};

export default AddTimeEntrySwitch;
