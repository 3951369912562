import "./CommentInput.scss";

interface ICommentInput {
  onBlur: (value: string) => void;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  value: string;
}

const CommentInput = ({ onChange, onBlur, value }: ICommentInput) => {
  return (
    <div>
      <div className="checklist-answers-page__common-input--label">Comment</div>
      <input
        placeholder="Input comment here..."
        className="checklist-answers-page__common-input--input"
        onChange={(e) => onChange(e.target.value)}
        onBlur={() => onBlur(value)}
        value={value}
      />
    </div>
  );
};

export default CommentInput;
