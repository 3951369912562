import ChecklistsCheckbox from "components/common/ChecklistsCheckbox/ChecklistsCheckbox";
import ChecklistsAnswerLabel from "components/common/ChecklistsComponents/ChecklistsAnswerLabel/ChecklistsAnswerLabel";
import { AnswerDto } from "interfaces/ViewModels/MyFormsViewModels/AnswerDto";
import { GroupedAnswersDto } from "interfaces/ViewModels/MyFormsViewModels/GroupedAnswerDto";
import { useState } from "react";
import { useAppDispatch } from "store/Redux/hooks";
import {
  updateChecklistAnswer,
  updateChecklistComment,
} from "store/Redux/Reducers/selectedChecklistsReducer";
import CommentInput from "../CommentInput/CommentInput";
import { fillArrayValues } from "./FillArrayValues";
import "./MultipleChoiceInputs.scss";

interface IMultipleChoiceInputs {
  answer: AnswerDto;
  userId: number;
  group?: GroupedAnswersDto;
  checklistId: number;
}

const MultipleChoiceInputs = ({
  answer,
  userId,
  group,
  checklistId,
}: IMultipleChoiceInputs) => {
  const [inputText, setInputText] = useState<string>(
    answer.comment ? answer.comment : ""
  );
  const dispatch = useAppDispatch();

  const setMultipleChoiceValue = (value: string) => {
    const answerValuesArray = fillArrayValues(answer.value, value);
    dispatch(
      updateChecklistAnswer({
        group,
        answer,
        value: answerValuesArray,
        userId,
        checklistId,
      })
    );
  };

  const setMultipleChoiceComment = (comment: string) => {
    dispatch(
      updateChecklistComment({ group, answer, comment, userId, checklistId })
    );
  };

  return (
    <div className="checklists-answers--margins">
      <ChecklistsAnswerLabel text={answer.header} position={answer.order} />
      {answer.possibleAnswersToChoose.map((possibleAnswer, index) => {
        return (
          <ChecklistsCheckbox
            key={`${answer.order}${answer.header}${index}`}
            text={possibleAnswer.content}
            checked={
              answer.value
                ? answer.value.includes(possibleAnswer.content)
                : false
            }
            onClick={setMultipleChoiceValue}
          />
        );
      })}
      <CommentInput
        onBlur={setMultipleChoiceComment}
        onChange={setInputText}
        value={inputText}
      />
    </div>
  );
};

export default MultipleChoiceInputs;
