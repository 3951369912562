/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { Method } from "axios";

/* eslint-disable @typescript-eslint/no-unused-vars */
const AxiosClient = async (
  method: Method,
  endpoint: string,
  applicationBase: string,
  { body, responseIsBlob = false }: any = {}
): Promise<any> => {
  const requestResult = await axios({
    method: method,
    url: `${applicationBase}/${endpoint}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: JSON.stringify(body),
    responseType: responseIsBlob ? "blob" : "json",
  });

  return requestResult.data;
};
export { AxiosClient };
