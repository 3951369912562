import Checkbox from "components/common/Checkbox/Checkbox";
import { dayAndDateFormat } from "constants/DateFormats";
import { format } from "date-fns";
import { getHoursFromMinutes } from "helpers/TimeHelpers/GetHoursFromMinutes";
import { IMyTimeEntryVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVm";
import IdBadge from "one-common-components/lib/components/IdBadge/IdBadge";
import "./ApprovalItem.scss";

interface IApprovalItem {
  item: IMyTimeEntryVm;
}

const ApprovalItem = ({ item }: IApprovalItem) => {
  return (
    <div className={"time_entries_approval-item"}>
      <Checkbox checked={true} />
      <span className={"time_entries_approval-item-task-name"}>
        {item.taskName ? item.taskName : "No task"}
      </span>
      {item.taskId ? (
        <IdBadge id={item.taskNumberInProject} />
      ) : (
        <div className="time_entries_badge--empty" />
      )}
      <span className={"time_entries_approval-item-date"}>
        {format(new Date(item.startDate), dayAndDateFormat)}
      </span>
      <span>{getHoursFromMinutes(item.totalNumberOfMinutes)}</span>
    </div>
  );
};

export default ApprovalItem;
