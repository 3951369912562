import "./RadioButton.scss";

interface IRadioButton {
  text: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  checked: boolean;
}

const RadioButton = ({
  text,
  name,
  value,
  onChange,
  checked,
}: IRadioButton) => {
  return (
    <div className="radio-button--wrapper" onClick={() => onChange(value)}>
      <input
        type="radio"
        className="radio-button__single-choice-possible-option"
        name={`${name}`}
        defaultValue={value}
        checked={checked}
        onChange={(e) => onChange(e.target.value)}
      />
      <label className="radio-button__label">{text}</label>
    </div>
  );
};

export default RadioButton;
