import NotifiactionsBellAndMenu from "components/common/NotifiactionsBellAndMenu/NotifiactionsBellAndMenu";
import { useEffect, useState } from "react";
import { IMyOrganizationVm } from "interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationVm";
import { IMyProjectTasksVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectTasksVm";
import { IMyProjectVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectVm";
import { getOrganizationByProjectId } from "Pages/api/MyOrganizationsClient";
import {
  getProjectById,
  getProjectMembers,
  getProjectTasksWithDetails,
} from "Pages/api/MyProjectsClient";
import "./ProjectPage.scss";
import { projectsRoute } from "constants/Routes";
import { useParams } from "react-router-dom";
import ProjectPageMain from "components/ProjectsComponents/ProjectPageComponents/ProjectPageMain/ProjectPageMain";
import Footer from "components/common/Footers/Footer";
import {
  ProjectsPermissions,
  setEndProjectDateFromTasks,
  setProjectMembers,
  setProjectMembersPermissions,
  setProjectPermissions,
  setProjectTasks,
  setSelectedProject,
  setStartProjectDateFromTasks,
} from "store/Redux/Reducers/projectReducer";
import { useAppDispatch } from "store/Redux/hooks";
import {
  chosenOrganization,
  setChosenOrganizationState,
} from "store/Redux/Reducers/chosenOrganizationReducer";
import HeaderAndCustomEndElement from "components/common/Headers/HeaderWithNotifications/HeaderAndCustomEndElement";
import { useSelector } from "react-redux";
import { setUserProfileState } from "store/Redux/Reducers/userProfileReducer";
import { getUserProjectRoleId } from "Pages/api/MyProfileClient";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";

const ProjectPage = () => {
  const globalStates = useSelector(chosenOrganization);
  const [project, setProject] = useState<IMyProjectVm>();
  const [tasks, setTasks] = useState<IMyProjectTasksVm>();
  const [myOrganization, setMyOrganization] = useState<IMyOrganizationVm>();
  const { projectId } = useParams();
  const dispatch = useAppDispatch();

  const handleProjects = async (projectId: number) => {
    const project = await getProjectById(+projectId);
    setProject(project);
    const projectPermissions: ProjectsPermissions = {
      canCreateTasks: project.canCreateTasks,
      canCreateMembers: project.canCreateMembers,
    };
    dispatch(setProjectPermissions(projectPermissions));
    dispatch(setSelectedProject(project));
  };

  const handleProjectMembers = async (projectId: number) => {
    const users = await getProjectMembers(projectId);
    dispatch(
      setProjectMembers(
        users.projectMembers.filter(
          (item) => item.projectRoleId !== ProjectRolesEnum.Viewer
        )
      )
    );
    dispatch(setProjectMembersPermissions(users.canEditProjectMembers));
  };

  const handleTasks = async (projectId: number) => {
    const tasks = await getProjectTasksWithDetails(+projectId);
    setTasks(tasks);
    dispatch(setProjectTasks(tasks));
    dispatch(
      setStartProjectDateFromTasks(tasks ? tasks.firstTaskStartDate : null)
    );
    dispatch(setEndProjectDateFromTasks(tasks ? tasks.lastTaskEndDate : null));
  };

  const handleMyOrganization = async (projectId: number) => {
    const myOrganization = await getOrganizationByProjectId(+projectId); //TODO check isOwner property becasue may show falsy value (not relevant);
    setMyOrganization(myOrganization);
    dispatch(
      setChosenOrganizationState(myOrganization ? myOrganization : null)
    );
  };

  const handleUpdateUserProfile = async (projectId: number) => {
    const projectRoleId = await getUserProjectRoleId(projectId);
    const myProfile = globalStates.userProfileReducer.userProfile;
    if (!myProfile) {
      return SimpleToast({
        mode: ToastModes.error,
        message: "User profile was not found",
      });
    }
    const newProfile = { ...myProfile };
    newProfile.projectRole = projectRoleId;
    dispatch(setUserProfileState(newProfile));
  };

  useEffect(() => {
    if (projectId) {
      handleProjectMembers(+projectId);
      handleProjects(+projectId);
      handleTasks(+projectId);
      handleMyOrganization(+projectId);
      handleUpdateUserProfile(+projectId);
    }
  }, [projectId]);

  if (
    !globalStates.userProfileReducer.userProfile ||
    !globalStates.allUsersOrganizationsSlice.usersOrganizations ||
    !project ||
    !tasks ||
    !myOrganization
  ) {
    return <div />;
  }

  return (
    <div className={"project_page__main"}>
      <HeaderAndCustomEndElement
        isProjectPage={true}
        endElement={
          <NotifiactionsBellAndMenu
            organizations={
              globalStates.allUsersOrganizationsSlice.usersOrganizations
            }
            userDetails={globalStates.userProfileReducer.userProfile}
            myOrganizationId={myOrganization.id}
          />
        }
        returnUrl={`/${projectsRoute}`}
      />
      <ProjectPageMain
        project={project}
        myOrganization={myOrganization}
        tasks={tasks}
      />
      <Footer
        isInProjectsPage={true}
        isInTimeEntriesPage={false}
        hasPermissionToCreate={restrictProjectAccessBySpecifiedRoles(
          globalStates.userProfileReducer.userProfile.projectRole,
          [ProjectRolesEnum.Viewer]
        )}
      />
    </div>
  );
};

export default ProjectPage;
