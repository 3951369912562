import { IProjectOverviewInputs } from "Pages/ProjectsPage/ProjectPage/ProjectOverviewPage/ProjectOverviewEditPage/ProjectOverviewEditPage";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectedProject } from "store/Redux/Reducers/projectReducer";
import "./ProjectOverviewEditPageMain.scss";
import BorderedTextAreaDescriptionComponent from "components/common/DescriptionComponents/BorderedTextAreaDescriptionComponent/BorderedTextAreaDescrptionComponent";
import { useEffect } from "react";
import InputWithLabel from "one-common-components/lib/components/SingleInputsPack/InputWithLabel/InputWithLabel";

interface IProjectOverviewEditPageMain {
  register: UseFormRegister<IProjectOverviewInputs>;
  setValue: UseFormSetValue<IProjectOverviewInputs>;
}

const ProjectOverviewEditPageMain = ({
  register,
  setValue,
}: IProjectOverviewEditPageMain) => {
  const globalStates = useSelector(selectedProject);

  useEffect(() => {
    const project = globalStates.chosenProjectReducer.project;
    if (project) {
      setValue("Budget", project.budget);
      setValue("Estimated cost", project.estimatedCost);
      setValue("Description", project.description);
      setValue("Project name", project.name);
    }
  }, []);

  return (
    <main className="project-overview-edit-page__main">
      <InputWithLabel
        label={"Project name"}
        marginTop={24}
        inputPlaceholder={"Enter project name"}
        register={register}
        required
      />
      <InputWithLabel
        label={"Budget"}
        labelWithVariables={`Budget [${globalStates.chosenOrganizationReducer.myOrganization?.currency}]`}
        marginTop={24}
        type="number"
        min={0}
        inputPlaceholder={"Enter budget"}
        register={register}
      />
      <InputWithLabel
        label={`Estimated cost`}
        labelWithVariables={`Estimated cost [${globalStates.chosenOrganizationReducer.myOrganization?.currency}]`}
        marginTop={24}
        type="number"
        min={0}
        inputPlaceholder={"Enter estimated cost"}
        register={register}
      />
      <BorderedTextAreaDescriptionComponent
        marginTop={24}
        register={register}
        label={"Description"}
      />
    </main>
  );
};

export default ProjectOverviewEditPageMain;
