import { useState } from "react";
import { UseFormRegister } from "react-hook-form";
import "./InputWithLabel.scss";

interface InputWithLabel {
  label: string;
  labelWithVariables?: string;
  inputPlaceholder: string;
  showCalendarIcon?: boolean;
  isFirstCalendarInput?: boolean;
  marginTop?: number;
  marginBottom?: number;
  showSignsCounter?: boolean;
  maxInputLength?: number;
  type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  value?: string | number;
  onValueChange?: (value: string) => void;
  onBlur?: (value: string, index?: number) => void;
  min?: number;
  pattern?: string;
  index?: number;
  inputMode?: string;
}

const InputWithLabel = ({
  label,
  labelWithVariables,
  inputPlaceholder,
  showCalendarIcon,
  isFirstCalendarInput,
  marginTop,
  marginBottom,
  showSignsCounter,
  maxInputLength,
  register,
  type,
  value,
  onValueChange,
  min,
  onBlur,
  index,
  pattern,
  inputMode,
}: InputWithLabel) => {
  const [counter, setCounter] = useState<number>(0);

  const updateSignsCounter = (inputValue: string) => {
    setCounter(inputValue.length);
  };

  return (
    <div
      className={"common-components__input-with-label-wrapper"}
      style={{
        paddingRight: isFirstCalendarInput ? "10px" : "0px",
        paddingLeft: showCalendarIcon && !isFirstCalendarInput ? "10px" : "0px",
        marginTop: marginTop,
        marginBottom: marginBottom,
      }}
    >
      <div className={"common-components__input-with-label"}>
        <span>{labelWithVariables ? labelWithVariables : label}</span>
        {showSignsCounter && <span>{counter}/500</span>}
      </div>
      {onBlur ? (
        <input
          id={"inputWithLabel"}
          type={type}
          min={min}
          maxLength={maxInputLength}
          {...register(label)}
          onChange={(e) => {
            updateSignsCounter(e.target.value);
            if (onValueChange) {
              onValueChange(e.target.value);
            }
          }}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e.target.value, index);
            }
          }}
          pattern={pattern}
          inputMode={inputMode ? "numeric" : "text"}
          className={`${"common-components__input-with-label-input"} + ${
            showCalendarIcon &&
            "common-components__input-with-label-show-calendar-icon"
          }`}
          placeholder={inputPlaceholder}
          value={value}
        />
      ) : (
        <input
          id={"inputWithLabel"}
          type={type}
          min={min}
          maxLength={maxInputLength}
          {...register(label)}
          onChange={(e) => {
            updateSignsCounter(e.target.value);
            if (onValueChange) {
              onValueChange(e.target.value);
            }
          }}
          className={`${"common-components__input-with-label-input"} + ${
            showCalendarIcon &&
            "common-components__input-with-label-show-calendar-icon"
          }`}
          inputMode={inputMode ? "numeric" : "text"}
          placeholder={inputPlaceholder}
          value={value}
        />
      )}
    </div>
  );
};

export default InputWithLabel;
