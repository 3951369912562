export const horizontalArrowCommonWidth = 11;
export const horizontalArrowCommonHeight = 7;
export const horizontalBigArrowCommonHeight = 19;
export const verticalArrowCommonWidth = 7;
export const verticalArrowCommonHeight = 11;
export const switchButtonDiameter = 20;
export const switchHeight = 14;
export const switchWidth = 34;
export const calendarHeight = 18;
export const calendarWidth = 20;
export const HeadingAndHeaderHeight = 409;
export const FooterHeight = 72;
export const RoundIconSize = 8;
