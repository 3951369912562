import "./FiltrationsHeader.scss";
import GrayLeftArrow from "public/GrayLeftArrow.svg";
import React from "react";
import {
  horizontalBigArrowCommonHeight,
  horizontalArrowCommonWidth,
} from "../../../constants/Consts";
import { useNavigate } from "react-router-dom";
import SimpleToast from "../Toasts/SimpleToast";
import { ToastModes } from "interfaces/Enums/ToastModes";

interface IHeader {
  pageName: string;
  applyFiltersText: string;
  confimationFunction?: () => void;
  rejectFunction?: () => void;
  showConfirmationToast?: boolean;
  navigateUrl?: string;
}

const FiltrationsHeader = ({
  applyFiltersText,
  pageName,
  confimationFunction,
  rejectFunction,
  showConfirmationToast = true,
  navigateUrl,
}: IHeader) => {
  const navigate = useNavigate();
  const returnBack = () => {
    if (navigateUrl) {
      navigate(navigateUrl);
    } else {
      navigate(-1);
    }
  };
  return (
    <React.Fragment>
      <div className={"common-components__filtration-header"}>
        <div className={"common-components__filtration-header-wrapper"}>
          <div className="common-components__filtration-first-part-wrapper">
            <img
              onClick={returnBack}
              src={GrayLeftArrow}
              alt="Gray left arrow"
              width={horizontalArrowCommonWidth}
              height={horizontalBigArrowCommonHeight}
            />
            <span>{pageName}</span>
          </div>
          <div className="common-components__filtration-header-buttons">
            {confimationFunction && (
              <div
                className={
                  "common-components__filtration-header-apply-filter-text"
                }
                onClick={() => {
                  confimationFunction();
                  if (showConfirmationToast) {
                    SimpleToast({
                      mode: ToastModes.success,
                      message: "You have applied the filter settings",
                    });
                  }
                }}
              >
                {applyFiltersText}
              </div>
            )}
            {rejectFunction && (
              <div
                className={
                  "common-components__filtration-header-cancel-filter-text"
                }
                onClick={() => {
                  rejectFunction();
                  SimpleToast({
                    mode: ToastModes.info,
                    message: "You have reset the filters",
                  });
                }}
              >
                Cancel
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default FiltrationsHeader;
