import application from "../../config.json";
import { AxiosClient } from "./AxiosClient";

const fileManagement = "FileManagement";
const base = application.baseUrl;

const DeleteFile = async (fileId: string): Promise<null> => {
  return AxiosClient("DELETE", `${fileManagement}/Delete/${fileId}`, base, {
    responseIsBlob: true,
  });
};

export { DeleteFile };
