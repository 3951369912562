import { updateFileName } from "Pages/api/FileUploadClient";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import "./ChangeFileNameInput.scss";

interface IChangeFileNameInput {
  fileName: string;
  fileId: string;
  canEdit: boolean;
}

interface IUpdateFileName {
  fileName: string;
}

const ChangeFileNameInput = ({
  fileId,
  fileName,
  canEdit,
}: IChangeFileNameInput) => {
  const { register, setValue } = useForm<IUpdateFileName>();

  useEffect(() => {
    setValue("fileName", fileName);
  }, [fileName]);

  const saveFileName = async (fileName: string) => {
    await updateFileName(fileId, fileName);
  };

  return (
    <>
      {canEdit ? (
        <input
          className={"edit-file-name__input"}
          {...register("fileName", {
            onBlur: (e) => saveFileName(e.target.value),
          })}
        />
      ) : (
        <span>{fileName}</span>
      )}
    </>
  );
};

export default ChangeFileNameInput;
