import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMyTimeEntryVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVm";
import type { RootState } from "../store";

export interface IMySelectedTimeEntryState {
  timeEntry: IMyTimeEntryVm | null;
}

const initialState: IMySelectedTimeEntryState = {
  timeEntry: null,
};

export const selectedTimeEntriesSlice = createSlice({
  name: "selectedProjectMember",
  initialState,
  reducers: {
    changeSelectedTimeEntry: (
      state,
      action: PayloadAction<IMyTimeEntryVm | null>
    ) => {
      state.timeEntry = action.payload;
    },
    resetInitialValue: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
  },
});

export const { changeSelectedTimeEntry } = selectedTimeEntriesSlice.actions;

export const selectedTimeEntry = (state: RootState) => state;

export default selectedTimeEntriesSlice.reducer;
