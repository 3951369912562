import FiltrationsHeader from "components/common/FiltrationsHeader/FiltrationsHeader";
import SimpleToast from "components/common/Toasts/SimpleToast";
import ProjectFiltersPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/ProjectFiltersPageComponents/ProjectFiltersPageMain/ProjectFiltersPageMain";
import { projectsRoute } from "constants/Routes";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import {
  setBooleanFilters,
  selectFilters,
  IFiltration,
  IBooleanFiltration,
  resetProjectsFilters,
} from "store/Redux/Reducers/projectsFilterReducer";
import "./ProjectFiltersPage.scss";

const ProjectFiltersPage = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const filters = useSelector(selectFilters);
  const foundFilter = filters.projectsFiltersReducer.filters.find(
    (filter: IFiltration) => projectId && +filter.projectId === +projectId
  );

  const [completedToggleChecked, setCompletedToggleChecked] = useState<boolean>(
    foundFilter ? foundFilter.showCompleted : false
  );
  const [toDoToggleChecked, setToDoToggleChecked] = useState<boolean>(
    foundFilter ? foundFilter.showToDo : false
  );
  const [inProgressToggleChecked, setInProgressToggleChecked] =
    useState<boolean>(foundFilter ? foundFilter.showInProgress : false);
  const [startDate, setStartDate] = useState<Date | null>(
    foundFilter && foundFilter.startDate
      ? new Date(foundFilter.startDate)
      : null
  );
  const [deadlineDate, setDeadlineDate] = useState<Date | null>(
    foundFilter && foundFilter.deadline ? new Date(foundFilter.deadline) : null
  );
  const [resetFilters, setResetFilters] = useState<boolean>(false);

  const onFiltersApply = () => {
    if (projectId) {
      const changingState: IBooleanFiltration = {
        showToDo: toDoToggleChecked,
        showCompleted: completedToggleChecked,
        showInProgress: inProgressToggleChecked,
        projectId: +projectId,
      };
      dispatch(
        setBooleanFilters({
          ...changingState,
        })
      );
      navigate(`/${projectsRoute}/${projectId}`);
    } else {
      SimpleToast({
        mode: ToastModes.error,
        message: "Project id cannot be null",
      });
    }
  };

  const handleResetStates = () => {
    setDeadlineDate(null);
    setStartDate(null);
    setInProgressToggleChecked(true);
    setCompletedToggleChecked(true);
    setToDoToggleChecked(true);
    setResetFilters(false);
  };

  const cancelFiltersAppliance = () => {
    if (projectId) {
      setResetFilters(true);
      dispatch(resetProjectsFilters(+projectId));
    }
  };

  useEffect(() => {
    if (resetFilters) {
      handleResetStates();
    }
  }, [resetFilters]);

  const dispatch = useAppDispatch();

  return (
    <div className="project-filters-page__layout">
      <FiltrationsHeader
        pageName={"Filters"}
        applyFiltersText={"Apply"}
        confimationFunction={onFiltersApply}
        rejectFunction={cancelFiltersAppliance}
        navigateUrl={`/${projectsRoute}/${projectId}`}
      />
      <ProjectFiltersPageMain
        setDeadlineDate={setDeadlineDate}
        deadlineDate={deadlineDate}
        setStartDate={setStartDate}
        startDate={startDate}
        setCompletedToggleChecked={setCompletedToggleChecked}
        completedToggleChecked={completedToggleChecked}
        setInProgressToggleChecked={setInProgressToggleChecked}
        inProgressToggleChecked={inProgressToggleChecked}
        setToDoToggleChecked={setToDoToggleChecked}
        toDoToggleChecked={toDoToggleChecked}
        tasks={foundFilter?.tasks}
        users={foundFilter?.assignees}
      />
    </div>
  );
};

export default ProjectFiltersPage;
