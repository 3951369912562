import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";

export const checkProjectAccessByGreaterThan = (
  projectRoleEnum: number,
  requiredPermission: ProjectRolesEnum
) => {
  return projectRoleEnum <= requiredPermission;
};

export const checkProjectAccessBySpecifiedRoles = (
  projectRoleEnum: number,
  roles: ProjectRolesEnum[]
) => {
  return roles.includes(projectRoleEnum);
};

export const restrictProjectAccessBySpecifiedRoles = (
  projectRoleEnum: number,
  roles: ProjectRolesEnum[]
) => {
  return !roles.includes(projectRoleEnum);
};
