import SeparationLine from "components/common/SeparationLine/SeparationLine";
import { blackText, lightGreen } from "constants/JsColors";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import IdBadge from "one-common-components/lib/components/IdBadge/IdBadge";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./AddTimeEntryTaskFilterItem.scss";

interface ITaskFilterItems {
  task: IMyTaskVm | null;
  setChosenTask: React.Dispatch<React.SetStateAction<IMyTaskVm | null>>;
  isHighlighted: boolean;
}

const AddTimeEntryTaskFilterItem = ({
  task,
  setChosenTask,
  isHighlighted,
}: ITaskFilterItems) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div
        className={"time-entry-tasks__task-filter-item"}
        onClick={() => {
          setChosenTask(task);
          navigate(-1);
        }}
        style={{
          background: isHighlighted ? lightGreen : "",
          padding: "0 16px",
        }}
      >
        <div className={"time-entry-tasks__task-filter-item-left-info"}>
          <span
            className={"time-entry-tasks__task-filter-item-text"}
            style={{ color: blackText }}
          >
            {task ? task.name : "No task"}
          </span>
          {task && <IdBadge id={task.taskNumberInProject} />}
        </div>
      </div>
      <SeparationLine />
    </React.Fragment>
  );
};

export default AddTimeEntryTaskFilterItem;
