import "./NoItemContainer.scss";

interface NoItemContainer {
  imgSrc: string;
  imgAlt: string;
  imgWidth: number;
  imgHeight: number;
  firstText: string;
  secondText: string;
  button: JSX.Element | null;
}

const NoItemContainer = ({
  imgSrc,
  imgAlt,
  imgWidth,
  imgHeight,
  firstText,
  secondText,
  button,
}: NoItemContainer) => {
  return (
    <div
      id={"noTaskItemContainer"}
      className={"common-components__no-item-container"}
    >
      <img src={imgSrc} alt={imgAlt} width={imgWidth} height={imgHeight} />
      <span className={"common-components__no-item-title"}>{firstText}</span>
      <span className={"common-components__no-item-title--secondary"}>
        {secondText}
      </span>
      {button}
    </div>
  );
};

export default NoItemContainer;
