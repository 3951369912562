import { slashesDateFormat } from "constants/DateFormats";
import { useState } from "react";
import CalendarComponent from "../CalendarComponent/CalendarComponent";
import { format } from "date-fns";
import "./FromToCalendarComponents.scss";
interface IFromToCalendarComponents {
  handleSetDueDate: (date: Date | null) => void;
  handleSetStartDate: (date: Date | null) => void;
  startDate: Date | null;
  dueDate: Date | null;
  showWeekends?: boolean;
}

const FromToCalendarComponents = ({
  handleSetDueDate,
  handleSetStartDate,
  startDate,
  dueDate,
  showWeekends,
}: IFromToCalendarComponents) => {
  const [isStartDateCalendarOpen, setIsStartDateCalendarOpen] =
    useState<boolean>(false);
  const [isDueDateCalendarOpen, setIsDueDateCalendarOpen] =
    useState<boolean>(false);
  return (
    <>
      <div className={"new-task-page-main__dates-input-wrapper"}>
        <div
          className={"new-task-page-main__input-wrapper"}
          style={{
            paddingRight: "10px",
          }}
        >
          <div className={"new-task-page-main__input-wrapper-label"}>
            <span>From</span>
          </div>
          <div
            data-testid="fromButton"
            onClick={() => setIsStartDateCalendarOpen(true)}
            className={`${"new-task-page-main__input-wrapper-input"} + ${"new-task-page-main__show-calendar-icon"}`}
          >
            {startDate ? format(startDate, slashesDateFormat) : null}
          </div>
        </div>
        <div
          className={"new-task-page-main__input-wrapper"}
          style={{
            paddingLeft: "10px",
          }}
        >
          <div className={"new-task-page-main__input-wrapper-label"}>
            <span>To</span>
          </div>
          <div
            data-testid="toButton"
            className={`${"new-task-page-main__input-wrapper-input"} + ${"new-task-page-main__show-calendar-icon"}`}
            onClick={() => setIsDueDateCalendarOpen(true)}
          >
            {dueDate ? format(dueDate, slashesDateFormat) : null}
          </div>
        </div>
      </div>
      <CalendarComponent
        isOpen={isStartDateCalendarOpen}
        isOpenSetter={setIsStartDateCalendarOpen}
        date={startDate}
        onSaveClick={handleSetStartDate}
        showWeekends={showWeekends}
      />
      <CalendarComponent
        isOpen={isDueDateCalendarOpen}
        isOpenSetter={setIsDueDateCalendarOpen}
        date={dueDate}
        onSaveClick={handleSetDueDate}
        showWeekends={showWeekends}
      />
    </>
  );
};

export default FromToCalendarComponents;
