import { HttpRequestsMethods } from "../../interfaces/Enums/HttpRequestsMethods";
import { ICreateOrganizationRequest } from "../../interfaces/Requests/ICreateOrganizationRequest";
import { IMyOrganizationsVm } from "../../interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationsVm";
import { IMyOrganizationVm } from "../../interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationVm";
import application from "../../config.json";
import { AxiosClient } from "./AxiosClient";

const base = application.baseUrl;
const MyOrganizations = "MyOrganizations";

const getMyOrganizations = async (): Promise<IMyOrganizationsVm> => {
  return AxiosClient(HttpRequestsMethods.GET, `${MyOrganizations}`, base);
};

const getOrganizationByProjectId = async (
  projectId: number
): Promise<IMyOrganizationVm> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyOrganizations}/${projectId}`,
    base
  );
};

const getChosenOrganizationId = async (): Promise<number> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyOrganizations}/chosenOrganization`,
    base
  );
};

const switchOrganization = async (organizationId: number): Promise<null> => {
  return AxiosClient(
    HttpRequestsMethods.POST,
    `${MyOrganizations}/SwitchTo/${organizationId}`,
    base
  );
};

const createOrganization = async (
  body: ICreateOrganizationRequest
): Promise<number> => {
  return AxiosClient(HttpRequestsMethods.POST, `${MyOrganizations}`, base, {
    body,
  });
};

export {
  getMyOrganizations,
  getOrganizationByProjectId,
  getChosenOrganizationId,
  switchOrganization,
  createOrganization,
};
