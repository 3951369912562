import "./ChecklistsAnswerLabel.scss";

interface IChecklistAnswerLabel {
  text: string;
  position: number;
}

const ChecklistsAnswerLabel = ({ text, position }: IChecklistAnswerLabel) => {
  return (
    <div className="checklists-answers__label-font-styles">
      {position}.&nbsp;{text}
    </div>
  );
};

export default ChecklistsAnswerLabel;
