import Navigation from "components/common/Navigation/Navigation";
import { IMyOrganizationVm } from "interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationVm";
import { IMyProfileVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProfileVm";
import "./NotifiactionsBellAndMenu.scss";

interface INotifiactionsBellAndMenu {
  organizations: IMyOrganizationVm[];
  userDetails: IMyProfileVm;
  myOrganizationId: number;
}

const NotifiactionsBellAndMenu = ({
  organizations,
  userDetails,
  myOrganizationId,
}: INotifiactionsBellAndMenu) => {
  return (
    <div className={"common-components__notifications-bell-and-menu"}>
      <Navigation
        userDetails={userDetails}
        organizations={organizations}
        myOrganizationId={myOrganizationId}
      />
    </div>
  );
};

export default NotifiactionsBellAndMenu;
