import { IUpdateFormAnswers } from "interfaces/Requests/IUpdateFormAnswers";
import { IFormsTemplatesVm } from "interfaces/ViewModels/MyFormsViewModels/ITaskFormsTemplatesVm";
import application from "../../config.json";
import { HttpRequestsMethods } from "../../interfaces/Enums/HttpRequestsMethods";
import { AxiosClient } from "./AxiosClient";

const base = application.baseUrl;
const MyFormsAnswers = "MyFormsAnswers";

const updateChecklistsAnswers = async (
  body: IUpdateFormAnswers
): Promise<IFormsTemplatesVm[]> => {
  return AxiosClient(HttpRequestsMethods.PUT, `${MyFormsAnswers}`, base, {
    body,
  });
};

export { updateChecklistsAnswers };
