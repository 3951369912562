import SeparationLine from "components/common/SeparationLine/SeparationLine";
import {
  calendarHeight,
  calendarWidth,
  verticalArrowCommonHeight,
  verticalArrowCommonWidth,
} from "constants/Consts";
import React, { useState } from "react";
import "./TimeEntriesFiltersPageMain.scss";
import GrayRightArrow from "public/GrayRightArrow.svg";
import GreenRightArrow from "public/GreenRightArrow.svg";
import GrayCalendar from "public/GrayCalendar.svg";
import ToggleSwitch from "components/common/ToggleSwitch/ToggleSwitch";
import { Link, useParams } from "react-router-dom";
import {
  projectsRoute,
  filtersRoute,
  tasksRoute,
  timeEntriesRoute,
} from "constants/Routes";
import "react-datepicker/dist/react-datepicker.css";
import { useAppDispatch } from "store/Redux/hooks";
import { slashesDateFormat } from "constants/DateFormats";
import format from "date-fns/format";
import CalendarComponent from "components/common/CalendarComponent/CalendarComponent";
import FiltrationPosition from "components/common/FIltrationPosition/FiltrationPosition";
import SelectButton from "components/common/Buttons/SelectButton/SelectButton";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import {
  IDeadlineDateFiltration,
  IStartDateFiltration,
  setDeadlineDateState,
  setStartDateState,
} from "store/Redux/Reducers/timeEntriesFilterReducer";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { ToastModes } from "interfaces/Enums/ToastModes";

interface IMain {
  setApprovedToggleChecked: React.Dispatch<React.SetStateAction<boolean>>;
  approvedToggleChecked: boolean;
  setDisapprovedToggleChecked: React.Dispatch<React.SetStateAction<boolean>>;
  disapprovedToggleChecked: boolean;
  setAwaitingToggleChecked: React.Dispatch<React.SetStateAction<boolean>>;
  awaitingToggleChecked: boolean;
  setNotSendToApprovalToggleChecked: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  notSendToApprovalToggleChecked: boolean;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  startDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  tasks: IMyTaskVm[] | undefined;
}

const TimeEntriesFiltersPageMain = ({
  setApprovedToggleChecked,
  approvedToggleChecked,
  setDisapprovedToggleChecked,
  disapprovedToggleChecked,
  setAwaitingToggleChecked,
  awaitingToggleChecked,
  notSendToApprovalToggleChecked,
  setNotSendToApprovalToggleChecked,
  setStartDate,
  startDate,
  setEndDate,
  endDate,
  tasks,
}: IMain) => {
  const [isStartCalendarOpen, setIsStartCalendarOpen] =
    useState<boolean>(false);
  const { projectId } = useParams();
  const [isDeadlineCalendarOpen, setIsDeadlineCalendarOpen] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const saveStartDate = (date: Date | null) => {
    if (projectId) {
      const changingStartDate: IStartDateFiltration = {
        startDate: date ? date.toString() : null,
        projectId: +projectId,
      };
      setStartDate(date);
      dispatch(
        setStartDateState({
          ...changingStartDate,
        })
      );
    } else {
      SimpleToast({
        mode: ToastModes.error,
        message: "Project id cannot be null",
      });
    }
  };

  const saveEndDate = (date: Date | null) => {
    if (projectId) {
      const changingDeadlineDate: IDeadlineDateFiltration = {
        deadline: date ? date.toString() : null,
        projectId: +projectId,
      };
      setEndDate(date);
      dispatch(
        setDeadlineDateState({
          ...changingDeadlineDate,
        })
      );
    } else {
      SimpleToast({
        mode: ToastModes.error,
        message: "Project id cannot be null",
      });
    }
  };

  return (
    <React.Fragment>
      <div className={"time-entries-filters-page__main"}>
        <SeparationLine />
        <div onClick={() => setIsStartCalendarOpen(true)}>
          <FiltrationPosition
            title={"Start date"}
            selectButton={
              <SelectButton
                selectIcon={GrayCalendar}
                imgWidth={calendarWidth}
                imgHeight={calendarHeight}
                value={
                  startDate
                    ? format(new Date(startDate), slashesDateFormat)
                    : null
                }
                isActive={startDate !== null}
              />
            }
          />
        </div>
        <CalendarComponent
          isOpen={isStartCalendarOpen}
          isOpenSetter={setIsStartCalendarOpen}
          date={startDate}
          onSaveClick={saveStartDate}
        />
        <div onClick={() => setIsDeadlineCalendarOpen(true)}>
          <FiltrationPosition
            title={"Deadline"}
            selectButton={
              <SelectButton
                selectIcon={GrayCalendar}
                imgWidth={calendarWidth}
                imgHeight={calendarHeight}
                value={
                  endDate ? format(new Date(endDate), slashesDateFormat) : null
                }
                isActive={endDate !== null}
              />
            }
          />
        </div>
        <CalendarComponent
          isOpen={isDeadlineCalendarOpen}
          isOpenSetter={setIsDeadlineCalendarOpen}
          date={endDate}
          onSaveClick={saveEndDate}
        />
        <Link
          to={`/${projectsRoute}/${projectId}/${timeEntriesRoute}/${filtersRoute}/${tasksRoute}`}
        >
          <FiltrationPosition
            title={"Tasks"}
            selectButton={
              <SelectButton
                greenSelectIcon={GreenRightArrow}
                selectIcon={GrayRightArrow}
                imgWidth={verticalArrowCommonWidth}
                imgHeight={verticalArrowCommonHeight}
                isActive={tasks && tasks?.length > 0}
              />
            }
          />
        </Link>
        <FiltrationPosition
          title={"Show approved"}
          selectButton={
            <ToggleSwitch
              setter={setApprovedToggleChecked}
              getter={approvedToggleChecked}
            />
          }
        />
        <FiltrationPosition
          title={"Show disapproved"}
          selectButton={
            <ToggleSwitch
              setter={setDisapprovedToggleChecked}
              getter={disapprovedToggleChecked}
            />
          }
        />
        <FiltrationPosition
          title={"Show awaiting"}
          selectButton={
            <ToggleSwitch
              setter={setAwaitingToggleChecked}
              getter={awaitingToggleChecked}
            />
          }
        />
        <FiltrationPosition
          title={"Show did not send to approve"}
          selectButton={
            <ToggleSwitch
              setter={setNotSendToApprovalToggleChecked}
              getter={notSendToApprovalToggleChecked}
            />
          }
        />
      </div>
    </React.Fragment>
  );
};

export default TimeEntriesFiltersPageMain;
