import YellowVerticalUnderline from "public/YellowVerticalUnderline.svg";
import BlueVerticalUnderline from "public/BlueVerticalUnderline.svg";
import GreenVerticalUnderline from "public/GreenVerticalUnderline.svg";
import GrayVerticalUnderline from "public/GrayVerticalUnderline.svg";
import { TaskExecutionState } from "interfaces/Enums/TasksExecutionState";

export const checkTaskStateAndReturnUnderline = (
  executionState: TaskExecutionState
): string => {
  switch (executionState) {
    case TaskExecutionState.Completed: {
      return GreenVerticalUnderline;
    }
    case TaskExecutionState.InProgress: {
      return YellowVerticalUnderline;
    }
    case TaskExecutionState.ToDo: {
      return BlueVerticalUnderline;
    }
    default: {
      return GrayVerticalUnderline;
    }
  }
};
