import { Link } from "react-router-dom";
import {
  lightGreenBackground,
  whiteBackground,
  greenText,
  grayText,
} from "../../../../constants/JsColors";
import "./NavigationRouteItem.scss";

interface INavigationRouteItem {
  icon: string;
  href: string;
  location: string;
  isActive: boolean;
  activeIcon: string;
}

const NavigationRouteItem = ({
  icon,
  href,
  location,
  isActive,
  activeIcon,
}: INavigationRouteItem) => {
  return (
    <Link to={href}>
      <div
        className={"common-components__navigation-route-item-wrapper"}
        style={{
          background: isActive ? lightGreenBackground : whiteBackground,
        }}
      >
        <img
          src={isActive ? activeIcon : icon}
          alt="Dropdown down icon"
          width={20}
          height={20}
        />
        <span
          className={"common-components__navigation-route-item-location"}
          style={{
            color: isActive ? greenText : grayText,
          }}
        >
          {location}
        </span>
      </div>
    </Link>
  );
};

export default NavigationRouteItem;
