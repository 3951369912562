import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import "./TaskInfoContainer.scss";
import format from "date-fns/format";
import { slashesDateFormat } from "constants/DateFormats";
import SmallGreenDot from "public/SmallGreenDot.svg";

interface ITaskInfoContainer {
  task: IMyTaskVm;
  taskCategoryName?: string;
  imgSrc: string;
  verticalUnderline: string;
}

const TaskInfoContainer = ({
  task,
  taskCategoryName,
  imgSrc,
  verticalUnderline,
}: ITaskInfoContainer) => {
  return (
    <>
      <div className={"common-components__task-info-container-name-and-id"}>
        <div
          className={
            "common-components__task-info-container-image--custom-size"
          }
        >
          <img src={verticalUnderline} alt="Task dot" />
        </div>
        <span className={"common-components__task-info-container-title"}>
          {task.name}
        </span>
        <div
          className={"common-components__task-info-container-task-id-wrapper"}
        >
          {task.taskNumberInProject}
        </div>
      </div>
      <div
        className={"common-components__task-info-container-start-and-end-dates"}
      >
        <span className={"common-components__task-info-container-start-date"}>
          {format(new Date(task.startDate), slashesDateFormat)}
        </span>
        <div
          className={"common-components__task-info-container-end-date-wrapper"}
        >
          <img
            src={taskCategoryName === "Completed" ? SmallGreenDot : imgSrc}
            alt="Task dot"
            width={6}
            height={6}
          />
          <span className={"common-components__task-info-container-end-date"}>
            {format(new Date(task.dueDate), slashesDateFormat)}
          </span>
        </div>
      </div>
    </>
  );
};

export default TaskInfoContainer;
