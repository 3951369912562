import {
  blueBackground,
  blueText,
  greenText,
  lightGreenBackground,
  lightRed,
  redAccent,
} from "constants/JsColors";
import { ColoredBadgeColors } from "interfaces/Enums/ColoredBadgeColors";
import "./ColoredBadge.scss";

interface IColoredBadge {
  text: string;
  color: ColoredBadgeColors;
}

const ColoredBadge = ({ text, color }: IColoredBadge) => {
  const getBackground = (color: ColoredBadgeColors) => {
    switch (color) {
      case ColoredBadgeColors.red: {
        return { textColor: redAccent, backgroundColor: lightRed };
      }
      case ColoredBadgeColors.green: {
        return { textColor: greenText, backgroundColor: lightGreenBackground };
      }
      case ColoredBadgeColors.blue: {
        return { textColor: blueText, backgroundColor: blueBackground };
      }
    }
  };

  const colors = getBackground(color);

  return (
    <div
      style={{
        background: colors.backgroundColor,
      }}
      className={"common-components__colored-badge"}
    >
      <span
        style={{
          color: colors.textColor,
        }}
      >
        {text}
      </span>
    </div>
  );
};

export default ColoredBadge;
