import { ILabelValueStructure } from "interfaces/Other/ILabelValueStructure";

interface IItemHasId {
  id: number;
}

export const convertToSelectOptions = <T extends IItemHasId>(
  collection: T[]
) => {
  const commonNameCollection: ILabelValueStructure[] = [];
  collection.map((item: T) => {
    for (const prop in item) {
      if (Object.keys(item).length > 2) {
        return [];
      }
      if (Object.prototype.hasOwnProperty.call(item, prop) && prop !== "id") {
        commonNameCollection.push({
          label: item[prop] as unknown as string,
          value: item.id,
        });
      }
    }
  });
  return commonNameCollection;
};
