import { IMyTimeEntryVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVm";

export const checkArrayIfBooleanConditionIsMet = (
  filteredArray: IMyTimeEntryVm[],
  filterCondition: boolean,
  conditionToMeet: string
) => {
  return filterCondition
    ? filteredArray
    : filteredArray.filter((item) => !(item.status === conditionToMeet));
};
