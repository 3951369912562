import { IMyOrganizationsVm } from "interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationsVm";
import { IMyProfileVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProfileVm";
import { IMyProjectVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectVm";
import { IMyTimeEntriesVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntriesVm";
import { getProjectsTimeEntries } from "Pages/api/MyTimeEntriesClient";
import { useEffect, useState } from "react";
import "./TimeEntriesPage.scss";
import GrayTimeEntriesIcon from "public/GrayTimeEntriesIcon.svg";
import GreenPlusButton from "public/GreenPlusButton.svg";
import { projectsRoute, timeEntriesRoute, addRoute } from "constants/Routes";
import { useParams, Link, useLocation } from "react-router-dom";
import NotifiactionsBellAndMenu from "components/common/NotifiactionsBellAndMenu/NotifiactionsBellAndMenu";
import TimeEntriesPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/TimeEntriesPageComponents/TimeEntriesPageMain/TimeEntriesPageMain";
import { storePathValues } from "helpers/General/StorePathValue";
import { useSelector } from "react-redux";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import Footer from "components/common/Footers/Footer";
import HeaderAndCustomEndElement from "components/common/Headers/HeaderWithNotifications/HeaderAndCustomEndElement";
import NoAccessPage from "Pages/NoAccessPage/NoAccessPage";
import useViewerPageRestriction from "Hooks/UseViewerPageRestrictions";

export type PreLoadedMyProject = {
  projects: IMyProjectVm;
  organizations: IMyOrganizationsVm;
  userDetails: IMyProfileVm;
  myOrganizationId: number;
  timeEntriesVm: IMyTimeEntriesVm;
};

const TimeEntriesPage = () => {
  const globalStates = useSelector(chosenOrganization);
  const { hasPermissions } = useViewerPageRestriction();
  const [timeEntriesVm, setTimeEntriesVm] = useState<IMyTimeEntriesVm>();
  const { projectId } = useParams();
  const location = useLocation();

  const handleTimeEntriesVm = async (projectId: number) => {
    setTimeEntriesVm(await getProjectsTimeEntries(+projectId));
  };

  useEffect(() => storePathValues, [location]);

  useEffect(() => {
    if (projectId) {
      handleTimeEntriesVm(+projectId);
    }
  }, [projectId]);

  if (!hasPermissions) {
    return <NoAccessPage />;
  }

  if (
    !globalStates.allUsersOrganizationsSlice.usersOrganizations ||
    !globalStates.userProfileReducer.userProfile ||
    !timeEntriesVm ||
    !projectId
  ) {
    return <div />;
  }

  return (
    <div className={"time-entries-page__main-layout"}>
      <HeaderAndCustomEndElement
        endElement={
          <NotifiactionsBellAndMenu
            organizations={
              globalStates.allUsersOrganizationsSlice.usersOrganizations
            }
            userDetails={globalStates.userProfileReducer.userProfile}
            myOrganizationId={
              globalStates.chosenOrganizationReducer.chosenOrganizationId
            }
          />
        }
        returnUrl={`/${projectsRoute}/${projectId}`}
      />
      {timeEntriesVm.timeEntries.length === 0 ? (
        <div className={"time-entries-page__main-no-time-entries-container"}>
          <div className={"time-entries-page__main-time-entries-icon"}>
            <img
              src={GrayTimeEntriesIcon}
              alt="Right green arrow"
              width={42}
              height={49}
            />
          </div>
          <span className={"time-entries-page__main-time-entries-icon-text"}>
            You have no time entries.
          </span>
          {hasPermissions && (
            <div>
              <Link
                to={`/${projectsRoute}/${projectId}/${timeEntriesRoute}/${addRoute}`}
                className={
                  "time-entries-page__main-time-entries-plus-button--green"
                }
              >
                <img
                  src={GreenPlusButton}
                  alt="Plus button"
                  width={48}
                  height={48}
                />
              </Link>
            </div>
          )}
        </div>
      ) : (
        <>
          <TimeEntriesPageMain
            projectId={+projectId}
            timeEntriesVm={timeEntriesVm}
            hasPermissionToEditTimeEntries={hasPermissions}
          />
          <Footer
            isInProjectsPage={false}
            isInTimeEntriesPage={true}
            hasPermissionToCreate={hasPermissions}
          />
        </>
      )}
    </div>
  );
};

export default TimeEntriesPage;
