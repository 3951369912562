import isNullOrWhitespace from "./IsNullOrWhitespace";

export const separateCamelCaseWithSpaces = (text: string) => {
  if (isNullOrWhitespace(text)) {
    return "";
  }
  const updatedText = text
    .replace(/([A-Z])/g, " $1")
    .trim()
    .toLocaleLowerCase();
  return updatedText.charAt(0).toUpperCase() + updatedText.slice(1);
};
