import {
  grayBackground,
  grayText,
  greenText,
  lightGreenBackground,
  lightRed,
  redAccent,
} from "constants/JsColors";
import "./MoneyValueBadge.scss";
import GrayMoneyIcon from "public/GrayMoneyIcon.svg";
import GreenMoneyIcon from "public/GreenMoneyIcon.svg";
import RedMoneyIcon from "public/RedMoneyIcon.svg";

export enum AvailableMoneyBadgeColours {
  green = "green",
  gray = "gray",
  red = "red",
}

interface IMoneyValueBadge {
  moneySum: number;
  currency: string;
  badgeAndIconColor: AvailableMoneyBadgeColours;
}

const MoneyValueBadge = ({
  currency,
  moneySum,
  badgeAndIconColor,
}: IMoneyValueBadge) => {
  const getColorsSet = (badgeAndIconColor: AvailableMoneyBadgeColours) => {
    switch (badgeAndIconColor) {
      case "green":
        return {
          color: greenText,
          backgroundColor: lightGreenBackground,
          icon: GreenMoneyIcon,
        };
      case "gray":
        return {
          color: grayText,
          backgroundColor: grayBackground,
          icon: GrayMoneyIcon,
        };
      case "red":
        return {
          color: redAccent,
          backgroundColor: lightRed,
          icon: RedMoneyIcon,
        };
      default:
        return {
          color: grayText,
          backgroundColor: grayBackground,
          icon: GrayMoneyIcon,
        };
    }
  };

  const colorsSet = getColorsSet(badgeAndIconColor);

  return (
    <div
      style={{
        background: colorsSet.backgroundColor,
        color: colorsSet.color,
      }}
      className="money-value-badge__wrapper"
    >
      <img src={colorsSet.icon} alt={"Money symbol"} />
      {moneySum}&nbsp;{currency}
    </div>
  );
};

export default MoneyValueBadge;
