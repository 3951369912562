import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUpdateDeviationReduxStatus } from "components/common/FormStatusBar/FormStatusBar";
import DeviationVm from "interfaces/ViewModels/MyDeviationsViewModels/DeviationVm";
import type { RootState } from "../store";

export interface IMyDeviationsReducerInitialState {
  deviations: DeviationVm[];
  originalDeviations: DeviationVm[];
}

const initialState: IMyDeviationsReducerInitialState = {
  deviations: [],
  originalDeviations: [],
};

export const selectedFormSlice = createSlice({
  name: "selectedDeviation",
  initialState,
  reducers: {
    changeSelectedDeviation: (state, action: PayloadAction<DeviationVm>) => {
      const foundFormIndex = state.deviations.findIndex(
        (item) => item.id === action.payload.id
      );

      if (foundFormIndex == -1) {
        state.deviations.push(action.payload);
        state.originalDeviations.push(action.payload);
      } else {
        state.deviations.splice(foundFormIndex, 1, action.payload);
        state.originalDeviations.splice(foundFormIndex, 1, action.payload);
      }
    },
    revertChangesInDeviation: (state, action: PayloadAction<DeviationVm>) => {
      const updatedState = state.deviations.map((modifiedForm) => {
        if (action.payload.id === modifiedForm.id) {
          return action.payload;
        }
        return modifiedForm;
      });
      state.deviations = updatedState;
    },
    updateDeviationStatus: (
      state,
      action: PayloadAction<IUpdateDeviationReduxStatus>
    ) => {
      const foundDeviation = state.deviations.find(
        (item) => item.id === action.payload.formLinkId
      );

      if (!foundDeviation) {
        return;
      }
      foundDeviation.status = "COMPLETED";
    },
  },
});

export const {
  changeSelectedDeviation,
  revertChangesInDeviation,
  updateDeviationStatus,
} = selectedFormSlice.actions;

export const selectedForm = (state: RootState) => state;

export default selectedFormSlice.reducer;
