import GrayLeftArrow from "public/GrayLeftArrow.svg";
import "./ProfilePageHeader.scss";
import {
  horizontalArrowCommonWidth,
  horizontalBigArrowCommonHeight,
} from "constants/Consts";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <div className={"profile-page-components__header"}>
      <div data-testid="return-link" onClick={() => navigate(-1)}>
        <img
          src={GrayLeftArrow}
          alt="Gray left arrow"
          width={horizontalArrowCommonWidth}
          height={horizontalBigArrowCommonHeight}
        />
      </div>
      <div className={"profile-page-components__header-title"}>
        <span>My profile</span>
      </div>
    </div>
  );
};

export default Header;
