import { grayText, greenText } from "constants/JsColors";

import "./SelectButton.scss";

interface ISelectButton {
  selectIcon: string;
  greenSelectIcon?: string;
  imgWidth: number;
  imgHeight: number;
  value?: Date | string | number | boolean | null;
  isActive?: boolean;
}

const SelectButton = ({
  selectIcon,
  greenSelectIcon,
  imgHeight,
  imgWidth,
  value,
  isActive,
}: ISelectButton) => {
  return (
    <div className={"common-components__select-button"}>
      <span style={{ color: isActive ? greenText : grayText }}>
        {value ? value.toString() : "Select"}
      </span>
      {!value && (
        <div>
          <img
            src={isActive && greenSelectIcon ? greenSelectIcon : selectIcon}
            alt="Gray select icon"
            width={imgWidth}
            height={imgHeight}
          />
        </div>
      )}
    </div>
  );
};

export default SelectButton;
