import { projectsRoute } from "constants/Routes";
import ZeroAsNotFoundIcon from "public/ZeroAsNotFoundIcon.svg";
import { useNavigate } from "react-router-dom";
import "./NotFoundPage.scss";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className="not-found-page__layout">
      <div className="not-found-page__content-wrapper">
        <div className="not-found-page__title">
          <span>4</span>
          <img src={ZeroAsNotFoundIcon} alt="0" />
          <span>4</span>
        </div>
        <div className="not-found-page__explanation">
          <span>Ohh no!</span>
          <span className="not-found-page__second-explanation-text">
            The page is taking a nap! <br />
            Good reason for a coffee break.
          </span>
        </div>
        <div
          onClick={() => navigate(`/${projectsRoute}`)}
          className="not-found-page__return-button"
        >
          Back to home
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
