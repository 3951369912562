import FiltrationsHeader from "components/common/FiltrationsHeader/FiltrationsHeader";
import GrayFiltrationBar from "components/common/GrayFiltrationBar/GrayFiltrationBar";
import ListOfTasksContainer from "components/common/ListOfTasks/ListOfTasksContainer/ListOfTasksContainer";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { filtersRoute, projectsRoute } from "constants/Routes";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { IMyProjectTasksVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectTasksVm";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import { getProjectTasksWithDetails } from "Pages/api/MyProjectsClient";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import {
  IFiltration,
  ITasksState,
  selectFilters,
  setTasksState,
} from "store/Redux/Reducers/projectsFilterReducer";
import "./TasksFilterPage.scss";

const TasksFilterPage = () => {
  const { projectId } = useParams();
  const [tasks, setTasks] = useState<IMyProjectTasksVm>();
  const [allTasks, setAllTasks] = useState<IMyTaskVm[]>([]);
  const filters = useSelector(selectFilters);
  const navigate = useNavigate();
  const foundFilter = filters.projectsFiltersReducer.filters.find(
    (filter: IFiltration) => projectId && +filter.projectId === +projectId
  );

  const handleSetAllTasks = () => {
    if (tasks) {
      const allTasks = [
        ...tasks.completedTasks,
        ...tasks.inProgressTasks,
        ...tasks.toDoTasks,
      ];
      setAllTasks(allTasks);
      setFiltratedItems(allTasks);
    }
  };
  const [filtratedItems, setFiltratedItems] = useState<IMyTaskVm[]>(allTasks);
  const [chosenTasks, setChosenTasks] = useState<IMyTaskVm[]>(
    foundFilter ? foundFilter.tasks : []
  );
  const dispatch = useAppDispatch();

  const confirmStateChange = () => {
    if (projectId) {
      const changingTasks: ITasksState = {
        tasks: chosenTasks,
        projectId: +projectId,
      };
      dispatch(
        setTasksState({
          ...changingTasks,
        })
      );
      navigate(`/${projectsRoute}/${projectId}/${filtersRoute}`);
    } else {
      SimpleToast({
        mode: ToastModes.error,
        message: "Project id cannot be null",
      });
    }
  };

  const handleProjectTasksWithDetails = async (projectId: number) => {
    setTasks(await getProjectTasksWithDetails(projectId));
  };

  useEffect(() => {
    if (projectId) {
      handleProjectTasksWithDetails(+projectId);
    }
  }, []);

  useEffect(() => {
    handleSetAllTasks();
  }, [tasks]);

  if (!tasks) {
    return <div />;
  }
  return (
    <div className="tasks-filter-page__layout">
      <FiltrationsHeader
        pageName={"Task Id"}
        applyFiltersText={"Save"}
        confimationFunction={confirmStateChange}
        showConfirmationToast={false}
      />
      <main className="tasks-filter-page__main-layout">
        <GrayFiltrationBar
          originalArray={allTasks}
          setFiltratedItems={setFiltratedItems}
        />
        <ListOfTasksContainer
          tasks={filtratedItems}
          chosenTasks={chosenTasks}
          setChosenTasks={setChosenTasks}
        />
      </main>
    </div>
  );
};

export default TasksFilterPage;
