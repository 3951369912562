import "./Footer.scss";
import GreenPlusButton from "public/GreenPlusButton.svg";
import GreenHomeIcon from "public/GreenHomeIcon.svg";
import GrayHomeIcon from "public/GrayHomeIcon.svg";
import GrayTimeEntriesIcon from "public/GrayTimeEntriesIcon.svg";
import GreenTimeEntriesIcon from "public/GreenTimeEntriesIcon.svg";
import GrayModalOverlay from "../GrayModalOverlay/GrayModalOverlay";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { projectsRoute, timeEntriesRoute } from "../../../constants/Routes";
import FooterAddButtonDropdown from "./FooterAddButtonDropdown/FooterAddButtonDropdown";
interface IFooter {
  isInProjectsPage: boolean;
  isInTimeEntriesPage: boolean;
  hasPermissionToCreate: boolean;
}

const Footer = ({
  isInProjectsPage,
  isInTimeEntriesPage,
  hasPermissionToCreate,
}: IFooter) => {
  const [isGrayShadowModalOpen, setIsGrayShadowModalOpen] =
    useState<boolean>(false);
  const { projectId } = useParams();

  return (
    <div className={"common-components__footer"}>
      <GrayModalOverlay
        isGrayShadowModalOpen={isGrayShadowModalOpen}
        setIsGrayShadowModalOpen={setIsGrayShadowModalOpen}
      />
      <Link to="/projects" className={"common-components__footer-home"}>
        <img
          src={isInProjectsPage ? GreenHomeIcon : GrayHomeIcon}
          alt="Home button"
          width={25}
          height={22}
        />
      </Link>
      {hasPermissionToCreate && (
        <div className={"common-components__footer-center-button-wrapper"}>
          <div className={"common-components__footer-center-button"}>
            {isGrayShadowModalOpen && <FooterAddButtonDropdown />}
            <div onClick={() => setIsGrayShadowModalOpen(true)}>
              <img
                src={GreenPlusButton}
                alt="Plus button"
                width={48}
                height={48}
              />
            </div>
          </div>
          <div className={"common-components__footer-button--left-indent"} />
        </div>
      )}
      <Link
        to={`/${projectsRoute}/${projectId}/${timeEntriesRoute}`}
        className={"common-components__footer-time-entries"}
      >
        <img
          src={isInTimeEntriesPage ? GreenTimeEntriesIcon : GrayTimeEntriesIcon}
          alt="Time entries button"
          width={20}
          height={23}
        />
      </Link>
    </div>
  );
};

export default Footer;
