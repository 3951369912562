import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMyOrganizationVm } from "interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationVm";
import type { RootState } from "../store";

export interface IUsersAllOrganizations {
  usersOrganizations: IMyOrganizationVm[];
}

const initialState: IUsersAllOrganizations = {
  usersOrganizations: [],
};

export const allUsersOrganizationsSlice = createSlice({
  name: "allUsersOrganizations",
  initialState,
  reducers: {
    setAllUsersOrganizations: (
      state,
      action: PayloadAction<IMyOrganizationVm[]>
    ) => {
      state.usersOrganizations = action.payload;
    },
  },
});

export const { setAllUsersOrganizations } = allUsersOrganizationsSlice.actions;

export const allUsersOrganizations = (state: RootState) => state;

export default allUsersOrganizationsSlice.reducer;
