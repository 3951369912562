import SmallGrayCalendarIcon from "public/SmallGrayCalendarIcon.svg";
import "./Label.scss";

interface IDateLabel {
  text: string;
  showCalendar?: boolean;
  marginTop?: number;
  marginBottom?: number;
}

const Label = ({
  text,
  showCalendar,
  marginTop = 0,
  marginBottom = 0,
}: IDateLabel) => {
  return (
    <div
      style={{ marginTop: marginTop, marginBottom: marginBottom }}
      className={"add-time-entry__date-container-label"}
    >
      {showCalendar && (
        <div className={"add-time-entry__date-container-calendar"}>
          <img
            src={SmallGrayCalendarIcon}
            alt="Small calendar icon"
            width={13}
            height={15}
          />
        </div>
      )}
      <span className={"add-time-entry__date-container-label--small"}>
        {text}
      </span>
    </div>
  );
};

export default Label;
