import ColoredBadge from "components/common/Badges/ColoredBadge/ColoredBadge";
import UserAvatarWithCredentials from "components/common/UserAvatarWithCredentials/UserAvatarWithCredentials";
import { returnUsersCredentials } from "helpers/General/ReturnUserCredentials";
import { ColoredBadgeColors } from "interfaces/Enums/ColoredBadgeColors";
import ChecklistVm from "interfaces/ViewModels/MyChecklistsViewModels/ChecklistVm";
import "./ChecklistsAnswersHeader.scss";

interface IChecklistsAnswersHeader {
  checklist: ChecklistVm;
}

const ChecklistsAnswersHeader = ({ checklist }: IChecklistsAnswersHeader) => {
  const userCredentials = returnUsersCredentials(
    checklist.formResponsibleUserDto.firstName,
    checklist.formResponsibleUserDto.lastName,
    checklist.formResponsibleUserDto.email
  );
  return (
    <header className="checklists-answers-page-main__header">
      <div className="checklists-answers-page-main__header-info">
        <div className="checklists-answers-page-main__header-right-info">
          <span className="checklists-answers-page-main__checklist-name">
            {checklist.name}
          </span>
        </div>
      </div>
      <div className="checklists-answers-page-main__header-colored-badge">
        <ColoredBadge
          text={checklist.formCategory}
          color={ColoredBadgeColors.blue}
        />
      </div>
      <span className="checklists-answers-page-main__responsible">
        Responsible
      </span>
      <UserAvatarWithCredentials
        validatedUserCredentials={userCredentials}
        role={checklist.formResponsibleUserDto.role}
      />
    </header>
  );
};

export default ChecklistsAnswersHeader;
