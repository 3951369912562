import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import React from "react";
import ListOfTasksItem from "../ListOfTasksItem/ListOfTasksItem";
import "./ListOfTasksContainer.scss";

interface IListOfTasksContainer {
  tasks: IMyTaskVm[];
  setChosenTasks: React.Dispatch<React.SetStateAction<IMyTaskVm[]>>;
  chosenTasks: IMyTaskVm[];
}

const ListOfTasksContainer = ({
  tasks,
  chosenTasks,
  setChosenTasks,
}: IListOfTasksContainer) => {
  return (
    <div className={"list-of-tasks__main-layout"}>
      {tasks.map((task) => (
        <ListOfTasksItem
          key={task.taskNumberInProject}
          task={task}
          chosenTasks={chosenTasks}
          setChosenTasks={setChosenTasks}
        />
      ))}
    </div>
  );
};

export default ListOfTasksContainer;
