import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Toaster } from "react-hot-toast";
import auth from "./config.json";
import Auth0Provider from "Auth/auth0-provider";
import { Provider } from "react-redux";
import { store } from "store/Redux/store";
import { debounce } from "debounce";
import { saveState } from "store/Redux/browser-storage";
import GreenSuccessIcon from "public/GreenSuccessIcon.svg";
import RedErrorIcon from "public/RedErrorIcon.svg";
import { grayBackground } from "constants/JsColors";

// here we subscribe to the store changes
store.subscribe(
  // we use debounce to save the state once each 800ms
  // for better performances in case multiple changes occur in a short time
  debounce(() => {
    saveState(store.getState());
  }, 800)
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onRedirectCallback = (appState: any) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={auth.domain}
    clientId={auth.clientID}
    redirectUri={window.location.origin}
    audience={auth.audience}
    scope={auth.scope}
    cacheLocation="localstorage"
    onRedirectCallback={onRedirectCallback}
  >
    <Provider store={store}>
      <React.StrictMode>
        <Toaster
          toastOptions={{
            success: {
              style: {
                color: "#33CA75",
                backgroundColor: "#EAF9F1",
                width: "100%",
              },
              duration: 2000,
              icon: <img src={GreenSuccessIcon} />,
            },
            error: {
              style: {
                color: "#CA333D",
                backgroundColor: "#FAEBEC",
                width: "100%",
              },
              duration: 2000,
              icon: <img src={RedErrorIcon} />,
            },
            loading: {
              style: {
                color: "#black",
                backgroundColor: `${grayBackground}`,
                width: "100%",
              },
            },
          }}
        />
        <App />
      </React.StrictMode>
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// expose store when run in Cypress
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (window.Cypress) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.store = store;
}
