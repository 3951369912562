import "./TaskPageHeader.scss";
import GrayLeftArrow from "public/GrayLeftArrow.svg";
import {
  horizontalArrowCommonWidth,
  horizontalBigArrowCommonHeight,
} from "constants/Consts";
import ApprovedIcon from "public/ApprovedIcon.svg";
import GrayTaskStateIcon from "public/GrayTaskStateIcon.svg";
import YellowTaskStateIcon from "public/YellowTaskStateIcon.svg";
import DisapprovedIcon from "public/DisapprovedIcon.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectedTask } from "store/Redux/Reducers/selectedTaskReducer";
import { useMemo, useState } from "react";
import DropdownWrapper from "one-common-components/lib/components/DropdownWrapper/DropdownWrapper";
import TaskPageExecutionStateDropdown from "./TaskPageExecutionStateDropdown/TaskPageExecutionStateDropdown";
import { TaskExecutionState } from "interfaces/Enums/TasksExecutionState";
import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";

const TaskPageHeader = () => {
  const navigate = useNavigate();
  const returnBack = () => {
    navigate(-1);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const globalStates = useSelector(selectedTask);

  const getRightImageForExecutionState = () => {
    switch (globalStates.selectedTaskReducer.task?.executionState) {
      case TaskExecutionState.Completed:
        return ApprovedIcon;
      case TaskExecutionState.InProgress:
        return YellowTaskStateIcon;
      case TaskExecutionState.ToDo:
        return GrayTaskStateIcon;
      default:
        return DisapprovedIcon;
    }
  };

  const hasPermissionToEditTask = useMemo(() => {
    if (globalStates.userProfileReducer.userProfile !== null) {
      return restrictProjectAccessBySpecifiedRoles(
        globalStates.userProfileReducer.userProfile.projectRole,
        [ProjectRolesEnum.Viewer]
      );
    }
    return false;
  }, [globalStates.userProfileReducer.userProfile]);

  return (
    <div className="task-page__header-wrapper">
      <div>
        <img
          onClick={returnBack}
          src={GrayLeftArrow}
          alt="Gray left arrow"
          width={horizontalArrowCommonWidth}
          height={horizontalBigArrowCommonHeight}
        />
      </div>
      <div className="task-page__header-icons--right">
        <DropdownWrapper
          isOpen={isDropdownOpen}
          setIsOpen={setIsDropdownOpen}
          dropdownContent={
            <TaskPageExecutionStateDropdown
              setIsDropdownOpen={setIsDropdownOpen}
            />
          }
          dropdownContainer={
            <img
              onClick={() => hasPermissionToEditTask && setIsDropdownOpen(true)}
              src={getRightImageForExecutionState()}
              alt={"Approved icon"}
              width={20}
              height={20}
            />
          }
        />
      </div>
    </div>
  );
};

export default TaskPageHeader;
