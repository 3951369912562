import { useForm } from "react-hook-form";
import "./CreateOrganizationMain.scss";
import { ILabelValueStructure } from "interfaces/Other/ILabelValueStructure";
import { Dispatch, SetStateAction, useState } from "react";
import ReturnButton from "../ReturnButton/ReturnButton";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { ICreateOrganizationRequest } from "interfaces/Requests/ICreateOrganizationRequest";
import AsyncToast from "components/common/Toasts/AsyncToast";
import { createOrganization } from "Pages/api/MyOrganizationsClient";
import { useAppDispatch } from "store/Redux/hooks";
import { setChosenOrganizationIdState } from "store/Redux/Reducers/chosenOrganizationReducer";
import CreateOrganizationInputs from "components/common/CreateOrganizationInputs/CreateOrganizationInputs";
import { ICreateOrganizationFormInputs } from "interfaces/RegisterInterfaces/ICreateOrganizationFormInputs";

interface ICreateOrganizationMain {
  setMyOrganizationId: Dispatch<SetStateAction<number | undefined>>;
}

const CreateOrganizationMain = ({
  setMyOrganizationId,
}: ICreateOrganizationMain) => {
  const [countryOption, setCountryOption] = useState<ILabelValueStructure>();
  const dispatch = useAppDispatch();
  const { register, getValues } = useForm<ICreateOrganizationFormInputs>();

  const handleSubmit = async () => {
    if (countryOption && getValues()["Organization name"]) {
      const request: ICreateOrganizationRequest = {
        country: countryOption.label,
        currency: countryOption.value.toString().toLocaleUpperCase(),
        name: getValues()["Organization name"],
        url: getValues()["Organization website"],
      };
      const result = await AsyncToast(
        createOrganization(request),
        "Created organization"
      );
      setMyOrganizationId(result);
      dispatch(setChosenOrganizationIdState(result));
    } else {
      SimpleToast({
        mode: ToastModes.info,
        message: "Name and country are required",
      });
    }
  };

  return (
    <main className={"getting-started-page__create-organization-main"}>
      <div className={"getting-started-page__create-organization-main-text"}>
        <ReturnButton />
        Create organization
      </div>
      <CreateOrganizationInputs
        register={register}
        countryOption={countryOption}
        setCountryOption={setCountryOption}
      />
      <div
        data-cy="Create-organization-button"
        className={
          "getting-started-page__create-organization-main-button--green"
        }
        onClick={() => {
          handleSubmit();
        }}
      >
        Create
      </div>
    </main>
  );
};

export default CreateOrganizationMain;
