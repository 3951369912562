import "./ProjectsPageHeader.scss";
import Logo from "public/OneLogo.svg";
import { IMyProfileVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProfileVm";
import Navigation from "components/common/Navigation/Navigation";
import { IMyOrganizationVm } from "interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationVm";

interface IHeader {
  userDetails: IMyProfileVm;
  organizations: IMyOrganizationVm[];
  myOrganizationId: number;
  setMyOrganizationId?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
}

const ProjectsPageHeader = ({
  userDetails,
  organizations,
  myOrganizationId,
  setMyOrganizationId,
}: IHeader) => {
  return (
    <div className={"projects-page-components__projects-header"}>
      <div className={"projects-page-components__projects-header-wrapper"}>
        <div>
          <img
            id="test_image"
            src={Logo}
            alt="Onesoft logo"
            width={40}
            height={40}
          />
        </div>
        <div className={"projects-page-components__projects-header-title"}>
          My projects
        </div>
        <Navigation
          userDetails={userDetails}
          organizations={organizations}
          myOrganizationId={myOrganizationId}
          setMyOrganizationId={setMyOrganizationId}
        />
      </div>
    </div>
  );
};

export default ProjectsPageHeader;
