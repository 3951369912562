import "./ProjectsPageFooter.scss";
import GreenPlusButton from "public/GreenPlusButton.svg";
import { Link } from "react-router-dom";
import { projectsRoute, newProjectRoute } from "constants/Routes";

const ProjectsPageFooter = () => {
  return (
    <div className={"common-components__footer"}>
      <div className={"common-components__footer-home"} />
      <div className={"common-components__footer-center-button-wrapper"}>
        <div className={"common-components__footer-center-button"}>
          <Link to={`/${projectsRoute}/${newProjectRoute}`}>
            <div>
              <img
                src={GreenPlusButton}
                alt="Plus button"
                width={48}
                height={48}
              />
            </div>
          </Link>
        </div>
        <div className={"common-components__footer-button--left-indent"} />
      </div>
      <div className={"common-components__footer-time-entries"} />
    </div>
  );
};

export default ProjectsPageFooter;
