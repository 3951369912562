import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  modifyTasksStateForNewTimeEntry,
  selectNewTimeEntriesTasks,
} from "store/Redux/Reducers/newTimeEntryReducer";
import "./AddTimeEntryPage.scss";
import { projectsRoute, timeEntriesRoute } from "constants/Routes";

import { useAppDispatch } from "store/Redux/hooks";
import { useLocalStorage } from "Hooks/UseLocalStorage";
import { useLocation, useParams } from "react-router-dom";
import AddTimeEntryPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/TimeEntriesPageComponents/AddTimeEntryPageComponents/AddTimeEntryPageMain/AddTimeEntryPageMain";
import AddTimeEntrySwitch from "components/ProjectsComponents/ProjectPageComponents/Pages/TimeEntriesPageComponents/AddTimeEntryPageComponents/AddTimeEntrySwitch/AddTimeEntrySwitch";
import { storePathValues } from "helpers/General/StorePathValue";
import { selectedProject } from "store/Redux/Reducers/projectReducer";
import HeaderAndCustomEndElement from "components/common/Headers/HeaderWithNotifications/HeaderAndCustomEndElement";
import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import NoAccessPage from "Pages/NoAccessPage/NoAccessPage";

const AddTimeEntryPage = () => {
  const globalStates = useSelector(selectedProject);
  const [areHoursActive, setAreHoursActive] = useLocalStorage<boolean>(
    "timeEntriesAreHoursActive",
    false
  );
  const states = useSelector(selectNewTimeEntriesTasks);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const saveTasksStateToStorage = () => {
    dispatch(modifyTasksStateForNewTimeEntry(null));
  };

  useEffect(() => storePathValues, [location]);

  useEffect(() => {
    const storage = globalThis?.sessionStorage;
    if (!storage.prevPath.includes("/timeEntries/add/tasks")) {
      saveTasksStateToStorage();
      setAreHoursActive(false);
    }
    setIsLoading(false);
  }, []);

  const hasPermissionToAddTimeEntry = useMemo(() => {
    if (globalStates.userProfileReducer.userProfile !== null) {
      return restrictProjectAccessBySpecifiedRoles(
        globalStates.userProfileReducer.userProfile.projectRole,
        [
          ProjectRolesEnum.Member,
          ProjectRolesEnum.Guest,
          ProjectRolesEnum.Viewer,
        ]
      );
    }
    return false;
  }, [globalStates.userProfileReducer.userProfile]);

  if (!hasPermissionToAddTimeEntry) {
    return <NoAccessPage />;
  }

  if (!globalStates.chosenProjectReducer.project || isLoading) {
    return <div />;
  }

  return (
    <div className={"add-time-entry-page__layout"}>
      <HeaderAndCustomEndElement
        endElement={
          <AddTimeEntrySwitch
            isActive={areHoursActive}
            setIsActive={setAreHoursActive}
          />
        }
        returnUrl={`/${projectsRoute}/${projectId}/${timeEntriesRoute}`}
      />
      <AddTimeEntryPageMain
        showHours={areHoursActive}
        project={globalStates.chosenProjectReducer.project}
        selectedTask={states.newTimeEntryReducer.task}
      />
    </div>
  );
};

export default AddTimeEntryPage;
