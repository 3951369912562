import ChecklistsAnswerLabel from "components/common/ChecklistsComponents/ChecklistsAnswerLabel/ChecklistsAnswerLabel";
import { AnswerDto } from "interfaces/ViewModels/MyFormsViewModels/AnswerDto";
import ChecklistsAnswerContent from "../../ChecklistsAnswerContent/ChecklistsAnswerContent";
import CommentAnswers from "../CommentAnswers/CommentAnswers";
import "./MultipleChoiceAnswers.scss";

interface IMultipleChoiceAnswers {
  answer: AnswerDto;
}

const MultipleChoiceAnswers = ({ answer }: IMultipleChoiceAnswers) => {
  return (
    <div className="checklists-answers--margins">
      <ChecklistsAnswerLabel text={answer.header} position={answer.order} />
      {Array.isArray(answer.value) &&
        answer.value.map((answer: string) => {
          return <ChecklistsAnswerContent key={answer} text={answer} />;
        })}
      <CommentAnswers text={answer.comment} />
    </div>
  );
};

export default MultipleChoiceAnswers;
