interface IItemHasId {
  id: number;
}

export const arrayPushOrRemoveItem = <T extends IItemHasId>(
  array: T[],
  elementToCheck: T
) => {
  const itemIndex = array.findIndex((task) => task.id === elementToCheck.id);
  if (itemIndex === -1) {
    array.push(elementToCheck);
  } else {
    array.splice(itemIndex, 1);
  }
};

export const arrayPushOrRemoveString = (
  array: string[],
  elementToCheck: string
) => {
  const itemIndex = array.findIndex((item) => item === elementToCheck);
  if (itemIndex === -1) {
    array.push(elementToCheck);
  } else {
    array.splice(itemIndex, 1);
  }
};
