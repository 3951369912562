import "./TimeEntryDropdown.scss";
import GrayDot from "public/GrayDot.svg";
import GreenDot from "public/GreenDot.svg";
import RightGrayArrow from "public/RightGrayArrow.svg";
import {
  RoundIconSize,
  verticalArrowCommonHeight,
  verticalArrowCommonWidth,
} from "constants/Consts";
import { blackText, grayText } from "constants/JsColors";

interface ITimeEntryDropdown {
  isActive?: boolean;
  isRightArrowVisible?: boolean;
  firstText: string;
  secondText: string;
}

const TimeEntryDropdown = ({
  isActive,
  firstText,
  secondText,
  isRightArrowVisible = true,
}: ITimeEntryDropdown) => {
  return (
    <div className={"add-time-entry__dropdown-wrapper"}>
      <div>
        <span className={"add-time-entry__dropdown-title"}>{firstText}</span>
      </div>
      <div className={"add-time-entry__dropdown-item"}>
        <div className={"add-time-entry__dropdown-dot"}>
          <img
            src={isActive ? GreenDot : GrayDot}
            alt="Dot icon"
            width={RoundIconSize}
            height={RoundIconSize}
          />
        </div>
        <span
          className={"add-time-entry__dropdown-text--secondary"}
          style={{
            color: isActive ? blackText : grayText,
            marginRight: isRightArrowVisible ? "22px" : "29px",
          }}
        >
          {secondText}
        </span>
        {isRightArrowVisible && (
          <img
            src={RightGrayArrow}
            alt="Right arrow"
            width={verticalArrowCommonWidth}
            height={verticalArrowCommonHeight}
          />
        )}
      </div>
    </div>
  );
};

export default TimeEntryDropdown;
