import React, { useEffect, useRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "./CalendarComponent.scss";
import enGB from "date-fns/locale/en-GB";
import { LocaleTimeZone } from "constants/TimeZone";
import { isBusinessDay } from "helpers/TimeHelpers/IsBusinessDay";

interface ICalendarComponent {
  isOpen: boolean;
  isOpenSetter: React.Dispatch<React.SetStateAction<boolean>>;
  date: Date | null;
  onSaveClick: (date: Date | null) => void;
  showWeekends?: boolean;
}

const CalendarComponent = ({
  isOpen,
  isOpenSetter,
  date,
  onSaveClick,
  showWeekends = true,
}: ICalendarComponent) => {
  const calendarRef = useRef<HTMLDivElement>(null);

  registerLocale(LocaleTimeZone, enGB);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const checkIfClickedOutside = (e: { target: any }) => {
      if (
        isOpen &&
        calendarRef.current &&
        !calendarRef.current.contains(e.target)
      ) {
        isOpenSetter(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);
  const handleBusinessDays = (date: Date) => {
    if (showWeekends) {
      return true;
    }
    return isBusinessDay(date);
  };

  return (
    <React.Fragment>
      {isOpen && (
        <div
          data-testid="calendarComponent"
          ref={calendarRef}
          className={"common-components__calendar-component-wrapper"}
        >
          <div
            className={
              "common-components__calendar-component-datepicker-wrapper"
            }
          >
            <DatePicker
              locale={LocaleTimeZone}
              selected={date ? new Date(date) : new Date()}
              inline
              onChange={(date) => {
                onSaveClick(date);
                isOpenSetter(false);
              }}
              formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
              disabledKeyboardNavigation
              filterDate={(date) => handleBusinessDays(date)}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CalendarComponent;
