import application from "../../config.json";
import { HttpRequestsMethods } from "../../interfaces/Enums/HttpRequestsMethods";
import { JSONPatchDocumentReplaceString } from "../../interfaces/JSONPatchFormats/JSONPatchDocumentReplaceString";
import { ICreateTimeEntryRequest } from "../../interfaces/Requests/ICreateTimeEntryRequest";
import { IPatchMultipleTimeEntries } from "../../interfaces/Requests/IPatchMultipleTimeEntries";
import { IMyTimeEntriesVm } from "../../interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntriesVm";
import { IMyTimeEntryVm } from "../../interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVm";
import { AxiosClient } from "./AxiosClient";

const base = application.baseUrl;
const MyTimeEntries = "MyTimeEntries";

const getProjectsTimeEntries = async (
  projectId: number
): Promise<IMyTimeEntriesVm> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyTimeEntries}/${projectId}`,
    base
  );
};

const createTimeEntry = async (
  body: ICreateTimeEntryRequest
): Promise<IMyTimeEntryVm> => {
  return AxiosClient(HttpRequestsMethods.POST, `${MyTimeEntries}`, base, {
    body,
  });
};

const patchTimeEntry = async (
  body: JSONPatchDocumentReplaceString[],
  timeEntryId: number,
  projectId: number
): Promise<IMyTimeEntriesVm> => {
  return AxiosClient(
    HttpRequestsMethods.PATCH,
    `${MyTimeEntries}/Project/${projectId}/Patch/${timeEntryId}`,
    base,
    {
      body,
    }
  );
};

const patchMultipleTimeEntries = async (
  body: IPatchMultipleTimeEntries[],
  projectId: number
): Promise<IMyTimeEntriesVm> => {
  return AxiosClient(
    HttpRequestsMethods.PATCH,
    `${MyTimeEntries}/Project/${projectId}/MultiplePatch`,
    base,
    {
      body,
    }
  );
};

const updateTimeEntry = async (
  body: ICreateTimeEntryRequest,
  timeEntryId: number
): Promise<IMyTimeEntryVm> => {
  return AxiosClient(
    HttpRequestsMethods.PUT,
    `${MyTimeEntries}/${timeEntryId}`,
    base,
    {
      body,
    }
  );
};

export {
  getProjectsTimeEntries,
  createTimeEntry,
  patchTimeEntry,
  patchMultipleTimeEntries,
  updateTimeEntry,
};
