import { IFormsTemplatesVm } from "interfaces/ViewModels/MyFormsViewModels/ITaskFormsTemplatesVm";
import application from "../../config.json";
import { HttpRequestsMethods } from "../../interfaces/Enums/HttpRequestsMethods";
import { AxiosClient } from "./AxiosClient";

const base = application.baseUrl;
const MyTemplates = "MyTemplates";

const getChecklistsFormTemplates = async (
  organizationId: number
): Promise<IFormsTemplatesVm[]> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyTemplates}/Organization/${organizationId}/ChecklistTemplates`,
    base
  );
};

export { getChecklistsFormTemplates };
