import { FormAnswersCategories } from "interfaces/Enums/FormAnswersCategories";
import { AnswerDto } from "interfaces/ViewModels/MyFormsViewModels/AnswerDto";
import { GroupedAnswersDto } from "interfaces/ViewModels/MyFormsViewModels/GroupedAnswerDto";
import CostAnswers from "../../components/ProjectsComponents/ProjectPageComponents/Pages/ChecklistsPageComponents/Pages/ChecklistsAnswersPageComponents/ChecklistsAnswers/CostAnwers/CostAnswers";
import MultipleChoiceAnswers from "../../components/ProjectsComponents/ProjectPageComponents/Pages/ChecklistsPageComponents/Pages/ChecklistsAnswersPageComponents/ChecklistsAnswers/MultipleChoiceAnswers/MultipleChoiceAnswers";
import NumberFieldAnswers from "../../components/ProjectsComponents/ProjectPageComponents/Pages/ChecklistsPageComponents/Pages/ChecklistsAnswersPageComponents/ChecklistsAnswers/NumberFieldAnswers/NumberFieldAnswers";
import SingleChoiceAnswers from "../../components/ProjectsComponents/ProjectPageComponents/Pages/ChecklistsPageComponents/Pages/ChecklistsAnswersPageComponents/ChecklistsAnswers/SingleChoiceAnswers/SingleChoiceAnswers";
import TextBoxAnswers from "../../components/ProjectsComponents/ProjectPageComponents/Pages/ChecklistsPageComponents/Pages/ChecklistsAnswersPageComponents/ChecklistsAnswers/TextBoxAnswers/TextBoxAnswers";
import CostInputs from "../../components/ProjectsComponents/ProjectPageComponents/Pages/ChecklistsPageComponents/Pages/ChecklistsAnswersPageComponents/ChecklistsInputs/CostInputs/CostInputs";
import MultipleChoiceInputs from "../../components/ProjectsComponents/ProjectPageComponents/Pages/ChecklistsPageComponents/Pages/ChecklistsAnswersPageComponents/ChecklistsInputs/MultipleChoiceInputs/MultipleChoiceInputs";
import MultipleTextBoxInputs from "../../components/ProjectsComponents/ProjectPageComponents/Pages/ChecklistsPageComponents/Pages/ChecklistsAnswersPageComponents/ChecklistsInputs/MultipleTextBoxInputs/MultipleTextBoxInputs";
import NumberFieldInputs from "../../components/ProjectsComponents/ProjectPageComponents/Pages/ChecklistsPageComponents/Pages/ChecklistsAnswersPageComponents/ChecklistsInputs/NumberFieldInputs/NumberFieldInputs";
import SingleChoiceInputs from "../../components/ProjectsComponents/ProjectPageComponents/Pages/ChecklistsPageComponents/Pages/ChecklistsAnswersPageComponents/ChecklistsInputs/SingleChoiceInputs/SingleChoiceInputs";
import TextBoxInputs from "../../components/ProjectsComponents/ProjectPageComponents/Pages/ChecklistsPageComponents/Pages/ChecklistsAnswersPageComponents/ChecklistsInputs/TextBoxInputs/TextBoxInputs";

interface IStatusAnswerAndEditComponents {
  status: FormAnswersCategories;
  answerComponent: JSX.Element;
  editComponent: JSX.Element;
}

export const categoriesStatusComponents = (
  item: AnswerDto,
  userId: number,
  checklistId: number,
  group?: GroupedAnswersDto
) => {
  const formStatusComponents: IStatusAnswerAndEditComponents[] = [
    {
      status: FormAnswersCategories.SingleChoice,
      answerComponent: (
        <SingleChoiceAnswers
          key={`${item.order}, ${item.comment} ${FormAnswersCategories.SingleChoice} Answer`}
          answer={item}
        />
      ),
      editComponent: (
        <>
          <SingleChoiceInputs
            key={`${item.order}, ${item.comment} ${FormAnswersCategories.SingleChoice} Inputs`}
            answer={item}
            group={group}
            userId={userId}
            checklistId={checklistId}
          />
        </>
      ),
    },
    {
      status: FormAnswersCategories.MultipleChoice,
      answerComponent: (
        <MultipleChoiceAnswers
          answer={item}
          key={`${item.order}, ${item.comment} ${FormAnswersCategories.MultipleChoice} Answer`}
        />
      ),
      editComponent: (
        <MultipleChoiceInputs
          key={`${item.order}, ${item.comment} ${FormAnswersCategories.MultipleChoice} Inputs`}
          answer={item}
          group={group}
          userId={userId}
          checklistId={checklistId}
        />
      ),
    },
    {
      status: FormAnswersCategories.Cost,
      answerComponent: (
        <CostAnswers
          answer={item}
          key={`${item.order}, ${item.comment} ${FormAnswersCategories.Cost} Answer`}
        />
      ),
      editComponent: (
        <CostInputs
          key={`${item.order}, ${item.comment} ${FormAnswersCategories.Cost} Inputs`}
          answer={item}
          group={group}
          userId={userId}
          checklistId={checklistId}
        />
      ),
    },
    {
      status: FormAnswersCategories.MultipleTextBox,
      answerComponent: (
        <TextBoxAnswers
          answer={item}
          key={`${item.order}, ${item.comment} ${FormAnswersCategories.MultipleTextBox} Answer`}
        />
      ),
      editComponent: (
        <MultipleTextBoxInputs
          key={`${item.order}, ${item.comment} ${FormAnswersCategories.MultipleTextBox} Inputs`}
          answer={item}
          group={group}
          userId={userId}
          checklistId={checklistId}
        />
      ),
    },
    {
      status: FormAnswersCategories.NumberField,
      answerComponent: (
        <NumberFieldAnswers
          answer={item}
          key={`${item.order}, ${item.comment} ${FormAnswersCategories.NumberField} Answer`}
        />
      ),
      editComponent: (
        <NumberFieldInputs
          key={`${item.order}, ${item.comment} ${FormAnswersCategories.NumberField} Inputs`}
          answer={item}
          group={group}
          userId={userId}
          checklistId={checklistId}
        />
      ),
    },
    {
      status: FormAnswersCategories.TextBox,
      answerComponent: (
        <TextBoxAnswers
          answer={item}
          key={`${item.order}, ${item.comment} ${FormAnswersCategories.TextBox} Answer`}
        />
      ),
      editComponent: (
        <TextBoxInputs
          key={`${item.order}, ${item.comment} ${FormAnswersCategories.TextBox} Inputs`}
          answer={item}
          group={group}
          userId={userId}
          checklistId={checklistId}
        />
      ),
    },
  ];
  return formStatusComponents.find(
    (answer) => answer.status === item.formQuestionType
  );
};
