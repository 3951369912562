import application from "../../config.json";
import { HttpRequestsMethods } from "../../interfaces/Enums/HttpRequestsMethods";
import { IMyProjectChecklists } from "../../interfaces/ViewModels/MyProjectsViewModels/IMyProjectChecklists";
import { IMyProjectTasksVm } from "../../interfaces/ViewModels/MyProjectsViewModels/IMyProjectTasksVm";
import { IMyProjectVm } from "../../interfaces/ViewModels/MyProjectsViewModels/IMyProjectVm";
import { IMyProjectsVm } from "../../interfaces/ViewModels/MyTasksViewModels/IMyProjectsVm";
import { IProjectMembersViewModel } from "../../interfaces/ViewModels/MyUserViewModels/IProjectMembersViewModel";
import { ICreateProjectRequest } from "interfaces/Requests/ICreateNewProjectRequest";
import { IUpdateProjectRequest } from "interfaces/Requests/IUpdateProjectRequest";
import { AxiosClient } from "./AxiosClient";

const base = application.baseUrl;
const MyProjects = "MyProjects";

const getAllMyProjects = async (
  organizationId: number
): Promise<IMyProjectsVm> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyProjects}/organization/${organizationId}`,
    base
  );
};

const getProjectById = async (projectId: number): Promise<IMyProjectVm> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyProjects}/${projectId}`,
    base
  );
};

const getProjectMembers = async (
  projectId: number
): Promise<IProjectMembersViewModel> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyProjects}/${projectId}/members`,
    base
  );
};

const getProjectTasksWithDetails = async (
  projectId: number
): Promise<IMyProjectTasksVm> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyProjects}/${projectId}/tasks`,
    base
  );
};

const getProjectMemberTasksWithDetails = async (
  projectId: number,
  memberId: number
): Promise<IMyProjectTasksVm> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyProjects}/${projectId}/member/${memberId}/tasks`,
    base
  );
};

const getProjectChecklists = async (
  projectId: number
): Promise<IMyProjectChecklists> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyProjects}/${projectId}/checklists`,
    base
  );
};

const createProject = async (
  body: ICreateProjectRequest
): Promise<IMyProjectChecklists> => {
  return AxiosClient(HttpRequestsMethods.POST, `${MyProjects}`, base, {
    body,
  });
};

const updateProject = async (
  body: IUpdateProjectRequest,
  projectId: number
): Promise<IMyProjectChecklists> => {
  return AxiosClient(
    HttpRequestsMethods.PUT,
    `${MyProjects}/${projectId}`,
    base,
    {
      body,
    }
  );
};

export {
  getAllMyProjects,
  getProjectById,
  getProjectTasksWithDetails,
  getProjectMembers,
  getProjectChecklists,
  createProject,
  updateProject,
  getProjectMemberTasksWithDetails,
};
