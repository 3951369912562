import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import { returnUsersCredentials } from "./ReturnUserCredentials";

export interface IMyUserVmMerged {
  name: string;
  id: number;
  role: string;
}

export const mergeMembersCredentials = (
  collection: IMyUserVm[]
): IMyUserVmMerged[] => {
  const commonNameCollection: IMyUserVmMerged[] = [];

  collection.map((item: IMyUserVm) => {
    commonNameCollection.push({
      name: returnUsersCredentials(item.firstName, item.lastName, item.email),
      id: item.id,
      role: ProjectRolesEnum[item.projectRoleId],
    });
  });
  return commonNameCollection;
};
