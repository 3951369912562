import RadioButton from "components/common/Buttons/RadioButton/RadioButton";
import ChecklistsAnswerLabel from "components/common/ChecklistsComponents/ChecklistsAnswerLabel/ChecklistsAnswerLabel";
import { AnswerDto } from "interfaces/ViewModels/MyFormsViewModels/AnswerDto";
import { GroupedAnswersDto } from "interfaces/ViewModels/MyFormsViewModels/GroupedAnswerDto";
import React, { useState } from "react";
import { useAppDispatch } from "store/Redux/hooks";
import {
  updateChecklistAnswer,
  updateChecklistComment,
} from "store/Redux/Reducers/selectedChecklistsReducer";
import CommentInput from "../CommentInput/CommentInput";
import "./SingleChoiceInputs.scss";

interface ISingleChoiceInputs {
  answer: AnswerDto;
  group?: GroupedAnswersDto;
  userId: number;
  checklistId: number;
}

const SingleChoiceInputs = ({
  answer,
  userId,
  group,
  checklistId,
}: ISingleChoiceInputs) => {
  const [inputText, setInputText] = useState<string>(
    answer.comment ? answer.comment : ""
  );
  const dispatch = useAppDispatch();

  const setSingleChoiceValue = (value: string) => {
    dispatch(
      updateChecklistAnswer({ group, answer, value, userId, checklistId })
    );
  };

  const setSingleChoiceComment = (comment: string) => {
    dispatch(
      updateChecklistComment({ group, answer, comment, userId, checklistId })
    );
  };

  return (
    <div className="checklists-answers--margins">
      <ChecklistsAnswerLabel text={answer.header} position={answer.order} />
      <React.Fragment>
        {answer.possibleAnswersToChoose.map((possibleAnswer) => {
          return (
            <RadioButton
              key={possibleAnswer.order}
              text={possibleAnswer.content}
              name={answer.order.toString()}
              value={possibleAnswer.content}
              onChange={setSingleChoiceValue}
              checked={possibleAnswer.content === answer.value}
            />
          );
        })}
        <CommentInput
          onBlur={setSingleChoiceComment}
          onChange={setInputText}
          value={inputText}
        />
      </React.Fragment>
    </div>
  );
};

export default SingleChoiceInputs;
