import ChecklistVm from "interfaces/ViewModels/MyChecklistsViewModels/ChecklistVm";
import React from "react";
import { categoriesStatusComponents } from "./CategoriesStatusComponents";
import "./DifferentiateAnswersAmongTypes.scss";

export const differentiateAnswersAmongTypes = (
  checklist: ChecklistVm,
  userId: number
) => {
  return (
    <React.Fragment>
      {checklist.formAnswer.answers.map((item, index) => {
        return (
          <React.Fragment key={`${item.order}, ${item.value} ${index}`}>
            {
              categoriesStatusComponents(item, userId, checklist.id)
                ?.answerComponent
            }
          </React.Fragment>
        );
      })}
      {checklist.formAnswer.groupedAnswers.map((item, index) => {
        return (
          <React.Fragment
            key={`${item.groupIndex}, ${item.groupName} ${index}`}
          >
            <div className="title-group-name">{item.groupName}</div>
            {item.questions.map((item, index) => {
              return (
                <React.Fragment key={`${item.order}, ${item.value} ${index}`}>
                  {
                    categoriesStatusComponents(item, userId, checklist.id)
                      ?.answerComponent
                  }
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
