import { IMyTimeEntryVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVm";
import React from "react";

interface ITimeEntriesContext {
  setSelectedTimeEntries: React.Dispatch<
    React.SetStateAction<IMyTimeEntryVm[]>
  >;
  selectedTimeEntries: IMyTimeEntryVm[];
  projectId: number;
  hasPermissionToEditTimeEntries: boolean;
}
const defaultItem: ITimeEntriesContext = {
  setSelectedTimeEntries: function (): void {
    throw new Error("Function not implemented.");
  },
  selectedTimeEntries: [],
  projectId: 0,
  hasPermissionToEditTimeEntries: false,
};
export const TimeEntriesContext = React.createContext(defaultItem);
