import { IMyProfileVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProfileVm";
import { getUserProfileDetails } from "Pages/api/MyProfileClient";
import { useEffect, useState } from "react";
import ProfilePageHeader from "components/ProfilePageComponents/ProfilePageHeader/ProfilePageHeader";
import ProfilePageMain from "components/ProfilePageComponents/ProfilePageMain/ProfilePageMain";

const Profile = () => {
  const [userDetails, setUserDetails] = useState<IMyProfileVm>();
  const handleUserProfileDetails = async () => {
    setUserDetails(await getUserProfileDetails());
  };

  useEffect(() => {
    handleUserProfileDetails();
  }, []);

  if (!userDetails) {
    return <div />;
  }
  return (
    <div className={"profile-page__main-layout"}>
      <ProfilePageHeader />
      <ProfilePageMain userDetails={userDetails} />
    </div>
  );
};

export default Profile;
