import ColoredBadge from "components/common/Badges/ColoredBadge/ColoredBadge";
import DescriptionOverviewComponent from "components/common/DescriptionComponents/DescriptionOverviewComponent/DescriptionOverviewComponent";
import Label from "components/common/TimeEntriesInputs/DateContainer/DateLabel/Label";
import UserAvatarWithCredentials from "components/common/UserAvatarWithCredentials/UserAvatarWithCredentials";
import { returnUsersCredentials } from "helpers/General/ReturnUserCredentials";
import { ColoredBadgeColors } from "interfaces/Enums/ColoredBadgeColors";
import "./DeviationOverviewPageMain.scss";
import { useAppSelector } from "store/Redux/hooks";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import { format } from "date-fns";
import { slashesDateFormat } from "constants/DateFormats";
import MoneyValueBadge, {
  AvailableMoneyBadgeColours,
} from "components/common/Badges/MoneyValueBadge/MoneyValueBadge";
import GrayBadgeWithBlackText from "components/common/Badges/GrayBadgeWithBlackText/GrayBadgeWithBlackText";
import { DeleteFile } from "Pages/api/FileManagementClient";
import FormStatusBar from "components/common/FormStatusBar/FormStatusBar";
import DeviationVm from "interfaces/ViewModels/MyDeviationsViewModels/DeviationVm";
import { FormStatuses } from "interfaces/Enums/FormsStatuses";
import DownloadOverviewImagesComponent from "one-common-components/lib/components/Files/Components/DownloadSpecificComponents/DownloadOverviewImagesComponent/DownloadOverviewImagesComponent";
import { DownloadedFile } from "one-common-components/lib/components/Files/Interfaces/DownloadedFile";
import IdBadge from "one-common-components/lib/components/IdBadge/IdBadge";
import DownloadedFilesItems from "one-common-components/lib/components/Files/Components/DownloadSpecificComponents/DownloadedFilesItems/DownloadedFilesItems";
import UsersAvatarsGroup from "one-common-components/lib/components/UsersAvatarsGroup/UsersAvatarsGroup";
import { updateFileName } from "Pages/api/FileUploadClient";
interface IDeviationOverviewPageMain {
  chosenDeviation: DeviationVm;
  myFiles: DownloadedFile[];
  setMyFiles: React.Dispatch<React.SetStateAction<DownloadedFile[]>>;
  filesWereLoaded: boolean;
  hasPermissionToEditDeviation: boolean;
}

const DeviationOverviewPageMain = ({
  chosenDeviation,
  myFiles,
  setMyFiles,
  filesWereLoaded,
  hasPermissionToEditDeviation,
}: IDeviationOverviewPageMain) => {
  const validatedUserCredentials = returnUsersCredentials(
    chosenDeviation.formResponsibleUserDto.firstName,
    chosenDeviation.formResponsibleUserDto.lastName,
    chosenDeviation.formResponsibleUserDto.email
  );
  const globalStates = useAppSelector(chosenOrganization);
  const chosenOrganizationCurrency =
    globalStates.chosenOrganizationReducer.myOrganization?.currency;
  const userCredentials = returnUsersCredentials(
    chosenDeviation.assignedBy.firstName,
    chosenDeviation.assignedBy.lastName,
    chosenDeviation.assignedBy.email
  );

  const removeFile = async (file: DownloadedFile) => {
    const newFiles = [...myFiles];
    const result = newFiles.splice(
      newFiles.findIndex((item) => item.guid === file.guid),
      1
    );
    setMyFiles(newFiles);
    await DeleteFile(result[0].guid);
  };

  return (
    <div className="deviation-overview-page__wrapper">
      <FormStatusBar status={FormStatuses[chosenDeviation.status]} />
      <header className="deviation-overview-page-main__header">
        <div className="deviation-overview-page-main__title-wrapper">
          <span className="deviation-overview-page-main__header-title">
            {chosenDeviation.name}
          </span>
          <IdBadge id={chosenDeviation.id} />
        </div>
        <ColoredBadge
          text={chosenDeviation.categoryName}
          color={ColoredBadgeColors.red}
        />
        <Label text={"Responsible"} marginBottom={8} marginTop={16} />
        <UserAvatarWithCredentials
          validatedUserCredentials={validatedUserCredentials}
          role={chosenDeviation.formResponsibleUserDto.role}
        />
        <div className="deviation-overview-page__reported-by">
          <span className="deviation-overview-page__reported-by-text">
            Reported by
          </span>
          <span>
            {userCredentials}
            ,&nbsp;
            {format(new Date(chosenDeviation.assigned), slashesDateFormat)}
          </span>
        </div>

        <DescriptionOverviewComponent
          marginTop={24}
          label="Description"
          text={chosenDeviation.description}
          showSeparationLine={true}
          marginBottom={4}
        />
        <DescriptionOverviewComponent
          marginTop={24}
          label="Immediate reaction"
          text={
            chosenDeviation.immediateReaction
              ? chosenDeviation.immediateReaction
              : "-"
          }
          showSeparationLine={true}
          marginBottom={4}
        />
        <DescriptionOverviewComponent
          marginTop={24}
          label="Corrective actions"
          text={
            chosenDeviation.correctivesActions
              ? chosenDeviation.correctivesActions
              : "-"
          }
          showSeparationLine={true}
          marginBottom={4}
        />
      </header>
      <main className="deviation-overview-page-main">
        <>
          <Label text={"Files"} marginBottom={8} marginTop={16} />
          {filesWereLoaded ? (
            <>
              <DownloadedFilesItems
                myFiles={myFiles}
                removeFile={removeFile}
                isImageUploading={false}
                isReadonly
                setOtherFiles={setMyFiles}
                organizationId={
                  globalStates.chosenOrganizationReducer.chosenOrganizationId
                }
                updateFileName={updateFileName}
                hasPermissionsToEdit={hasPermissionToEditDeviation}
              />
              <DownloadOverviewImagesComponent
                marginTop={16}
                removeFile={removeFile}
                myFiles={
                  Array.isArray(myFiles)
                    ? myFiles.filter((files) =>
                        files.imageDetails.contentType.includes("image")
                      )
                    : []
                }
                setOtherFiles={setMyFiles}
                organizationId={
                  globalStates.chosenOrganizationReducer.chosenOrganizationId
                }
                hasPermissionToEdit={false}
                updateFileName={updateFileName}
              />
            </>
          ) : (
            <div className="deviation-overview-page-main__loading-text">
              Loading...
            </div>
          )}
          {chosenDeviation.formLinkUsers.length > 0 && (
            <>
              <Label text={"Assigned to"} marginBottom={8} marginTop={12} />
              <div className="deviation-overview-page-main__assignees">
                <UsersAvatarsGroup
                  users={chosenDeviation.formLinkUsers}
                  maxAvatarsNumber={3}
                  size={32}
                />
              </div>
            </>
          )}
          <Label text={"Cost implication"} marginBottom={8} marginTop={24} />
          <MoneyValueBadge
            moneySum={chosenDeviation.cost ? chosenDeviation.cost : 0}
            currency={`${chosenOrganizationCurrency}`}
            badgeAndIconColor={AvailableMoneyBadgeColours.red}
          />
          <Label text={"Time implication"} marginBottom={8} marginTop={24} />
          <GrayBadgeWithBlackText
            text={
              chosenDeviation.timeImplication
                ? chosenDeviation.timeImplication
                : 0
            }
            marginBottom={24}
          />
        </>
      </main>
    </div>
  );
};

export default DeviationOverviewPageMain;
