import CancelSaveBottomBar from "components/common/Footers/CancelSaveBottomBar/CancelSaveBottomBar";
import HeaderPageTitleAndReturn from "components/common/Headers/HeaderPageTitleAndReturn/HeaderPageTitleAndReturn";
import AsyncToast from "components/common/Toasts/AsyncToast";
import SimpleToast from "components/common/Toasts/SimpleToast";
import useViewerPageRestriction from "Hooks/UseViewerPageRestrictions";
import { FormLinksSources } from "interfaces/Enums/FormLinksSources";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { ILabelValueStructure } from "interfaces/Other/ILabelValueStructure";
import { IAddChecklistRequest } from "interfaces/Requests/IAddChecklistRequest";
import { IFormsTemplatesVm } from "interfaces/ViewModels/MyFormsViewModels/ITaskFormsTemplatesVm";
import { addChecklist } from "Pages/api/MyChecklistsClient";
import { getChecklistsFormTemplates } from "Pages/api/MyFormsClient";
import NoAccessPage from "Pages/NoAccessPage/NoAccessPage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/Redux/hooks";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import { changeSelectedChecklist } from "store/Redux/Reducers/selectedChecklistsReducer";
import "./CreateChecklistsPage.scss";
import CreateChecklistsPageMain from "./Main/CreateChecklistsPageMain";

export interface ICreateChecklistsFormInputs {
  Name: string;
}

const CreateChecklistsPage = () => {
  const [taskFormTemplates, setTaskFormTemplates] = useState<
    IFormsTemplatesVm[]
  >([]);
  const globalStates = useSelector(chosenOrganization);
  const { projectId, taskId } = useParams();
  const [source, setSource] = useState<ILabelValueStructure | undefined>();
  const [responsibleUser, setResponsibleUser] = useState<
    ILabelValueStructure | undefined
  >();
  const { hasPermissions } = useViewerPageRestriction();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleTaskFormsTemplates = async () => {
    setTaskFormTemplates(
      await getChecklistsFormTemplates(
        globalStates.chosenOrganizationReducer.chosenOrganizationId
      )
    );
  };

  useEffect(() => {
    if (projectId) {
      handleTaskFormsTemplates();
    }
  }, [projectId]);

  const assignAChecklistTemplate = async () => {
    if (responsibleUser?.value === 0 || !responsibleUser) {
      return SimpleToast({
        mode: ToastModes.info,
        message: "Responsible user is required",
      });
    }
    if (source && projectId) {
      const request: IAddChecklistRequest = {
        formId: +source.value,
        responsibleUserId: +responsibleUser.value,
        projectId: +projectId,
        sourceRefId: taskId ? taskId : `0`,
        source: taskId ? FormLinksSources.Task : undefined,
      };
      const checklist = await AsyncToast(
        addChecklist(request),
        "Checklist successfully submitted"
      );
      dispatch(changeSelectedChecklist(checklist));
      returnToPreviousPage();
    } else {
      SimpleToast({
        mode: ToastModes.info,
        message: "Checklist template required",
      });
    }
  };

  const returnToPreviousPage = () => {
    navigate(-1);
  };

  if (!hasPermissions) {
    return <NoAccessPage />;
  }

  return (
    <div className="create-checklists-page__layout">
      <HeaderPageTitleAndReturn
        returnToPreviousPage={returnToPreviousPage}
        text={"Checklists Form"}
      />
      <CreateChecklistsPageMain
        taskFormTemplates={taskFormTemplates}
        source={source}
        setSource={setSource}
        setResponsibleUser={setResponsibleUser}
        responsibleUser={responsibleUser}
      />
      <CancelSaveBottomBar
        onSaveClick={assignAChecklistTemplate}
        onCancelClick={returnToPreviousPage}
      />
    </div>
  );
};

export default CreateChecklistsPage;
