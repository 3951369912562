import React from "react";
import Checkbox from "../Checkbox/Checkbox";
import "./CheckboxItem.scss";

interface ICheckboxItem {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  text: string;
  height?: number;
}

const CheckboxItem = ({
  isActive,
  setIsActive,
  text,
  height,
}: ICheckboxItem) => {
  return (
    <div
      className={"common-components__checkbox-item-wrapper"}
      style={{ height: height }}
    >
      <Checkbox setIsActive={setIsActive} checked={isActive} />
      <span className={"common-components__checkbox-item-text"}>{text}</span>
    </div>
  );
};

export default CheckboxItem;
