import Footer from "components/common/Footers/Footer";
import HeaderAndCustomEndElement from "components/common/Headers/HeaderWithNotifications/HeaderAndCustomEndElement";
import NotifiactionsBellAndMenu from "components/common/NotifiactionsBellAndMenu/NotifiactionsBellAndMenu";
import ProjectTeamPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/ProjectTeamPageComponents/ProjectTeamPageMain";
import { projectsRoute } from "constants/Routes";
import { restrictProjectAccessBySpecifiedRoles } from "helpers/Permission/CheckProjectAccess";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import "./ProjectTeamPage.scss";

const ProjectTeamPage = () => {
  const { projectId } = useParams();
  const globalStates = useSelector(chosenOrganization);

  if (
    !globalStates.allUsersOrganizationsSlice.usersOrganizations ||
    !globalStates.userProfileReducer.userProfile ||
    !globalStates.chosenProjectReducer.projectMembers
  ) {
    return <div />;
  }

  return (
    <div className="project-team-page__layout">
      <HeaderAndCustomEndElement
        endElement={
          <NotifiactionsBellAndMenu
            organizations={
              globalStates.allUsersOrganizationsSlice.usersOrganizations
            }
            userDetails={globalStates.userProfileReducer.userProfile}
            myOrganizationId={
              globalStates.chosenOrganizationReducer.chosenOrganizationId
            }
          />
        }
        returnUrl={`/${projectsRoute}/${projectId}`}
      />
      <ProjectTeamPageMain
        projectMembers={globalStates.chosenProjectReducer.projectMembers}
      />
      <Footer
        isInProjectsPage={true}
        isInTimeEntriesPage={false}
        hasPermissionToCreate={restrictProjectAccessBySpecifiedRoles(
          globalStates.userProfileReducer.userProfile.projectRole,
          [ProjectRolesEnum.Viewer]
        )}
      />
    </div>
  );
};

export default ProjectTeamPage;
