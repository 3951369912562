import application from "../../config.json";
import { HttpRequestsMethods } from "../../interfaces/Enums/HttpRequestsMethods";
import { AxiosClient } from "./AxiosClient";

const base = application.baseUrl;
const MyOrganizationInvite = "MyOrganizationInvite";

const joinOrganization = async (inviteCode: string): Promise<number> => {
  return AxiosClient(
    HttpRequestsMethods.POST,
    `${MyOrganizationInvite}/${inviteCode}`,
    base
  );
};

export { joinOrganization };
