import ChecklistsAnswerLabel from "components/common/ChecklistsComponents/ChecklistsAnswerLabel/ChecklistsAnswerLabel";
import InputWithLabel from "components/common/SingleInputsWithLabel/InputWithLabel/InputWithLabel";
import { AnswerDto } from "interfaces/ViewModels/MyFormsViewModels/AnswerDto";
import { GroupedAnswersDto } from "interfaces/ViewModels/MyFormsViewModels/GroupedAnswerDto";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "store/Redux/hooks";
import {
  updateChecklistAnswer,
  updateChecklistComment,
} from "store/Redux/Reducers/selectedChecklistsReducer";
import CommentInput from "../CommentInput/CommentInput";
import "./NumberFieldInputs.scss";

interface INumberFieldInputs {
  answer: AnswerDto;
  userId: number;
  group?: GroupedAnswersDto;
  checklistId: number;
}

interface INumberInput {
  Answer: string;
}

const NumberFieldInputs = ({
  answer,
  userId,
  group,
  checklistId,
}: INumberFieldInputs) => {
  const [inputText, setInputText] = useState<string>(
    answer.comment ? answer.comment : ""
  );
  const dispatch = useAppDispatch();
  const { register, setValue } = useForm<INumberInput>();

  const setNumberFieldInputValue = (value: string) => {
    dispatch(
      updateChecklistAnswer({ group, answer, value, userId, checklistId })
    );
  };

  const setNumberFieldComment = (comment: string) => {
    dispatch(
      updateChecklistComment({ group, answer, comment, userId, checklistId })
    );
  };

  useEffect(() => {
    setValue("Answer", answer.value ? answer.value.toString() : "");
  }, []);

  return (
    <div className="checklists-answers--margins">
      <ChecklistsAnswerLabel text={answer.header} position={answer.order} />
      <InputWithLabel
        register={register}
        label={"Answer"}
        inputPlaceholder={"Enter Your Answer [number]"}
        onBlur={setNumberFieldInputValue}
        type={"number"}
        min={0}
        inputMode={"numeric"}
        pattern="[0-9]*"
        marginBottom={16}
      />
      <CommentInput
        onBlur={setNumberFieldComment}
        onChange={setInputText}
        value={inputText}
      />
    </div>
  );
};

export default NumberFieldInputs;
