import "./DropdownPosition.scss";
import SmallGreenPlusIcon from "public/SmallGreenPlusIcon.svg";

interface IDropdownPosition {
  text: string;
  pathname?: string;
}

const DrodpownPosition = ({ text }: IDropdownPosition) => {
  return (
    <div className={"common-components__dropdown-position-item-wrapper"}>
      <img src={SmallGreenPlusIcon} alt="Plus icon" width={12} height={12} />
      <span className={"common-components__dropdown-position-text"}>
        {text}
      </span>
    </div>
  );
};

export default DrodpownPosition;
