import toast from "react-hot-toast";
import RedErrorIcon from "public/RedErrorIcon.svg";

export const errorToast = (message: string) => {
  return toast(message, {
    duration: 2000,
    icon: <img src={RedErrorIcon} />,
    style: {
      color: "#CA333D",
      backgroundColor: "#FAEBEC",
      width: "100%",
    },
  });
};
