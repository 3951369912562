import SeparationLine from "components/common/SeparationLine/SeparationLine";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { separateTimeEntriesIntoPeriodSections } from "helpers/TimeEntriesHelpers/SeparateTimeEntriesIntoPeriodSections";
import { TimeEntryStatuses } from "interfaces/Enums/TimeEntryStatuses";
import { ToastModes } from "interfaces/Enums/ToastModes";
import { IPatchMultipleTimeEntries } from "interfaces/Requests/IPatchMultipleTimeEntries";
import { IMyTimeEntriesVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntriesVm";
import { IMyTimeEntriesWeekPeriods } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntriesWeekPeriods";
import { IMyTimeEntryVm } from "interfaces/ViewModels/MyTimeEntriesViewModels/IMyTimeEntryVm";
import { patchMultipleTimeEntries } from "Pages/api/MyTimeEntriesClient";
import { useParams } from "react-router-dom";
import ApprovalItem from "./ApprovalItem/ApprovalItem";
import "./ApprovalModalContent.scss";

interface IApprovalModalContent {
  selectedTimeEntries: IMyTimeEntryVm[];
  closeApprovalModal: () => void;
  setSelectedTimeEntries: React.Dispatch<
    React.SetStateAction<IMyTimeEntryVm[]>
  >;
  setSeparatedTimeEntries: React.Dispatch<
    React.SetStateAction<IMyTimeEntriesWeekPeriods>
  >;
  timeEntriesVm: IMyTimeEntriesVm;
}

const ApprovalModalContent = ({
  selectedTimeEntries,
  timeEntriesVm,
  closeApprovalModal,
  setSelectedTimeEntries,
  setSeparatedTimeEntries,
}: IApprovalModalContent) => {
  const { projectId } = useParams();
  const updateArrayLocally = (timeEntries: IMyTimeEntryVm[]) => {
    const result = timeEntriesVm.timeEntries.map((timeEntry) => {
      if (timeEntries.includes(timeEntry)) {
        timeEntry.status = TimeEntryStatuses.Awaiting;
        return timeEntry;
      }
      return timeEntry;
    });
    return result;
  };

  const approveTimeEntries = async () => {
    if (projectId) {
      const request: IPatchMultipleTimeEntries[] = [];
      selectedTimeEntries.map((timeEntry: IMyTimeEntryVm): void => {
        const JSONrequest = [
          {
            op: "replace",
            path: "/status",
            value: TimeEntryStatuses.Awaiting,
          },
        ];
        request.push({
          timeEntryId: timeEntry.id,
          patchDocument: JSONrequest,
        });
      });
      await patchMultipleTimeEntries(request, +projectId);
      closeApprovalModal();
      SimpleToast({
        mode: ToastModes.success,
        message: "Time entries were sent to approval",
      });
      setSelectedTimeEntries([]);
      const result = updateArrayLocally(selectedTimeEntries);
      setSeparatedTimeEntries(separateTimeEntriesIntoPeriodSections(result));
    }
  };

  return (
    <div>
      <header className={"time-entries__approval-modal-content-header"}>
        Time entries to approval
      </header>
      <SeparationLine marginTop={16} marginBottom={11} />
      <main className={"time-entries__approval-modal-content-main"}>
        {selectedTimeEntries.map((item) => {
          return <ApprovalItem item={item} key={item.id} />;
        })}
      </main>
      <footer className={"time-entries__approval-modal-content-footer"}>
        <div
          onClick={() => {
            closeApprovalModal();
          }}
          className={"time-entries__approval-modal-content-cancel-button"}
        >
          Cancel
        </div>
        <div
          onClick={() => approveTimeEntries()}
          className={"time-entries__approval-modal-content-confirm-button"}
        >
          Send
        </div>
      </footer>
    </div>
  );
};

export default ApprovalModalContent;
