import HeaderPageTitleAndReturn from "components/common/Headers/HeaderPageTitleAndReturn/HeaderPageTitleAndReturn";
import NewOrganizationPageMain from "components/NewOrganizationPageComponents/NewOrganizationPageMain";
import { useNavigate } from "react-router-dom";
import "./NewOrganizationPage.scss";

const NewOrganizationPage = () => {
  const navigate = useNavigate();
  const returnToPreviousPage = () => {
    navigate(-1);
  };
  return (
    <div className="new-organization-page__layout">
      <HeaderPageTitleAndReturn
        returnToPreviousPage={returnToPreviousPage}
        text={"Create organization"}
      />
      <NewOrganizationPageMain />
    </div>
  );
};

export default NewOrganizationPage;
