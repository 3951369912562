import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import "./ProjectTaskSectionModule.scss";
import TaskInfoContainer from "components/common/TaskInfoContainer/TaskInfoContainer";
import { Link, useParams } from "react-router-dom";
import { projectsRoute, taskRoute } from "constants/Routes";
import { useAppDispatch } from "store/Redux/hooks";
import { changeSelectedTask } from "store/Redux/Reducers/selectedTaskReducer";

interface ITasksSectionItem {
  tasks: IMyTaskVm[];
  imgSrc: string;
  verticalUnderline: string;
  taskCategoryName: string;
}

const TasksSectionModule = ({
  tasks,
  imgSrc,
  verticalUnderline,
  taskCategoryName,
}: ITasksSectionItem) => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const saveTaskToRedux = (task: IMyTaskVm) => {
    dispatch(changeSelectedTask(task));
  };

  return (
    <div className={"project-page__tasks-list--gray-background"}>
      {tasks.map((task) => (
        <Link
          to={`/${projectsRoute}/${projectId}/${taskRoute}/${task.id}`}
          key={task.taskNumberInProject}
          className={"common-components__task-info-container-wrapper"}
          onClick={() => saveTaskToRedux(task)}
        >
          <TaskInfoContainer
            task={task}
            taskCategoryName={taskCategoryName}
            imgSrc={imgSrc}
            verticalUnderline={verticalUnderline}
          />
        </Link>
      ))}
    </div>
  );
};

export default TasksSectionModule;
