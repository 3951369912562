import { useSelector } from "react-redux";
import {
  changeSelectedTaskDueDate,
  changeSelectedTaskStartDate,
  updateSelectedTaskDescription,
  updateSelectedTaskTitle,
} from "store/Redux/Reducers/selectedTaskReducer";
import GrayCalendarIcon from "public/GrayCalendarIcon.svg";
import { format } from "date-fns";
import { slashesDateFormat } from "constants/DateFormats";
import "./TaskPageMain.scss";
import GrayAddUserIcon from "public/GrayAddUserIcon.svg";
import SeparationLine from "components/common/SeparationLine/SeparationLine";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  updateTaskDescription,
  updateTaskDates,
  updateTaskName,
} from "Pages/api/MyTasksClient";
import { ToastModes } from "interfaces/Enums/ToastModes";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { useNavigate, useParams } from "react-router-dom";
import {
  checklistsRoute,
  deviationsRoute,
  projectsRoute,
  taskRoute,
  usersRoute,
} from "constants/Routes";
import { useAppDispatch } from "store/Redux/hooks";
import React from "react";
import {
  fixTextareaHeight,
  fixTextareaHeightOnChange,
} from "helpers/General/FixTextareaHeight";
import UsersAvatarsGroup from "one-common-components/lib/components/UsersAvatarsGroup/UsersAvatarsGroup";
import { addFile } from "helpers/Files/AddNewFileRequest";
import { DeleteFile } from "Pages/api/FileManagementClient";
import { FileModuleEnum } from "one-common-components/lib/enums/FileModuleEnum";
import UnderlinedNavigation from "components/common/UnderlinedNavigation/UnderlinedNavigation";
import AddFileButton from "components/common/Buttons/AddFileButton/AddFileButton";
import { useLocalStorage } from "Hooks/UseLocalStorage";
import { getTaskChecklists } from "Pages/api/MyChecklistsClient";
import ChecklistVm from "interfaces/ViewModels/MyChecklistsViewModels/ChecklistVm";
import DeviationVm from "interfaces/ViewModels/MyDeviationsViewModels/DeviationVm";
import { getTaskDeviations } from "Pages/api/MyDeviationsClient";
import TasksChecklists from "../FormsComponent/TasksChecklists/TasksChecklists";
import TasksDeviations from "../FormsComponent/TasksDeviations/TasksDeviations";
import { DownloadedFile } from "one-common-components/lib/components/Files/Interfaces/DownloadedFile";
import IdBadge from "one-common-components/lib/components/IdBadge/IdBadge";
import DownloadOverviewImagesComponent from "one-common-components/lib/components/Files/Components/DownloadSpecificComponents/DownloadOverviewImagesComponent/DownloadOverviewImagesComponent";
import DownloadedFilesItems from "one-common-components/lib/components/Files/Components/DownloadSpecificComponents/DownloadedFilesItems/DownloadedFilesItems";
import isNullOrWhitespace from "helpers/General/IsNullOrWhitespace";
import CalendarComponent from "components/common/CalendarComponent/CalendarComponent";
import { isAfter } from "date-fns";
import SmallGreenDot from "public/SmallGreenDot.svg";
import SmallGrayDot from "public/SmallGrayDot.svg";
import { updateFileName, uploadFile } from "Pages/api/FileUploadClient";
import AddFileFromProjectDocuments from "components/common/AddFileFromProjectDocuments/AddFileFromProjectDocuments";
import { ILabelValueStructure } from "interfaces/Other/ILabelValueStructure";
import { downloadFile } from "Pages/api/FileDownloadClient";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import { IUpdateTaskDateRequest } from "interfaces/Requests/IUpdateTaskDateRequest";
import { calculateDifferenceInBusinessDays } from "helpers/TimeHelpers/CalculateDifferenceInDays";
interface ITaskFormInputs {
  description: string;
  myFiles: DownloadedFile[];
  taskName: string;
}

interface ITaskPageMain {
  myFiles: DownloadedFile[];
  setMyFiles: React.Dispatch<React.SetStateAction<DownloadedFile[]>>;
  filesWereLoaded: boolean;
}

const TaskPageMain = ({
  myFiles,
  setMyFiles,
  filesWereLoaded,
}: ITaskPageMain) => {
  const globalStates = useSelector(chosenOrganization);
  const task = globalStates.selectedTaskReducer.task;
  const descriptionMaxLength = 75;
  const [expandDescription, setExpandDescription] = useState<boolean>(
    task && task.description.length >= descriptionMaxLength ? false : true
  );
  const [saveButtonActive, setSaveButtonActive] = useState<boolean>(false);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [checklists, setChecklists] = useState<ChecklistVm[]>();
  const [deviations, setDeviations] = useState<DeviationVm[]>();
  const [taskName, setTaskName] = useState<string>(task ? task.name : "");
  const [areChecklistsActive, setAreChecklistsActive] =
    useLocalStorage<boolean>("areChecklistsActive", true);
  const [isNameAlterable, setIsNameAlterable] = useState<boolean>(false);
  const [isTaskDueDateCalendarOpen, setIsTaskDueDateCalendarOpen] =
    useState<boolean>(false);
  const [isTaskStartDateCalendarOpen, setIsTaskStartDateCalendarOpen] =
    useState<boolean>(false);
  const [taskDueDate, setTaskDueDate] = useState<Date>(
    task ? new Date(task.dueDate) : new Date()
  );
  const [taskStartDate, setTaskStartDate] = useState<Date>(
    task ? new Date(task.startDate) : new Date()
  );
  const { projectId, taskId } = useParams();
  const { register, getValues, setValue } = useForm<ITaskFormInputs>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const saveTaskChanges = async () => {
    const description = getValues("description");
    if (description && task) {
      await updateTaskDescription(task.id, description);
      setSaveButtonActive(false);
      dispatch(updateSelectedTaskDescription(description));
      setExpandDescription(description.length >= descriptionMaxLength);
    } else {
      SimpleToast({ mode: ToastModes.error, message: "Decription is empty" });
    }
  };

  const redirectToTaskUsersPage = () => {
    navigate(
      `/${projectsRoute}/${projectId}/${taskRoute}/${task?.id}/${usersRoute}`
    );
  };

  const handleAssignedForms = async (taskId: number, projectId: number) => {
    setChecklists(await getTaskChecklists(taskId));
    setDeviations(await getTaskDeviations(taskId, projectId));
  };

  const removeFile = async (file: DownloadedFile) => {
    const newFiles = [...myFiles];
    const result = newFiles.splice(
      newFiles.findIndex((item) => item.guid === file.guid),
      1
    );
    setMyFiles(newFiles);
    await DeleteFile(result[0].guid);
  };

  useEffect(() => {
    if (taskId && projectId) {
      handleAssignedForms(+taskId, +projectId);
      setValue("description", task ? task.description : "");
      fixTextareaHeight();
    }
  }, [taskId]);

  useEffect(() => {
    fixTextareaHeightOnChange();
  }, [getValues("description")]);

  useEffect(() => {
    setValue("taskName", task ? task.name : "");
  }, []);

  const handleExpandDescription = () => {
    if (task) {
      setExpandDescription(true);
      setValue("description", task.description);
      setExpandDescription(
        task && task.description.length >= descriptionMaxLength
      );
      fixTextareaHeight();
    }
  };

  const handleShortenDescription = () => {
    if (task) {
      setExpandDescription(false);
      setValue("description", task.description.slice(0, descriptionMaxLength));
      fixTextareaHeight();
    }
  };

  useEffect(() => {
    handleShortenDescription();
  }, []);

  const handleClickOnTextarea = () => {
    if (task && !saveButtonActive) {
      setSaveButtonActive(true);
      setValue("description", task.description);
      fixTextareaHeight();
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setIsImageUploading(true);
      const existsInCollection: DownloadedFile | undefined = myFiles.find(
        (item) => item.imageDetails.fileDownloadName === acceptedFiles[0].name
      );
      if (existsInCollection) {
        setIsImageUploading(false);
        return SimpleToast({
          mode: ToastModes.info,
          message: "There is already a file named like that.",
        });
      }
      if (!taskId) {
        setIsImageUploading(false);
        return navigate("/error");
      }
      if (!projectId) {
        return SimpleToast({
          mode: ToastModes.error,
          message: "Project id was not found",
        });
      }
      const result = await addFile(
        +taskId,
        acceptedFiles[0],
        FileModuleEnum.Tasks,
        globalStates.chosenOrganizationReducer.chosenOrganizationId,
        +projectId
      );
      const mappedCreatedObject: DownloadedFile = {
        imageDetails: {
          contentType: result.contentType,
          enableRangeProcessing: true,
          entityTag: "",
          fileContents: result.content,
          fileDownloadName: result.fileName,
        },
        guid: result.guid,
        createdOn: new Date(),
        groupIndex: "",
        questionIndex: "",
      };
      setMyFiles([...myFiles, mappedCreatedObject]);
      setIsImageUploading(false);
    },
    [myFiles]
  );

  const handleChecklistsClick = () => {
    setAreChecklistsActive(true);
  };

  const handleDeviationsClick = () => {
    setAreChecklistsActive(false);
  };

  const redirectToTaskChecklistsPage = () => {
    navigate(
      `/${projectsRoute}/${projectId}/${taskRoute}/${taskId}/${checklistsRoute}`
    );
  };

  const redirectToTaskDeviationsPage = () => {
    navigate(
      `/${projectsRoute}/${projectId}/${taskRoute}/${taskId}/${deviationsRoute}`
    );
  };

  const makeNameAlterable = () => {
    setIsNameAlterable(true);
  };

  const saveTaskName = async (value: string) => {
    setIsNameAlterable(false);
    if (!task) {
      navigate("/error");
      return;
    }
    if (isNullOrWhitespace(value)) {
      value = "No name";
    }
    setTaskName(value);
    await updateTaskName(task.id, value);
    dispatch(updateSelectedTaskTitle(value));
  };

  const openDueDateCalendarDropdown = () => {
    setIsTaskDueDateCalendarOpen(true);
  };

  const openStartDateCalendarDropdown = () => {
    setIsTaskStartDateCalendarOpen(true);
  };

  const handleChangeDueDateInTask = async (date: Date | null) => {
    if (!date || !task) {
      navigate("/error");
      return;
    }
    if (!isAfter(new Date(date), new Date(task.startDate))) {
      return SimpleToast({
        mode: ToastModes.error,
        message: "You cannot set due date before start date",
      });
    }
    setTaskDueDate(date);
    dispatch(changeSelectedTaskDueDate(date.toString()));
    const request: IUpdateTaskDateRequest = {
      startDate: new Date(task.startDate),
      dueDate: new Date(date),
      duration:
        calculateDifferenceInBusinessDays(
          new Date(task.startDate),
          new Date(date)
        ) + 1,
    };
    await updateTaskDates(task.id, request);
  };

  const handleAddItemToList = async (option: ILabelValueStructure) => {
    if (!projectId) {
      return SimpleToast({
        mode: ToastModes.error,
        message: "Project id was not found",
      });
    }
    if (!task) {
      return SimpleToast({
        mode: ToastModes.error,
        message: "Task was not found",
      });
    }
    setIsImageUploading(true);
    downloadFile(option.value.toString()).then(async (fileBlob) => {
      const formData = new FormData();
      formData.append("FormFile", fileBlob);
      formData.append("FileName", option.label);
      formData.append(
        "OrganizationId",
        globalStates.chosenOrganizationReducer.chosenOrganizationId.toString()
      );
      formData.append("FileModule", `${FileModuleEnum.Tasks}`);
      formData.append("RefId", `${task.id}`);
      projectId && formData.append("ProjectId", projectId.toString());
      const result = await uploadFile(formData);
      const mappedCreatedObject: DownloadedFile = {
        imageDetails: {
          contentType: result.contentType,
          enableRangeProcessing: true,
          entityTag: "",
          fileContents: result.content,
          fileDownloadName: result.fileName,
        },
        guid: result.guid,
        createdOn: new Date(),
        groupIndex: "",
        questionIndex: "",
      };
      setMyFiles([...myFiles, mappedCreatedObject]);
      setIsImageUploading(false);
    });
  };

  const handleChangeStartDateInTask = async (date: Date | null) => {
    if (!date || !task) {
      navigate("/error");
      return;
    }
    if (!isAfter(new Date(task.dueDate), new Date(date))) {
      return SimpleToast({
        mode: ToastModes.error,
        message: "You cannot set start date before due date",
      });
    }
    setTaskStartDate(date);
    dispatch(changeSelectedTaskStartDate(date.toString()));
    const request: IUpdateTaskDateRequest = {
      startDate: new Date(date),
      dueDate: new Date(task.dueDate),
      duration:
        calculateDifferenceInBusinessDays(
          new Date(date),
          new Date(task.dueDate)
        ) + 1,
    };
    await updateTaskDates(task.id, request);
  };

  const hasPermissionToCreateTask = useMemo(() => {
    return globalStates.chosenProjectReducer.project
      ? globalStates.chosenProjectReducer.project.canCreateTasks
      : false;
  }, [globalStates.chosenProjectReducer.project]);

  return (
    <main className="task-page__main">
      <div className="task-page__main-wrapper">
        <div className="task-page__title-wrapper">
          {!isNameAlterable ? (
            <>
              {task?.canEditTask ? (
                <span className="task-page__title" onClick={makeNameAlterable}>
                  {taskName}
                </span>
              ) : (
                <span className="task-page__title">{taskName}</span>
              )}
            </>
          ) : (
            <input
              className={"task-page__name-input"}
              {...register("taskName", {
                onBlur: (e) => saveTaskName(e.target.value),
              })}
            />
          )}
          <IdBadge id={task ? task.taskNumberInProject : 0} />
        </div>
        <div>
          <div
            className="task-page__calendar-wrapper-first"
            onClick={() => task?.canEditTask && openStartDateCalendarDropdown()}
          >
            <img src={SmallGrayDot} alt="Task dot" width={8} height={8} />
            <img src={GrayCalendarIcon} alt="Calendar icon" />
            <span>
              {task && format(new Date(task.startDate), slashesDateFormat)}
            </span>
          </div>
          <div className="task-page__calendar-start-date-component-wrapper">
            <CalendarComponent
              isOpen={isTaskStartDateCalendarOpen}
              isOpenSetter={setIsTaskStartDateCalendarOpen}
              date={taskStartDate}
              onSaveClick={handleChangeStartDateInTask}
              showWeekends={false}
            />
          </div>
          <div
            className="task-page__calendar-wrapper"
            onClick={() => task?.canEditTask && openDueDateCalendarDropdown()}
          >
            <img src={SmallGreenDot} alt="Task dot" width={8} height={8} />
            <img src={GrayCalendarIcon} alt="Calendar icon" />
            <span>
              {task && format(new Date(task.dueDate), slashesDateFormat)}
            </span>
          </div>
          <div className="task-page__calendar-due-date-component-wrapper">
            <CalendarComponent
              isOpen={isTaskDueDateCalendarOpen}
              isOpenSetter={setIsTaskDueDateCalendarOpen}
              date={taskDueDate}
              onSaveClick={handleChangeDueDateInTask}
              showWeekends={false}
            />
          </div>
        </div>
      </div>
      <div className="task-page__main-assigned-to-part">
        <span>Assigned to</span>
        <div className="task-page__main-assigned-to--icon">
          <UsersAvatarsGroup
            size={32}
            users={task ? task.assignedUsers : []}
            maxAvatarsNumber={3}
          />
          {hasPermissionToCreateTask && (
            <img
              src={GrayAddUserIcon}
              onClick={redirectToTaskUsersPage}
              style={{
                marginLeft: task && task.assignedUsers.length > 0 ? "16px" : "",
              }}
              alt={"Add user icon"}
            />
          )}
        </div>
      </div>
      <div className="task-page__main-description-part">
        <span className="task-page__main-description-part-text">
          Description
        </span>
        <SeparationLine />
        {task?.canEditTask ? (
          <>
            <textarea
              id="textarea"
              onClick={handleClickOnTextarea}
              placeholder={"Tap here to add a description"}
              className="task-page__main-no-description"
              {...register("description")}
            />
            {getValues("description") &&
              !saveButtonActive &&
              getValues("description").length >= descriptionMaxLength && (
                <React.Fragment>
                  {!expandDescription ? (
                    <span onClick={handleExpandDescription}>Show more</span>
                  ) : (
                    <span onClick={handleShortenDescription}>Show less</span>
                  )}
                </React.Fragment>
              )}
            {saveButtonActive && (
              <div
                className="task-page__main-add-button"
                onClick={saveTaskChanges}
              >
                Save description
              </div>
            )}
          </>
        ) : (
          <div className="task-page__no-permission-description">
            {getValues("description")}
          </div>
        )}
      </div>
      <div className="task-page__main-files-part">
        <span className="task-page__main-files-part-text">Files</span>
        <SeparationLine marginBottom={16} />
        {task?.canEditTask && (
          <AddFileFromProjectDocuments
            handleAddItemToList={handleAddItemToList}
          />
        )}
        {filesWereLoaded && task?.canEditTask && (
          <AddFileButton onDrop={onDrop} />
        )}
        <div>
          {filesWereLoaded ? (
            <>
              <DownloadedFilesItems
                myFiles={myFiles}
                removeFile={removeFile}
                isImageUploading={isImageUploading}
                setOtherFiles={setMyFiles}
                organizationId={
                  globalStates.chosenOrganizationReducer.chosenOrganizationId
                }
                updateFileName={updateFileName}
                hasPermissionsToEdit={task?.canEditTask}
                isReadonly={!task?.canEditTask}
              />
              <DownloadOverviewImagesComponent
                marginTop={16}
                removeFile={removeFile}
                myFiles={myFiles.filter((files) =>
                  files.imageDetails.contentType.includes("image")
                )}
                setOtherFiles={setMyFiles}
                organizationId={
                  globalStates.chosenOrganizationReducer.chosenOrganizationId
                }
                updateFileName={updateFileName}
                hasPermissionToEdit={task?.canEditTask}
              />
            </>
          ) : (
            <div className="task-page__loading-text">Loading...</div>
          )}
        </div>
        <div className="task-page__main-forms">
          <UnderlinedNavigation
            marginTop={12}
            isActive={areChecklistsActive}
            label={"Checklists"}
            onClick={handleChecklistsClick}
          />
          <UnderlinedNavigation
            marginTop={12}
            isActive={!areChecklistsActive}
            label={"Deviations"}
            onClick={handleDeviationsClick}
          />
        </div>
        <div>
          {areChecklistsActive ? (
            <TasksChecklists
              assignedForms={checklists}
              label={"Checklist"}
              redirectToForm={redirectToTaskChecklistsPage}
              hasPermissionToEditTask={task ? task.canEditTask : false}
            />
          ) : (
            <TasksDeviations
              assignedForms={deviations}
              label={"Deviation"}
              redirectToForm={redirectToTaskDeviationsPage}
              hasPermissionToEditTask={task ? task.canEditTask : false}
            />
          )}
        </div>
      </div>
    </main>
  );
};

export default TaskPageMain;
