import GrayFiltrationBar from "components/common/GrayFiltrationBar/GrayFiltrationBar";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import React from "react";
import AddTimeEntryTaskFilterItem from "../TimeEntryFilterItem/AddTimeEntryTaskFilterItem";
import "./TimeEntryTasksPageMain.scss";

interface IMain {
  filtratedTasks: IMyTaskVm[];
  setChosenTask: React.Dispatch<React.SetStateAction<IMyTaskVm | null>>;
  chosenTask: IMyTaskVm | null;
  setFiltratedTask: React.Dispatch<React.SetStateAction<IMyTaskVm[]>>;
  allTasks: IMyTaskVm[];
}

const Main = ({
  filtratedTasks,
  chosenTask,
  setChosenTask,
  setFiltratedTask,
  allTasks,
}: IMain) => (
  <div className={"time-entry-tasks__page-main"}>
    <div className="time-entry-tasks__filter-wrapper">
      <GrayFiltrationBar
        originalArray={allTasks}
        setFiltratedItems={setFiltratedTask}
      />
    </div>
    <AddTimeEntryTaskFilterItem
      isHighlighted={chosenTask === null}
      task={null}
      setChosenTask={setChosenTask}
    />
    <>
      {filtratedTasks.map((task) => (
        <AddTimeEntryTaskFilterItem
          key={task.taskNumberInProject}
          isHighlighted={
            chosenTask?.taskNumberInProject === task.taskNumberInProject
          }
          task={task}
          setChosenTask={setChosenTask}
        />
      ))}
    </>
  </div>
);

export default Main;
