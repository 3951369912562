import { slashesDateFormat } from "constants/DateFormats";
import ProjectsStartEndTimeLineIcon from "public/ProjectsStartEndTimeLineIcon.svg";
import { IMyProjectVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectVm";
import { IMyOrganizationVm } from "interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationVm";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { selectedProject } from "store/Redux/Reducers/projectReducer";
import "./ProjectPageInformationHeader.scss";
import IdBadge from "one-common-components/lib/components/IdBadge/IdBadge";

interface IProjectPageInformationHeader {
  project: IMyProjectVm;
  myOrganization: IMyOrganizationVm;
}

const ProjectPageInformationHeader = ({
  project,
  myOrganization,
}: IProjectPageInformationHeader) => {
  const globalStates = useSelector(selectedProject);
  return (
    <header className={"project-page-information-header__main-heading"}>
      <div className={"project-page-information-header__main-info-container"}>
        <div
          className={
            "project-page-information-header__main-project-info-container"
          }
        >
          <span>{project.name}</span>
          <IdBadge id={project.id} />
        </div>
        <div
          className={"project-page-information-header__main-organization-info"}
        >
          {myOrganization.name}
        </div>
      </div>
      {globalStates.chosenProjectReducer.doesProjectContainTasks &&
        globalStates.chosenProjectReducer.startDateInferredFromTasks &&
        globalStates.chosenProjectReducer.endDateInferredFromTasks && (
          <div
            className={"project-page-information-header__main-project-dates"}
          >
            <img
              src={ProjectsStartEndTimeLineIcon}
              alt="Project time line"
              width={8}
              height={48}
            />
            <div
              className={
                "project-page-information-header__main-project-dates-wrapper"
              }
            >
              <div>
                {format(
                  new Date(
                    globalStates.chosenProjectReducer.startDateInferredFromTasks
                  ),
                  slashesDateFormat
                )}
              </div>
              <div>
                {format(
                  new Date(
                    globalStates.chosenProjectReducer.endDateInferredFromTasks
                  ),
                  slashesDateFormat
                )}
              </div>
            </div>
          </div>
        )}
    </header>
  );
};

export default ProjectPageInformationHeader;
