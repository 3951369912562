import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import type { RootState } from "../store";

export interface ISelectedProjectMemberReducerInitialState {
  user: IMyUserVm | null;
  managingUserRole: ProjectRolesEnum;
}

const initialState: ISelectedProjectMemberReducerInitialState = {
  user: null,
  managingUserRole: ProjectRolesEnum.Viewer,
};

export const selectedProjectMemberSlice = createSlice({
  name: "selectedProjectMember",
  initialState,
  reducers: {
    changeSelectedProjectMember: (
      state,
      action: PayloadAction<ISelectedProjectMemberReducerInitialState>
    ) => {
      state.user = action.payload.user;
      state.managingUserRole = action.payload.managingUserRole;
    },
    updateSelectedMemberRole: (
      state,
      action: PayloadAction<ProjectRolesEnum>
    ) => {
      if (!state.user) {
        return;
        //TODO validate invalid redux calls
      }
      state.user.projectRoleId = action.payload;
    },
  },
});

export const { changeSelectedProjectMember, updateSelectedMemberRole } =
  selectedProjectMemberSlice.actions;

export const selectedTask = (state: RootState) => state;

export default selectedProjectMemberSlice.reducer;
