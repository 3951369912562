import "./GrayBadgeWithBlackText.scss";

interface IGrayBadgeWithBlackText {
  text: number;
  marginBottom?: number;
}

const GrayBadgeWithBlackText = ({
  text,
  marginBottom = 0,
}: IGrayBadgeWithBlackText) => {
  return (
    <div
      className={"common-components__gray-badge--black-text"}
      style={{ marginBottom: marginBottom }}
    >
      <span>+&nbsp;{text}&nbsp;Days</span>
    </div>
  );
};

export default GrayBadgeWithBlackText;
