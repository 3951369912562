import "./SendToApprovalButton.scss";

interface ISendToApprovalButton {
  onApproval: () => void;
}

const SendToApprovalButton = ({ onApproval }: ISendToApprovalButton) => {
  return (
    <div
      className={"time-entries__send-to-approval-button"}
      onClick={() => onApproval()}
    >
      Send to approval
    </div>
  );
};
export default SendToApprovalButton;
