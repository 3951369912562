import CancelSaveBottomBar from "components/common/Footers/CancelSaveBottomBar/CancelSaveBottomBar";
import { useForm } from "react-hook-form";
import "./CreateDeviationPageMain.scss";
import Select from "react-select";
import { ILabelValueStructure } from "interfaces/Other/ILabelValueStructure";
import { dropdownStyles } from "styles/DropdownStyles";
import { useEffect, useMemo, useState } from "react";
import CalendarWithLabel from "one-common-components/lib/components/SingleInputsPack/CalendarWithLabel/CalendarWithLabel";
import BorderedTextAreaDescriptionComponent from "one-common-components/lib/components/SingleInputsPack/BorderedTextAreaDescriptionComponent/BorderedTextAreaDescriptionComponent";
import { convertToSelectOptions } from "helpers/General/ConvertToSelectOptions";
import {
  IMyUserVmMerged,
  mergeMembersCredentials,
} from "helpers/General/MergeMembersCredentials";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import { useAppSelector } from "store/Redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ToastModes } from "interfaces/Enums/ToastModes";
import SimpleToast from "components/common/Toasts/SimpleToast";
import { IAddDeviationRequest } from "interfaces/Requests/IAddDeviationRequest";
import AsyncToast from "components/common/Toasts/AsyncToast";
import { addMultipleFiles } from "helpers/Files/AddNewFileRequest";
import { FileModuleEnum } from "one-common-components/lib/enums/FileModuleEnum";
import useAuth0 from "Auth/use-auth0";
import { DeviationCategoryVm } from "interfaces/ViewModels/MyFormsViewModels/DeviationCategoryVm";
import { FormLinksSources } from "interfaces/Enums/FormLinksSources";
import { addDeviation } from "Pages/api/MyDeviationsClient";
import UploadedImagesButton from "one-common-components/lib/components/Files/Components/UploadSpecificComponents/UploadedImagesButton/UploadedImagesButton";
import UploadFileComponent from "one-common-components/lib/components/Files/Components/UploadSpecificComponents/UploadFileComponent/UploadFileComponent";
import { HasPreviewItem } from "one-common-components/lib/components/Files/Interfaces/HasPreviewItem";
import DropdownWithLabel from "one-common-components/lib/components/SingleInputsPack/DropdownWithLabel/DropdownWithLabel";
import InputWithLabel from "one-common-components/lib/components/SingleInputsPack/InputWithLabel/InputWithLabel";
import isNullOrWhitespace from "helpers/General/IsNullOrWhitespace";
import SeparationLine from "components/common/SeparationLine/SeparationLine";
import AddFileFromProjectDocuments from "components/common/AddFileFromProjectDocuments/AddFileFromProjectDocuments";
import { downloadFile } from "Pages/api/FileDownloadClient";
import { DeviationCauseVm } from "interfaces/ViewModels/MyFormsViewModels/DeviationCauseVm";

interface ICreateDeviationPageMain {
  setResponsibleUser: React.Dispatch<
    React.SetStateAction<ILabelValueStructure | undefined>
  >;
  responsibleUser: ILabelValueStructure | undefined;
  deviationsCategories: DeviationCategoryVm[];
  deviationsCauses: DeviationCauseVm[];
}

const CreateDeviationPageMain = ({
  setResponsibleUser,
  responsibleUser,
  deviationsCauses,
  deviationsCategories,
}: ICreateDeviationPageMain) => {
  const { user } = useAuth0();
  const globalStates = useAppSelector(chosenOrganization);
  const mergedNames: IMyUserVmMerged[] = mergeMembersCredentials(
    globalStates.chosenProjectReducer.projectMembers
  );
  const mappedProjectMembers = mergedNames.map((member) => {
    return { name: member.name, id: member.id };
  });
  const { projectId, taskId } = useParams();
  const navigate = useNavigate();
  const deviationsOptions = convertToSelectOptions(deviationsCategories);

  const teamOptions = convertToSelectOptions(mappedProjectMembers);
  const [deadline, setDeadline] = useState<Date | null>(new Date());
  const { register, getValues } = useForm();
  const [myFiles, setMyFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<HasPreviewItem[]>([]);
  const [selectedCategory, setSelectedCategory] =
    useState<ILabelValueStructure>();
  const [selectedCause, setSelectedCause] = useState<ILabelValueStructure>();

  const deviationsCausesOptions: ILabelValueStructure[] = useMemo(() => {
    return selectedCategory
      ? deviationsCauses
          .filter(
            (item) => item.deviationCategoriesId === selectedCategory.value
          )
          .map((item) => {
            return { label: item.name, value: item.id };
          })
      : [];
  }, [selectedCategory]);

  const handleSetDeadline = (date: Date | null) => {
    setDeadline(date);
  };

  const validateRequest = (request: IAddDeviationRequest) => {
    if (!request.categoryId) {
      SimpleToast({
        mode: ToastModes.error,
        message: "Deviation category cannot be empty",
      });
      return false;
    }
    if (isNullOrWhitespace(request.formName)) {
      SimpleToast({
        mode: ToastModes.error,
        message: "Deviation title cannot be empty",
      });
      return false;
    }
    if (!request.formDescription) {
      SimpleToast({
        mode: ToastModes.error,
        message: "Deviation description cannot be empty",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!projectId) {
      navigate("/error");
      return;
    }
    if (!responsibleUser) {
      SimpleToast({
        mode: ToastModes.error,
        message: "Deviation responsible cannot be empty",
      });
      return;
    }
    const deviationTypeId = 2;
    const request: IAddDeviationRequest = {
      categoryId: selectedCategory ? +selectedCategory.value : 0,
      organizationId:
        +globalStates.chosenOrganizationReducer.chosenOrganizationId,
      formId: deviationTypeId,
      responsibleUserId: +responsibleUser.value,
      usersIds: [],
      causeId: selectedCause ? +selectedCause.value : 0,
      deadline: deadline ? new Date(deadline) : null,
      sourceRefId: taskId ? taskId : `0`,
      source: taskId ? FormLinksSources.Task : undefined,
      formDescription: getValues("Description"),
      formName: getValues("Title"),
      projectId: +projectId,
      correctivesActions: "",
      immediateReaction: "",
    };
    if (!validateRequest(request)) {
      return;
    }
    const formLinkId = await addDeviation(request);
    if (!formLinkId) {
      SimpleToast({
        mode: ToastModes.error,
        message: "Assigning a template went wrong",
      });
      return;
    }
    SimpleToast({
      mode: ToastModes.success,
      message: "Deviation successfully submitted",
    });
    if (myFiles.length > 0) {
      await AsyncToast(
        addMultipleFiles(
          formLinkId,
          myFiles,
          FileModuleEnum.Deviation,
          globalStates.chosenOrganizationReducer.chosenOrganizationId,
          +projectId
        ),
        "Adding file completed"
      );
    }
    navigate(-1);
  };

  const handleAddItemToList = async (option: ILabelValueStructure) => {
    if (!projectId) {
      return SimpleToast({
        mode: ToastModes.error,
        message: "Project id was not found",
      });
    }
    downloadFile(option.value.toString()).then(async (fileBlob) => {
      const file = new File([fileBlob], option.label, { type: fileBlob.type });
      setMyFiles([...myFiles, file]);
      const preview: HasPreviewItem = {
        preview: URL.createObjectURL(file),
        name: option.label,
        size: file.size,
        lastModified: file.lastModified,
      };
      setPreviews((previews) => [...previews, preview]);
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!responsibleUser) {
      const responsible = teamOptions.find(
        (item) => item.label === user?.email
      );
      setResponsibleUser(responsible);
    }
  }, [teamOptions]);

  return (
    <>
      <div className="create-deviation-page__main">
        <div className="create-deviation-page__upper-part">
          <InputWithLabel
            label={"Title"}
            inputPlaceholder={"Enter form title"}
            register={register}
            marginBottom={16}
            required
          />
          <DropdownWithLabel
            label={"Category"}
            marginBottom={16}
            required
            SelectComponent={
              <Select
                styles={dropdownStyles}
                options={deviationsOptions}
                onChange={(option: ILabelValueStructure | null) => {
                  if (option) {
                    setSelectedCategory(option);
                    setSelectedCause(undefined);
                  }
                }}
                value={selectedCategory}
              />
            }
          />
          <DropdownWithLabel
            label={"Responsible"}
            marginBottom={16}
            required
            SelectComponent={
              <Select
                styles={dropdownStyles}
                options={teamOptions}
                onChange={(option: ILabelValueStructure | null) =>
                  option && setResponsibleUser(option)
                }
                value={responsibleUser}
              />
            }
          />
          <BorderedTextAreaDescriptionComponent
            register={register}
            label={"Description"}
            marginBottom={16}
            required
          />
          <DropdownWithLabel
            label={"Cause"}
            marginBottom={16}
            SelectComponent={
              <Select
                styles={dropdownStyles}
                options={deviationsCausesOptions}
                onChange={(option: ILabelValueStructure | null) =>
                  option && setSelectedCause(option)
                }
                value={selectedCause ? selectedCause : null}
              />
            }
          />
          <CalendarWithLabel
            date={deadline}
            handleSetDate={handleSetDeadline}
            label={"Deadline"}
            marginBottom={16}
            marginTop={16}
          />
        </div>
        <div className="create-deviation-page__files-label">Files</div>
        <SeparationLine marginBottom={16} marginTop={16} />
        <div className="create-deviation-page__project-files-wrapper">
          <AddFileFromProjectDocuments
            handleAddItemToList={handleAddItemToList}
          />
        </div>
        <UploadFileComponent
          myFiles={myFiles}
          setMyFiles={setMyFiles}
          setPreviews={setPreviews}
          previews={previews}
          serachButton={
            <UploadedImagesButton
              setPreviews={setPreviews}
              previews={previews}
              setMyFiles={setMyFiles}
              myFiles={myFiles}
            />
          }
        />
      </div>
      <CancelSaveBottomBar
        onCancelClick={handleCancel}
        onSaveClick={handleSubmit}
        saveButtonName={"Submit"}
      />
    </>
  );
};

export default CreateDeviationPageMain;
