import "./HeaderAndCustomEndElement.scss";
import Logo from "public/OneLogo.svg";
import GrayLeftArrow from "public/LeftGrayArrowIcon.svg";
import { useNavigate } from "react-router-dom";
import {
  horizontalArrowCommonWidth,
  horizontalBigArrowCommonHeight,
} from "constants/Consts";

interface IHeaderAndCustomEndElement {
  isProjectPage?: boolean;
  endElement: JSX.Element;
  returnUrl: string;
}

const HeaderAndCustomEndElement = ({
  isProjectPage,
  endElement,
  returnUrl,
}: IHeaderAndCustomEndElement) => {
  const navigate = useNavigate();
  return (
    <div className={"common-components__header-and-custom-end-element"}>
      <div className={"common-components__header-and-custom-end-element-logo"}>
        <div
          className={
            "common-components__header-and-custom-end-element-logo-wrapper"
          }
          onClick={() => {
            navigate(returnUrl);
          }}
        >
          {isProjectPage ? (
            <img src={Logo} alt="Logo" width={40} height={40} />
          ) : (
            <img
              src={GrayLeftArrow}
              alt="Gray left arrow"
              width={horizontalArrowCommonWidth}
              height={horizontalBigArrowCommonHeight}
            />
          )}
        </div>
      </div>
      {endElement}
    </div>
  );
};

export default HeaderAndCustomEndElement;
