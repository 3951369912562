import {
  maxFileSizeInBytes,
  acceptedFileExtensions,
} from "one-common-components/lib/constants/Files";
import SmallGreenPlusIcon from "public/SmallGreenPlusIcon.svg";
import { useDropzone } from "react-dropzone";
import "./AddFileButton.scss";

interface IAddFileButton {
  onDrop: (acceptedFiles: unknown) => Promise<string | void>;
  marginTop?: string;
  marginBottom?: string;
}

const AddFileButton = ({
  onDrop,
  marginTop = "24px",
  marginBottom = "16px",
}: IAddFileButton) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: maxFileSizeInBytes,
    accept: acceptedFileExtensions,
    multiple: false,
  });

  return (
    <div
      className="add-file-button__main-add-button"
      {...getRootProps()}
      style={{ marginTop: marginTop, marginBottom: marginBottom }}
    >
      <input {...getInputProps()} />
      <img src={SmallGreenPlusIcon} alt="Green plus icon" />
      Add file
    </div>
  );
};

export default AddFileButton;
