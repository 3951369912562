const StringIsNumber = (value: string) => isNaN(Number(value)) === false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapEnumToArray = (enumObject: any) => {
  return Object.keys(enumObject)
    .filter(StringIsNumber)
    .map((key) => enumObject[key]);
};

export default mapEnumToArray;
