import { grayBackground, grayText, redAccent } from "constants/JsColors";
import "./UnderlinedNavigation.scss";

interface IUnderlinedNavigation {
  isActive: boolean;
  marginTop?: number;
  marginBottom?: number;
  label: string;
  onClick: () => void;
}

const UnderlinedNavigation = ({
  isActive,
  marginTop,
  marginBottom,
  label,
  onClick,
}: IUnderlinedNavigation) => {
  return (
    <div
      onClick={onClick}
      className="common-components__wrapper"
      style={{ color: isActive ? redAccent : grayText }}
    >
      {label}
      <div
        className="common-components__underline"
        style={{
          background: isActive ? redAccent : grayBackground,
          marginTop: marginTop,
          marginBottom: marginBottom,
        }}
      />
    </div>
  );
};

export default UnderlinedNavigation;
