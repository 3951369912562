import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMyProfileVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProfileVm";
import type { RootState } from "../store";

export interface IUserProfileState {
  userProfile: IMyProfileVm | null;
}

const initialState: IUserProfileState = {
  userProfile: null,
};

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setUserProfileState: (state, action: PayloadAction<IMyProfileVm>) => {
      state.userProfile = action.payload;
    },
    resetInitialValue: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
  },
});

export const { setUserProfileState } = userProfileSlice.actions;

export const userProfile = (state: RootState) => state;

export default userProfileSlice.reducer;
