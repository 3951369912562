import HeaderPageTitleAndReturn from "components/common/Headers/HeaderPageTitleAndReturn/HeaderPageTitleAndReturn";
import NewProjectPageMain from "components/ProjectsComponents/ProjectPageComponents/Pages/NewProjectPageComponents/NewProjectPageMain/NewProjectPageMain";
import { checkOrganizationAccess } from "helpers/Permission/CheckOrganizationAccess";
import { OrganizationRolesEnum } from "interfaces/Enums/OrganizationRolesEnum";
import NoAccessPage from "Pages/NoAccessPage/NoAccessPage";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { chosenOrganization } from "store/Redux/Reducers/chosenOrganizationReducer";
import "./NewProjectPage.scss";

const NewProjectPage = () => {
  const navigate = useNavigate();
  const globalStates = useSelector(chosenOrganization);

  const returnToPreviousPage = () => {
    navigate(-1);
  };

  if (!globalStates.userProfileReducer.userProfile) {
    return <div />;
  }
  const hasAccess = checkOrganizationAccess(
    globalStates.userProfileReducer.userProfile.organizationRole,
    OrganizationRolesEnum.Manager
  );
  if (!hasAccess) {
    return <NoAccessPage />;
  }
  return (
    <div className="new-project-page__layout">
      <HeaderPageTitleAndReturn
        returnToPreviousPage={returnToPreviousPage}
        text={"Create new project"}
      />
      <NewProjectPageMain />
    </div>
  );
};

export default NewProjectPage;
