import { AxiosClient } from "./AxiosClient";
import application from "../../config.json";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import { HttpRequestsMethods } from "interfaces/Enums/HttpRequestsMethods";

const base = application.baseUrl;
const MyUserOrganizations = "MyUserOrganizations";

const getOrganizationUsers = async (
  organizationId: number
): Promise<IMyUserVm[]> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyUserOrganizations}/${organizationId}/Users`,
    base
  );
};

const getOrganizationUsersWithoutSpecificProject = async (
  organizationId: number,
  projectId: number
): Promise<IMyUserVm[]> => {
  return AxiosClient(
    HttpRequestsMethods.GET,
    `${MyUserOrganizations}/${organizationId}/Users/NotIn/Project/${projectId}`,
    base
  );
};

export { getOrganizationUsers, getOrganizationUsersWithoutSpecificProject };
