import CancelSaveBottomBar from "components/common/Footers/CancelSaveBottomBar/CancelSaveBottomBar";
import OnlyRightButtonFooter from "components/common/Footers/OnlyRightButtonFooter/OnlyRightButtonFooter";
import HeaderPageTitleAndReturn from "components/common/Headers/HeaderPageTitleAndReturn/HeaderPageTitleAndReturn";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/Redux/hooks";
import {
  changeSelectedChecklist,
  revertChangesInChecklist,
  selectedForm,
  updateChecklistStatus,
} from "store/Redux/Reducers/selectedChecklistsReducer";
import "./ChecklistsAnswersPage.scss";
import ChecklistsAnswersHeader from "components/ProjectsComponents/ProjectPageComponents/Pages/ChecklistsPageComponents/Pages/ChecklistsAnswersPageComponents/ChecklistsAnswersHeader/ChecklistsAnswersHeader";
import { updateChecklistsAnswers } from "Pages/api/MyFormsAnswersClient";
import { IUpdateFormAnswers } from "interfaces/Requests/IUpdateFormAnswers";
import { ToastModes } from "interfaces/Enums/ToastModes";
import SimpleToast from "components/common/Toasts/SimpleToast";
import DifferentiateFormsAnswers from "components/common/DifferentiateFormsAnswers/DifferentiateFormsAnswers";
import { differentiateEditAmongTypes } from "helpers/FormsHelpers/DifferentiateEditAmongTypes";
import { differentiateAnswersAmongTypes } from "helpers/FormsHelpers/DifferentiateAnswersAmongTypes";
import FormStatusBar from "components/common/FormStatusBar/FormStatusBar";
import { FormStatuses } from "interfaces/Enums/FormsStatuses";
import SubsectionsGrayBarSeparator from "one-common-components/lib/components/SubsectionsGrayBarSeparator/SubsectionsGrayBarSeparator";
import AsyncToast from "components/common/Toasts/AsyncToast";
import { changeChecklistStatus } from "Pages/api/MyChecklistsClient";

const ChecklistsAnswersPage = () => {
  const { checklistId, projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const globalStates = useAppSelector(selectedForm);
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const chosenChecklist =
    globalStates.selectedChecklistsReducer.checklists.find(
      (item) => item.id?.toString() === checklistId?.toString()
    );
  const userId = globalStates.userProfileReducer.userProfile?.id;

  const returnToPreviousPage = () => {
    saveInProgressChanges();
    navigate(-2);
  };

  const setComponentToEditMode = () => {
    setIsInEditMode(true);
  };

  const saveAnswers = async () => {
    if (chosenChecklist && projectId) {
      const request: IUpdateFormAnswers = {
        formAnswerId: chosenChecklist?.formAnswer.id,
        projectId: +projectId,
        updatedAnswers: JSON.stringify(
          chosenChecklist?.formAnswer.answers.length > 0
            ? chosenChecklist?.formAnswer.answers
            : chosenChecklist?.formAnswer.groupedAnswers
        ),
      };
      const updatedState: "NEW" | "COMPLETED" | "IN PROGRESS" | "VOID" =
        "COMPLETED";
      const updatedChosenChecklist = {
        ...chosenChecklist,
        formAnswer: {
          ...chosenChecklist.formAnswer,
          status: updatedState,
        },
      };
      await updateChecklistsAnswers(request);
      await AsyncToast(
        changeChecklistStatus({
          status: "COMPLETED",
          projectId: projectId ? +projectId : 0,
          formLinkId: chosenChecklist.id,
        }),
        "Changed status successfully"
      );
      dispatch(
        updateChecklistStatus({
          formLinkId: chosenChecklist.id,
          status: "COMPLETED",
        })
      );
      dispatch(changeSelectedChecklist(updatedChosenChecklist));
      setIsInEditMode(false);
    } else {
      SimpleToast({ mode: ToastModes.error, message: "Form was not set." });
    }
  };

  const cancelSaving = () => {
    const previousForm =
      globalStates.selectedChecklistsReducer.originalChecklists.find(
        (item) => item.id?.toString() === checklistId?.toString()
      );
    if (!previousForm) {
      SimpleToast({
        mode: ToastModes.error,
        message: "There is no original form found",
      });
      return;
    }
    setIsInEditMode(false);
    dispatch(revertChangesInChecklist(previousForm));
    SimpleToast({ mode: ToastModes.info, message: "Removed changes in form" });
  };

  const saveInProgressChanges = async () => {
    if (chosenChecklist && projectId) {
      const request: IUpdateFormAnswers = {
        formAnswerId: chosenChecklist?.formAnswer.id,
        projectId: +projectId,
        updatedAnswers: JSON.stringify(
          chosenChecklist?.formAnswer.answers.length > 0
            ? chosenChecklist?.formAnswer.answers
            : chosenChecklist?.formAnswer.groupedAnswers
        ),
      };
      await updateChecklistsAnswers(request);
    }
  };

  if (!chosenChecklist || !userId) {
    return <div />;
  } else {
    return (
      <div className="checklists-answers-page__main-layout">
        <HeaderPageTitleAndReturn
          returnToPreviousPage={returnToPreviousPage}
          text={"Checklist Preview"}
        />
        <main className="checklists-answers-page-main__layout">
          <FormStatusBar
            status={FormStatuses[chosenChecklist.formAnswer.status]}
          />
          <ChecklistsAnswersHeader checklist={chosenChecklist} />
          <SubsectionsGrayBarSeparator text={"Items"} />
          {isInEditMode ? (
            <>
              <DifferentiateFormsAnswers
                checklist={chosenChecklist}
                userId={userId}
                differentiateFunc={differentiateEditAmongTypes}
                isInEditMode={isInEditMode}
              />
              <CancelSaveBottomBar
                onCancelClick={cancelSaving}
                onSaveClick={saveAnswers}
                saveButtonName={"Mark as completed"}
              />
            </>
          ) : (
            <>
              <DifferentiateFormsAnswers
                checklist={chosenChecklist}
                userId={userId}
                differentiateFunc={differentiateAnswersAmongTypes}
              />
              {chosenChecklist.canEditChecklist && (
                <OnlyRightButtonFooter
                  buttonText={"Edit"}
                  onClick={setComponentToEditMode}
                />
              )}
            </>
          )}
        </main>
      </div>
    );
  }
};

export default ChecklistsAnswersPage;
