import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMyOrganizationVm } from "interfaces/ViewModels/MyOrganizationsViewModels/IMyOrganizationVm";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import type { RootState } from "../store";

export interface IChosenOrganizationSlice {
  chosenOrganizationId: number;
  myOrganization: IMyOrganizationVm | null;
  organizationMembers: IMyUserVm[];
}

const initialState: IChosenOrganizationSlice = {
  chosenOrganizationId: 0,
  myOrganization: null,
  organizationMembers: [],
};

export const chosenOrganizationSlice = createSlice({
  name: "allUsersOrganizations",
  initialState,
  reducers: {
    setChosenOrganizationIdState: (state, action: PayloadAction<number>) => {
      state.chosenOrganizationId = action.payload;
    },
    setChosenOrganizationState: (
      state,
      action: PayloadAction<IMyOrganizationVm | null>
    ) => {
      state.myOrganization = action.payload;
    },
    setOrganizationMembers: (state, action: PayloadAction<IMyUserVm[]>) => {
      state.organizationMembers = action.payload;
    },
  },
});

export const {
  setChosenOrganizationIdState,
  setChosenOrganizationState,
  setOrganizationMembers,
} = chosenOrganizationSlice.actions;

export const chosenOrganization = (state: RootState) => state;

export default chosenOrganizationSlice.reducer;
