import Checkbox from "components/common/Checkbox/Checkbox";
import SeparationLine from "components/common/SeparationLine/SeparationLine";
import { IMyUserVmMerged } from "helpers/General/MergeMembersCredentials";
import { IMyUserVm } from "interfaces/ViewModels/MyUserViewModels/IMyUserVm";
import React, { useState, useEffect } from "react";
import { selectedProject } from "store/Redux/Reducers/projectReducer";
import { useAppSelector } from "store/Redux/hooks";
import "./ListOfUsersItem.scss";
import UserAvatar from "one-common-components/lib/components/UserAvatar/UserAvatar";

interface IListOfUsersItem {
  user: IMyUserVmMerged;
  setChosenUsers: React.Dispatch<React.SetStateAction<IMyUserVm[]>>;
  chosenUsers: IMyUserVm[];
}

const ListOfUsersItem = ({
  user,
  setChosenUsers,
  chosenUsers,
}: IListOfUsersItem) => {
  const [active, setIsActive] = useState<boolean>(
    chosenUsers.some((item) => item.id === user.id)
  );
  const globalStates = useAppSelector(selectedProject);
  const chosenUser =
    globalStates.chosenOrganizationReducer.organizationMembers.find(
      (item) => item.id === user.id
    );
  const addOrRemoveUserToList = () => {
    if (chosenUsers.some((item) => item.id === user.id) && active === false) {
      const foundIndex = chosenUsers.findIndex((item) => item.id === user.id);
      const copiedArray = [...chosenUsers];
      copiedArray.splice(foundIndex, 1);
      setChosenUsers([...copiedArray]);
    }
    if (
      chosenUser &&
      !chosenUsers.some((item) => item.id === user.id) &&
      active === true
    ) {
      setChosenUsers([...chosenUsers, chosenUser]);
    }
  };

  useEffect(() => {
    addOrRemoveUserToList();
  }, [active]);

  return (
    <React.Fragment>
      <div className={"user-filter-item__layout"}>
        <div className={"user-filter-item__layout-left"}>
          <div className={"user-filter-item__layout-left-image"}>
            <UserAvatar displayName={user.name} size={32} />
          </div>
          <span className={"user-filter-item__layout-left-task-name"}>
            {user.name}
          </span>
        </div>
        <div>
          <Checkbox setIsActive={setIsActive} checked={active} />
        </div>
      </div>
      <SeparationLine />
    </React.Fragment>
  );
};

export default ListOfUsersItem;
