import SeparationLine from "components/common/SeparationLine/SeparationLine";
import { IMyProjectTasksVm } from "interfaces/ViewModels/MyProjectsViewModels/IMyProjectTasksVm";
import { useAppSelector } from "store/Redux/hooks";
import { selectedProject } from "store/Redux/Reducers/projectReducer";
import "./ProjectTeamMemberPageMain.scss";
import FiltrationBar from "components/common/FiltrationBar/FiltrationBar";
import { useState } from "react";
import { IMyTaskVm } from "interfaces/ViewModels/MyTasksViewModels/IMyTaskVm";
import { useNavigate, useParams } from "react-router-dom";
import ShowAllTasksComponent from "components/common/ShowAllTasksComponent/ShowAllTasksComponent";
import MemberRoleDropdownContainer from "./MemberRoleDropdownContainer/MemberRoleDropdownContainer";
import MemberRoleDropdownContent from "./MemberRoleDropdownContent/MemberRoleDropdownContent";
import { returnUsersCredentials } from "helpers/General/ReturnUserCredentials";
import BigGrayCheckedIcon from "public/BigGrayCheckedIcon.svg";
import {
  memberRoute,
  newTaskRoute,
  projectsRoute,
  tasksRoute,
  teamRoute,
} from "constants/Routes";
import SmallWhitePlusIcon from "public/SmallWhitePlusIcon.svg";
import DropdownWrapper from "one-common-components/lib/components/DropdownWrapper/DropdownWrapper";
import UserAvatar from "one-common-components/lib/components/UserAvatar/UserAvatar";
import { ProjectRolesEnum } from "interfaces/Enums/ProjectRolesEnum";

interface IProjectTeamMemberPageMain {
  memberTasks: IMyProjectTasksVm;
}

const ProjectTeamMemberPageMain = ({
  memberTasks,
}: IProjectTeamMemberPageMain) => {
  const allTasks: IMyTaskVm[] = [
    ...memberTasks.completedTasks,
    ...memberTasks.inProgressTasks,
    ...memberTasks.toDoTasks,
  ];
  const { projectId } = useParams();
  const [filtrationInputValue, setFiltrationInputValue] = useState<string>("");
  const [filtratedTasks, setFiltratedTasks] = useState<IMyTaskVm[]>(allTasks);
  const globalStates = useAppSelector(selectedProject);
  const member = globalStates.selectedProjectMemberReducer.user;
  const [isMemberRoleDropdownOpen, setIsMemberRoleDropdownOpen] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const openMemberRolesDropdown = () => {
    setIsMemberRoleDropdownOpen(true);
  };

  const navigateToAssignTask = () => {
    navigate(
      `/${projectsRoute}/${projectId}/${teamRoute}/${memberRoute}/${member?.id}/${tasksRoute}`
    );
  };

  const navigateToCreateTask = () => {
    navigate(`/${projectsRoute}/${projectId}/${newTaskRoute}`);
  };

  if (!projectId || !member || !globalStates.userProfileReducer.userProfile) {
    return <div />;
  }
  return (
    <main className="project-team-page-member-main__layout">
      <div className="project-team-page-member-main__custom-padding">
        <span className="project-team-page-member-main__title">
          Team member
        </span>
        <div className="project-team-page-member__user-credentials">
          <UserAvatar
            displayName={returnUsersCredentials(
              member.firstName,
              member.lastName,
              member.email
            )}
            size={32}
          />
          <span className="project-team-page__member-name">
            {returnUsersCredentials(
              member.firstName,
              member.lastName,
              member.email
            )}
          </span>
        </div>
        <SeparationLine marginTop={24} />
        <div className="project-team-page__dropdown-max-space">
          <DropdownWrapper
            isOpen={isMemberRoleDropdownOpen}
            setIsOpen={setIsMemberRoleDropdownOpen}
            dropdownContent={
              <MemberRoleDropdownContent
                memberId={member?.id}
                setIsMemberRoleDropdownOpen={setIsMemberRoleDropdownOpen}
              />
            }
            dropdownContainer={
              <MemberRoleDropdownContainer
                role={ProjectRolesEnum[member?.projectRoleId]}
                shouldShowArrow={member.canHaveRoleChanged}
                onContainerClick={() => {
                  if (member.canHaveRoleChanged) {
                    openMemberRolesDropdown();
                  }
                }}
              />
            }
          />
        </div>
        <SeparationLine marginBottom={24} />
        {allTasks.length > 0 && (
          <>
            {globalStates.chosenProjectReducer.canEditProjectMembers && (
              <div className="project-team-page-member-main__assign-task-buttons">
                <div
                  className="project-team-page-member-main__create-task-button"
                  onClick={navigateToCreateTask}
                >
                  <img
                    className="project-team-page-member-main__create-task-icon"
                    src={SmallWhitePlusIcon}
                    alt={"Small plus icon"}
                  />
                  create task
                </div>
                <div
                  onClick={navigateToAssignTask}
                  className="project-team-page-member-main__assign-task-button"
                >
                  Assign task
                </div>
              </div>
            )}
          </>
        )}
        {(globalStates.chosenProjectReducer.canEditProjectMembers ||
          allTasks.length > 0) && (
          <span className="project-team-page-member-main__title">
            USER’S TASKS
          </span>
        )}
        {allTasks.length > 0 && (
          <FiltrationBar
            items={allTasks}
            setFiltratedItems={setFiltratedTasks}
            filtrationInputValue={filtrationInputValue}
            setFiltrationInputValue={setFiltrationInputValue}
            filtrationMenu={null}
          />
        )}
      </div>
      {allTasks.length > 0 ? (
        <div className="project-team-page__tasks-lists-wrapper">
          <ShowAllTasksComponent tasks={filtratedTasks} />
        </div>
      ) : (
        <>
          {globalStates.chosenProjectReducer.canEditProjectMembers && (
            <div className="project-team-page__no-tasks-container">
              <img
                src={BigGrayCheckedIcon}
                alt={"Big gray checked icon"}
                className={"project-team-page__no-tasks-icon"}
              />
              <span className="project-team-page__no-tasks-title">
                No tasks assigned to the user
              </span>
              <div
                onClick={navigateToAssignTask}
                className="project-team-page__assign-or-create-task--button"
              >
                assign task
              </div>
              <div
                onClick={navigateToCreateTask}
                className="project-team-page__assign-or-create-task--button"
              >
                create task
              </div>
            </div>
          )}
        </>
      )}
    </main>
  );
};

export default ProjectTeamMemberPageMain;
